import { AxiosPromise } from "axios";
import { ApiRequestAdapter } from "../../common/api/ApiRequestAdapter";
import { apiService } from "../../common/api/apiService";
import { EntityIdObject, EntityObject } from "../../common/types";
import { serializeParams } from "../../common/utils/apiUtils";
import { Complicity, ComplicityFilterPageRequest, ComplicityFilterPageResult, CreateUpdateComplicity } from "./types";

const FILTER_COMPLICITIES = ApiRequestAdapter.create("/complicities", "GET");
const CREATE_COMPLICITY = ApiRequestAdapter.create("/complicities", "POST");
const UPDATE_COMPLICITY = ApiRequestAdapter.create("/complicities/{0}", "PUT");
const DELETE_COMPLICITY = ApiRequestAdapter.create("/complicities/{0}", "DELETE");

export const requests = {
  FILTER_COMPLICITIES,
  CREATE_COMPLICITY,
  UPDATE_COMPLICITY,
  DELETE_COMPLICITY
};

const api = {
  filterComplicities: (request: ComplicityFilterPageRequest): AxiosPromise<ComplicityFilterPageResult> => {
    return apiService.get(FILTER_COMPLICITIES.url, {
      params: request,
      paramsSerializer: { serialize: serializeParams }
    });
  },
  createComplicity: (request: CreateUpdateComplicity): AxiosPromise<Complicity> => {
    return apiService.post(CREATE_COMPLICITY.url, request);
  },
  updateComplicity: (request: EntityObject<CreateUpdateComplicity>): AxiosPromise<Complicity> => {
    return apiService.put(UPDATE_COMPLICITY.getParametrizedUrl(request.id), request.object);
  },
  deleteComplicity: (request: EntityIdObject): AxiosPromise => {
    return apiService.delete(DELETE_COMPLICITY.getParametrizedUrl(request.id));
  }
};

export default api;
