import { Divider } from "antd";
import { FormInstance } from "antd/lib/form";
import t from "../../../../../app/i18n";
import { SecondaryButton } from "../../../../../common/components/buttons/SecondaryButton";
import { GenGainersData } from "../../types";
import { GainersCommissionRateDivisionForm } from "./GainersCommissionRateDivisionForm";

type Props = {
  divideCommission: boolean;
  setDivideCommission: (value: boolean) => void;
  gainersDataForm: FormInstance<GenGainersData>;
  allowOtherOption?: boolean;
};

export const GainersCommissionRateSection = ({
  divideCommission,
  setDivideCommission,
  gainersDataForm,
  allowOtherOption
}: Props) => {
  return (
    <>
      <Divider orientation="left">{t("calc.summary.sections.commissionRateDivision")}</Divider>
      <div>
        <SecondaryButton hidden={divideCommission} onClick={() => setDivideCommission(true)}>
          {t("calc.summary.actions.commissionRateDivide")}
        </SecondaryButton>

        {divideCommission ? (
          <GainersCommissionRateDivisionForm form={gainersDataForm} allowOtherOption={allowOtherOption} />
        ) : undefined}
      </div>
    </>
  );
};
