import { Col, Form, Input } from "antd";
import { FormItemProps } from "antd/lib/form";
import debounce from "lodash/debounce";
import { useCallback, useMemo } from "react";
import t from "../../../../app/i18n";
import { FieldConstraintViolation } from "../../../../common/types";
import { ClientFormStage, ClientFormType, ClientSearchActionType } from "../../enums";
import { Client } from "../../types";
import ClientTypeTagWithName from "../tags/ClientTypeTagWithName";
import ClientActionsFormLabel from "./ClientActionsFormLabel";

export interface ClientSearchInputProps<Type = ClientFormType> {
  formItemProps: FormItemProps;
  formStage?: ClientFormStage;
  formType: Type;
  processedType?: Type;
  client?: Client;
  violationErrors: Map<Type, FieldConstraintViolation[]>;
  inProgress: boolean;
  disabled?: boolean;
  optional?: boolean;
  inputColSpan?: number;
  clientNameColSpan?: number;
  onActionClick: (formType: Type, actionType: ClientSearchActionType) => void;
  onFocus?: (formType: Type) => void;
  onSearch: (value: string, formType: Type) => void;
  onChange: (value: string, formType: Type) => void;
}

const DEFAULT_COL_SPAN = 8;

const ClientSearchInput = <Type,>({ formType, ...props }: ClientSearchInputProps<Type>) => {
  let errorStatus: Record<"help" | "validateStatus", any> = { help: undefined, validateStatus: undefined };
  if (props.violationErrors.has(formType)) {
    errorStatus = { help: t("client.helpers.search.clientsDataError"), validateStatus: "error" };
  } else if (props.formStage === ClientFormStage.NEW) {
    errorStatus = { help: t("client.helpers.search.clientsDataMissingError"), validateStatus: "warning" };
  }

  const handleChangeClientSearchDebounced = useMemo(() => debounce(props.onChange, 1000), []);

  const handleChangeClientSearch = useCallback(handleChangeClientSearchDebounced, [handleChangeClientSearchDebounced]);

  return (
    <>
      <Col span={props.inputColSpan || DEFAULT_COL_SPAN}>
        <Form.Item
          {...errorStatus}
          name={props.formItemProps.name}
          required={false}
          rules={props.formItemProps.rules}
          initialValue={props.formItemProps.initialValue}
          label={
            <ClientActionsFormLabel
              label={props.formItemProps.label}
              stage={props.formStage}
              optional={props.optional}
              onCreateClick={() => props.onActionClick(formType, ClientSearchActionType.CREATE)}
              onUpdateClick={() => props.onActionClick(formType, ClientSearchActionType.UPDATE)}
              onDeleteClick={
                !props.disabled ? () => props.onActionClick(formType, ClientSearchActionType.DELETE) : undefined
              }
            />
          }
        >
          <Input.Search
            disabled={props.disabled || props.formStage === ClientFormStage.SELECTED}
            loading={props.inProgress && props.processedType === formType}
            onFocus={() => props.onFocus?.(formType)}
            onSearch={value => props.onSearch(value, formType)}
            onChange={event => handleChangeClientSearch(event.target.value, formType)}
          />
        </Form.Item>
      </Col>
      <Col
        span={props.clientNameColSpan || DEFAULT_COL_SPAN}
        style={{ height: 32, display: "flex", alignItems: "center" }}
        className="form-item-without-label"
      >
        <ClientTypeTagWithName client={props.client} />
      </Col>
    </>
  );
};

export default ClientSearchInput;
