import { combineReducers } from "redux";
import { ActionType, createAction, createAsyncAction, createReducer } from "typesafe-actions";
import { RootState } from "../../../../common/types";
import { CalcResult, GenResponse, Offer } from "../types";
import { TravelCalc, TravelCalcDraft, TravelCalcReducerState, TravelCalcResultData, TravelGen } from "./types";

/**
 * ACTIONS
 */
export const calculateTravelActions = createAsyncAction(
  "calc-travel/CALCULATE_REQUEST",
  "calc-travel/CALCULATE_SUCCESS",
  "calc-travel/CALCULATE_FAILURE"
)<TravelCalc, CalcResult<TravelCalcResultData>[], void>();

export const generateTravelActions = createAsyncAction(
  "calc-travel/GENERATE_REQUEST",
  "calc-travel/GENERATE_SUCCESS",
  "calc-travel/GENERATE_FAILURE"
)<TravelGen, GenResponse, void>();

export const generateTravelOfferActions = createAsyncAction(
  "calc-travel/GENERATE_OFFER_REQUEST",
  "calc-travel/GENERATE_OFFER_SUCCESS",
  "calc-travel/GENERATE_OFFER_FAILURE"
)<Offer<TravelCalc, TravelCalcResultData>, void, void>();

export const sendTravelOfferActions = createAsyncAction(
  "calc-travel/SEND_OFFER_REQUEST",
  "calc-travel/SEND_OFFER_SUCCESS",
  "calc-travel/SEND_OFFER_FAILURE"
)<Offer<TravelCalc, TravelCalcResultData>, void, void>();

export const setTravelCalcResultsAction =
  createAction("calc-travel/SET_CALC_RESULTS")<CalcResult<TravelCalcResultData>[]>();
export const setTravelDraftAction = createAction("calc-travel/SET_DRAFT")<TravelCalcDraft>();
export const setTravelInitialCalcDataAction = createAction("calc-travel/SET_INITIAL_CALC_DATA")<TravelCalc>();
export const setTravelInitialGenDataAction = createAction("calc-travel/SET_INITIAL_GEN_DATA")<TravelGen>();

export const deleteStateTravelCalcResultsAction = createAction("calc-travel/DELETE_STATE_CALC_RESULTS")<void>();
export const deleteStateTravelGenResultAction = createAction("calc-travel/DELETE_STATE_GEN_RESULT")<void>();
export const deleteStateTravelDraftAction = createAction("calc-travel/DELETE_STATE_DRAFT")<void>();
export const deleteStateTravelInitialCalcGenDataAction = createAction(
  "calc-travel/DELETE_STATE_INITIAL_CALC_GEN_DATA"
)<void>();

const actions = {
  calculateTravelActions,
  generateTravelActions,
  generateTravelOfferActions,
  sendTravelOfferActions,
  setTravelCalcResultsAction,
  setTravelDraftAction,
  setTravelInitialCalcDataAction,
  setTravelInitialGenDataAction,
  deleteStateTravelCalcResultsAction,
  deleteStateTravelGenResultAction,
  deleteStateTravelDraftAction,
  deleteStateTravelInitialCalcGenDataAction
};

export type TravelCalcAction = ActionType<typeof actions>;

/**
 * REDUCERS
 */
const initialState: TravelCalcReducerState = {
  calcResults: [],
  genResult: null,
  draft: null,
  initialCalcData: null,
  initialGenData: null
};

const calcResultsReducer = createReducer(initialState.calcResults)
  .handleAction([calculateTravelActions.success, setTravelCalcResultsAction], (_, { payload }) => payload)
  .handleAction([calculateTravelActions.failure, deleteStateTravelCalcResultsAction], () => initialState.calcResults);

const genResultReducer = createReducer(initialState.genResult)
  .handleAction(generateTravelActions.success, (_, { payload }) => payload)
  .handleAction([generateTravelActions.failure, deleteStateTravelGenResultAction], () => initialState.genResult);

const draftReducer = createReducer(initialState.draft)
  .handleAction(setTravelDraftAction, (_, { payload }) => payload)
  .handleAction(deleteStateTravelDraftAction, () => initialState.draft);

const initialCalcDataReducer = createReducer(initialState.initialCalcData)
  .handleAction(setTravelInitialCalcDataAction, (_, { payload }) => payload)
  .handleAction(deleteStateTravelInitialCalcGenDataAction, () => initialState.initialCalcData);

const initialGenDataReducer = createReducer(initialState.initialGenData)
  .handleAction(setTravelInitialGenDataAction, (_, { payload }) => payload)
  .handleAction(deleteStateTravelInitialCalcGenDataAction, () => initialState.initialGenData);

export const travelCalcReducer = combineReducers<TravelCalcReducerState>({
  calcResults: calcResultsReducer,
  genResult: genResultReducer,
  draft: draftReducer,
  initialCalcData: initialCalcDataReducer,
  initialGenData: initialGenDataReducer
});

/**
 * SELECTORS
 */
const selectTravelCalc = (state: RootState): TravelCalcReducerState => state.calculator.calcs.travel;

export const selectTravelCalcResults = (state: RootState): CalcResult<TravelCalcResultData>[] =>
  selectTravelCalc(state).calcResults;
export const selectTravelGenResult = (state: RootState): GenResponse | undefined =>
  selectTravelCalc(state).genResult ?? undefined;
export const selectTravelDraft = (state: RootState): TravelCalcDraft | undefined =>
  selectTravelCalc(state).draft ?? undefined;
export const selectTravelInitialCalcData = (state: RootState): TravelCalc | undefined =>
  selectTravelCalc(state).initialCalcData ?? undefined;
export const selectTravelInitialGenData = (state: RootState): TravelGen | undefined =>
  selectTravelCalc(state).initialGenData ?? undefined;
