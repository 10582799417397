import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import api from "../../../modules/agent/api";
import { AgentMentoringFilterPageRequest, CreateUpdateAgentMentoring } from "../../../modules/agent/types";
import { UUID } from "../../../typings/global";
import { EntityObject } from "../../types";
import messageUtils from "../../utils/messageUtils";

export const AGENT_MENTORINGS_QUERY_KEYS = {
  filterAgentMentorings: (filter?: AgentMentoringFilterPageRequest) =>
    filter ? ["getAgentMentorings", filter] : ["getAgentMentorings"],
  createAgentMentoring: () => ["createAgentMentoring"],
  updateAgentMentoring: () => ["updateAgentMentoring"],
  deleteAgentMentoring: () => ["deleteAgentMentoring"]
};

export const useFilterAgentMentorings = (payload: AgentMentoringFilterPageRequest) => {
  return useQuery({
    queryKey: AGENT_MENTORINGS_QUERY_KEYS.filterAgentMentorings(payload),
    queryFn: async () => {
      const { data } = await api.filterAgentMentorings(payload);

      return data;
    }
  });
};

export const useCreateAgentMentoring = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: AGENT_MENTORINGS_QUERY_KEYS.createAgentMentoring(),
    mutationFn: async (payload: CreateUpdateAgentMentoring) => {
      const { data } = await api.createAgentMentoring(payload);

      return data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: AGENT_MENTORINGS_QUERY_KEYS.filterAgentMentorings() });
      messageUtils.itemCreatedNotification();
    }
  });
};

export const useUpdateAgentMentoring = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: AGENT_MENTORINGS_QUERY_KEYS.updateAgentMentoring(),
    mutationFn: async (payload: EntityObject<CreateUpdateAgentMentoring>) => {
      const { data } = await api.updateAgentMentoring(payload);

      return data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: AGENT_MENTORINGS_QUERY_KEYS.filterAgentMentorings() });
      messageUtils.itemUpdatedNotification();
    }
  });
};

export const useDeleteAgentMentoring = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: AGENT_MENTORINGS_QUERY_KEYS.deleteAgentMentoring(),
    mutationFn: async (id: UUID) => {
      return api.deleteAgentMentoring({ id });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: AGENT_MENTORINGS_QUERY_KEYS.filterAgentMentorings() });
      messageUtils.itemDeletedNotification();
    }
  });
};
