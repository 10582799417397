import { Card, Divider, Table, Tooltip } from "antd";
import { ColumnsType } from "antd/lib/table";
import { useSelector } from "react-redux";
import t from "../../../../app/i18n";
import ActionTextIcon from "../../../../common/components/icons/ActionTextIcon";
import Ellipsis from "../../../../common/components/views/Ellipsis";
import { TableSizes } from "../../../../common/constants";
import { RootState } from "../../../../common/types";
import { formatLocaleDateTime } from "../../../../common/utils/formatUtils";
import { fromNow } from "../../../../common/utils/formUtils";
import { paginationTableProps, tableStandardProps } from "../../../../common/utils/utils";
import { selectIsAnyRequestInProgress } from "../../../ducks";
import { requests } from "../../api";
import { togglePageNotificationSentAndSeenStatusActions } from "../../ducks";
import { NotificationStatus } from "../../enums";
import { Notification, NotificationFilterPageResult } from "../../types";

interface Props {
  currentPage: NotificationFilterPageResult;
  onPageChange: (pageNumber: number) => void;
  onStatusToggle: typeof togglePageNotificationSentAndSeenStatusActions.request;
  onShowClick: (notification: Notification) => void;
}

const NotificationTableView = ({ currentPage, onPageChange, onStatusToggle, onShowClick }: Props) => {
  const requestsInProgress = useSelector<RootState, boolean>(state =>
    selectIsAnyRequestInProgress(state, [
      requests.FILTER_NOTIFICATIONS,
      requests.MARK_ALL_NOTIFICATIONS_AS_SEEN,
      requests.TOGGLE_NOTIFICATION_SENT_AND_SEEN_STATUS
    ])
  );

  const columns: ColumnsType<Notification> = [
    {
      key: "title",
      title: t("notification.filter.title"),
      width: 170,
      ellipsis: { showTitle: false },
      render: (_, record) => <Ellipsis>{record.data.title}</Ellipsis>
    },
    {
      key: "content",
      title: t("notification.filter.content"),
      ellipsis: { showTitle: false },
      render: (_, record) => <Ellipsis>{record.data.content}</Ellipsis>
    },
    {
      key: "sentAt",
      title: t("notification.filter.sentAt"),
      width: 160,
      render: (_, record) => (
        <Tooltip title={formatLocaleDateTime(record.sentAt)}>
          {record.sentAt ? fromNow(record.sentAt) : undefined}
        </Tooltip>
      )
    },
    {
      key: "actions",
      align: "right",
      fixed: "right",
      width: 300,
      render: (_, record) => (
        <div className="nowrap">
          {record.status === NotificationStatus.SEEN ? (
            <ActionTextIcon
              icon="eye-invisible"
              text={t("notification.actions.markAsUnseen")}
              color="blue"
              onClick={() => onStatusToggle({ id: record.id })}
            />
          ) : (
            <ActionTextIcon
              icon="eye"
              text={t("notification.actions.markAsSeen")}
              color="red"
              onClick={() => onStatusToggle({ id: record.id })}
            />
          )}

          <Divider type="vertical" />

          <ActionTextIcon icon="arrows" color="green" text={t("common.show")} onClick={() => onShowClick(record)} />
        </div>
      )
    }
  ];

  return (
    <Card className="card-box">
      <Table<Notification>
        {...tableStandardProps(true, false)}
        columns={columns}
        dataSource={currentPage.pageData}
        scroll={{ x: TableSizes.MEDIUM }}
        loading={requestsInProgress}
        rowClassName={(record, index) =>
          record.status === NotificationStatus.SENT
            ? "table-row-unread"
            : index % 2 === 0
              ? "table-row-light"
              : "table-row-dark"
        }
        pagination={{
          ...paginationTableProps,
          current: currentPage.pageIndex + 1,
          pageSize: currentPage.pageSize,
          total: currentPage.totalElementsCount,
          onChange: onPageChange
        }}
      />
    </Card>
  );
};

export default NotificationTableView;
