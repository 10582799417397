import { Select } from "antd";
import { useTranslation } from "react-i18next";
import { CalcResultsSorting } from "../../enums";
import { CalcResult, CalcResultData } from "../../types";
import { sortCalcResultItems } from "../../utils";

type Props<T extends CalcResultData> = {
  resultItems: CalcResult<T>[];
  resultsSorting: CalcResultsSorting;
  onChange: (sorting: CalcResultsSorting, results: CalcResult<T>[]) => void;
};

export const CalcResultSorting = <T extends CalcResultData>({ resultItems, onChange, resultsSorting }: Props<T>) => {
  const { t } = useTranslation();

  const handleChangeSorting = (value: CalcResultsSorting) => {
    const result = sortCalcResultItems({
      sortBy: value,
      items: resultItems
    });

    onChange(value, result);
  };

  return (
    <div>
      <span style={{ paddingRight: 16 }}>{t("calc.enums.calcResultsSorting._label")}:</span>
      <Select<CalcResultsSorting>
        style={{ width: 160 }}
        value={resultsSorting}
        options={Object.values(CalcResultsSorting)
          .filter(value => value !== CalcResultsSorting.RECOMMENDED)
          .map(value => ({
            value: value,
            label: t(`calc.enums.calcResultsSorting.${value}`)
          }))}
        onChange={handleChangeSorting}
      />
    </div>
  );
};
