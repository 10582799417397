import { AutoComplete, Form } from "antd";
import { AutoCompleteProps } from "antd/lib/auto-complete";
import { FormItemProps } from "antd/lib/form";
import { isValidPhoneNumber } from "libphonenumber-js";
import { DefaultOptionType } from "rc-select/lib/Select";
import { ContactType } from "../../../modules/enums";
import { Contact } from "../../../modules/types";
import { formatLocalePhoneNumber, formatPhoneNumber } from "../../../utils/formatUtils";

interface Props {
  formItemProps: FormItemProps;
  autocompleteProps?: AutoCompleteProps<string, PhoneNumberOption>;
  contacts?: Contact[];
}

interface PhoneNumberOption extends DefaultOptionType {
  valueInInternationalFormat: string;
  valueInNationalFormat: string;
}

const phoneNumberFilterFunction = (input: string, option?: PhoneNumberOption): boolean => {
  if (!option) {
    return false;
  }

  return option.valueInInternationalFormat.indexOf(input) !== -1 || option.valueInNationalFormat.indexOf(input) !== -1;
};

const ContactsPhoneNumberAutoComplete = ({ formItemProps, autocompleteProps, contacts }: Props) => (
  <Form.Item {...formItemProps}>
    <AutoComplete<string, PhoneNumberOption>
      {...autocompleteProps}
      popupMatchSelectWidth={false}
      options={
        contacts &&
        [
          ...new Set(
            contacts
              .filter(
                c =>
                  c.type === ContactType.PHONE_NUMBER && isValidPhoneNumber(c.value as ContactType.PHONE_NUMBER, "SK")
              )
              .map(c => formatPhoneNumber(c.value))
          )
        ].map(phoneNumber => ({
          value: phoneNumber,
          label: phoneNumber,
          valueInInternationalFormat: phoneNumber,
          valueInNationalFormat: formatLocalePhoneNumber(phoneNumber)
        }))
      }
      filterOption={phoneNumberFilterFunction}
    />
  </Form.Item>
);

export default ContactsPhoneNumberAutoComplete;
