import { Middleware } from "@reduxjs/toolkit";
import { Modal } from "antd";
import { t } from "i18next";
import { getType } from "typesafe-actions";
import { switchSelectedAccountAction } from "../../../modules/auth/ducks";
import { setEnumerationsAction } from "../../../modules/enumerations/ducks";
import { Enumerations } from "../../../modules/enumerations/types";

type EventData = {
  tabId: string;
  type: string;
  payload: any;
};

const getTabId = () => {
  let tabId = sessionStorage.getItem("TAB_ID");

  if (!tabId) {
    tabId = Math.random().toString(36).substring(2, 15);
    sessionStorage.setItem("TAB_ID", tabId);
  }

  return tabId;
};

const channel = new BroadcastChannel("redux-sync-tabs");

const TAB_ID = getTabId();

export const syncTabsMiddleware: Middleware = store => {
  channel.onmessage = (event: MessageEvent) => {
    const { type, payload, tabId } = event.data as EventData;

    if (tabId === TAB_ID) {
      return;
    }

    if (type === getType(switchSelectedAccountAction)) {
      const currentAccountId = store.getState().auth.selectedAccount.accountId;

      if (currentAccountId === payload) {
        return;
      }

      Modal.destroyAll();

      Modal.info({
        title: t("helpers.accountSwitch"),
        content: t("helpers.accountSwitchDescription"),
        onOk: () => {
          store.dispatch(switchSelectedAccountAction(payload));
        },
        okText: t("common.submit")
      });
    }

    if (type === getType(setEnumerationsAction)) {
      const currentEnumerations = store.getState().enumerations.common as Enumerations;

      if (currentEnumerations.lastRefreshTime !== payload.lastRefreshTime) {
        store.dispatch(setEnumerationsAction(payload));
      }
    }
  };

  return next => action => {
    if (action.type === getType(switchSelectedAccountAction)) {
      const newAccountId = action.payload;
      const currentAccountId = store.getState().auth.selectedAccount.accountId;

      if (newAccountId !== currentAccountId) {
        channel.postMessage({
          type: action.type,
          payload: newAccountId,
          tabId: TAB_ID
        });
      }
    }

    if (action.type === getType(setEnumerationsAction)) {
      const newEnumerations = action.payload as Enumerations;
      const currentEnumerations = store.getState().enumerations.common as Enumerations;

      if (newEnumerations.lastRefreshTime !== currentEnumerations.lastRefreshTime) {
        channel.postMessage({
          type: action.type,
          payload: newEnumerations,
          tabId: TAB_ID
        });
      }
    }

    return next(action);
  };
};
