import { useMutation, useQuery } from "@tanstack/react-query";
import t from "../../../app/i18n";
import { contractFormApi } from "../../../modules/contractform/api";
import { ContractFormSearchRequest, GenerateContractTermination } from "../../../modules/contractform/types";
import messageUtils from "../../utils/messageUtils";
import { openBlobFile } from "../../utils/utils";

export const CONTRACT_FORM_QUERY_KEYS = {
  searchContract: (request: Partial<ContractFormSearchRequest>) => ["searchContract", request],
  generateContractTermination: () => ["generateContractTermination"],
  registerVehicleData: () => ["registerVehicle"]
};

export const useSearchContract = (payload: Partial<ContractFormSearchRequest>) => {
  return useQuery({
    queryKey: CONTRACT_FORM_QUERY_KEYS.searchContract(payload),
    queryFn: async () => {
      const { data } = await contractFormApi.searchContract(payload as ContractFormSearchRequest);

      return data;
    },
    enabled: !!payload.keyword,
    staleTime: 0
  });
};

export const useGenerateContractTermination = () => {
  return useMutation({
    mutationKey: CONTRACT_FORM_QUERY_KEYS.generateContractTermination(),
    mutationFn: async (payload: GenerateContractTermination) => {
      const response = await contractFormApi.generateContractTermination(payload);
      openBlobFile(response, true);
      return response.data;
    }
  });
};

export const useRegisterVehicleData = () => {
  return useMutation({
    mutationKey: CONTRACT_FORM_QUERY_KEYS.registerVehicleData(),
    mutationFn: async (payload: FormData) => {
      return await contractFormApi.registerVehicleData(payload);
    },
    onSuccess: () => {
      messageUtils.successNotification({
        message: t("common.operationSuccess"),
        description: t("registerVehicleData.helpers.successDescription")
      });
    }
  });
};
