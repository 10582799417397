import t from "../../../../app/i18n";
import { AffiliatePartnerBase } from "../../../../modules/affiliate/types";
import { GapDuration } from "../../../../modules/calculator/calcs/vehicle/enums";
import { ComplicityBase } from "../../../../modules/complicity/types";
import ContractStatusTag from "../../../../modules/contract/components/ContractStatusTag";
import ContractVerificationStatusTag from "../../../../modules/contract/components/ContractVerificationStatusTag";
import {
  ContractStatus,
  ContractVerificationStatus,
  InsurancePeriod,
  InvestmentRiskLevel,
  InvestmentType,
  PaymentFrequency,
  PeriodType,
  SecondPillarCreationType,
  SecondPillarFundType
} from "../../../../modules/contract/enums";
import {
  CrashInsuranceData,
  GapInsuranceData,
  GenericInsuranceData,
  LifeInsuranceData,
  MtplInsuranceData,
  RealtyInsuranceData,
  TravelInsuranceData,
  Vehicle,
  VehicleInsuranceData,
  VerificationAttachmentsData
} from "../../../../modules/contract/types";
import { CoverageLimitBase } from "../../../../modules/coveragelimit/types";
import { LifeInsuranceTariffBase } from "../../../../modules/lifeinsurancetariff/types";
import { ProductBase } from "../../../../modules/product/types";
import {
  formatCoverageLimit,
  formatIntegerLocaleCurrency,
  formatLicensePlate,
  formatLifeInsuranceTariff,
  formatLocaleDate,
  formatPlaceOfInsurance
} from "../../../utils/formatUtils";
import { tBoolean } from "../../../utils/translationUtils";
import { isDefinedValue } from "../../../utils/utils";
import {
  AgentHistoryItem,
  AreaHistoryItem,
  BooleanHistoryItem,
  ClientHistoryItem,
  CurrencyHistoryItem,
  DateHistoryItem,
  EmptyHistoryItem,
  InstitutionHistoryItem,
  NoteHistoryItem,
  PercentageHistoryItem
} from "./commonHistoryItems";

export const CONTRACT_SPECIFIC_FIELDS = ["policyHolder", "coDebtor"];
export const INSURANCE_SPECIFIC_FIELDS = ["insuredClient", "vehicleHolder", "vehicleOwner", "vinculationClient"];

const AffiliatePartnerItem = (value: AffiliatePartnerBase) => (value ? <span>{value.name}</span> : EmptyHistoryItem());

const InvestmentRiskLevelItem = (value: InvestmentRiskLevel) => (
  <span>{t("contract.enums.investmentRiskLevel." + value)}</span>
);

const CreationTypeItem = (value: SecondPillarCreationType) => (
  <span>{t("contract.enums.secondPillarCreationType." + value)}</span>
);

const FundTypeItem = (value: SecondPillarFundType) => <span>{t("contract.enums.secondPillarFundType." + value)}</span>;

const InsurancePeriodItem = (value: InsurancePeriod) => <span>{t("contract.enums.insurancePeriod." + value)}</span>;

const InvestmentTypeItem = (value: InvestmentType) => <span>{t("contract.enums.investmentType." + value)}</span>;

const PaymentFrequencyItem = (value: PaymentFrequency) => <span>{t("contract.enums.paymentFrequency." + value)}</span>;

const PeriodTypeItem = (value: PeriodType) => <span>{t("contract.enums.periodType." + value)}</span>;

const ProductNameItem = (value: ProductBase) => <span>{value.name}</span>;

const RateTypeItem = (value: string) => <span>{t("contract.enums.loanRateType." + value)}</span>;

const StatusItem = (value: ContractStatus) => <ContractStatusTag key={1} status={value} />;

const VerificationStatusItem = (value: ContractVerificationStatus) => <ContractVerificationStatusTag status={value} />;

const VerificationAttachmentsDataItem = (value: VerificationAttachmentsData) =>
  value ? (
    <>
      <span>{t("contract.attrs.verificationAttachmentsData.financialMediation")}: </span>
      <span>{tBoolean(value.financialMediation)}</span>
      <br />
      <span>{t("contract.attrs.verificationAttachmentsData.investmentServiceAgreement")}: </span>
      <span>{tBoolean(value.investmentServiceAgreement)}</span>
      <br />
      <span>{t("contract.attrs.verificationAttachmentsData.identityCardCopy")}: </span>
      <span>{tBoolean(value.identityCardCopy)}</span>
      <br />
      <span>{t("contract.attrs.verificationAttachmentsData.tipAgreement")}: </span>
      <span>{tBoolean(value.tipAgreement)}</span>
    </>
  ) : (
    EmptyHistoryItem()
  );

const TextItem = (value?: string | number) => (isDefinedValue(value) ? <span>{value}</span> : EmptyHistoryItem());

const ComplicityItem = (value: ComplicityBase) => (value ? <span>{value.value}</span> : EmptyHistoryItem());

const CoverageLimitItem = (value: CoverageLimitBase) =>
  value ? <span>{formatCoverageLimit(value)}</span> : EmptyHistoryItem();

const MtplCrashCoveragesItem = ({ coverages }: MtplInsuranceData & CrashInsuranceData) =>
  coverages ? (
    <>
      {Object.hasOwn(coverages, "extendedAssistance") && (
        <>
          <span>{t("contract.attrs.insurances.insuranceData.coverages.extendedAssistance")}: </span>
          <span>{tBoolean(coverages.extendedAssistance)}</span>
          <br />
        </>
      )}
      {Object.hasOwn(coverages, "glass") && (
        <>
          <span>{t("contract.attrs.insurances.insuranceData.coverages.glass")}: </span>
          <span>
            {tBoolean(coverages.glass)}{" "}
            {coverages.glassAmount ? <>({formatIntegerLocaleCurrency(coverages.glassAmount)})</> : undefined}
          </span>
          <br />
        </>
      )}
      {Object.hasOwn(coverages, "animal") && (
        <>
          <span>{t("contract.attrs.insurances.insuranceData.coverages.animal")}: </span>
          <span>{tBoolean(coverages.animal)}</span>
          <br />
        </>
      )}
      {Object.hasOwn(coverages, "element") && (
        <>
          <span>{t("contract.attrs.insurances.insuranceData.coverages.element")}: </span>
          <span>{tBoolean(coverages.element)}</span>
          <br />
        </>
      )}
      {Object.hasOwn(coverages, "injury") && (
        <>
          <span>{t("contract.attrs.insurances.insuranceData.coverages.injury")}: </span>
          <span>{tBoolean(coverages.injury)}</span>
          <br />
        </>
      )}
      {Object.hasOwn(coverages, "theftAndVandalism") && (
        <>
          <span>{t("contract.attrs.insurances.insuranceData.coverages.theftAndVandalism")}: </span>
          <span>{tBoolean(coverages.theftAndVandalism)}</span>
        </>
      )}
      {Object.hasOwn(coverages, "replacementVehicle") && (
        <>
          <span>{t("contract.attrs.insurances.insuranceData.coverages.replacementVehicle")}: </span>
          <span>{tBoolean(coverages.replacementVehicle)}</span>
          <br />
        </>
      )}
      {Object.hasOwn(coverages, "abroadVehicleRepair") && (
        <>
          <span>{t("contract.attrs.insurances.insuranceData.coverages.abroadVehicleRepair")}: </span>
          <span>{tBoolean(coverages.abroadVehicleRepair)}</span>
          <br />
        </>
      )}
      {Object.hasOwn(coverages, "gap") && (
        <>
          <span>{t("contract.attrs.insurances.insuranceData.coverages.gap")}: </span>
          <span>
            {tBoolean(coverages.gap)}{" "}
            {coverages.gapDuration ? <>({t("calc.vehicle.enums.gapDuration." + coverages.gapDuration)})</> : undefined}
          </span>
          <br />
        </>
      )}
      {Object.hasOwn(coverages, "gapComplicityReinsurance") && (
        <>
          <span>{t("contract.attrs.insurances.insuranceData.coverages.gapComplicityReinsurance")}: </span>
          <span>{tBoolean(coverages.gapComplicityReinsurance)}</span>
        </>
      )}
    </>
  ) : (
    EmptyHistoryItem()
  );

const GapDurationItem = (duration: GapDuration) =>
  duration ? <span>{t("calc.vehicle.enums.gapDuration." + duration)}</span> : EmptyHistoryItem();

const CrashInsuranceMtplItem = ({ mtpl }: CrashInsuranceData) =>
  mtpl ? (
    <>
      <span>{t("contract.attrs.insurances.insuranceData.mtpl.effectiveBeginningDate")}: </span>
      {mtpl.effectiveBeginningDate ? <span>{formatLocaleDate(mtpl.effectiveBeginningDate)}</span> : EmptyHistoryItem()}
    </>
  ) : (
    EmptyHistoryItem()
  );

const VehiclePurposeItem = ({ purpose }: VehicleInsuranceData) => (
  <span>{t("calc.vehicle.enums.vehiclePurpose." + purpose)}</span>
);

const LicensePlateItem = (value: string) => <span>{formatLicensePlate(value)}</span>;

const VehicleItem = (vehicle: Vehicle) => <span>{vehicle.vin}</span>;

const ApartmentItem = ({ apartment }: RealtyInsuranceData) =>
  apartment ? (
    <>
      <span>{t("contract.attrs.insurances.insuranceData.apartment.floor")}: </span>
      <span>{apartment.floor}</span>
      <br />
      <span>{t("contract.attrs.insurances.insuranceData.apartment.floorArea")}: </span>
      <span>{AreaHistoryItem(apartment.floorArea)}</span>
    </>
  ) : (
    EmptyHistoryItem()
  );

const BuildingStateItem = ({ buildingState }: RealtyInsuranceData) => (
  <span>{t("calc.realty.enums.buildingState." + buildingState)}</span>
);

const BuildingTypeItem = ({ buildingType }: RealtyInsuranceData) => (
  <span>{t("calc.realty.enums.buildingType." + buildingType)}</span>
);

const HouseOrRecreationalBuildingItem = ({ houseOrRecreationalBuilding }: RealtyInsuranceData) =>
  houseOrRecreationalBuilding ? (
    <>
      <span>{t("calc.realty.enums.constructionMaterial._label")}: </span>
      <span>{t("calc.realty.enums.constructionMaterial." + houseOrRecreationalBuilding.constructionMaterial)}</span>
      <br />
      <span>{t("calc.realty.enums.roofType._label")}: </span>
      <span>{t("calc.realty.enums.roofType." + houseOrRecreationalBuilding.roofType)}</span>
      <br />
      <span>{t("contract.attrs.insurances.insuranceData.houseOrRecreationalBuilding.buildArea")}: </span>
      <span>{AreaHistoryItem(houseOrRecreationalBuilding.buildArea)}</span>
    </>
  ) : (
    EmptyHistoryItem()
  );

const HouseholdItem = ({ household }: RealtyInsuranceData) =>
  household ? (
    <>
      <span>{t("contract.attrs.insurances.insuranceData.household.insuranceAmount")}: </span>
      <span>{formatIntegerLocaleCurrency(household.insuranceAmount)}</span>
      <br />
      <span>{t("contract.attrs.insurances.insuranceData.household.liabilityInsuranceAmount")}: </span>
      <span>{formatIntegerLocaleCurrency(household.liabilityInsuranceAmount)}</span>
    </>
  ) : (
    EmptyHistoryItem()
  );

const PlaceOfInsuranceItem = ({ placeOfInsurance }: RealtyInsuranceData) => (
  <span>{formatPlaceOfInsurance(placeOfInsurance, true)}</span>
);

const RealtyItem = ({ realty }: RealtyInsuranceData) =>
  realty ? (
    <>
      <span>{t("contract.attrs.insurances.insuranceData.realty.insuranceAmount")}: </span>
      <span>{formatIntegerLocaleCurrency(realty.insuranceAmount)}</span>
      <br />
      <span>{t("contract.attrs.insurances.insuranceData.realty.liabilityInsuranceAmount")}: </span>
      <span>{formatIntegerLocaleCurrency(realty.liabilityInsuranceAmount)}</span>
    </>
  ) : (
    EmptyHistoryItem()
  );

const ReinsurancesItem = ({ reinsurances }: RealtyInsuranceData) =>
  reinsurances ? (
    <>
      <span>{t("contract.attrs.insurances.insuranceData.reinsurances.cyberSecurity")}: </span>
      <span>{tBoolean(reinsurances.cyberSecurity)}</span>
      <br />
      <span>{t("contract.attrs.insurances.insuranceData.reinsurances.warranty")}: </span>
      <span>{tBoolean(reinsurances.warranty)}</span>
      <br />
      <span>{t("contract.attrs.insurances.insuranceData.reinsurances.cycling")}: </span>
      <span>{tBoolean(reinsurances.cycling)}</span>
      <br />
      <span>{t("contract.attrs.insurances.insuranceData.reinsurances.loan")}: </span>
      <span>{tBoolean(reinsurances.loan)}</span>
      <br />
      <span>{t("contract.attrs.insurances.insuranceData.reinsurances.rent")}: </span>
      <span>{tBoolean(reinsurances.rent)}</span>
      <br />
      <span>{t("contract.attrs.insurances.insuranceData.reinsurances.allRisk")}: </span>
      <span>{tBoolean(reinsurances.allRisk)}</span>
    </>
  ) : (
    EmptyHistoryItem()
  );

const InsuredClientsItem = ({ insuredClients }: TravelInsuranceData) => (
  <span>
    {insuredClients
      .map(client => `${client.firstName} ${client.lastName} (${formatLocaleDate(client.birthDate)})`)
      .join(", ")}
  </span>
);

const RiskGroupItem = ({ riskGroup }: TravelInsuranceData) =>
  riskGroup ? <span>{t("calc.travel.enums.riskGroup." + riskGroup)}</span> : EmptyHistoryItem();

const TerritorialValidityItem = ({ territorialValidity }: TravelInsuranceData) => (
  <span>{t("calc.travel.enums.territorialValidity." + territorialValidity)}</span>
);

const LifeInsuranceTariffItem = (value: LifeInsuranceTariffBase) => <span>{formatLifeInsuranceTariff(value)}</span>;

export const ContractHistoryItemsMap = new Map<string, (value: any) => React.JSX.Element>([
  // Contract general items
  ["affiliatePartner", AffiliatePartnerItem],
  ["amountOwed", CurrencyHistoryItem],
  ["annualPremium", CurrencyHistoryItem],
  ["applicationSignDate", DateHistoryItem],
  ["approvedAmount", CurrencyHistoryItem],
  ["basePremium", CurrencyHistoryItem],
  ["cancellationDate", DateHistoryItem],
  ["coDebtor", ClientHistoryItem],
  ["commissionPaid", BooleanHistoryItem],
  ["computedRiskLevel", InvestmentRiskLevelItem],
  ["contactClientDate", DateHistoryItem],
  ["creationType", CreationTypeItem],
  ["effectiveBeginningDate", DateHistoryItem],
  ["effectiveEndDate", DateHistoryItem],
  ["feeTotalAmount", CurrencyHistoryItem],
  ["fixationAnniversaryDate", DateHistoryItem],
  ["fundType", FundTypeItem],
  ["institution", InstitutionHistoryItem],
  ["insurancePeriod", InsurancePeriodItem],
  ["insurancePeriodEndDate", DateHistoryItem],
  ["interestRate", PercentageHistoryItem],
  ["investmentType", InvestmentTypeItem],
  ["lastContractCancellationDate", DateHistoryItem],
  ["loanMaturityDate", DateHistoryItem],
  ["ltvRatio", PercentageHistoryItem],
  ["managementFee", PercentageHistoryItem],
  ["manager", AgentHistoryItem],
  ["mediationReportSignDate", DateHistoryItem],
  ["monthlyPayment", CurrencyHistoryItem],
  ["monthlyPaymentAmount", CurrencyHistoryItem],
  ["note", NoteHistoryItem],
  ["onetimePayment", CurrencyHistoryItem],
  ["paidUntilDate", DateHistoryItem],
  ["partialPremium", CurrencyHistoryItem],
  ["paymentFrequency", PaymentFrequencyItem],
  ["periodType", PeriodTypeItem],
  ["policyHolder", ClientHistoryItem],
  ["prepaidTargetAmount", CurrencyHistoryItem],
  ["product", ProductNameItem],
  ["rateType", RateTypeItem],
  ["regularPayment", CurrencyHistoryItem],
  ["riskLevel", InvestmentRiskLevelItem],
  ["signDate", DateHistoryItem],
  ["signer", AgentHistoryItem],
  ["status", StatusItem],
  ["targetAmount", CurrencyHistoryItem],
  ["tradeAmount", CurrencyHistoryItem],
  ["transferredFromOtherBroker", BooleanHistoryItem],
  ["transferredToOtherBroker", BooleanHistoryItem],
  ["transferredToOtherBrokerDate", DateHistoryItem],
  ["verificationStatus", VerificationStatusItem],
  ["verificationAttachmentsData", VerificationAttachmentsDataItem],

  // Insurance general items
  ["contractEntryDate", DateHistoryItem],
  ["contractWithdrawalDate", DateHistoryItem],
  ["insuredClient", ClientHistoryItem],
  ["vehicleHolder", ClientHistoryItem],
  ["vehicleOwner", ClientHistoryItem],
  ["vinculationClient", ClientHistoryItem],

  // Generic insurance items
  ["insuranceData.description", ({ description }: GenericInsuranceData) => NoteHistoryItem(description)],

  // Vehicle insurance items
  ["complicity", ComplicityItem],
  ["coverageLimit", CoverageLimitItem],
  ["insuranceData.buyingPrice", ({ buyingPrice }: GapInsuranceData) => CurrencyHistoryItem(buyingPrice)],
  [
    "insuranceData.complicityReinsurance",
    ({ complicityReinsurance }: GapInsuranceData) => BooleanHistoryItem(complicityReinsurance)
  ],
  ["insuranceData.coverages", MtplCrashCoveragesItem],
  ["insuranceData.duration", ({ duration }: GapInsuranceData) => GapDurationItem(duration)],
  ["insuranceData.leasing", ({ leasing }: VehicleInsuranceData) => BooleanHistoryItem(leasing)],
  ["insuranceData.mtpl", CrashInsuranceMtplItem],
  ["insuranceData.price", ({ price }: CrashInsuranceData) => CurrencyHistoryItem(price)],
  ["insuranceData.purpose", VehiclePurposeItem],
  [
    "insuranceData.registrationCertificateNumber",
    ({ registrationCertificateNumber }) => TextItem(registrationCertificateNumber)
  ],
  ["licensePlate", LicensePlateItem],
  ["vehicle", VehicleItem],

  // Realty insurance items
  ["insuranceData.apartment", ApartmentItem],
  ["insuranceData.buildingState", BuildingStateItem],
  ["insuranceData.buildingType", BuildingTypeItem],
  ["insuranceData.complicity", ({ complicity }: RealtyInsuranceData) => BooleanHistoryItem(complicity)],
  ["insuranceData.constructionYear", ({ constructionYear }: RealtyInsuranceData) => TextItem(constructionYear)],
  ["insuranceData.houseOrRecreationalBuilding", HouseOrRecreationalBuildingItem],
  ["insuranceData.household", HouseholdItem],
  [
    "insuranceData.householdEnabled",
    ({ householdEnabled }: RealtyInsuranceData) => BooleanHistoryItem(householdEnabled)
  ],
  [
    "insuranceData.permanentlyOccupied",
    ({ permanentlyOccupied }: RealtyInsuranceData) => BooleanHistoryItem(permanentlyOccupied)
  ],
  ["insuranceData.placeOfInsurance", PlaceOfInsuranceItem],
  ["insuranceData.realty", RealtyItem],
  ["insuranceData.realtyEnabled", ({ realtyEnabled }: RealtyInsuranceData) => BooleanHistoryItem(realtyEnabled)],
  ["insuranceData.reinsurances", ReinsurancesItem],
  ["insuranceData.vinculation", ({ vinculation }: RealtyInsuranceData) => BooleanHistoryItem(vinculation)],

  // Travel insurance items
  ["insuranceData.insuredClients", InsuredClientsItem],
  [
    "insuranceData.policyHolderIsAlsoInsured",
    ({ policyHolderIsAlsoInsured }: TravelInsuranceData) => BooleanHistoryItem(policyHolderIsAlsoInsured)
  ],
  ["insuranceData.riskGroup", RiskGroupItem],
  ["insuranceData.territorialValidity", TerritorialValidityItem],

  // Life insurance items
  ["insuranceData.insuranceAmount", ({ insuranceAmount }: LifeInsuranceData) => CurrencyHistoryItem(insuranceAmount)],
  ["insuranceData.insurancePeriod", ({ insurancePeriod }: LifeInsuranceData) => TextItem(insurancePeriod)],
  ["tariff", LifeInsuranceTariffItem]
]);
