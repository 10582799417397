import { generatePath } from "react-router-dom";
import { Permission } from "../../common/security/authorization/enums";
import { QueryKeys, URLQuery } from "../../common/utils/queryUtils";
import { hasPermission } from "../../common/utils/utils";
import { AGENT_ROUTE_PATHS } from "../agent/paths";
import { CALC_ROUTE_PATHS } from "../calculator/paths";
import { CLIENT_ROUTE_PATHS } from "../client/paths";
import { CONTRACT_ROUTE_PATHS } from "../contract/paths";
import { USER_PROFILE_TABS } from "../globalsearch/utils";
import { JOB_ROUTE_PATHS } from "../jobs/paths";
import { CURRENT_USER_ROUTE_PATHS } from "../user/paths";
import { VEHICLE_BRAND_ROUTE_PATHS } from "../vehicle/brand/paths";
import { NotificationTopic } from "./enums";
import type {
  AgentContractGainersChangedAdditionalData,
  AgentContractInterventionAddedAdditionalData,
  EntityIdsAdditionalData,
  Notification
} from "./types";

export const getNotificationTopicPage = (notification: Notification): string | undefined => {
  let targetUrl;

  const topic: NotificationTopic = notification.data.topic;
  switch (topic) {
    case NotificationTopic.SYSADMIN_CALC_GENERATE_CONTRACT_FAILED:
      targetUrl = CALC_ROUTE_PATHS.records.to;
      break;
    case NotificationTopic.SYSADMIN_VEHICLE_MAPPING_FINISHED:
      targetUrl = VEHICLE_BRAND_ROUTE_PATHS.brands.to;
      break;
    case NotificationTopic.SYSADMIN_MONLY_WEBHOOK_EVENT_FAILED:
    case NotificationTopic.SYSADMIN_FINGO_EVENT_FAILED:
      targetUrl = JOB_ROUTE_PATHS.list.to;
      break;
    case NotificationTopic.AGENT_CONTRACT_GAINERS_CHANGED:
    case NotificationTopic.AGENT_CONTRACT_INTERVENTION_ADDED:
      targetUrl = generatePath(CONTRACT_ROUTE_PATHS.detail.to, {
        id: (
          notification.data.additionalData as
            | AgentContractGainersChangedAdditionalData
            | AgentContractInterventionAddedAdditionalData
        ).contractId
      });
      break;
    case NotificationTopic.AGENT_ENDING_COMPETENCE:
      targetUrl = `${CURRENT_USER_ROUTE_PATHS.profile.to}${URLQuery.searchParamsToString({ searchParams: { [QueryKeys.TAB]: USER_PROFILE_TABS.COMPETENCES } })}`;
      break;
    case NotificationTopic.AGENT_ENDING_COMPETENCE_FOR_BACKOFFICE:
      targetUrl = generatePath(AGENT_ROUTE_PATHS.endingCompetences.to);
      break;
    case NotificationTopic.AGENT_COMMISSIONS_OUTPUT_ATTACHMENT_CREATED:
      targetUrl = generatePath(CURRENT_USER_ROUTE_PATHS.commissions.to);
      break;
    case NotificationTopic.AGENT_ENDING_MENTORING:
      targetUrl = `${CURRENT_USER_ROUTE_PATHS.profile.to}${URLQuery.searchParamsToString({ searchParams: { [QueryKeys.TAB]: USER_PROFILE_TABS.MENTORING } })}`;
      break;
    case NotificationTopic.CONTRACT_IMPORT_FINISHED:
      targetUrl = generatePath(CONTRACT_ROUTE_PATHS.importResultFile.to, {
        id: (notification.data.additionalData as EntityIdsAdditionalData).id1
      });
      break;
    case NotificationTopic.CLIENT_IMPORT_FINISHED:
      targetUrl = generatePath(CLIENT_ROUTE_PATHS.importResultFile.to, {
        id: (notification.data.additionalData as EntityIdsAdditionalData).id1
      });
      break;
  }

  return targetUrl;
};

export const getAvailableNotificationTopics = (
  permissions: Permission[],
  isSystemAdmin?: boolean
): NotificationTopic[] => {
  const topics: NotificationTopic[] = [];

  if (isSystemAdmin) {
    topics.push(NotificationTopic.SYSADMIN_CALC_GENERATE_CONTRACT_FAILED);
    topics.push(NotificationTopic.SYSADMIN_VEHICLE_MAPPING_FINISHED);
    topics.push(NotificationTopic.SYSADMIN_BUG_REPORT_CREATED);
    topics.push(NotificationTopic.SYSADMIN_MONLY_WEBHOOK_EVENT_FAILED);
    topics.push(NotificationTopic.SYSADMIN_FINGO_EVENT_FAILED);
  }

  topics.push(NotificationTopic.AGENT_CONTRACT_GAINERS_CHANGED);
  topics.push(NotificationTopic.AGENT_CONTRACT_INTERVENTION_ADDED);

  if (hasPermission(permissions, Permission.PROFILE_COMPETENCE)) {
    topics.push(NotificationTopic.AGENT_ENDING_COMPETENCE);
  }

  if (hasPermission(permissions, Permission.AGENT_COMPETENCE)) {
    topics.push(NotificationTopic.AGENT_ENDING_COMPETENCE_FOR_BACKOFFICE);
  }

  if (hasPermission(permissions, Permission.PROFILE_COMMISSIONS)) {
    topics.push(NotificationTopic.AGENT_COMMISSIONS_OUTPUT_ATTACHMENT_CREATED);
  }

  if (hasPermission(permissions, Permission.PROFILE_MENTORING)) {
    topics.push(NotificationTopic.AGENT_ENDING_MENTORING);
  }

  if (hasPermission(permissions, Permission.CONTRACT_IMPORT)) {
    topics.push(NotificationTopic.CONTRACT_IMPORT_FINISHED);
  }

  if (hasPermission(permissions, Permission.CLIENT_IMPORT)) {
    topics.push(NotificationTopic.CLIENT_IMPORT_FINISHED);
  }

  return topics;
};
