import { Tooltip } from "antd";
import { CSSProperties } from "react";
import AntIcon from "../../icons/AntIcon";

interface Props {
  tooltip: string;
  label?: string;
  className?: string;
  style?: CSSProperties;
}

const LabelWithTooltip = ({ label, tooltip, className, style }: Props) => (
  <span style={{ width: "100%", ...style }} className={className}>
    {label && <span>{label}&nbsp;</span>}
    <Tooltip title={tooltip}>
      <span>
        <AntIcon type="question" className="cursor-help" />
      </span>
    </Tooltip>
  </span>
);

export default LabelWithTooltip;
