import { AxiosPromise } from "axios";
import { ApiRequestAdapter } from "../../../common/api/ApiRequestAdapter";
import { apiService } from "../../../common/api/apiService";
import { EntityIdObject, EntityObject, TwoLevelEntityIdObject, TwoLevelEntityObject } from "../../../common/types";
import { serializeParams } from "../../../common/utils/apiUtils";
import { BailAccountSettings, CreateUpdateBailAccountSettings } from "../bailaccount/types";
import {
  CommissionsSettingsLevel,
  CommissionsSettingsLevelAttachment,
  CommissionsSettingsLevelFilterPageRequest,
  CommissionsSettingsLevelFilterPageResult,
  CommissionsSettingsLevelRule,
  CreateUpdateCommissionsSettingsLevel,
  CreateUpdateCommissionsSettingsLevelRule
} from "./types";

const FILTER_COMMISSIONS_LEVELS = ApiRequestAdapter.create("commissions-settings-levels", "GET");
const GET_COMMISSIONS_LEVEL = ApiRequestAdapter.create("commissions-settings-levels/{0}", "GET");
const CREATE_COMMISSIONS_LEVEL = ApiRequestAdapter.create("commissions-settings-levels", "POST");
const UPDATE_COMMISSIONS_LEVEL = ApiRequestAdapter.create("commissions-settings-levels/{0}", "PUT");
const DELETE_COMMISSIONS_LEVEL = ApiRequestAdapter.create("commissions-settings-levels/{0}", "DELETE");

const CREATE_COMMISSIONS_LEVEL_RULE = ApiRequestAdapter.create("commissions-settings-levels/{0}/rules", "POST");
const UPDATE_COMMISSIONS_LEVEL_RULE = ApiRequestAdapter.create("commissions-settings-levels/{0}/rules/{1}", "PUT");
const DELETE_COMMISSIONS_LEVEL_RULE = ApiRequestAdapter.create("commissions-settings-levels/{0}/rules/{1}", "DELETE");

const CREATE_COMMISSIONS_LEVEL_BAIL_ACCOUNT_SETTINGS = ApiRequestAdapter.create(
  "commissions-settings-levels/{0}/bail-account-settings",
  "POST"
);
const UPDATE_COMMISSIONS_LEVEL_BAIL_ACCOUNT_SETTINGS = ApiRequestAdapter.create(
  "commissions-settings-levels/{0}/bail-account-settings",
  "PUT"
);
const DELETE_COMMISSIONS_LEVEL_BAIL_ACCOUNT_SETTINGS = ApiRequestAdapter.create(
  "commissions-settings-levels/{0}/bail-account-settings",
  "DELETE"
);

const UPLOAD_COMMISSIONS_LEVEL_ATTACHMENTS = ApiRequestAdapter.create(
  "/commissions-settings-levels/{0}/attachments",
  "POST"
);
const DOWNLOAD_COMMISSIONS_LEVEL_ATTACHMENT = ApiRequestAdapter.create(
  "/commissions-settings-levels/{0}/attachments/{1}",
  "GET"
);
const DELETE_COMMISSIONS_LEVEL_ATTACHMENT = ApiRequestAdapter.create(
  "/commissions-settings-levels/{0}/attachments/{1}",
  "DELETE"
);

export const requests = {
  FILTER_COMMISSIONS_LEVELS,
  GET_COMMISSIONS_LEVEL,
  CREATE_COMMISSIONS_LEVEL,
  UPDATE_COMMISSIONS_LEVEL,
  DELETE_COMMISSIONS_LEVEL,
  CREATE_COMMISSIONS_LEVEL_RULE,
  UPDATE_COMMISSIONS_LEVEL_RULE,
  DELETE_COMMISSIONS_LEVEL_RULE,
  CREATE_COMMISSIONS_LEVEL_BAIL_ACCOUNT_SETTINGS,
  UPDATE_COMMISSIONS_LEVEL_BAIL_ACCOUNT_SETTINGS,
  DELETE_COMMISSIONS_LEVEL_BAIL_ACCOUNT_SETTINGS,
  UPLOAD_COMMISSIONS_LEVEL_ATTACHMENTS,
  DOWNLOAD_COMMISSIONS_LEVEL_ATTACHMENT,
  DELETE_COMMISSIONS_LEVEL_ATTACHMENT
};

const api = {
  filterCommissionsLevels: (
    request: CommissionsSettingsLevelFilterPageRequest
  ): AxiosPromise<CommissionsSettingsLevelFilterPageResult> => {
    return apiService.get(FILTER_COMMISSIONS_LEVELS.url, {
      params: request,
      paramsSerializer: { serialize: serializeParams }
    });
  },
  getCommissionsLevel: (request: EntityIdObject): AxiosPromise<CommissionsSettingsLevel> => {
    return apiService.get(GET_COMMISSIONS_LEVEL.getParametrizedUrl(request.id));
  },
  createCommissionsLevel: (request: CreateUpdateCommissionsSettingsLevel): AxiosPromise<CommissionsSettingsLevel> => {
    return apiService.post(CREATE_COMMISSIONS_LEVEL.url, request);
  },
  updateCommissionsLevel: (
    request: EntityObject<CreateUpdateCommissionsSettingsLevel>
  ): AxiosPromise<CommissionsSettingsLevel> => {
    return apiService.put(UPDATE_COMMISSIONS_LEVEL.getParametrizedUrl(request.id), request.object);
  },
  deleteCommissionsLevel: (request: EntityIdObject): AxiosPromise => {
    return apiService.delete(DELETE_COMMISSIONS_LEVEL.getParametrizedUrl(request.id));
  },
  createCommissionsLevelRule: (
    request: EntityObject<CreateUpdateCommissionsSettingsLevelRule>
  ): AxiosPromise<CommissionsSettingsLevelRule> => {
    return apiService.post(CREATE_COMMISSIONS_LEVEL_RULE.getParametrizedUrl(request.id), request.object);
  },
  updateCommissionsLevelRule: (
    request: TwoLevelEntityObject<CreateUpdateCommissionsSettingsLevelRule>
  ): AxiosPromise<CommissionsSettingsLevelRule> => {
    return apiService.put(UPDATE_COMMISSIONS_LEVEL_RULE.getParametrizedUrl(request.id1, request.id2), request.object);
  },
  deleteCommissionsLevelRule: (request: TwoLevelEntityIdObject): AxiosPromise => {
    return apiService.delete(DELETE_COMMISSIONS_LEVEL_RULE.getParametrizedUrl(request.id1, request.id2));
  },
  createCommissionsLevelBailAccountSettings: (
    request: EntityObject<CreateUpdateBailAccountSettings>
  ): AxiosPromise<BailAccountSettings> => {
    return apiService.post(
      CREATE_COMMISSIONS_LEVEL_BAIL_ACCOUNT_SETTINGS.getParametrizedUrl(request.id),
      request.object
    );
  },
  updateCommissionsLevelBailAccountSettings: (
    request: EntityObject<CreateUpdateBailAccountSettings>
  ): AxiosPromise<BailAccountSettings> => {
    return apiService.put(
      UPDATE_COMMISSIONS_LEVEL_BAIL_ACCOUNT_SETTINGS.getParametrizedUrl(request.id),
      request.object
    );
  },
  deleteCommissionsLevelBailAccountSettings: (request: EntityIdObject): AxiosPromise => {
    return apiService.delete(DELETE_COMMISSIONS_LEVEL_BAIL_ACCOUNT_SETTINGS.getParametrizedUrl(request.id));
  },
  uploadCommissionsLevelAttachments: (
    request: EntityObject<FormData>
  ): AxiosPromise<CommissionsSettingsLevelAttachment[]> => {
    return apiService.post(UPLOAD_COMMISSIONS_LEVEL_ATTACHMENTS.getParametrizedUrl(request.id), request.object, {
      headers: { "Content-Type": "multipart/form-data" }
    });
  },
  downloadCommissionsLevelAttachment: (request: TwoLevelEntityIdObject): AxiosPromise<Blob> => {
    return apiService.get(DOWNLOAD_COMMISSIONS_LEVEL_ATTACHMENT.getParametrizedUrl(request.id1, request.id2), {
      responseType: "blob"
    });
  },
  deleteCommissionsLevelAttachment: (request: TwoLevelEntityIdObject): AxiosPromise => {
    return apiService.delete(DELETE_COMMISSIONS_LEVEL_ATTACHMENT.getParametrizedUrl(request.id1, request.id2));
  }
};

export default api;
