import { Button, Card } from "antd";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import AntIcon from "../../../common/components/icons/AntIcon";
import ContentWrapper from "../../../common/modules/wrappers/ContentWrapper";
import { getEnumerationsActions } from "../../enumerations/ducks";
import { AgentEducationFilterForm } from "../components/forms/AgentEducationFIlterForm";
import { AgentEducationModalForm } from "../components/forms/AgentEducationModalForm";
import { AgentEducationTableView } from "../components/views/education/AgentEducationTableView";
import { AgentEducation } from "../types";

export const AgentEducationContainer = () => {
  const { t } = useTranslation();

  const [isOpenModalForm, setIsOpenModalForm] = useState(false);

  const [education, setEducation] = useState<AgentEducation>();

  const dispatch = useDispatch();
  const actions = useMemo(
    () => bindActionCreators({ getEnumerations: getEnumerationsActions.request }, dispatch),
    [dispatch]
  );

  const handleCancelModalForm = () => {
    setEducation(undefined);
    setIsOpenModalForm(false);
    actions.getEnumerations();
  };

  const handleChangeAgentEducation = (education: AgentEducation) => {
    setEducation(education);
    setIsOpenModalForm(true);
  };

  const handleClickCreateAgentEducation = () => {
    setEducation(undefined);
    setIsOpenModalForm(true);
  };

  return (
    <ContentWrapper>
      <Card
        className="card-box"
        title={<h2>{t("agent.education.titles.admin")}</h2>}
        extra={
          <Button type="primary" icon={<AntIcon type="plus" />} onClick={handleClickCreateAgentEducation}>
            {t("agent.actions.addAgentEducation")}
          </Button>
        }
      >
        <AgentEducationFilterForm />
      </Card>

      <Card className="card-box">
        <AgentEducationTableView onChange={handleChangeAgentEducation} />
      </Card>

      <AgentEducationModalForm isOpen={isOpenModalForm} education={education} onCancel={handleCancelModalForm} />
    </ContentWrapper>
  );
};
