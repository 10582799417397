import { Button, Flex, Form, Modal, Tabs } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import t from "../../../../../app/i18n";
import { ModalSizes } from "../../../../../common/constants";
import { RootState } from "../../../../../common/types";
import { selectUserAccount } from "../../../../auth/ducks";
import { UserAccount } from "../../../../user/types";
import { CalcType } from "../../../enums";
import type { RealtyCalc, RealtyCalcResultData, RealtyFormClients, RealtyGenForm } from "../../realty/types";
import type { TravelCalc, TravelCalcResultData, TravelFormClients, TravelGenForm } from "../../travel/types";
import type { CalcResult, GenGainersData } from "../../types";
import type { VehicleCalc, VehicleCalcResultData, VehicleFormClients, VehicleGenForm } from "../../vehicle/types";
import ClientApprovalsCalcSummaryView from "./ClientApprovalsCalcSummaryView";
import RealtyCalcSummaryView from "./RealtyCalcSummaryView";
import TravelCalcSummaryView from "./TravelCalcSummaryView";
import VehicleCalcSummaryView from "./VehicleCalcSummaryView";

export type VehicleCalcSummaryViewData = {
  calcData: VehicleCalc;
  clients: VehicleFormClients;
  selectedCalcResult: CalcResult<VehicleCalcResultData>;
  formData: VehicleGenForm;
};

export type RealtyCalcSummaryViewData = {
  calcData: RealtyCalc;
  clients: RealtyFormClients;
  selectedCalcResult: CalcResult<RealtyCalcResultData>;
  formData: RealtyGenForm;
};

export type TravelCalcSummaryViewData = {
  calcData: TravelCalc;
  clients: TravelFormClients;
  selectedCalcResult: CalcResult<TravelCalcResultData>;
  formData: TravelGenForm;
};

interface Props {
  open: boolean;
  data: TravelCalcSummaryViewData | RealtyCalcSummaryViewData | VehicleCalcSummaryViewData;
  onOkClick: (gainersData?: GenGainersData) => void;
  onCancelClick: () => void;
}

enum SUMMARY_TABS_KEY {
  CONTRACT_DATA = "contract-data",
  CLIENT_APPROVALS = "client-approvals"
}

export const CalcSummaryModal = ({ open, onOkClick, onCancelClick, data }: Props) => {
  const [approvals, setApprovals] = useState<boolean[]>(Array(6).fill(false));
  const [allApprovals, setAllApprovals] = useState<boolean>(false);
  const [currentTab, setCurrentTab] = useState<string>(SUMMARY_TABS_KEY.CONTRACT_DATA);
  const [contractSubmitted, setContractSubmitted] = useState<boolean>(false);
  const userAccount = useSelector<RootState, UserAccount | undefined>(selectUserAccount);
  const [gainersDataForm] = Form.useForm<GenGainersData>();
  const [divideCommission, setDivideCommission] = useState(!userAccount?.agent?.canBeGainer);

  useEffect(() => {
    if (open) {
      setApprovals(Array(6).fill(false, 0, 6));
      setAllApprovals(false);
      setContractSubmitted(false);
    }
  }, [open]);

  useEffect(() => {
    const isAllApproved = approvals.every(approval => approval);

    if (!isAllApproved && allApprovals) {
      setAllApprovals(false);
    } else if (approvals.every(approval => approval)) {
      setAllApprovals(true);
    }
  }, [approvals, allApprovals]);

  const handleAllApprovalsChange = (): void => {
    setAllApprovals(!allApprovals);
    setApprovals(Array(6).fill(!allApprovals, 0, 6));
  };

  const handleApprovalChange = (checked: boolean, index: number): void => {
    const updatedApprovals = [...approvals];
    updatedApprovals[index] = checked;
    setApprovals(updatedApprovals);
  };

  const handleGenerateContractClick = (): void => {
    setContractSubmitted(true);
    const gainersData = divideCommission ? gainersDataForm.getFieldsValue() : undefined;
    onOkClick(gainersData);
  };

  const getCalcTypeSummaryView = (): React.JSX.Element | undefined => {
    switch (data.calcData.type) {
      case CalcType.MTPL:
      case CalcType.CRASH:
      case CalcType.MTPL_CRASH:
      case CalcType.GAP:
      case CalcType.PAS:
        return (
          <VehicleCalcSummaryView
            data={{ ...(data as VehicleCalcSummaryViewData) }}
            divideCommission={divideCommission}
            setDivideCommission={setDivideCommission}
            gainersDataForm={gainersDataForm}
          />
        );
      case CalcType.TRAVEL:
        return (
          <TravelCalcSummaryView
            data={{ ...(data as TravelCalcSummaryViewData) }}
            divideCommission={divideCommission}
            setDivideCommission={setDivideCommission}
            gainersDataForm={gainersDataForm}
          />
        );
      case CalcType.REALTY:
        return (
          <RealtyCalcSummaryView
            data={{ ...(data as RealtyCalcSummaryViewData) }}
            divideCommission={divideCommission}
            setDivideCommission={setDivideCommission}
            gainersDataForm={gainersDataForm}
          />
        );
      default:
        return undefined;
    }
  };

  return (
    <Modal
      title={
        currentTab === SUMMARY_TABS_KEY.CONTRACT_DATA
          ? t("calc.summary.title")
          : t("calc.helpers.clientApprovals.title")
      }
      open={open}
      width={ModalSizes.LARGE}
      onCancel={onCancelClick}
      centered
      footer={
        <Flex justify="space-between">
          <div className="left-align" style={{ marginTop: 4, width: 120 }}>
            {currentTab === SUMMARY_TABS_KEY.CLIENT_APPROVALS ? (
              <Button onClick={() => setCurrentTab(SUMMARY_TABS_KEY.CONTRACT_DATA)}>{t("common.back")}</Button>
            ) : undefined}
          </div>
          <div className="center-align">
            {currentTab === SUMMARY_TABS_KEY.CONTRACT_DATA ? (
              <Button
                type="primary"
                size="large"
                onClick={() => {
                  if (divideCommission) {
                    gainersDataForm.validateFields().then(() => {
                      setCurrentTab(SUMMARY_TABS_KEY.CLIENT_APPROVALS);
                    });
                  } else {
                    setCurrentTab(SUMMARY_TABS_KEY.CLIENT_APPROVALS);
                  }
                }}
              >
                {t("common.continue")}
              </Button>
            ) : (
              <Button
                type="primary"
                size="large"
                disabled={approvals.some(approval => !approval) || contractSubmitted}
                onClick={handleGenerateContractClick}
              >
                {t("calc.actions.generateContract")}
              </Button>
            )}
          </div>
          <div className="right-align" style={{ marginTop: 4, width: 120 }}>
            <Button onClick={onCancelClick}>{t("common.close")}</Button>
          </div>
        </Flex>
      }
    >
      <div style={{ overflowY: "auto", maxHeight: 925 }}>
        <Tabs
          activeKey={currentTab.toString()}
          onChange={setCurrentTab}
          renderTabBar={() => <></>}
          items={[
            {
              key: SUMMARY_TABS_KEY.CONTRACT_DATA,
              label: undefined,
              children: getCalcTypeSummaryView()
            },
            {
              key: SUMMARY_TABS_KEY.CLIENT_APPROVALS,
              label: undefined,
              children: (
                <ClientApprovalsCalcSummaryView
                  approvals={approvals}
                  allApprovals={allApprovals}
                  handleAllApprovals={handleAllApprovalsChange}
                  handleApprovalChange={handleApprovalChange}
                />
              )
            }
          ]}
        />
      </div>
    </Modal>
  );
};
