import { Card, Modal, Tabs, TabsProps } from "antd";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { generatePath, Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { bindActionCreators } from "redux";
import { SecondaryButton } from "../../../common/components/buttons/SecondaryButton";
import AntIcon from "../../../common/components/icons/AntIcon";
import BackNavigationArrow from "../../../common/components/views/BackNavigationArrow";
import ItemCreatedUpdatedInfoView from "../../../common/components/views/ItemCreatedUpdatedInfoView";
import HistoryView from "../../../common/modules/history/HistoryView";
import ContentWrapper from "../../../common/modules/wrappers/ContentWrapper";
import DisplayWrapper from "../../../common/modules/wrappers/DisplayWrapper";
import ComponentWithPermission from "../../../common/security/authorization/ComponentWithPermission";
import { Permission } from "../../../common/security/authorization/enums";
import { EntityIdObject, RootState } from "../../../common/types";
import { formatAgentAggregatedName } from "../../../common/utils/formatUtils";
import { useRequestFinishedCallback } from "../../../common/utils/hooksUtils";
import { appendSearchParamsToURL, hasPermission } from "../../../common/utils/utils";
import { selectPermissions } from "../../auth/ducks";
import {
  adminCreateUserWithSingleAgentUserAccountActions,
  adminDeleteAgentUserAccountActions,
  adminGetUsersByAgentActions,
  adminResendUserConfirmLinkActions,
  adminUpdateAgentUserAccountActions,
  adminUpdateUserActions,
  deleteStateAdminUsersByAgentAction,
  selectAdminUsersByAgent
} from "../../user/ducks";
import { UserAdminView } from "../../user/types";
import { requests } from "../api";
import { AgentForm } from "../components/forms/agent/AgentForm";
import AgentBrandingForm from "../components/forms/AgentBrandingForm";
import AgentTypeTag from "../components/tags/AgentTypeTag";
import AgentDetailView from "../components/views/detail/agent/AgentDetailView";
import AgentAttachmentsTableView from "../components/views/detail/AgentAttachmentsTableView";
import AgentBrokerNumberTableView from "../components/views/detail/AgentBrokerNumberTableView";
import AgentCompetenceTableView from "../components/views/detail/AgentCompetenceTableView";
import { AgentCompletedEducationTableView } from "../components/views/detail/AgentCompletedEducationTableView";
import AgentLicenseTableView from "../components/views/detail/AgentLicenseTableView";
import { AgentMentoringTableView } from "../components/views/detail/AgentMentoringTableView";
import AgentUserAccountsView from "../components/views/detail/AgentUserAccountsView";
import {
  createAgentBrandingActions,
  createAgentBrokerNumberActions,
  createAgentCompetenceActions,
  createAgentLicenseActions,
  deleteAgentActions,
  deleteAgentAttachmentActions,
  deleteAgentBrandingActions,
  deleteAgentBrokerNumberActions,
  deleteAgentCompetenceActions,
  deleteAgentLicenseActions,
  deleteStateAgentDetailAction,
  downloadAgentAttachmentsAsZipActions,
  getAgentActions,
  getAgentBrandingActions,
  getAgentBrokerNumbersActions,
  getAgentCompetencesActions,
  getAgentLicensesActions,
  selectAgentBranding,
  selectAgentBrokerNumbers,
  selectAgentCompetences,
  selectAgentDetail,
  selectAgentLicenses,
  updateAgentActions,
  updateAgentBrandingActions,
  updateAgentBrokerNumberActions,
  updateAgentCompetenceActions,
  updateAgentLicenseActions,
  uploadAgentAttachmentsActions
} from "../ducks";
import { AgentType } from "../enums";
import { AGENT_ROUTE_PATHS } from "../paths";
import { Agent, AgentBranding, AgentBrokerNumber, AgentCompetence, AgentLicense } from "../types";

const TAB = {
  AGENT: "agent",
  HISTORY: "history",
  ATTACHMENTS: "attachments",
  COMPETENCES: "competences",
  LICENSES: "licenses",
  BROKER_NUMBERS: "broker-numbers",
  EDUCATION: "education",
  MENTORING: "mentoring",
  ACCOUNTS: "accounts",
  BRANDING: "branding"
};

export const AgentDetailContainer = () => {
  const { t } = useTranslation();

  const { id } = useParams<EntityIdObject>() as { id: string };
  const navigate = useNavigate();
  const [urlParams] = useSearchParams();

  const [tabKey, setTabKey] = useState<string>(TAB.AGENT);
  const [agentUpdateMode, setAgentUpdateMode] = useState<boolean>(false);

  useRequestFinishedCallback([requests.UPDATE_AGENT], () => setAgentUpdateMode(false));

  const agent = useSelector<RootState, Agent | undefined>(selectAgentDetail);
  const competences = useSelector<RootState, AgentCompetence[]>(selectAgentCompetences);
  const licenses = useSelector<RootState, AgentLicense[]>(selectAgentLicenses);
  const brokerNumbers = useSelector<RootState, AgentBrokerNumber[]>(selectAgentBrokerNumbers);
  const branding = useSelector<RootState, AgentBranding | undefined>(selectAgentBranding);
  const usersByAgent = useSelector<RootState, UserAdminView[]>(selectAdminUsersByAgent);
  const permissions = useSelector<RootState, Permission[]>(selectPermissions);
  const urlTabKey = urlParams.get("tab");

  const dispatch = useDispatch();
  const actions = useMemo(
    () =>
      bindActionCreators(
        {
          getAgent: getAgentActions.request,
          updateAgent: updateAgentActions.request,
          deleteAgent: deleteAgentActions.request,
          deleteStateAgentDetail: deleteStateAgentDetailAction,
          downloadAgentAttachmentsAsZip: downloadAgentAttachmentsAsZipActions.request,
          uploadAgentAttachments: uploadAgentAttachmentsActions.request,
          deleteAgentAttachment: deleteAgentAttachmentActions.request,
          getAgentCompetences: getAgentCompetencesActions.request,
          createAgentCompetence: createAgentCompetenceActions.request,
          updateAgentCompetence: updateAgentCompetenceActions.request,
          deleteAgentCompetence: deleteAgentCompetenceActions.request,
          getAgentLicenses: getAgentLicensesActions.request,
          createAgentLicense: createAgentLicenseActions.request,
          updateAgentLicense: updateAgentLicenseActions.request,
          deleteAgentLicense: deleteAgentLicenseActions.request,
          getAgentBrokerNumbers: getAgentBrokerNumbersActions.request,
          createAgentBrokerNumber: createAgentBrokerNumberActions.request,
          updateAgentBrokerNumber: updateAgentBrokerNumberActions.request,
          deleteAgentBrokerNumber: deleteAgentBrokerNumberActions.request,
          getAgentBranding: getAgentBrandingActions.request,
          createAgentBranding: createAgentBrandingActions.request,
          updateAgentBranding: updateAgentBrandingActions.request,
          deleteAgentBranding: deleteAgentBrandingActions.request,
          adminGetUsersByAgent: adminGetUsersByAgentActions.request,
          adminCreateOrUpdateUserWithAgentUserAccount: adminCreateUserWithSingleAgentUserAccountActions.request,
          adminUpdateUser: adminUpdateUserActions.request,
          adminResendUserConfirmLink: adminResendUserConfirmLinkActions.request,
          adminUpdateAgentUserAccount: adminUpdateAgentUserAccountActions.request,
          adminDeleteAgentUserAccount: adminDeleteAgentUserAccountActions.request,
          deleteStateAdminUsersByAgent: deleteStateAdminUsersByAgentAction
        },
        dispatch
      ),
    [dispatch]
  );

  const {
    hasAgentUpdatePermission,
    hasAgentCompetencePermission,
    hasAgentCompetenceManagePermission,
    hasAgentLicensePermission,
    hasAgentLicenseManagePermission,
    hasAgentBrokerNumberPermission,
    hasAgentBrokerNumberManagePermission,
    hasAdminUsersPermission,
    hasAdminTopAgentsPermission,
    hasAgentEducationPermission,
    hasAgentEducationManagePermission,
    hasAgentMentoringPermission,
    hasAgentMentoringManagePermission
  } = useMemo(
    () => ({
      hasAgentUpdatePermission: hasPermission(permissions, Permission.AGENT_UPDATE),
      hasAgentCompetencePermission: hasPermission(permissions, Permission.AGENT_COMPETENCE),
      hasAgentCompetenceManagePermission: hasPermission(permissions, Permission.AGENT_COMPETENCE_MANAGE),
      hasAgentLicensePermission: hasPermission(permissions, Permission.AGENT_LICENSE),
      hasAgentLicenseManagePermission: hasPermission(permissions, Permission.AGENT_LICENSE_MANAGE),
      hasAgentBrokerNumberPermission: hasPermission(permissions, Permission.AGENT_BROKER_NUMBER),
      hasAgentBrokerNumberManagePermission: hasPermission(permissions, Permission.AGENT_BROKER_NUMBER_MANAGE),
      hasAdminUsersPermission: hasPermission(permissions, Permission.ADMIN_USERS),
      hasAdminTopAgentsPermission: hasPermission(permissions, Permission.ADMIN_TOP_AGENTS),
      hasAgentEducationPermission: hasPermission(permissions, Permission.AGENT_EDUCATION),
      hasAgentEducationManagePermission: hasPermission(permissions, Permission.AGENT_EDUCATION_MANAGE),
      hasAgentMentoringPermission: hasPermission(permissions, Permission.AGENT_MENTORING),
      hasAgentMentoringManagePermission: hasPermission(permissions, Permission.AGENT_MENTORING_MANAGE)
    }),
    [permissions]
  );

  useEffect(() => {
    switch (urlTabKey) {
      case TAB.AGENT:
      case TAB.HISTORY:
      case TAB.ATTACHMENTS:
        setTabKey(urlTabKey);
        break;
      case TAB.COMPETENCES:
        setTabKey(hasAgentCompetencePermission ? urlTabKey : TAB.AGENT);
        break;
      case TAB.LICENSES:
        setTabKey(hasAgentLicensePermission ? urlTabKey : TAB.AGENT);
        break;
      case TAB.BROKER_NUMBERS:
        setTabKey(hasAgentBrokerNumberPermission ? urlTabKey : TAB.AGENT);
        break;
      case TAB.EDUCATION:
        setTabKey(hasAgentEducationPermission ? urlTabKey : TAB.AGENT);
        break;
      case TAB.MENTORING:
        setTabKey(hasAgentMentoringPermission ? urlTabKey : TAB.AGENT);
        break;
      case TAB.ACCOUNTS:
        setTabKey(hasAdminUsersPermission ? urlTabKey : TAB.AGENT);
        break;
      case TAB.BRANDING:
        setTabKey(hasAdminTopAgentsPermission ? urlTabKey : TAB.AGENT);
        break;
      default:
        setTabKey(TAB.AGENT);
        break;
    }
  }, [urlTabKey]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (id) {
      actions.getAgent({ id });
    }
  }, [id]);

  useEffect(() => {
    return () => {
      actions.deleteStateAgentDetail();
    };
  }, []);

  const moveViewToTab = (tabKey: string): void => {
    navigate(appendSearchParamsToURL({ tab: tabKey }), { replace: true });
  };

  const handleTabKeyChange = (key: string): void => {
    if (tabKey === TAB.AGENT && agentUpdateMode) {
      Modal.confirm({
        title: t("agent.helpers.unsavedChanges"),
        okText: t("agent.helpers.unsavedChangesConfirm"),
        cancelText: t("common.back"),
        onOk: () => moveViewToTab(key)
      });
    } else {
      moveViewToTab(key);
    }
  };

  const getTabsItems = (agent: Agent) => {
    const items: TabsProps["items"] = [
      {
        key: TAB.AGENT,
        icon: <AntIcon type="solution" />,
        label: t("agent.titles.data"),
        children: agentUpdateMode ? (
          <AgentForm agent={agent} onUpdate={actions.updateAgent} onCancelClick={() => setAgentUpdateMode(false)} />
        ) : (
          <AgentDetailView
            agent={agent}
            onUpdateClick={() => setAgentUpdateMode(true)}
            onDelete={actions.deleteAgent}
          />
        )
      },
      {
        key: TAB.HISTORY,
        icon: <AntIcon type="history" />,
        label: t("agent.titles.history"),
        children: <HistoryView item={agent} translationRootPath="agent.attrs" type="agent" />
      },
      {
        key: TAB.ATTACHMENTS,
        icon: <AntIcon type="paper-clip" />,
        label: t("agent.titles.attachments"),
        children: (
          <AgentAttachmentsTableView
            agent={agent}
            onDownloadAsZip={actions.downloadAgentAttachmentsAsZip}
            onUpload={hasAgentUpdatePermission ? actions.uploadAgentAttachments : undefined}
            onDelete={hasAgentUpdatePermission ? actions.deleteAgentAttachment : undefined}
          />
        )
      }
    ];

    if (agent.type !== AgentType.LEGAL && hasAgentCompetencePermission) {
      items.push({
        key: TAB.COMPETENCES,
        icon: <AntIcon type="file-done" />,
        label: t("agent.titles.competences"),
        children: (
          <AgentCompetenceTableView
            agentId={agent.id}
            competences={competences}
            showAllColumns
            showActions={hasAgentCompetenceManagePermission}
            onGet={actions.getAgentCompetences}
            onCreate={actions.createAgentCompetence}
            onUpdate={actions.updateAgentCompetence}
            onDelete={actions.deleteAgentCompetence}
          />
        )
      });
    }

    if (hasAgentLicensePermission) {
      items.push({
        key: TAB.LICENSES,
        icon: <AntIcon type="bank" />,
        label: t("agent.titles.licenses"),
        children: (
          <AgentLicenseTableView
            agentId={agent.id}
            defaultLicenseValue={agent.nbsRegistrationNumber}
            licenses={licenses}
            showAllColumns
            showActions={hasAgentLicenseManagePermission}
            onGet={actions.getAgentLicenses}
            onCreate={actions.createAgentLicense}
            onUpdate={actions.updateAgentLicense}
            onDelete={actions.deleteAgentLicense}
          />
        )
      });
    }

    if (hasAgentBrokerNumberPermission) {
      items.push({
        key: TAB.BROKER_NUMBERS,
        icon: <AntIcon type="id-card" />,
        label: t("agent.titles.brokerNumbers"),
        children: (
          <AgentBrokerNumberTableView
            agentId={agent.id}
            brokerNumbers={brokerNumbers}
            showAllColumns
            showActions={hasAgentBrokerNumberManagePermission}
            onGet={actions.getAgentBrokerNumbers}
            onCreate={actions.createAgentBrokerNumber}
            onUpdate={actions.updateAgentBrokerNumber}
            onDelete={actions.deleteAgentBrokerNumber}
          />
        )
      });
    }

    if (hasAgentEducationPermission) {
      items.push({
        key: TAB.EDUCATION,
        icon: <AntIcon type="reconciliation" />,
        label: t("agent.titles.education"),
        children: (
          <AgentCompletedEducationTableView agentId={agent.id} showActions={hasAgentEducationManagePermission} />
        )
      });
    }

    if (hasAgentMentoringPermission) {
      items.push({
        key: TAB.MENTORING,
        icon: <AntIcon type="projection" />,
        label: t("agent.titles.mentoring"),
        children: <AgentMentoringTableView agentId={agent.id} showActions={hasAgentMentoringManagePermission} />
      });
    }

    if (hasAdminUsersPermission) {
      items.push({
        key: TAB.ACCOUNTS,
        icon: <AntIcon type="user-switch" />,
        label: t("agent.titles.users"),
        children: (
          <AgentUserAccountsView
            agent={agent}
            users={usersByAgent}
            onGetUsers={actions.adminGetUsersByAgent}
            onCreateUser={actions.adminCreateOrUpdateUserWithAgentUserAccount}
            onUpdateUser={actions.adminUpdateUser}
            onResendUserConfirmLink={actions.adminResendUserConfirmLink}
            onUpdateUserAccount={actions.adminUpdateAgentUserAccount}
            onDeleteUserAccount={actions.adminDeleteAgentUserAccount}
            onDeleteStateAdminUsersByAgent={actions.deleteStateAdminUsersByAgent}
          />
        )
      });
    }

    if (hasAdminTopAgentsPermission) {
      items.push({
        key: TAB.BRANDING,
        icon: <AntIcon type="bg-colors" />,
        label: t("agent.titles.branding"),
        children: (
          <AgentBrandingForm
            branding={branding}
            agent={agent}
            onGet={actions.getAgentBranding}
            onCreate={actions.createAgentBranding}
            onUpdate={actions.updateAgentBranding}
            onDelete={actions.deleteAgentBranding}
          />
        )
      });
    }

    return items;
  };

  return (
    <DisplayWrapper itemLoaded={!!agent} notFoundCheckRequest={requests.GET_AGENT}>
      {agent && (
        <ContentWrapper>
          <Card
            className="card-box"
            title={
              <>
                <BackNavigationArrow>
                  <h2 className="left-float">{formatAgentAggregatedName(agent)}</h2>
                </BackNavigationArrow>
                <AgentTypeTag style={{ margin: "6px 0 0 8px" }} type={agent.type} />
              </>
            }
            extra={
              <ComponentWithPermission permissions={[Permission.AGENT_COMMISSIONS]}>
                <Link to={generatePath(AGENT_ROUTE_PATHS.commissions.to, { id: agent.id })}>
                  <SecondaryButton size="small" icon={<AntIcon type="euro" />}>
                    {t("agent.actions.goToAgentCommissions")}
                  </SecondaryButton>
                </Link>
              </ComponentWithPermission>
            }
          >
            <ItemCreatedUpdatedInfoView item={agent} className="clear-both margin-bottom-small" />

            <Tabs activeKey={tabKey} onChange={handleTabKeyChange} items={getTabsItems(agent)} />
          </Card>
        </ContentWrapper>
      )}
    </DisplayWrapper>
  );
};
