import { Checkbox, DatePicker, Form, Modal } from "antd";
import { useForm } from "antd/es/form/Form";
import { Rule } from "antd/lib/form";
import dayjs from "dayjs";
import { t } from "i18next";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useCreateAgentMentoring, useUpdateAgentMentoring } from "../../../../common/api/queries/agentMentorings";
import HiddenInput from "../../../../common/components/form/components/HiddenInput";
import { ModalSizes } from "../../../../common/constants";
import {
  datePickerFormItemProps,
  datePickerStandardProps,
  dateToIsoDateString,
  setErrorsToForm
} from "../../../../common/utils/formUtils";
import { validations } from "../../../../common/utils/validationUtils";
import { UUID } from "../../../../typings/global";
import AgentSelect from "../../../enumerations/components/form/AgentSelect";
import { selectAllAgentsEnums } from "../../../enumerations/ducks";
import { AgentBase, AgentMentoring, CreateUpdateAgentMentoring } from "../../types";
import { convertAgentMentoringToCreateUpdateObject } from "../../utils";

const mentorOrAdeptIsCurrentUserAgent =
  <T extends AgentBase>(currentAgent: T): Rule =>
  ({ getFieldValue }) => ({
    validator: () => {
      const mentorId: string | undefined = getFieldValue("mentorId");
      const adeptId: string | undefined = getFieldValue("adeptId");

      if (!mentorId || !adeptId) {
        return Promise.resolve();
      }

      if (mentorId === currentAgent.id || adeptId === currentAgent.id) {
        return Promise.resolve();
      }

      return Promise.reject(
        t("agent.mentoring.helpers.validationMentorAdeptAsCurrentAgent", { agentName: currentAgent.aggregatedName })
      );
    }
  });

type Props = {
  isOpen: boolean;
  agentId: UUID;
  mentoring?: AgentMentoring;
  onCancel: VoidFunction;
};

export const AgentMentoringModalForm = ({ isOpen, mentoring, onCancel, agentId }: Props) => {
  const { t } = useTranslation();

  const currentAgent = useSelector(selectAllAgentsEnums).find(agentEnum => agentEnum.id === agentId);

  const createAgentMentoring = useCreateAgentMentoring();
  const updateAgentMentoring = useUpdateAgentMentoring();

  const [form] = useForm<CreateUpdateAgentMentoring>();
  const startDate = Form.useWatch("startDate", form);
  const endDate = startDate ? dateToIsoDateString(dayjs(startDate).add(1, "year").subtract(1, "day")) : undefined;

  useEffect(() => {
    if (isOpen && mentoring) {
      const formData = convertAgentMentoringToCreateUpdateObject(mentoring);

      form.setFieldsValue(formData);
    }
  }, [isOpen, form, mentoring]);

  useEffect(() => {
    if (endDate) {
      form.setFieldValue("endDate", endDate);
    }
  }, [form, endDate]);

  const handleFormSubmit = () => {
    form.validateFields().then(async values => {
      if (mentoring) {
        updateAgentMentoring.mutate(
          {
            id: mentoring.id,
            object: values
          },
          {
            onSuccess: onCancel,
            onError: error => {
              setErrorsToForm(form, "agent.mentoring.attrs", error.violations);
            }
          }
        );
      } else {
        createAgentMentoring.mutate(values, {
          onSuccess: onCancel,
          onError: error => {
            setErrorsToForm(form, "agent.mentoring.attrs", error.violations);
          }
        });
      }
    });
  };

  return (
    <Modal
      width={ModalSizes.SMALL}
      open={isOpen}
      title={mentoring ? t("agent.mentoring.titles.updateMentoring") : t("agent.mentoring.titles.createMentoring")}
      cancelText={t("common.cancel")}
      okText={t("common.save")}
      confirmLoading={createAgentMentoring.isPending || updateAgentMentoring.isPending}
      afterClose={() => form.resetFields()}
      onOk={handleFormSubmit}
      onCancel={onCancel}
    >
      <Form form={form} layout="vertical" name="agentMentoringForm">
        <HiddenInput name="optimisticLockVersion" />

        <AgentSelect
          formItemProps={{
            name: "mentorId",
            label: t("agent.mentoring.attrs.mentorId"),
            dependencies: ["adeptId"],
            rules: [
              validations.notNull,
              validations.notEqualTo("adeptId", t("agent.mentoring.attrs.adeptId")),
              currentAgent ? mentorOrAdeptIsCurrentUserAgent(currentAgent) : validations.none
            ]
          }}
          optionsProps={{ currentAgentId: currentAgent ? currentAgent.id : undefined }}
        />

        <AgentSelect
          formItemProps={{
            name: "adeptId",
            label: t("agent.mentoring.attrs.adeptId"),
            dependencies: ["mentorId"],
            rules: [
              validations.notNull,
              validations.notEqualTo("mentorId", t("agent.mentoring.attrs.mentorId")),
              currentAgent ? mentorOrAdeptIsCurrentUserAgent(currentAgent) : validations.none
            ]
          }}
          optionsProps={{ currentAgentId: currentAgent ? currentAgent.id : undefined }}
        />

        <Form.Item
          name="startDate"
          label={t("agent.mentoring.attrs.startDate")}
          rules={[validations.notNull]}
          {...datePickerFormItemProps}
        >
          <DatePicker {...datePickerStandardProps} />
        </Form.Item>

        <Form.Item
          name="endDate"
          label={t("agent.mentoring.attrs.endDate")}
          rules={[validations.notNull]}
          {...datePickerFormItemProps}
        >
          <DatePicker {...datePickerStandardProps} disabled={true} />
        </Form.Item>

        <Form.Item name="completed" valuePropName="checked" rules={[validations.notNull]} initialValue={false}>
          <Checkbox>{t("agent.mentoring.attrs.completed")}</Checkbox>
        </Form.Item>
      </Form>
    </Modal>
  );
};
