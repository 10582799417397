import { AxiosPromise } from "axios";
import { ApiRequestAdapter } from "../../common/api/ApiRequestAdapter";
import { apiService } from "../../common/api/apiService";
import { HttpContentType, HttpHeaders, HttpMethod } from "../../common/api/http";
import { ContractFormSearchRequest, ContractFormSearchResult, GenerateContractTermination } from "./types";

const SEARCH_CONTRACT = ApiRequestAdapter.create("/contract-forms/search", HttpMethod.GET);
const GENERATE_CONTRACT_TERMINATION = ApiRequestAdapter.create("/contract-forms/generate-termination", HttpMethod.POST);
const REGISTER_VEHICLE_DATA = ApiRequestAdapter.create("/contract-forms/register-vehicle-data", HttpMethod.POST);

export const contractFormRequests = {
  SEARCH_CONTRACT,
  GENERATE_CONTRACT_TERMINATION,
  REGISTER_VEHICLE_DATA
};

export const contractFormApi = {
  searchContract: (request: ContractFormSearchRequest): AxiosPromise<ContractFormSearchResult> => {
    return apiService.get(SEARCH_CONTRACT.url, { params: request });
  },
  generateContractTermination: (request: GenerateContractTermination): AxiosPromise<Blob> => {
    return apiService.post(GENERATE_CONTRACT_TERMINATION.url, request, { responseType: "blob" });
  },
  registerVehicleData: (request: FormData): AxiosPromise<void> => {
    return apiService.post(REGISTER_VEHICLE_DATA.url, request, {
      headers: { [HttpHeaders.CONTENT_TYPE]: HttpContentType.MULTIPART_FORM_DATA[0] }
    });
  }
};
