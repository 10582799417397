import classNames from "classnames";
import { useContext } from "react";
import { SortableItemContext } from "../../../../../common/components/sortable/SortableItemContext";
import styles from "./DragHandle.module.scss";

type Props = {
  isDraggable: boolean;
};

export const DragHandle = ({ isDraggable }: Props) => {
  const { draggableElementProps } = useContext(SortableItemContext);

  return (
    <div
      {...draggableElementProps}
      className={classNames(styles.dragBox, { [styles.hoverable as string]: isDraggable })}
    >
      <span className={styles.dots}></span>
    </div>
  );
};
