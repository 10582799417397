import { configureStore } from "@reduxjs/toolkit";
import localForage from "localforage";
import { persistReducer, persistStore } from "redux-persist";
import createSagaMiddleware from "redux-saga";
import { isLocalhostDevMode } from "../../common/utils/utils";
import { syncTabsMiddleware } from "./middlewares/syncTabsMiddleware";
import { createReduxHistory, routerMiddleware } from "./rootHistory";
import rootReducer from "./rootReducer";
import rootSaga from "./rootSaga";

const sagaMiddleware = createSagaMiddleware();

const persistedReducer = persistReducer(
  {
    key: "root",
    storage: localForage,
    whitelist: ["auth", "enumerations", "persistData"],
    debug: isLocalhostDevMode()
  },
  // @ts-ignore ts-problem
  rootReducer
);

const middlewares = [sagaMiddleware, routerMiddleware, syncTabsMiddleware];

const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({ serializableCheck: false, immutableCheck: { warnAfter: 100 } }).concat(middlewares)
});

const history = createReduxHistory(store);
const persistor = persistStore(store);

sagaMiddleware.run(rootSaga);

export { store, history, persistor };
