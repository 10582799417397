export enum HttpHeaders {
  ACCEPT = "accept",
  AUTHORIZATION = "authorization",
  CONTENT_TYPE = "content-type",
  HOST = "host",
  LAST_MODIFIED = "last-modified",
  REFERER = "referer",
  USER_AGENT = "user-agent",
  X_FORWARDED_FOR = "x-forwarded-for"
}

export enum HttpMethod {
  GET = "GET",
  POST = "POST",
  DELETE = "DELETE",
  PUT = "PUT",
  PATCH = "PATCH"
}

export const HttpStatus = {
  OK: 200,
  CREATED: 201,
  NO_CONTENT: 204,
  MULTI_STATUS: 207,
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  METHOD_NOT_ALLOWED: 405,
  UNSUPPORTED_MEDIA_TYPE: 415,
  UNPROCESSABLE_ENTITY: 422,
  INTERNAL_SERVER_ERROR: 500
};

export const HttpContentType = {
  RAR: ["application/octet-stream", "application/x-compressed"],
  ZIP: ["application/x-zip-compressed"],
  PDF: ["application/pdf"],
  DOCX: ["application/vnd.openxmlformats-officedocument.wordprocessingml.document"],
  XLSX: ["application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"],
  PPTX: ["application/vnd.openxmlformats-officedocument.presentationml.presentation"],
  CSV: ["text/csv", "application/vnd.ms-excel"],
  MULTIPART_FORM_DATA: ["multipart/form-data"],
  JSON: ["application/json"]
};
