import { Card, Cascader, Checkbox, Col, Divider, Form, Input, InputNumber, Row, Select } from "antd";
import { FormInstance } from "antd/lib/form";
import dayjs from "dayjs";
import { DefaultOptionType } from "rc-select/lib/Select";
import { Fragment } from "react";
import t from "../../../../../../../app/i18n";
import InputAddon from "../../../../../../../common/components/form/addons/InputAddon";
import HiddenInput from "../../../../../../../common/components/form/components/HiddenInput";
import LabelWithTooltip from "../../../../../../../common/components/form/labels/LabelWithTooltip";
import { rowGutter } from "../../../../../../../common/constants";
import PlaceOfInsuranceForm from "../../../../../../../common/modules/address/PlaceOfInsuranceForm";
import { inputNumberIntegerStandardProps, selectStandardProps } from "../../../../../../../common/utils/formUtils";
import { numberOrZero } from "../../../../../../../common/utils/utils";
import { regexPatterns, validations } from "../../../../../../../common/utils/validationUtils";
import { ClientType } from "../../../../../../client/enums";
import { Client } from "../../../../../../client/types";
import CityAutoComplete from "../../../../../../enumerations/components/form/CityAutoComplete";
import InstitutionSelect from "../../../../../../enumerations/components/form/InstitutionSelect";
import { InstitutionEnum, InstitutionType } from "../../../../../../institution/enums";
import { ClientExperience } from "../../../../../enums";
import { CalcResult } from "../../../../types";
import { filterApplicableSuccessResults } from "../../../../utils";
import { BuildingState, BuildingType, HouseholdEquipmentType, NearbyBuildingType } from "../../../enums";
import { RealtyCalc, RealtyCalcResultData, RealtyGenForm } from "../../../types";
import FloorPopoverLabel from "../../FloorPopoverLabel";

interface Props {
  form: FormInstance<RealtyGenForm>;
  calcData: RealtyCalc;
  policyHolder?: Client;
  calcResults: CalcResult<RealtyCalcResultData>[][];
  selectedResult: CalcResult<RealtyCalcResultData>;
}

const ALLOWED_HOUSEHOLD_EQUIPMENTS = new Map<InstitutionEnum, HouseholdEquipmentType[]>([
  [
    InstitutionEnum.COLONNADE,
    [
      HouseholdEquipmentType.BIKE,
      HouseholdEquipmentType.E_BIKE,
      HouseholdEquipmentType.SCOOTER,
      HouseholdEquipmentType.E_SCOOTER,
      HouseholdEquipmentType.BIKE_TRAILER,
      HouseholdEquipmentType.BIKE_CART
    ]
  ],
  [
    InstitutionEnum.CSOB,
    [
      HouseholdEquipmentType.BIKE,
      HouseholdEquipmentType.E_BIKE,
      HouseholdEquipmentType.SCOOTER,
      HouseholdEquipmentType.E_SCOOTER,
      HouseholdEquipmentType.BIKE_TRAILER,
      HouseholdEquipmentType.BIKE_CART,
      HouseholdEquipmentType.OTHER
    ]
  ],
  [InstitutionEnum.PREMIUM, []],
  [
    InstitutionEnum.UNIQA,
    [HouseholdEquipmentType.BIKE, HouseholdEquipmentType.E_BIKE, HouseholdEquipmentType.STRUCTURAL_COMPONENTS]
  ]
]);

const RealtyGenOtherDataSection = ({ form, calcData, policyHolder, calcResults, selectedResult }: Props) => {
  const colSpan = 4;
  const bigColSpan = 8;

  const selectedInstitution = selectedResult.insuranceInstitution;
  const allianzSelected = selectedInstitution.institutionEnum === InstitutionEnum.ALLIANZ;
  const colonnadeSelected = selectedInstitution.institutionEnum === InstitutionEnum.COLONNADE;
  const csobSelected = selectedInstitution.institutionEnum === InstitutionEnum.CSOB;
  const generaliSelected = selectedInstitution.institutionEnum === InstitutionEnum.GENERALI;
  const premiumSelected = selectedInstitution.institutionEnum === InstitutionEnum.PREMIUM;
  const unionSelected = selectedInstitution.institutionEnum === InstitutionEnum.UNION;
  const uniqaSelected = selectedInstitution.institutionEnum === InstitutionEnum.UNIQA;

  let showGarageReinsurance = false;
  if (csobSelected) {
    showGarageReinsurance =
      (calcData.houseOrRecreationalBuildingData &&
        calcData.realtyInsuranceData?.nearbyBuildingReinsurances?.some(
          r => r.type === NearbyBuildingType.DETACHED_GARAGE && !r.placeOfGarageSameAsBuilding
        )) ||
      !!calcData.apartmentData?.garageArea;
  } else if (premiumSelected) {
    showGarageReinsurance = !!(
      calcData.houseOrRecreationalBuildingData &&
      !!calcData.realtyInsuranceData?.nearbyBuildingReinsurances?.some(
        r => r.type === NearbyBuildingType.DETACHED_GARAGE && !r.placeOfGarageSameAsBuilding
      )
    );
  } else if (unionSelected) {
    showGarageReinsurance = !!calcData.realtyInsuranceData?.nearbyBuildingReinsurances?.some(
      r =>
        r.type === NearbyBuildingType.DETACHED_GARAGE &&
        !r.placeOfGarageSameAsBuilding &&
        r.insuranceAmount > (calcData.realtyInsuranceData?.insuranceAmount ?? 0) * 0.3
    );
  }

  const anotherInsuranceCompanyEnabled = Form.useWatch(
    ["generalInsuranceData", "anotherInsuranceCompanyEnabled"],
    form
  );
  const photovoltaicIncluded = Form.useWatch(["generalInsuranceData", "photovoltaicIncluded"], form);
  const underConstruction = Form.useWatch(["realtyInsuranceData", "garageReinsurance", "underConstruction"], form);

  return (
    <Card type="inner" className="card-box" title={t("calc.realty.sections.otherData")}>
      {allianzSelected ||
      colonnadeSelected ||
      csobSelected ||
      generaliSelected ||
      premiumSelected ||
      unionSelected ||
      uniqaSelected ? (
        <>
          <Row gutter={rowGutter}>
            {generaliSelected ? (
              <Col span={colSpan}>
                <CityAutoComplete
                  formItemProps={{
                    name: ["generalInsuranceData", "signCity"],
                    label: t("calc.realty.attrs.generalInsuranceData.signCity"),
                    rules: [validations.notBlank, validations.size(1, 64), validations.pattern(regexPatterns.wordRegex)]
                  }}
                />
              </Col>
            ) : null}

            {(unionSelected || premiumSelected) && (
              <>
                {calcData.generalBuildingData.state === BuildingState.UNDER_CONSTRUCTION && (
                  <>
                    {!calcData.generalBuildingData.placeOfInsurance.parcelNumber1 && (
                      <>
                        <Col span={colSpan - 1}>
                          <Form.Item
                            name={["generalBuildingData", "placeOfInsurance", "parcelNumber1"]}
                            label={t("calc.realty.attrs.generalBuildingData.placeOfInsurance.parcelNumber1")}
                            rules={[
                              validations.notBlank,
                              validations.size(1, 64),
                              validations.pattern(regexPatterns.parcelNumberRegex)
                            ]}
                          >
                            <Input />
                          </Form.Item>
                        </Col>

                        <Col span={colSpan - 1}>
                          <Form.Item
                            name={["generalBuildingData", "placeOfInsurance", "parcelNumber2"]}
                            label={t("calc.realty.attrs.generalBuildingData.placeOfInsurance.parcelNumber2")}
                            rules={[validations.size(1, 64), validations.pattern(regexPatterns.parcelNumberRegex)]}
                          >
                            <Input />
                          </Form.Item>
                        </Col>
                      </>
                    )}

                    <Col span={colSpan}>
                      <Form.Item
                        name={["generalBuildingData", "cadastralArea"]}
                        label={
                          <LabelWithTooltip
                            tooltip={t("calc.realty.helpers.cadastralAreaDesc")}
                            label={t("calc.realty.attrs.generalBuildingData.cadastralArea")}
                          />
                        }
                        rules={[validations.notBlank, validations.size(1, 64)]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                  </>
                )}

                {unionSelected && calcData.generalInsuranceData.crossSelling.unionHealthContract && (
                  <Col span={colSpan}>
                    <Form.Item
                      name={["generalInsuranceData", "crossSelling", "unionHealthContractNumber"]}
                      label={
                        <LabelWithTooltip
                          label={t("calc.realty.attrs.generalInsuranceData.crossSelling.unionHealthContractNumber")}
                          tooltip={t("calc.realty.helpers.unionHealthContractNumberDesc")}
                        />
                      }
                      rules={[validations.notNull, validations.size(4, 15)]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                )}
              </>
            )}

            {(allianzSelected || colonnadeSelected || csobSelected) && (
              <>
                <Col span={bigColSpan}>
                  <Form.Item
                    name={["generalInsuranceData", "anotherInsuranceCompanyEnabled"]}
                    valuePropName="checked"
                    rules={[validations.none]}
                    initialValue={false}
                  >
                    <Checkbox style={{ maxHeight: 22 }}>
                      {t("calc.realty.attrs.generalInsuranceData.anotherInsuranceCompanyIdLabel")}
                    </Checkbox>
                  </Form.Item>
                </Col>

                {anotherInsuranceCompanyEnabled && (
                  <Col span={colSpan}>
                    <InstitutionSelect
                      formItemProps={{
                        name: ["generalInsuranceData", "anotherInsuranceCompanyId"],
                        label: t("calc.realty.attrs.generalInsuranceData.anotherInsuranceCompanyId"),
                        rules: [validations.notBlank]
                      }}
                      optionsProps={{
                        filterType: InstitutionType.INSURANCE_COMPANY,
                        includeDeactivated: true
                      }}
                    />
                  </Col>
                )}
              </>
            )}

            {allianzSelected &&
              (dayjs().year() - numberOrZero(calcData.generalBuildingData.constructionYear) >
                (calcData.generalBuildingData.permanentlyOccupied ? 50 : 15) ||
                numberOrZero(selectedResult.data?.realtyInsuranceAmount) > 300_000) && (
                <Col span={colSpan}>
                  <Form.Item
                    name={["generalInsuranceData", "inspectionExecuted"]}
                    className="form-item-without-label"
                    valuePropName="checked"
                    rules={[validations.trueValue]}
                    initialValue={false}
                  >
                    <Checkbox style={{ maxHeight: 22 }}>
                      <LabelWithTooltip
                        label={t("calc.realty.attrs.generalInsuranceData.inspectionExecuted")}
                        tooltip={t("calc.realty.helpers.inspectionExecutedDesc")}
                      />
                    </Checkbox>
                  </Form.Item>
                </Col>
              )}

            {colonnadeSelected && calcData.generalBuildingData.type === BuildingType.APARTMENT && (
              <Col span={colSpan}>
                <Form.Item
                  name={["apartmentData", "numberOfFloors"]}
                  label={
                    <FloorPopoverLabel
                      label={t("calc.realty.attrs.apartmentData.numberOfFloors")}
                      popoverTitle={t("calc.realty.helpers.numberOfFloorsDesc")}
                      additionalText={t("calc.realty.helpers.numberOfFloorsAdditionalDesc")}
                    />
                  }
                  rules={[validations.notNull, validations.minNumber(calcData.apartmentData?.floor)]}
                >
                  <InputNumber style={{ width: "100%" }} />
                </Form.Item>
              </Col>
            )}
          </Row>

          {csobSelected && (
            <>
              <Row gutter={rowGutter}>
                <Col span={bigColSpan}>
                  <Form.Item
                    name={["generalInsuranceData", "photovoltaicIncluded"]}
                    valuePropName="checked"
                    rules={[validations.none]}
                    initialValue={false}
                  >
                    <Checkbox style={{ maxHeight: 22 }}>
                      {t("calc.realty.attrs.generalInsuranceData.photovoltaicIncludedLabel")}
                    </Checkbox>
                  </Form.Item>
                </Col>

                {photovoltaicIncluded && (
                  <>
                    <Col span={colSpan}>
                      <Form.Item
                        name={["generalInsuranceData", "photovoltaicPower"]}
                        label={t("calc.realty.attrs.generalInsuranceData.photovoltaicPower")}
                        rules={[validations.notNull, validations.minNumber(1)]}
                      >
                        <InputNumber
                          {...inputNumberIntegerStandardProps}
                          addonAfter={<InputAddon type="photovoltaicPower" />}
                        />
                      </Form.Item>
                    </Col>

                    <Col span={colSpan}>
                      <Form.Item
                        name={["generalInsuranceData", "photovoltaicPurchasePrice"]}
                        label={t("calc.realty.attrs.generalInsuranceData.photovoltaicPurchasePrice")}
                        rules={[validations.notNull, validations.minNumber(1)]}
                      >
                        <InputNumber {...inputNumberIntegerStandardProps} addonAfter={<InputAddon type="euro" />} />
                      </Form.Item>
                    </Col>
                  </>
                )}
              </Row>

              <Divider className="divider-subheader">{t("calc.realty.sections.conflictOfInterestsStatement")}</Divider>

              <Row gutter={rowGutter}>
                <Col span={colSpan * 3}>
                  <Form.Item
                    name={["generalInsuranceData", "agentIsClosePersonToClient"]}
                    valuePropName="checked"
                    rules={[validations.none]}
                    initialValue={false}
                  >
                    <Checkbox style={{ maxHeight: 22 }}>
                      <LabelWithTooltip
                        label={t("calc.realty.attrs.generalInsuranceData.agentIsClosePersonToClient")}
                        tooltip={t("calc.realty.helpers.agentIsClosePersonToClientDesc")}
                      />
                    </Checkbox>
                  </Form.Item>
                </Col>
              </Row>
            </>
          )}

          {showGarageReinsurance && (
            <>
              <Divider className="divider-subheader">
                {calcData.generalBuildingData.type === BuildingType.APARTMENT
                  ? t("calc.realty.sections.garageReinsuranceApartment")
                  : t("calc.realty.sections.garageReinsurance")}
              </Divider>

              <>
                {unionSelected && (
                  <Row gutter={rowGutter}>
                    <Col span={colSpan}>
                      <Form.Item
                        name={["realtyInsuranceData", "garageReinsurance", "underConstruction"]}
                        className="form-item-without-label"
                        valuePropName="checked"
                        rules={[validations.none]}
                        initialValue={false}
                      >
                        <Checkbox style={{ maxHeight: 22 }}>
                          {t("calc.realty.attrs.realtyInsuranceData.garageReinsurance.underConstruction")}
                        </Checkbox>
                      </Form.Item>
                    </Col>

                    {underConstruction && (
                      <Col span={colSpan}>
                        <Form.Item
                          name={["realtyInsuranceData", "garageReinsurance", "cadastralArea"]}
                          label={
                            <LabelWithTooltip
                              tooltip={t("calc.realty.helpers.cadastralAreaDesc")}
                              label={t("calc.realty.attrs.realtyInsuranceData.garageReinsurance.cadastralArea")}
                            />
                          }
                          rules={[validations.notBlank, validations.size(1, 64)]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                    )}
                  </Row>
                )}

                <PlaceOfInsuranceForm
                  form={form}
                  rootNamePath={["realtyInsuranceData", "garageReinsurance", "placeOfInsurance"]}
                  label={t("calc.realty.attrs.realtyInsuranceData.garageReinsurance.placeOfInsurance._label")}
                  required
                  buildingFinished={
                    csobSelected || premiumSelected
                      ? calcData.generalBuildingData.state === BuildingState.FINISHED ||
                        calcData.generalBuildingData.state === BuildingState.FINISHED_UNDER_RECONSTRUCTION
                      : !underConstruction
                  }
                  allowOnlySvkCountry
                />
              </>
            </>
          )}

          {(colonnadeSelected || csobSelected || premiumSelected || uniqaSelected) &&
            !!calcData.householdInsuranceData?.equipmentReinsurances?.length && (
              <>
                {!premiumSelected && (
                  <Divider className="divider-subheader">{t("calc.realty.sections.equipmentReinsurances")}</Divider>
                )}

                {calcData.householdInsuranceData.equipmentReinsurances.map((equipment, index) => (
                  <Fragment key={index}>
                    {selectedInstitution.institutionEnum &&
                    ALLOWED_HOUSEHOLD_EQUIPMENTS.get(selectedInstitution.institutionEnum)?.includes(equipment.type) ? (
                      <Row gutter={rowGutter}>
                        <Col span={colSpan}>
                          <Form.Item
                            name={["householdInsuranceData", "equipmentReinsurances", index, "type"]}
                            label={t("calc.realty.enums.householdEquipmentType._label")}
                            rules={[validations.notNull]}
                          >
                            <Select
                              {...selectStandardProps}
                              disabled
                              options={Object.keys(HouseholdEquipmentType).map(type => ({
                                value: type,
                                label: t("calc.realty.enums.householdEquipmentType." + type)
                              }))}
                            />
                          </Form.Item>
                        </Col>

                        <Col span={colSpan}>
                          <Form.Item
                            name={["householdInsuranceData", "equipmentReinsurances", index, "insuranceAmount"]}
                            label={t("calc.realty.attrs.householdInsuranceData.equipmentReinsurances.insuranceAmount")}
                            rules={[
                              validations.notNull,
                              validations.minNumber(1),
                              validations.maxNumber(20000),
                              validations.multipleOf100
                            ]}
                          >
                            <InputNumber
                              {...inputNumberIntegerStandardProps}
                              disabled
                              addonAfter={<InputAddon type="euro" />}
                              step={100}
                            />
                          </Form.Item>
                        </Col>

                        {equipment.type === HouseholdEquipmentType.OTHER ? (
                          <Col span={colSpan}>
                            <Form.Item
                              name={["householdInsuranceData", "equipmentReinsurances", index, "otherDesc"]}
                              label={t("calc.realty.attrs.householdInsuranceData.equipmentReinsurances.otherDesc")}
                              rules={[validations.notBlank, validations.size(1, 64)]}
                            >
                              <Input />
                            </Form.Item>
                          </Col>
                        ) : equipment.type !== HouseholdEquipmentType.STRUCTURAL_COMPONENTS ? (
                          <>
                            <Col span={colSpan}>
                              <Form.Item
                                name={["householdInsuranceData", "equipmentReinsurances", index, "manufacturer"]}
                                label={t("calc.realty.attrs.householdInsuranceData.equipmentReinsurances.manufacturer")}
                                rules={[validations.notBlank, validations.size(1, 64)]}
                              >
                                <Input />
                              </Form.Item>
                            </Col>

                            <Col span={colSpan}>
                              <Form.Item
                                name={["householdInsuranceData", "equipmentReinsurances", index, "serialNumber"]}
                                label={t("calc.realty.attrs.householdInsuranceData.equipmentReinsurances.serialNumber")}
                                rules={[validations.notBlank, validations.size(1, 64)]}
                              >
                                <Input />
                              </Form.Item>
                            </Col>
                          </>
                        ) : null}
                      </Row>
                    ) : (
                      <>
                        <HiddenInput name={["householdInsuranceData", "equipmentReinsurances", index, "type"]} />
                        <HiddenInput
                          name={["householdInsuranceData", "equipmentReinsurances", index, "insuranceAmount"]}
                        />
                      </>
                    )}
                  </Fragment>
                ))}
              </>
            )}
        </>
      ) : undefined}

      <Divider className="divider-subheader">{t("calc.realty.sections.financialMediationData")}</Divider>

      <Row gutter={rowGutter}>
        <Col span={colSpan}>
          <Form.Item
            name={["financialMediationData", "clientExperience"]}
            label={t("calc.enums.clientExperience._label")}
            rules={[validations.notNull]}
            initialValue={
              policyHolder?.type === ClientType.SELF_EMPLOYED || policyHolder?.type === ClientType.LEGAL
                ? ClientExperience.SUFFICIENT
                : undefined
            }
          >
            <Select
              {...selectStandardProps}
              disabled={policyHolder?.type === ClientType.SELF_EMPLOYED || policyHolder?.type === ClientType.LEGAL}
              options={(policyHolder?.type === ClientType.SELF_EMPLOYED || policyHolder?.type === ClientType.LEGAL
                ? [ClientExperience.SUFFICIENT]
                : [ClientExperience.NONE, ClientExperience.MINIMAL]
              ).map(experience => ({
                value: experience,
                label: t("calc.enums.clientExperience." + experience)
              }))}
            />
          </Form.Item>
        </Col>

        <Col span={bigColSpan}>
          <Form.Item
            name={["financialMediationData", "otherClientRequirements"]}
            label={t("calc.realty.attrs.financialMediationData.otherClientRequirements")}
            rules={[validations.none]}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={rowGutter}>
        <Col span={bigColSpan}>
          <Form.Item
            name={["financialMediationData", "recommendedResult"]}
            label={t("calc.realty.attrs.financialMediationData.recommendedResult")}
            rules={[validations.notNull]}
          >
            <Cascader
              allowClear={false}
              expandTrigger="hover"
              options={calcResults
                .filter(resultsRow => filterApplicableSuccessResults(resultsRow).length > 0)
                .map<DefaultOptionType>(resultsRow => {
                  const results = filterApplicableSuccessResults<RealtyCalcResultData>(resultsRow);
                  return {
                    label: results[0]?.insuranceInstitution.name,
                    value: results[0]?.insuranceInstitution.institutionEnum,
                    children: results[0]?.coverage
                      ? results.map<DefaultOptionType>(result => ({
                          label: `${t("calc.realty.sections.coverage")} ${result.coverage}`,
                          value: result.coverage
                        }))
                      : undefined
                  };
                })}
            />
          </Form.Item>
        </Col>

        <Col span={bigColSpan}>
          <Form.Item
            name={["financialMediationData", "recommendationReason"]}
            label={t("calc.realty.attrs.financialMediationData.recommendationReason")}
            rules={[validations.none]}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={rowGutter}>
        <Col span={bigColSpan}>
          <Form.Item
            name={["financialMediationData", "additionalClientStatement"]}
            label={t("calc.realty.attrs.financialMediationData.additionalClientStatement")}
            rules={[validations.none]}
          >
            <Input />
          </Form.Item>
        </Col>

        <Col span={colSpan * 2}>
          <Form.Item
            name={["financialMediationData", "additionalSuitabilityStatement"]}
            label={
              <LabelWithTooltip
                label={t("calc.realty.attrs.financialMediationData.additionalSuitabilityStatement")}
                tooltip={t("calc.realty.helpers.additionalSuitabilityStatementDesc")}
              />
            }
            rules={[validations.none]}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={rowGutter}>
        <Col span={colSpan}>
          <Form.Item
            name={["financialMediationData", "ownedByClient"]}
            valuePropName="checked"
            rules={[validations.none]}
            initialValue={true}
          >
            <Checkbox>{t("calc.realty.attrs.financialMediationData.ownedByClient")}</Checkbox>
          </Form.Item>
        </Col>

        {calcData.generalBuildingData.business && (
          <Col span={colSpan * 2}>
            <Form.Item
              name={["financialMediationData", "businessDesc"]}
              label={t("calc.realty.attrs.financialMediationData.businessDesc")}
              rules={[validations.notBlank, validations.size(1, 255)]}
            >
              <Input />
            </Form.Item>
          </Col>
        )}
      </Row>
    </Card>
  );
};

export default RealtyGenOtherDataSection;
