import { Button, Divider, Flex, Popconfirm, Table, TablePaginationConfig } from "antd";
import { ColumnsType } from "antd/lib/table";
import dayjs from "dayjs";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useDeleteAgentCompletedEducation, useGetAgentCompletedEducations } from "../../../../../common/api/queries";
import LabelWithTooltip from "../../../../../common/components/form/labels/LabelWithTooltip";
import ActionTextIcon from "../../../../../common/components/icons/ActionTextIcon";
import AntIcon from "../../../../../common/components/icons/AntIcon";
import PopconfirmDeleteIcon from "../../../../../common/components/icons/PopconfirmDeleteIcon";
import Ellipsis from "../../../../../common/components/views/Ellipsis";
import { formatLocaleDate } from "../../../../../common/utils/formatUtils";
import { dateToIsoDateString } from "../../../../../common/utils/formUtils";
import { useFilterSearchParams } from "../../../../../common/utils/hooksUtils";
import { URLQuery } from "../../../../../common/utils/queryUtils";
import { numberOrZero, tableStandardProps } from "../../../../../common/utils/utils";
import { UUID } from "../../../../../typings/global";
import { AgentEducationType } from "../../../enums";
import { AgentCompletedEducation } from "../../../types";
import { AgentCompletedEducationModalForm } from "../../forms/AgentCompletedEducationModalForm";

type ImportantDate = {
  key: AgentEducationType;
  data?: AgentCompletedEducation;
};

type ImportantDatesProps = {
  importantDates: ImportantDate[];
  agentId: UUID;
  onCreateManagerialAcademyCompletion: (isManagerialCompleted?: boolean) => void;
  onChangeManagerialAcademyCompletion: (education: AgentCompletedEducation, isManagerialCompleted?: boolean) => void;
  showActions?: boolean;
};

const ImportantDates = ({
  importantDates,
  agentId,
  onCreateManagerialAcademyCompletion,
  onChangeManagerialAcademyCompletion,
  showActions
}: ImportantDatesProps) => {
  const { t } = useTranslation();

  const deleteAgentCompletedEducation = useDeleteAgentCompletedEducation();

  const renderValueOrActions = (record: ImportantDate) => {
    const data = record.data;

    if (data && data.type === AgentEducationType.MANAGERIAL_ACADEMY_COMPLETION && showActions) {
      return (
        <>
          {formatLocaleDate(data.completedDate)}

          <span className="margin-left-tiny">
            <ActionTextIcon icon="edit" color="blue" onClick={() => onChangeManagerialAcademyCompletion(data, true)} />

            <Divider type="vertical" />

            <Popconfirm
              title={t("agent.education.helpers.deleteConfirm")}
              icon={<PopconfirmDeleteIcon />}
              cancelText={t("common.no")}
              okText={t("common.yes")}
              okType="danger"
              onConfirm={() => deleteAgentCompletedEducation.mutate({ id1: agentId, id2: data.id })}
            >
              <span>
                <ActionTextIcon icon="delete" color="red" />
              </span>
            </Popconfirm>
          </span>
        </>
      );
    }

    if (record.key === AgentEducationType.MANAGERIAL_ACADEMY_COMPLETION && !data && showActions) {
      const hasBasicAcademyCompleted = !!importantDates.find(
        date => date.key === AgentEducationType.BASIC_ACADEMY_COMPLETION
      )?.data;

      const hasAdvancedAcademyCompleted = !!importantDates.find(
        date => date.key === AgentEducationType.ADVANCED_ACADEMY_COMPLETION
      )?.data;

      const isDisabled = !hasBasicAcademyCompleted || !hasAdvancedAcademyCompleted;

      return (
        <>
          <ActionTextIcon
            icon="plus"
            color="green"
            text={t("common.add")}
            disabled={isDisabled}
            onClick={() => onCreateManagerialAcademyCompletion(true)}
          />

          {isDisabled ? (
            <LabelWithTooltip
              style={{ marginLeft: 8 }}
              tooltip={t("agent.completedEducation.helpers.completedManagerialHint")}
            />
          ) : undefined}
        </>
      );
    }

    if (!data) {
      return <span className="sub-header-info">{t("agent.education.helpers.notCompleted")}</span>;
    }

    return formatLocaleDate(data.completedDate);
  };

  return (
    <div className="left-align">
      <div className="margin-bottom-tiny">
        <b>{t("agent.education.titles.importantInfo")}</b>
      </div>

      <Table<ImportantDate>
        size="small"
        dataSource={importantDates}
        showHeader={false}
        columns={[
          {
            key: "key",
            width: 250,
            render: (_, record) => t(`agent.education.enums.agentEducationType.${record.key}`)
          },
          {
            key: "value",
            align: "right",
            width: 150,
            render: (_, record) => renderValueOrActions(record)
          }
        ]}
        scroll={{ x: "max-content" }}
        pagination={false}
        bordered={true}
      />
    </div>
  );
};

type Props = {
  agentId: string;
  showActions?: boolean;
};

export const AgentCompletedEducationTableView = ({ agentId, showActions }: Props) => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const searchParams = useFilterSearchParams();

  const [completedEducation, setCompletedEducation] = useState<AgentCompletedEducation>();
  const [managerialCompletionMinDate, setManagerialCompletionMinDate] = useState<string>();
  const [isOpenModal, setIsOpenModal] = useState(false);

  const { data, isLoading } = useGetAgentCompletedEducations({ id: agentId, object: searchParams });
  const deleteAgentCompletedEducation = useDeleteAgentCompletedEducation();

  const importantDates = [
    { key: AgentEducationType.WEBINAR, data: data?.webinarEducation },
    {
      key: AgentEducationType.BASIC_ACADEMY_COMPLETION,
      data: data?.basicAcademyCompletionEducation
    },
    {
      key: AgentEducationType.ADVANCED_ACADEMY_COMPLETION,
      data: data?.advancedAcademyCompletionEducation
    },
    {
      key: AgentEducationType.MANAGERIAL_ACADEMY_COMPLETION,
      data: data?.managerialAcademyCompletionEducation
    }
  ];

  const handleTableChange = (pagination: TablePaginationConfig) => {
    navigate(
      {
        search: URLQuery.searchParamsToString({
          searchParams: {
            pageIndex: pagination.current ? pagination.current - 1 : undefined
          },
          append: true
        })
      },
      { replace: true }
    );
  };

  const setMinDateForManagerialCompletion = () => {
    const minDate = dayjs.max(
      dayjs(data?.basicAcademyCompletionEducation?.completedDate),
      dayjs(data?.advancedAcademyCompletionEducation?.completedDate)
    );

    setManagerialCompletionMinDate(dateToIsoDateString(minDate.add(1, "day")));
  };

  const handleCancel = () => {
    setCompletedEducation(undefined);
    setManagerialCompletionMinDate(undefined);
    setIsOpenModal(false);
  };

  const handleChangeAgentCompletedEducation = (education: AgentCompletedEducation, isManagerialCompleted?: boolean) => {
    setCompletedEducation(education);
    setIsOpenModal(true);

    if (isManagerialCompleted) {
      setMinDateForManagerialCompletion();
    }
  };

  const handleCreateAgentCompletedEducation = (isManagerialCompleted?: boolean) => {
    setIsOpenModal(true);

    if (isManagerialCompleted) {
      setMinDateForManagerialCompletion();
    }
  };

  const getColumns = () => {
    const columns: ColumnsType<AgentCompletedEducation> = [
      {
        key: "completedDate",
        title: t("agent.completedEducation.attrs.completedDate"),
        ellipsis: { showTitle: false },
        width: 140,
        render: (_, record) => <Ellipsis>{formatLocaleDate(record.completedDate)}</Ellipsis>
      },
      {
        key: "code",
        title: t("agent.education.attrs.code"),
        ellipsis: { showTitle: false },
        width: 150,
        render: (_, record) => (record.education ? <Ellipsis>{record.education.code}</Ellipsis> : undefined)
      },
      {
        key: "name",
        title: t("agent.education.attrs.name"),
        ellipsis: { showTitle: false },
        width: 250,
        render: (_, record) => (record.education ? <Ellipsis>{record.education.name}</Ellipsis> : undefined)
      },
      {
        key: "type",
        title: t("agent.education.enums.agentEducationType._label"),
        ellipsis: { showTitle: false },
        width: 180,
        render: (_, record) =>
          record.education?.type ? (
            <Ellipsis>{t(`agent.education.enums.agentEducationType.${record.education?.type}`)}</Ellipsis>
          ) : undefined
      }
    ];

    if (showActions) {
      columns.push({
        key: "actions",
        align: "right",
        fixed: "right",
        width: 180,
        render: (_, record) => (
          <>
            <ActionTextIcon
              icon="edit"
              color="blue"
              text={t("common.edit")}
              onClick={() => handleChangeAgentCompletedEducation(record)}
            />

            <Divider type="vertical" />

            <Popconfirm
              title={t("agent.education.helpers.deleteConfirm")}
              icon={<PopconfirmDeleteIcon />}
              cancelText={t("common.no")}
              okText={t("common.yes")}
              okType="danger"
              onConfirm={() => deleteAgentCompletedEducation.mutate({ id1: agentId, id2: record.id })}
            >
              <span>
                <ActionTextIcon icon="delete" color="red" text={t("common.delete")} />
              </span>
            </Popconfirm>
          </>
        )
      });
    }

    return columns;
  };

  return (
    <div>
      <Flex className="margin-bottom-medium" justify="start">
        <ImportantDates
          importantDates={importantDates}
          agentId={agentId}
          onChangeManagerialAcademyCompletion={handleChangeAgentCompletedEducation}
          onCreateManagerialAcademyCompletion={handleCreateAgentCompletedEducation}
          showActions={showActions}
        />
      </Flex>

      <Table<AgentCompletedEducation>
        {...tableStandardProps()}
        loading={isLoading}
        columns={getColumns()}
        dataSource={data?.pageData}
        pagination={{
          ...data,
          current: numberOrZero(data?.pageIndex) + 1,
          pageSize: data?.pageSize,
          total: data?.totalElementsCount
        }}
        onChange={handleTableChange}
      />

      {showActions ? (
        <>
          <div className={data?.pageData?.length ? "table-footer-margin" : "margin-top-small"}>
            <Button
              size="small"
              type="primary"
              onClick={() => handleCreateAgentCompletedEducation()}
              icon={<AntIcon type="plus" />}
            >
              {t("common.add")}
            </Button>
          </div>

          <AgentCompletedEducationModalForm
            isOpen={isOpenModal}
            managerialCompletion={managerialCompletionMinDate ? { minDate: managerialCompletionMinDate } : undefined}
            agentId={agentId}
            education={completedEducation}
            onCancel={handleCancel}
            isFirstEducation={!data?.pageData.length}
          />
        </>
      ) : undefined}
    </div>
  );
};
