import dayjs, { Dayjs } from "dayjs";
import { dateToIsoDateString, toDate } from "../../../common/utils/formUtils";
import { RealtyCalcDraft } from "../calcs/realty/types";
import { TravelCalcDraft } from "../calcs/travel/types";
import { isRealtyCalcDraft, isTravelCalcDraft } from "../calcs/utils";
import { VehicleCalcDraft } from "../calcs/vehicle/types";

export const reviseDraftData = <T extends RealtyCalcDraft | VehicleCalcDraft | TravelCalcDraft>(data: T): T => {
  const now = dayjs();
  const tomorrow = now.add(1, "day");

  const adjustDate = (date: Dayjs, minDate: Dayjs) => (date.isBefore(minDate) ? minDate : date);

  if (isRealtyCalcDraft(data)) {
    const beginningDate = toDate(data.draftData.generalInsuranceData.effectiveBeginningDate) as Dayjs;

    return {
      ...data,
      draftData: {
        ...data.draftData,
        generalInsuranceData: {
          ...data.draftData.generalInsuranceData,
          effectiveBeginningDate: dateToIsoDateString(adjustDate(beginningDate, tomorrow))
        }
      }
    };
  }

  const beginningDate = toDate(data.draftData.generalData.effectiveBeginningDate) as Dayjs;
  const finalBeginningDate = adjustDate(beginningDate, now);

  return {
    ...data,
    draftData: {
      ...data.draftData,
      generalData: {
        ...data.draftData.generalData,
        effectiveBeginningDate: dateToIsoDateString(finalBeginningDate),
        effectiveEndDate:
          isTravelCalcDraft(data) && finalBeginningDate.isSame(beginningDate)
            ? data.draftData.generalData.effectiveEndDate
            : undefined
      }
    }
  };
};
