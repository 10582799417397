import { Badge, Flex, List, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import classNames from "classnames";
import { TFunction } from "i18next";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { generatePath, Link } from "react-router-dom";
import { FlipCard } from "../../../../../../../common/components/flip-card/FlipCard";
import FileTypeIcon from "../../../../../../../common/components/icons/FileTypeIcon";
import { LeftCard } from "../../../../../../../common/components/left-card/LeftCard";
import Ellipsis from "../../../../../../../common/components/views/Ellipsis";
import { FieldConstraintViolation } from "../../../../../../../common/types";
import { formatLocaleCurrency } from "../../../../../../../common/utils/formatUtils";
import { cssVariables, numberOrZero } from "../../../../../../../common/utils/utils";
import { InstitutionEnum } from "../../../../../../institution/enums";
import { CALC_ROUTE_PATHS } from "../../../../../paths";
import { CalcResultActions } from "../../../../components/calc-result/CalcResultActions";
import { DragHandle } from "../../../../components/calc-result/DragHandle";
import { CalcResult } from "../../../../types";
import { RealtyCalcResultData } from "../../../types";
import styles from "./RealtyCalcResult.module.scss";
import { RealtyCalcResultInfo } from "./RealtyCalcResultInfo";

const getCoverages = (item: CalcResult<RealtyCalcResultData>, t: TFunction): Coverage[] => {
  const coverages: Coverage[] = [];

  if (item.data?.realtyAnnualPremium || item.data?.realtyInsuranceAmount) {
    coverages.push({
      title: (
        <span>
          {t("calc.realty.sections.realtyInsurance")} <br />
          <small>{t("calc.realty.sections.realtyInsuranceWithNearbyBuildings")}</small>
        </span>
      ),
      annualPremium: item.data?.annualPremium,
      insuranceAmount: item.data?.realtyInsuranceAmount
    });
  }

  if (item.data?.householdAnnualPremium || item.data?.householdInsuranceAmount) {
    coverages.push({
      title: t("calc.realty.sections.householdInsurance"),
      annualPremium: item.data?.householdAnnualPremium,
      insuranceAmount: item.data?.householdInsuranceAmount
    });
  }

  if (item.data?.equipmentReinsuranceAnnualPremium) {
    coverages.push({
      title: t("calc.realty.results.equipmentReinsurance"),
      annualPremium: item.data?.equipmentReinsuranceAnnualPremium
    });
  }

  if (item.data?.realtyLiabilityAnnualPremium || item.data?.realtyLiabilityInsuranceAmount) {
    coverages.push({
      title: t("calc.realty.results.realtyLiability"),
      annualPremium: item.data?.realtyLiabilityAnnualPremium,
      insuranceAmount: item.data?.realtyLiabilityInsuranceAmount
    });
  }

  if (item.data?.householdLiabilityAnnualPremium || item.data?.householdLiabilityInsuranceAmount) {
    coverages.push({
      title: t("calc.realty.results.householdLiability"),
      annualPremium: item.data?.householdLiabilityAnnualPremium,
      insuranceAmount: item.data?.householdLiabilityInsuranceAmount
    });
  }

  if (item.data?.liabilityAnnualPremium || item.data?.liabilityInsuranceAmount) {
    coverages.push({
      title: t("calc.realty.results.liability"),
      annualPremium: item.data?.liabilityAnnualPremium,
      insuranceAmount: item.data?.liabilityInsuranceAmount
    });
  }

  if (item.data?.tenantLiabilityAnnualPremium || item.data?.tenantLiabilityInsuranceAmount) {
    coverages.push({
      title: t("calc.realty.results.tenantLiability"),
      annualPremium: item.data?.tenantLiabilityAnnualPremium,
      insuranceAmount: item.data?.tenantLiabilityInsuranceAmount
    });
  }

  if (item.data?.civilLiabilityReinsuranceAnnualPremium) {
    coverages.push({
      title: t("calc.realty.results.civilLiabilityReinsurance"),
      annualPremium: item.data?.civilLiabilityReinsuranceAnnualPremium
    });
  }

  if (item.data?.cyberneticReinsuranceAnnualPremium || item.data?.cyberneticReinsuranceInsuranceAmount) {
    coverages.push({
      title: t("calc.realty.results.cyberneticReinsurance"),
      annualPremium: item.data?.cyberneticReinsuranceAnnualPremium,
      insuranceAmount: item.data?.cyberneticReinsuranceInsuranceAmount
    });
  }

  if (item.data?.warrantyReinsuranceAnnualPremium) {
    coverages.push({
      title: t("calc.realty.results.warrantyReinsurance"),
      annualPremium: item.data?.warrantyReinsuranceAnnualPremium
    });
  }

  if (item.data?.cyclingReinsuranceAnnualPremium) {
    coverages.push({
      title: t("calc.realty.results.cyclingReinsurance"),
      annualPremium: item.data?.cyclingReinsuranceAnnualPremium
    });
  }

  if (item.data?.loanReinsuranceAnnualPremium) {
    coverages.push({
      title: t("calc.realty.results.loanReinsurance"),
      annualPremium: item.data?.loanReinsuranceAnnualPremium
    });
  }

  if (item.data?.rentReinsuranceAnnualPremium) {
    coverages.push({
      title: t("calc.realty.results.rentReinsurance"),
      annualPremium: item.data?.rentReinsuranceAnnualPremium
    });
  }

  if (item.data?.allRiskReinsuranceAnnualPremium) {
    coverages.push({
      title: t("calc.realty.results.allRiskReinsurance"),
      annualPremium: item.data?.allRiskReinsuranceAnnualPremium
    });
  }

  coverages.push({
    title:
      item.insuranceInstitution.institutionEnum === InstitutionEnum.PREMIUM
        ? t("calc.realty.results.complicityAltName")
        : t("calc.realty.results.complicity"),
    insuranceAmount: numberOrZero(item.data?.complicity)
  });

  return coverages;
};

type Coverage = {
  title: React.ReactNode;
  annualPremium?: number;
  insuranceAmount?: number;
};

type Props = {
  item: CalcResult<RealtyCalcResultData>;
  handleItemActivation: (id: string, activation: boolean) => void;
  handleItemRecommendation: (itemId: string, activation: boolean) => void;
  handleGenerateContract: (selectedItem: CalcResult<RealtyCalcResultData>) => void;
  handleShowErrors: (errors: FieldConstraintViolation[]) => void;
};

export const RealtyCalcResult = ({
  item,
  handleItemActivation,
  handleGenerateContract,
  handleShowErrors,
  handleItemRecommendation
}: Props) => {
  const { t } = useTranslation();

  const [isRotated, setIsRotated] = useState(false);

  const coverages = getCoverages(item, t);

  const coveragesTableColumns: ColumnsType<Coverage> = [
    {
      key: "coverageName",
      title: t("calc.realty.results.coverage"),
      width: 200,
      ellipsis: { showTitle: false },
      render: (_, record) => <Ellipsis>{record.title}</Ellipsis>
    },
    {
      key: "annualPremium",
      title: t("calc.realty.results.annualPremium"),
      width: 100,
      align: "right",
      render: (_, record) => formatLocaleCurrency(record.annualPremium)
    },
    {
      key: "insuranceAmount",
      title: t("calc.realty.results.insuranceAmount"),
      width: 120,
      align: "right",
      render: (_, record) => formatLocaleCurrency(record.insuranceAmount)
    }
  ];

  return (
    <FlipCard
      isRotated={isRotated}
      closeRotation={() => setIsRotated(false)}
      frontCard={
        <Badge.Ribbon
          placement="start"
          text={t("calc.helpers.recommended")}
          color={cssVariables.colorGreen}
          style={{ display: item.recommended ? undefined : "none" }}
        >
          <div
            className={classNames(styles.wrapper, {
              [styles.error as string]: !!item.error,
              [styles.disabled as string]: !item.active && !item.error,
              [styles.recommended as string]: item.recommended
            })}
          >
            <DragHandle isDraggable={item.active && !item.error} />

            <div className={styles.infoBox}>
              <RealtyCalcResultInfo
                item={item}
                handleItemRecommendation={handleItemRecommendation}
                rotate={() => setIsRotated(!isRotated)}
              />
            </div>

            <div className={styles.actionsBox}>
              <CalcResultActions
                item={item}
                handleItemActivation={handleItemActivation}
                handleGenerateContract={handleGenerateContract}
                handleShowErrors={handleShowErrors}
              />
            </div>
          </div>
        </Badge.Ribbon>
      }
      backCard={
        <div style={{ padding: 20 }} className={styles.wrapper}>
          <Flex>
            <div className={styles.additionalInfoWrapper}>
              <Table
                columns={coveragesTableColumns}
                dataSource={coverages.map((coverage, index) => ({ ...coverage, id: index }))}
                rowKey="id"
                pagination={false}
                size="small"
                scroll={{ y: 150 }}
              />
            </div>
            <div className={styles.documentsWrapper}>
              <LeftCard description={t("common.documents")}>
                <div style={{ overflowY: "auto", width: "100%", height: "100%", minWidth: 0 }}>
                  <List
                    size="small"
                    dataSource={item.attachments}
                    renderItem={attachment => (
                      <List.Item key={attachment.id}>
                        <Ellipsis>
                          <Link
                            to={generatePath(CALC_ROUTE_PATHS.resultAttachment.to, { id: attachment.id })}
                            target="_blank"
                          >
                            <FileTypeIcon contentType={attachment.file.contentType} /> {attachment.filename}
                          </Link>
                        </Ellipsis>
                      </List.Item>
                    )}
                  />
                </div>
              </LeftCard>
            </div>
          </Flex>
        </div>
      }
    />
  );
};
