import { Popconfirm } from "antd";
import { useTranslation } from "react-i18next";
import ActionButton from "../../../../../common/components/buttons/ActionButton";
import AntIcon from "../../../../../common/components/icons/AntIcon";
import VehicleBrandEnumName from "../../../../enumerations/components/view/VehicleBrandEnumName";
import VehicleModelEnumName from "../../../../enumerations/components/view/VehicleModelEnumName";
import { VehicleCalcDraft } from "../types";

type Props = {
  draft?: VehicleCalcDraft;
  handleSaveDraft: (value: boolean) => void;
};

export const VehicleSaveDraftButton = ({ draft, handleSaveDraft }: Props) => {
  const { t } = useTranslation();

  return (
    <div>
      {draft ? (
        <Popconfirm
          title={
            <>
              <b>{t("calc.helpers.loadedDraftTitle")}:</b>
              <div className="margin-top-tiny margin-bottom-tiny">
                {draft.clientName}
                <br />
                <VehicleBrandEnumName
                  brandId={draft.draftData.vehicleData.brandId}
                  otherBrandName={draft.draftData.vehicleData.customBrand}
                />{" "}
                <VehicleModelEnumName
                  brandId={draft.draftData.vehicleData.brandId}
                  modelId={draft.draftData.vehicleData.modelId}
                  otherModelName={draft.draftData.vehicleData.customModel}
                />
                <br />
                {draft.draftData.vehicleData.licensePlate}{" "}
                {draft.draftData.vehicleData.vin && ` | ${draft.draftData.vehicleData.vin}`}
                <br />
                {t("calc.vehicle.enums.vehiclePurpose." + draft.draftData.vehicleData.purpose)}
              </div>
              <i>{t("calc.helpers.loadedDraftDesc")}</i>
            </>
          }
          icon={<AntIcon type="question" />}
          okText={t("calc.actions.overwriteDraft")}
          cancelText={t("calc.actions.saveNewDraft")}
          overlayStyle={{ maxWidth: 450 }}
          onConfirm={() => handleSaveDraft(true)}
          onCancel={() => handleSaveDraft(false)}
        >
          <span>
            <ActionButton icon="form" color="primary" variant="outlined" size="middle">
              {t("calc.actions.saveDraft")}
            </ActionButton>
          </span>
        </Popconfirm>
      ) : (
        <ActionButton
          icon="form"
          color="primary"
          variant="outlined"
          size="middle"
          onClick={() => handleSaveDraft(false)}
        >
          {t("calc.actions.saveDraft")}
        </ActionButton>
      )}
    </div>
  );
};
