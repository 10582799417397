import { Button, Flex, Space, Switch } from "antd";
import { useTranslation } from "react-i18next";
import { HttpStatus } from "../../../../../common/api/http";
import LabelWithPopover from "../../../../../common/components/form/labels/LabelWithPopover";
import { FieldConstraintViolation } from "../../../../../common/types";
import { formatLocaleCurrency } from "../../../../../common/utils/formatUtils";
import { cssVariables } from "../../../../../common/utils/utils";
import { PaymentFrequency } from "../../../../contract/enums";
import { CalcResult, CalcResultData } from "../../types";
import { isRealtyCalcResultsData, isVehicleCalcResultsData } from "../../utils";
import { isGapCalcResult, isMtplCrashCalcResult } from "../../vehicle/utils";

type Props<T extends CalcResultData> = {
  item: CalcResult<T>;
  handleItemActivation: (id: string, activation: boolean) => void;
  handleGenerateContract: (selectedItem: CalcResult<T>) => void;
  handleShowErrors: (errors: FieldConstraintViolation[]) => void;
};

export const CalcResultActions = <T extends CalcResultData>({
  item,
  handleItemActivation,
  handleGenerateContract,
  handleShowErrors
}: Props<T>) => {
  const { t } = useTranslation();

  const renderMainButton = () => {
    if (!item.error) {
      return (
        <Button
          disabled={!item.active}
          onClick={event => {
            event.currentTarget.blur();
            handleGenerateContract(item);
          }}
          type="primary"
        >
          {t("calc.actions.generateContract")}
        </Button>
      );
    }

    if (item.error?.status === HttpStatus.UNPROCESSABLE_ENTITY) {
      return (
        <Button
          onClick={event => {
            handleShowErrors(item.error?.violations ?? []);
            event.currentTarget.blur();
          }}
        >
          {t("calc.travel.actions.showErrors")}
        </Button>
      );
    }

    return (
      <Button disabled type="primary">
        {t("calc.actions.generateContract")}
      </Button>
    );
  };

  const renderPartialPremium = (data: CalcResultData) => {
    if (
      !data.paymentFrequency ||
      data.paymentFrequency === PaymentFrequency.ONCE ||
      data.paymentFrequency === PaymentFrequency.ANNUALLY
    ) {
      return undefined;
    }

    if (isRealtyCalcResultsData(data) || isVehicleCalcResultsData(data)) {
      return (
        <i style={{ color: cssVariables.colorGrey }}>
          {t(`contract.enums.paymentFrequency.${data.paymentFrequency}`)} {formatLocaleCurrency(data.partialPremium)}
        </i>
      );
    }

    return undefined;
  };

  const renderAnnualPremium = () => {
    if (isGapCalcResult(item)) {
      return <span>({t("calc.vehicle.helpers.accordingToTable")})</span>;
    } else if (isMtplCrashCalcResult(item)) {
      const hasMtplCrashAnnualPremium =
        !!item.data?.mtplInsuranceAnnualPremium && !!item.data.crashInsuranceAnnualPremium;

      return (
        <Space align="start">
          <b style={{ fontSize: 26, lineHeight: "30px" }}>{formatLocaleCurrency(item.data?.annualPremium)}</b>
          {hasMtplCrashAnnualPremium ? (
            <LabelWithPopover
              popoverContent={
                <>
                  <Flex justify="space-between" gap="middle">
                    <span>{t("calc.vehicle.helpers.mtplAnnualPremium")}</span>
                    <b>{formatLocaleCurrency(item.data?.mtplInsuranceAnnualPremium)}</b>
                  </Flex>

                  <Flex justify="space-between" gap="middle">
                    <span>{t("calc.vehicle.helpers.crashAnnualPremium")}</span>
                    <b>{formatLocaleCurrency(item.data?.crashInsuranceAnnualPremium)}</b>
                  </Flex>
                </>
              }
            />
          ) : undefined}
        </Space>
      );
    }

    return <b style={{ display: "block", fontSize: 26 }}>{formatLocaleCurrency(item.data?.annualPremium)}</b>;
  };

  return (
    <>
      <Flex justify="end" style={{ marginBottom: 16 }}>
        <span style={{ paddingRight: 4 }}>{t("calc.actions.activeOffer")}</span>
        {item.error ? (
          <Switch disabled checkedChildren={t("common.yes")} unCheckedChildren={t("common.no")} />
        ) : (
          <Switch
            checkedChildren={t("common.yes")}
            unCheckedChildren={t("common.no")}
            value={item.active}
            onChange={(checked: boolean) => handleItemActivation(item.id, checked)}
          />
        )}
      </Flex>
      <Flex vertical>
        {item.error ? undefined : (
          <Flex align="end" vertical style={{ marginBottom: 16 }}>
            <span>{t("calc.helpers.offerPrice")}</span>
            {renderAnnualPremium()}
            {item.data ? renderPartialPremium(item.data) : undefined}
          </Flex>
        )}
        {renderMainButton()}
      </Flex>
    </>
  );
};
