import AgentActiveTag from "../../../../modules/agent/components/tags/AgentActiveTag";
import AgentCanBeGainerTag from "../../../../modules/agent/components/tags/AgentCanBeGainerTag";
import AgentTypeTag from "../../../../modules/agent/components/tags/AgentTypeTag";
import { AgentType, FinancialSector } from "../../../../modules/agent/enums";
import { AgentBase, AgentDirectorateBase } from "../../../../modules/agent/types";
import AgentEnumName from "../../../../modules/enumerations/components/view/AgentEnumName";
import { accessTreePathDelimiter } from "../../../constants";
import { formatAgentDirectorateName } from "../../../utils/formatUtils";
import { isDefinedValue } from "../../../utils/utils";
import {
  AgentHistoryItem,
  BooleanHistoryItem,
  DateHistoryItem,
  EmptyHistoryItem,
  FinancialSectorsHistoryItem,
  IbanHistoryItem,
  NoteHistoryItem,
  PhoneHistoryItem
} from "./commonHistoryItems";

const AccessTreePathItem = (value: string) => {
  const tokens = (value as string).split(accessTreePathDelimiter);
  return <AgentEnumName id={tokens[tokens.length - 2] as string} />;
};

const CanBeGainerItem = (value: boolean) => <AgentCanBeGainerTag canBeGainer={value} />;

const DeactivatedItem = (value: boolean) => <AgentActiveTag agentDeactivated={value} />;

const SystemIdNumberItem = (value: number) => <span>{value.toString().padStart(5, "0")}</span>;

const StructureIdNumberItem = (value: number) =>
  isDefinedValue(value) ? <span>{value.toString().padStart(3, "0")}</span> : EmptyHistoryItem();

const TypeItem = (value: AgentType) => <AgentTypeTag type={value} />;

const AgentDirectorateItem = (value: AgentDirectorateBase) =>
  value ? <span>{formatAgentDirectorateName(value)}</span> : EmptyHistoryItem();

export const AgentHistoryItemsMap = new Map<
  string,
  (
    value: string | number | boolean | AgentBase | FinancialSector[] | AgentType | AgentDirectorateBase
  ) => React.JSX.Element
>([
  ["accessTreePath", value => AccessTreePathItem(value as string)],
  ["bankAccountNumber", value => IbanHistoryItem(value as string)],
  ["birthDate", value => DateHistoryItem(value as string)],
  ["canBeGainer", value => CanBeGainerItem(value as boolean)],
  ["deactivated", value => DeactivatedItem(value as boolean)],
  ["systemIdNumber", value => SystemIdNumberItem(value as number)],
  ["note", value => NoteHistoryItem(value as string)],
  ["parent", value => AgentHistoryItem(value as AgentBase)],
  ["phone", value => PhoneHistoryItem(value as string)],
  ["sectors", value => FinancialSectorsHistoryItem(value as FinancialSector[])],
  ["structureIdNumber", value => StructureIdNumberItem(value as number)],
  ["tipper", value => BooleanHistoryItem(!!value as boolean)],
  ["type", value => TypeItem(value as AgentType)],
  ["directorate", value => AgentDirectorateItem(value as AgentDirectorateBase)]
]);
