import { Route, Routes } from "react-router";
import { WILDCARD_ROUTE } from "../../common/constants";
import NotFound from "../../common/pages/NotFound/NotFound";
import { Permission } from "../../common/security/authorization/enums";
import PageWithPermission from "../../common/security/authorization/PageWithPermission";
import BailAccountsReportContainer from "../commissions/bailaccount/containers/BailAccountsReportContainer";
import DebtAccountsReportContainer from "../commissions/debtaccount/containers/DebtAccountsReportContainer";
import PointsReportContainer from "../commissions/points/containers/PointsReportContainer";
import AgentCommissionsContainer from "./containers/AgentCommissionsContainer";
import { AgentCreateContainer } from "./containers/AgentCreateContainer";
import { AgentDetailContainer } from "./containers/AgentDetailContainer";
import { AgentDirectorateContainer } from "./containers/AgentDirectorateContainer";
import { AgentEducationContainer } from "./containers/AgentEducationContainer";
import AgentEndingCompetenceContainer from "./containers/AgentEndingCompetenceContainer";
import AgentListContainer from "./containers/AgentListContainer";
import AgentTreeContainer from "./containers/AgentTreeContainer";
import { AGENT_ROUTE_PATHS } from "./paths";

export const AgentRoutes = () => (
  <Routes>
    <Route
      index
      element={<PageWithPermission component={<AgentListContainer />} permissions={[Permission.AGENT_READ]} />}
    />

    <Route
      path={AGENT_ROUTE_PATHS.tree.path}
      element={<PageWithPermission component={<AgentTreeContainer />} permissions={[Permission.AGENT_READ]} />}
    />

    <Route
      path={AGENT_ROUTE_PATHS.create.path}
      element={<PageWithPermission component={<AgentCreateContainer />} permissions={[Permission.AGENT_CREATE]} />}
    />

    <Route
      path={AGENT_ROUTE_PATHS.endingCompetences.path}
      element={
        <PageWithPermission
          component={<AgentEndingCompetenceContainer />}
          permissions={[Permission.AGENT_COMPETENCE]}
        />
      }
    />

    <Route
      path={AGENT_ROUTE_PATHS.pointsReport.path}
      element={
        <PageWithPermission component={<PointsReportContainer />} permissions={[Permission.AGENT_COMMISSIONS]} />
      }
    />

    <Route
      path={AGENT_ROUTE_PATHS.bailAccountsReport.path}
      element={
        <PageWithPermission component={<BailAccountsReportContainer />} permissions={[Permission.AGENT_COMMISSIONS]} />
      }
    />

    <Route
      path={AGENT_ROUTE_PATHS.debtAccountsReport.path}
      element={
        <PageWithPermission component={<DebtAccountsReportContainer />} permissions={[Permission.AGENT_COMMISSIONS]} />
      }
    />

    <Route
      path={AGENT_ROUTE_PATHS.detail.path}
      element={<PageWithPermission component={<AgentDetailContainer />} permissions={[Permission.AGENT_READ]} />}
    />

    <Route
      path={AGENT_ROUTE_PATHS.commissions.path}
      element={
        <PageWithPermission component={<AgentCommissionsContainer />} permissions={[Permission.AGENT_COMMISSIONS]} />
      }
    />

    <Route path={WILDCARD_ROUTE} element={<NotFound />} />
  </Routes>
);

export const AgentDirectorateRoutes = () => (
  <Routes>
    <Route
      path={AGENT_ROUTE_PATHS.directorates.path}
      element={
        <PageWithPermission component={<AgentDirectorateContainer />} permissions={[Permission.ADMIN_ENUMERATIONS]} />
      }
    />

    <Route path={WILDCARD_ROUTE} element={<NotFound />} />
  </Routes>
);

export const AgentEducationRoutes = () => (
  <Routes>
    <Route
      path={AGENT_ROUTE_PATHS.education.path}
      element={
        <PageWithPermission component={<AgentEducationContainer />} permissions={[Permission.ADMIN_ENUMERATIONS]} />
      }
    />

    <Route path={WILDCARD_ROUTE} element={<NotFound />} />
  </Routes>
);
