import { AxiosPromise } from "axios";
import { ApiRequestAdapter } from "../../../common/api/ApiRequestAdapter";
import { apiService } from "../../../common/api/apiService";
import {
  EntityObject,
  PageRequest,
  PageResult,
  TwoLevelEntityIdObject,
  TwoLevelEntityObject
} from "../../../common/types";
import { serializeParams } from "../../../common/utils/apiUtils";
import { CommissionsBatchOutputAttachment } from "../batch/types";
import {
  SpecialCommissionBase,
  SpecialCommissionsFilterPageRequest,
  SpecialCommissionsFilterPageResult
} from "../special/types";
import { CalculatedCommissionsFilterPageRequest, CalculatedCommissionsFilterPageResult } from "./types";

const FILTER_COMMISSIONS_OUTPUTS = ApiRequestAdapter.create("/agents/{0}/commissions-outputs", "GET");
const DOWNLOAD_COMMISSIONS_OUTPUT = ApiRequestAdapter.create("/agents/{0}/commissions-outputs/{1}", "GET");
const FILTER_OUTPUT_CALCULATED_COMMISSIONS = ApiRequestAdapter.create(
  "/agents/{0}/commissions-outputs/{1}/calculated-commissions",
  "GET"
);
const FILTER_OUTPUT_SPECIAL_COMMISSIONS = ApiRequestAdapter.create(
  "/agents/{0}/commissions-outputs/{1}/special-commissions",
  "GET"
);

export const requests = {
  FILTER_COMMISSIONS_OUTPUTS,
  DOWNLOAD_COMMISSIONS_OUTPUT,
  FILTER_OUTPUT_CALCULATED_COMMISSIONS,
  FILTER_OUTPUT_SPECIAL_COMMISSIONS
};

const api = {
  filterCommissionsOutputs: (
    request: EntityObject<PageRequest>
  ): AxiosPromise<PageResult<CommissionsBatchOutputAttachment>> => {
    return apiService.get(FILTER_COMMISSIONS_OUTPUTS.getParametrizedUrl(request.id), { params: request.object });
  },
  downloadCommissionsOutput: (request: TwoLevelEntityIdObject): AxiosPromise<Blob> => {
    return apiService.get(DOWNLOAD_COMMISSIONS_OUTPUT.getParametrizedUrl(request.id1, request.id2), {
      responseType: "blob"
    });
  },
  filterOutputCalculatedCommissions: (
    request: TwoLevelEntityObject<CalculatedCommissionsFilterPageRequest>
  ): AxiosPromise<CalculatedCommissionsFilterPageResult> => {
    return apiService.get(FILTER_OUTPUT_CALCULATED_COMMISSIONS.getParametrizedUrl(request.id1, request.id2), {
      params: request.object,
      paramsSerializer: { serialize: serializeParams }
    });
  },
  filterOutputSpecialCommissions: (
    request: TwoLevelEntityObject<SpecialCommissionsFilterPageRequest>
  ): AxiosPromise<SpecialCommissionsFilterPageResult<SpecialCommissionBase>> => {
    return apiService.get(FILTER_OUTPUT_SPECIAL_COMMISSIONS.getParametrizedUrl(request.id1, request.id2), {
      params: request.object
    });
  }
};

export default api;
