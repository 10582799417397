import {
  AimOutlined,
  AlertOutlined,
  ApartmentOutlined,
  AppstoreOutlined,
  ArrowLeftOutlined,
  ArrowRightOutlined,
  ArrowsAltOutlined,
  AuditOutlined,
  BankOutlined,
  BellOutlined,
  BgColorsOutlined,
  BranchesOutlined,
  BugOutlined,
  CalculatorOutlined,
  CarOutlined,
  CheckCircleOutlined,
  CheckCircleTwoTone,
  CheckOutlined,
  CloseCircleOutlined,
  CloseCircleTwoTone,
  CloseOutlined,
  CloudDownloadOutlined,
  CloudOutlined,
  CloudSyncOutlined,
  CloudUploadOutlined,
  ClusterOutlined,
  CodeSandboxOutlined,
  ContactsOutlined,
  ContainerOutlined,
  CopyOutlined,
  DashboardOutlined,
  DatabaseOutlined,
  DeleteOutlined,
  DeliveredProcedureOutlined,
  DeploymentUnitOutlined,
  DoubleLeftOutlined,
  DoubleRightOutlined,
  DownloadOutlined,
  DownOutlined,
  EditOutlined,
  EuroCircleOutlined,
  EuroOutlined,
  ExceptionOutlined,
  ExclamationCircleOutlined,
  ExportOutlined,
  EyeInvisibleOutlined,
  EyeOutlined,
  FieldTimeOutlined,
  FileDoneOutlined,
  FileExcelOutlined,
  FileImageOutlined,
  FileOutlined,
  FilePdfOutlined,
  FilePptOutlined,
  FileSearchOutlined,
  FileTextOutlined,
  FileUnknownOutlined,
  FileWordOutlined,
  FileZipOutlined,
  FolderAddOutlined,
  FolderOpenOutlined,
  FolderOutlined,
  ForkOutlined,
  FormOutlined,
  FundProjectionScreenOutlined,
  GiftOutlined,
  GlobalOutlined,
  GroupOutlined,
  HddOutlined,
  HeatMapOutlined,
  HistoryOutlined,
  HomeOutlined,
  IdcardOutlined,
  ImportOutlined,
  InfoCircleOutlined,
  InfoCircleTwoTone,
  InteractionOutlined,
  KeyOutlined,
  LaptopOutlined,
  LeftSquareOutlined,
  LikeOutlined,
  LoadingOutlined,
  LockOutlined,
  LogoutOutlined,
  MailOutlined,
  MenuUnfoldOutlined,
  MessageOutlined,
  MinusCircleOutlined,
  MinusOutlined,
  MinusSquareOutlined,
  MobileOutlined,
  MoreOutlined,
  NodeExpandOutlined,
  NotificationOutlined,
  OrderedListOutlined,
  PaperClipOutlined,
  PartitionOutlined,
  PauseCircleOutlined,
  PhoneOutlined,
  PlusCircleOutlined,
  PlusOutlined,
  PlusSquareOutlined,
  ProfileOutlined,
  PullRequestOutlined,
  QuestionCircleOutlined,
  ReconciliationOutlined,
  ReloadOutlined,
  RetweetOutlined,
  RobotOutlined,
  RollbackOutlined,
  SafetyCertificateOutlined,
  SafetyOutlined,
  SaveOutlined,
  ScheduleOutlined,
  SearchOutlined,
  SelectOutlined,
  SendOutlined,
  SettingOutlined,
  ShopOutlined,
  ShrinkOutlined,
  SisternodeOutlined,
  SolutionOutlined,
  StopOutlined,
  SwapOutlined,
  SyncOutlined,
  TeamOutlined,
  TrophyOutlined,
  UnorderedListOutlined,
  UserAddOutlined,
  UserOutlined,
  UserSwitchOutlined,
  ZoomInOutlined
} from "@ant-design/icons";
import classNames from "classnames";
import { CSSProperties, MouseEventHandler } from "react";
import styles from "./AntIcon.module.scss";

export type AntIconType =
  | "aim"
  | "alert"
  | "apartment"
  | "appstore"
  | "arrow-left"
  | "arrow-right"
  | "arrows"
  | "audit"
  | "bank"
  | "bell"
  | "bg-colors"
  | "box"
  | "branch"
  | "bug"
  | "calculator"
  | "car"
  | "check"
  | "check-circle"
  | "check-circle-two-tone"
  | "close"
  | "close-circle"
  | "close-circle-two-tone"
  | "cloud"
  | "cloud-download"
  | "cloud-sync"
  | "cloud-upload"
  | "cluster"
  | "contact"
  | "container"
  | "copy"
  | "dashboard"
  | "database"
  | "delete"
  | "delivered-procedure"
  | "deployment-unit"
  | "double-left"
  | "double-right"
  | "down"
  | "download"
  | "edit"
  | "euro"
  | "euro-circle"
  | "exception"
  | "exclamation"
  | "export"
  | "eye"
  | "eye-invisible"
  | "field-time"
  | "file"
  | "file-done"
  | "file-excel"
  | "file-image"
  | "file-pdf"
  | "file-ppt"
  | "file-search"
  | "file-text"
  | "file-unknown"
  | "file-word"
  | "file-zip"
  | "folder"
  | "fork"
  | "form"
  | "gift"
  | "global"
  | "group"
  | "hdd"
  | "heat-map"
  | "history"
  | "home"
  | "id-card"
  | "import"
  | "info"
  | "info-circle"
  | "interaction"
  | "key"
  | "laptop"
  | "left-square"
  | "like"
  | "loading"
  | "lock"
  | "logout"
  | "mail"
  | "menu-unfold"
  | "message"
  | "minus"
  | "minus-circle"
  | "minus-square"
  | "mobile"
  | "more"
  | "new-folder"
  | "node-expand"
  | "notification"
  | "open-folder"
  | "ordered-list"
  | "paper-clip"
  | "partition"
  | "pause"
  | "phone"
  | "plus"
  | "plus-circle"
  | "plus-square"
  | "profile"
  | "projection"
  | "pull-request"
  | "question"
  | "reconciliation"
  | "reload"
  | "retweet"
  | "robot"
  | "rollback"
  | "safety"
  | "safety-certificate"
  | "save"
  | "schedule"
  | "search"
  | "select"
  | "send"
  | "settings"
  | "shop"
  | "shrink"
  | "sister-node"
  | "solution"
  | "stop"
  | "swap"
  | "sync"
  | "team"
  | "trophy"
  | "unordered-list"
  | "user"
  | "user-add"
  | "user-switch"
  | "zoom";

interface Props {
  type: AntIconType;
  color?: "blue" | "green" | "red" | "orange" | "grey";
  size?: "small" | "default" | "large" | "huge";
  twoToneColor?: string;
  className?: string;
  style?: CSSProperties;
  spin?: boolean;
  onClick?: MouseEventHandler;
}

const AntIcon = ({ type, color, twoToneColor, className, style, spin, onClick, size }: Props) => {
  const properties = {
    className: classNames(color ? styles[color] : "", size && size !== "default" ? styles[size] : "", className),
    style,
    spin,
    twoToneColor,
    onClick
  };

  switch (type) {
    case "aim":
      return <AimOutlined {...properties} />;
    case "alert":
      return <AlertOutlined {...properties} />;
    case "apartment":
      return <ApartmentOutlined {...properties} />;
    case "appstore":
      return <AppstoreOutlined {...properties} />;
    case "arrow-left":
      return <ArrowLeftOutlined {...properties} />;
    case "arrow-right":
      return <ArrowRightOutlined {...properties} />;
    case "arrows":
      return <ArrowsAltOutlined {...properties} />;
    case "audit":
      return <AuditOutlined {...properties} />;
    case "bank":
      return <BankOutlined {...properties} />;
    case "bell":
      return <BellOutlined {...properties} />;
    case "bg-colors":
      return <BgColorsOutlined {...properties} />;
    case "box":
      return <CodeSandboxOutlined {...properties} />;
    case "branch":
      return <BranchesOutlined {...properties} />;
    case "bug":
      return <BugOutlined {...properties} />;
    case "calculator":
      return <CalculatorOutlined {...properties} />;
    case "car":
      return <CarOutlined {...properties} />;
    case "check":
      return <CheckOutlined {...properties} />;
    case "check-circle":
      return <CheckCircleOutlined {...properties} />;
    case "check-circle-two-tone":
      return <CheckCircleTwoTone {...properties} />;
    case "close":
      return <CloseOutlined {...properties} />;
    case "close-circle":
      return <CloseCircleOutlined {...properties} />;
    case "close-circle-two-tone":
      return <CloseCircleTwoTone {...properties} />;
    case "cloud":
      return <CloudOutlined {...properties} />;
    case "cloud-download":
      return <CloudDownloadOutlined {...properties} />;
    case "cloud-sync":
      return <CloudSyncOutlined {...properties} />;
    case "cloud-upload":
      return <CloudUploadOutlined {...properties} />;
    case "cluster":
      return <ClusterOutlined {...properties} />;
    case "contact":
      return <ContactsOutlined {...properties} />;
    case "container":
      return <ContainerOutlined {...properties} />;
    case "copy":
      return <CopyOutlined {...properties} />;
    case "dashboard":
      return <DashboardOutlined {...properties} />;
    case "database":
      return <DatabaseOutlined {...properties} />;
    case "delete":
      return <DeleteOutlined {...properties} />;
    case "delivered-procedure":
      return <DeliveredProcedureOutlined {...properties} />;
    case "deployment-unit":
      return <DeploymentUnitOutlined {...properties} />;
    case "double-left":
      return <DoubleLeftOutlined {...properties} />;
    case "double-right":
      return <DoubleRightOutlined {...properties} />;
    case "down":
      return <DownOutlined {...properties} />;
    case "download":
      return <DownloadOutlined {...properties} />;
    case "edit":
      return <EditOutlined {...properties} />;
    case "euro":
      return <EuroOutlined {...properties} />;
    case "euro-circle":
      return <EuroCircleOutlined {...properties} />;
    case "exception":
      return <ExceptionOutlined {...properties} />;
    case "exclamation":
      return <ExclamationCircleOutlined {...properties} />;
    case "export":
      return <ExportOutlined {...properties} />;
    case "eye":
      return <EyeOutlined {...properties} />;
    case "eye-invisible":
      return <EyeInvisibleOutlined {...properties} />;
    case "field-time":
      return <FieldTimeOutlined {...properties} />;
    case "file":
      return <FileOutlined {...properties} />;
    case "file-done":
      return <FileDoneOutlined {...properties} />;
    case "file-excel":
      return <FileExcelOutlined {...properties} />;
    case "file-image":
      return <FileImageOutlined {...properties} />;
    case "file-pdf":
      return <FilePdfOutlined {...properties} />;
    case "file-ppt":
      return <FilePptOutlined {...properties} />;
    case "file-search":
      return <FileSearchOutlined {...properties} />;
    case "file-text":
      return <FileTextOutlined {...properties} />;
    case "file-unknown":
      return <FileUnknownOutlined {...properties} />;
    case "file-word":
      return <FileWordOutlined {...properties} />;
    case "file-zip":
      return <FileZipOutlined {...properties} />;
    case "folder":
      return <FolderOutlined {...properties} />;
    case "fork":
      return <ForkOutlined {...properties} />;
    case "form":
      return <FormOutlined {...properties} />;
    case "gift":
      return <GiftOutlined {...properties} />;
    case "global":
      return <GlobalOutlined {...properties} />;
    case "group":
      return <GroupOutlined {...properties} />;
    case "hdd":
      return <HddOutlined {...properties} />;
    case "heat-map":
      return <HeatMapOutlined {...properties} />;
    case "history":
      return <HistoryOutlined {...properties} />;
    case "home":
      return <HomeOutlined {...properties} />;
    case "id-card":
      return <IdcardOutlined {...properties} />;
    case "import":
      return <ImportOutlined {...properties} />;
    case "info":
      return <InfoCircleTwoTone {...properties} />;
    case "info-circle":
      return <InfoCircleOutlined {...properties} />;
    case "interaction":
      return <InteractionOutlined {...properties} />;
    case "key":
      return <KeyOutlined {...properties} />;
    case "laptop":
      return <LaptopOutlined {...properties} />;
    case "left-square":
      return <LeftSquareOutlined {...properties} />;
    case "like":
      return <LikeOutlined {...properties} />;
    case "loading":
      return <LoadingOutlined {...properties} />;
    case "lock":
      return <LockOutlined {...properties} />;
    case "logout":
      return <LogoutOutlined {...properties} />;
    case "mail":
      return <MailOutlined {...properties} />;
    case "menu-unfold":
      return <MenuUnfoldOutlined {...properties} />;
    case "message":
      return <MessageOutlined {...properties} />;
    case "minus":
      return <MinusOutlined {...properties} />;
    case "minus-circle":
      return <MinusCircleOutlined {...properties} />;
    case "minus-square":
      return <MinusSquareOutlined {...properties} />;
    case "mobile":
      return <MobileOutlined {...properties} />;
    case "more":
      return <MoreOutlined {...properties} />;
    case "new-folder":
      return <FolderAddOutlined {...properties} />;
    case "node-expand":
      return <NodeExpandOutlined {...properties} />;
    case "notification":
      return <NotificationOutlined {...properties} />;
    case "open-folder":
      return <FolderOpenOutlined {...properties} />;
    case "ordered-list":
      return <OrderedListOutlined {...properties} />;
    case "paper-clip":
      return <PaperClipOutlined {...properties} />;
    case "partition":
      return <PartitionOutlined {...properties} />;
    case "pause":
      return <PauseCircleOutlined {...properties} />;
    case "phone":
      return <PhoneOutlined {...properties} />;
    case "plus":
      return <PlusOutlined {...properties} />;
    case "plus-circle":
      return <PlusCircleOutlined {...properties} />;
    case "plus-square":
      return <PlusSquareOutlined {...properties} />;
    case "profile":
      return <ProfileOutlined {...properties} />;
    case "projection":
      return <FundProjectionScreenOutlined {...properties} />;
    case "pull-request":
      return <PullRequestOutlined {...properties} />;
    case "question":
      return <QuestionCircleOutlined {...properties} />;
    case "reconciliation":
      return <ReconciliationOutlined {...properties} />;
    case "reload":
      return <ReloadOutlined {...properties} />;
    case "retweet":
      return <RetweetOutlined {...properties} />;
    case "robot":
      return <RobotOutlined {...properties} />;
    case "rollback":
      return <RollbackOutlined {...properties} />;
    case "safety":
      return <SafetyOutlined {...properties} />;
    case "safety-certificate":
      return <SafetyCertificateOutlined {...properties} />;
    case "save":
      return <SaveOutlined {...properties} />;
    case "schedule":
      return <ScheduleOutlined {...properties} />;
    case "search":
      return <SearchOutlined {...properties} />;
    case "select":
      return <SelectOutlined {...properties} />;
    case "send":
      return <SendOutlined {...properties} />;
    case "settings":
      return <SettingOutlined {...properties} />;
    case "shop":
      return <ShopOutlined {...properties} />;
    case "shrink":
      return <ShrinkOutlined {...properties} />;
    case "sister-node":
      return <SisternodeOutlined {...properties} />;
    case "solution":
      return <SolutionOutlined {...properties} />;
    case "stop":
      return <StopOutlined {...properties} />;
    case "swap":
      return <SwapOutlined {...properties} />;
    case "sync":
      return <SyncOutlined {...properties} />;
    case "team":
      return <TeamOutlined {...properties} />;
    case "trophy":
      return <TrophyOutlined {...properties} />;
    case "unordered-list":
      return <UnorderedListOutlined {...properties} />;
    case "user":
      return <UserOutlined {...properties} />;
    case "user-add":
      return <UserAddOutlined {...properties} />;
    case "user-switch":
      return <UserSwitchOutlined {...properties} />;
    case "zoom":
      return <ZoomInOutlined {...properties} />;
    default:
      return null;
  }
};

export default AntIcon;
