import { AxiosPromise } from "axios";
import { ApiRequestAdapter } from "../../../common/api/ApiRequestAdapter";
import { apiService } from "../../../common/api/apiService";
import {
  EntityIdObject,
  EntityObject,
  OptimisticLockVersion,
  ThreeLevelEntityObject,
  TwoLevelEntityIdObject,
  TwoLevelEntityObject
} from "../../../common/types";
import { serializeParams } from "../../../common/utils/apiUtils";
import { BailAccountMovement } from "../bailaccount/types";
import {
  SpecialCommission,
  SpecialCommissionsFilterPageRequest,
  SpecialCommissionsFilterPageResult
} from "../special/types";
import {
  CalculateBatchPaymentToBePaid,
  ChangeCommissionsBatchStepRequest,
  Commission,
  CommissionsBatch,
  CommissionsBatchFilterPageRequest,
  CommissionsBatchFilterPageResult,
  CommissionsBatchInputAttachment,
  CommissionsFilterPageRequest,
  CommissionsFilterPageResult,
  CommissionsUnit,
  CreateCommissionsUnit,
  CreateManuallyAddedCommission,
  CreateUpdateCommissionsBatch,
  DeleteCommissionsUnitRequest,
  DownloadBatchOutputAttachmentsAsZip,
  ExcludeSpecialCommission,
  GenerateBatchPaymentOrder,
  ManualBailAccountMovementsFilterPageRequest,
  ManualBailAccountMovementsFilterPageResult,
  PostponeCommission,
  RecalculateCommissionsForContractRequest,
  UpdateCalculatedCommissionList,
  UpdateCommission,
  UpdateCommissionsUnit
} from "./types";

const FILTER_COMMISSIONS_BATCHES = ApiRequestAdapter.create("/commissions-batches", "GET");
const GET_COMMISSIONS_BATCH = ApiRequestAdapter.create("/commissions-batches/{0}", "GET");
const CALCULATE_COMMISSIONS_BATCH_PAYMENT_TO_BE_PAID = ApiRequestAdapter.create(
  "/commissions-batches/{0}/calculate-payment-to-be-paid",
  "POST"
);
const GENERATE_COMMISSIONS_BATCH_PAYMENT_ORDER = ApiRequestAdapter.create(
  "/commissions-batches/{0}/generate-payment-order",
  "POST"
);
const DOWNLOAD_COMMISSIONS_BATCH_ATTACHMENTS_AS_ZIP = ApiRequestAdapter.create(
  "/commissions-batches/{0}/download-output-attachments-zip",
  "GET"
);
const GET_COMMISSIONS_BATCH_NAME_PREFIX = ApiRequestAdapter.create("/commissions-batches/name-prefix", "GET");
const CREATE_COMMISSIONS_BATCH = ApiRequestAdapter.create("/commissions-batches", "POST");
const UPDATE_COMMISSIONS_BATCH = ApiRequestAdapter.create("/commissions-batches/{0}", "PUT");
const DELETE_COMMISSIONS_BATCH = ApiRequestAdapter.create("/commissions-batches/{0}", "DELETE");
const CHANGE_COMMISSIONS_BATCH_STEP = ApiRequestAdapter.create("/commissions-batches/{0}/change-step", "POST");

const CREATE_COMMISSIONS_UNIT = ApiRequestAdapter.create("/commissions-batches/{0}/units", "POST");
const UPDATE_COMMISSIONS_UNIT = ApiRequestAdapter.create("/commissions-batches/{0}/units/{1}", "PUT");
const DELETE_COMMISSIONS_UNIT = ApiRequestAdapter.create("/commissions-batches/{0}", "DELETE");

const UPLOAD_COMMISSIONS_BATCH_ATTACHMENT = ApiRequestAdapter.create("/commissions-batches/{0}/attachments", "POST");
const REPLACE_COMMISSIONS_BATCH_ATTACHMENT = ApiRequestAdapter.create(
  "/commissions-batches/{0}/attachments/{1}",
  "PUT"
);
const DOWNLOAD_COMMISSIONS_BATCH_ATTACHMENT = ApiRequestAdapter.create(
  "/commissions-batches/{0}/attachments/{1}",
  "GET"
);
const DELETE_COMMISSIONS_BATCH_ATTACHMENT = ApiRequestAdapter.create(
  "/commissions-batches/{0}/attachments/{1}",
  "DELETE"
);

const FILTER_COMMISSIONS = ApiRequestAdapter.create("/commissions-batches/{0}/commissions", "GET");
const CREATE_COMMISSION = ApiRequestAdapter.create("/commissions-batches/{0}/commissions", "POST");
const UPDATE_COMMISSION = ApiRequestAdapter.create("/commissions-batches/{0}/commissions/{1}", "PUT");
const POSTPONE_COMMISSION = ApiRequestAdapter.create("/commissions-batches/{0}/commissions/{1}/postpone", "PUT");
const TRY_TO_INCLUDE_COMMISSION = ApiRequestAdapter.create("/commissions-batches/{0}/commissions/{1}/include", "PUT");
const RECALCULATE_COMMISSIONS_FOR_CONTRACT = ApiRequestAdapter.create(
  "/commissions-batches/{0}/commissions/recalculate-for-contract",
  "PUT"
);
const UPDATE_CALCULATED_COMMISSIONS = ApiRequestAdapter.create(
  "/commissions-batches/{0}/commissions/{1}/calculations",
  "PUT"
);
const DELETE_COMMISSION = ApiRequestAdapter.create("/commissions-batches/{0}/commissions/{1}", "DELETE");

const FILTER_MANUAL_BAIL_ACCOUNT_MOVEMENTS = ApiRequestAdapter.create(
  "/commissions-batches/{0}/manual-bail-account-movements",
  "GET"
);
const ASSOCIATE_BAIL_ACCOUNT_MOVEMENT_TO_BATCH = ApiRequestAdapter.create(
  "/commissions-batches/{0}/manual-bail-account-movements/{1}/associate",
  "PUT"
);
const DISASSOCIATE_BAIL_ACCOUNT_MOVEMENT_FROM_BATCH = ApiRequestAdapter.create(
  "/commissions-batches/{0}/manual-bail-account-movements/{1}/disassociate",
  "PUT"
);

const FILTER_SPECIAL_COMMISSIONS = ApiRequestAdapter.create(
  "/commissions-batches/{0}/agents/{1}/special-commissions",
  "GET"
);
const EXCLUDE_SPECIAL_COMMISSION = ApiRequestAdapter.create(
  "/commissions-batches/{0}/agents/{1}/special-commissions/{2}/exclude",
  "PUT"
);
const INCLUDE_SPECIAL_COMMISSION = ApiRequestAdapter.create(
  "/commissions-batches/{0}/agents/{1}/special-commissions/{2}/include",
  "PUT"
);

export const requests = {
  FILTER_COMMISSIONS_BATCHES,
  GET_COMMISSIONS_BATCH,
  CALCULATE_COMMISSIONS_BATCH_PAYMENT_TO_BE_PAID,
  GENERATE_COMMISSIONS_BATCH_PAYMENT_ORDER,
  DOWNLOAD_COMMISSIONS_BATCH_ATTACHMENTS_AS_ZIP,
  GET_COMMISSIONS_BATCH_NAME_PREFIX,
  CREATE_COMMISSIONS_BATCH,
  UPDATE_COMMISSIONS_BATCH,
  DELETE_COMMISSIONS_BATCH,
  CHANGE_COMMISSIONS_BATCH_STEP,
  CREATE_COMMISSIONS_UNIT,
  UPDATE_COMMISSIONS_UNIT,
  DELETE_COMMISSIONS_UNIT,
  UPLOAD_COMMISSIONS_BATCH_ATTACHMENT,
  REPLACE_COMMISSIONS_BATCH_ATTACHMENT,
  DOWNLOAD_COMMISSIONS_BATCH_ATTACHMENT,
  DELETE_COMMISSIONS_BATCH_ATTACHMENT,
  FILTER_COMMISSIONS,
  CREATE_COMMISSION,
  UPDATE_COMMISSION,
  POSTPONE_COMMISSION,
  TRY_TO_INCLUDE_COMMISSION,
  RECALCULATE_COMMISSIONS_FOR_CONTRACT,
  UPDATE_CALCULATED_COMMISSIONS,
  DELETE_COMMISSION,
  FILTER_SPECIAL_COMMISSIONS,
  EXCLUDE_SPECIAL_COMMISSION,
  INCLUDE_SPECIAL_COMMISSION,
  FILTER_MANUAL_BAIL_ACCOUNT_MOVEMENTS,
  ASSOCIATE_BAIL_ACCOUNT_MOVEMENT_TO_BATCH,
  DISASSOCIATE_BAIL_ACCOUNT_MOVEMENT_FROM_BATCH
};

const api = {
  filterCommissionsBatches: (
    request: CommissionsBatchFilterPageRequest
  ): AxiosPromise<CommissionsBatchFilterPageResult> => {
    return apiService.get(FILTER_COMMISSIONS_BATCHES.url, {
      params: request,
      paramsSerializer: { serialize: serializeParams }
    });
  },
  getCommissionsBatch: (request: EntityIdObject): AxiosPromise<CommissionsBatch> => {
    return apiService.get(GET_COMMISSIONS_BATCH.getParametrizedUrl(request.id));
  },
  calculateCommissionsBatchPaymentToBePaid: (
    request: EntityObject<CalculateBatchPaymentToBePaid>
  ): AxiosPromise<number> => {
    return apiService.post(
      CALCULATE_COMMISSIONS_BATCH_PAYMENT_TO_BE_PAID.getParametrizedUrl(request.id),
      request.object
    );
  },
  generateCommissionsBatchPaymentOrder: (request: EntityObject<GenerateBatchPaymentOrder>): AxiosPromise<Blob> => {
    return apiService.post(GENERATE_COMMISSIONS_BATCH_PAYMENT_ORDER.getParametrizedUrl(request.id), request.object);
  },
  downloadCommissionsBatchAttachmentsAsZip: (
    request: EntityObject<DownloadBatchOutputAttachmentsAsZip>
  ): AxiosPromise<Blob> => {
    return apiService.get(DOWNLOAD_COMMISSIONS_BATCH_ATTACHMENTS_AS_ZIP.getParametrizedUrl(request.id), {
      params: request.object,
      paramsSerializer: { serialize: serializeParams },
      responseType: "blob"
    });
  },
  getCommissionsBatchNamePrefix: (): AxiosPromise<string> => {
    return apiService.get(GET_COMMISSIONS_BATCH_NAME_PREFIX.url);
  },
  createCommissionsBatch: (request: CreateUpdateCommissionsBatch): AxiosPromise<CommissionsBatch> => {
    return apiService.post(CREATE_COMMISSIONS_BATCH.url, request);
  },
  updateCommissionsBatch: (request: EntityObject<CreateUpdateCommissionsBatch>): AxiosPromise<CommissionsBatch> => {
    return apiService.put(UPDATE_COMMISSIONS_BATCH.getParametrizedUrl(request.id), request.object);
  },
  deleteCommissionsBatch: (request: EntityIdObject): AxiosPromise => {
    return apiService.delete(DELETE_COMMISSIONS_BATCH.getParametrizedUrl(request.id));
  },
  changeCommissionsBatchStep: (
    request: EntityObject<ChangeCommissionsBatchStepRequest>
  ): AxiosPromise<CommissionsBatch> => {
    return apiService.post(CHANGE_COMMISSIONS_BATCH_STEP.getParametrizedUrl(request.id), request.object);
  },
  createCommissionsUnit: (request: EntityObject<CreateCommissionsUnit>): AxiosPromise<CommissionsUnit> => {
    return apiService.post(CREATE_COMMISSIONS_UNIT.getParametrizedUrl(request.id), request.object);
  },
  updateCommissionsUnit: (request: TwoLevelEntityObject<UpdateCommissionsUnit>): AxiosPromise<CommissionsUnit> => {
    return apiService.put(UPDATE_COMMISSIONS_UNIT.getParametrizedUrl(request.id1, request.id2), request.object);
  },
  deleteCommissionsUnit: (request: EntityObject<DeleteCommissionsUnitRequest>): AxiosPromise => {
    return apiService.delete(DELETE_COMMISSIONS_UNIT.getParametrizedUrl(request.id), { params: request.object });
  },
  uploadCommissionsBatchAttachment: (
    request: EntityObject<FormData>
  ): AxiosPromise<CommissionsBatchInputAttachment> => {
    return apiService.post(UPLOAD_COMMISSIONS_BATCH_ATTACHMENT.getParametrizedUrl(request.id), request.object, {
      headers: { "Content-Type": "multipart/form-data" }
    });
  },
  replaceCommissionsBatchAttachment: (
    request: TwoLevelEntityObject<FormData>
  ): AxiosPromise<CommissionsBatchInputAttachment> => {
    return apiService.put(
      REPLACE_COMMISSIONS_BATCH_ATTACHMENT.getParametrizedUrl(request.id1, request.id2),
      request.object,
      { headers: { "Content-Type": "multipart/form-data" } }
    );
  },
  downloadCommissionsBatchAttachment: (request: TwoLevelEntityIdObject): AxiosPromise<Blob> => {
    return apiService.get(DOWNLOAD_COMMISSIONS_BATCH_ATTACHMENT.getParametrizedUrl(request.id1, request.id2), {
      responseType: "blob"
    });
  },
  deleteCommissionsBatchAttachment: (request: TwoLevelEntityIdObject): AxiosPromise => {
    return apiService.delete(DELETE_COMMISSIONS_BATCH_ATTACHMENT.getParametrizedUrl(request.id1, request.id2));
  },
  filterCommissions: (
    request: EntityObject<CommissionsFilterPageRequest>
  ): AxiosPromise<CommissionsFilterPageResult> => {
    return apiService.get(FILTER_COMMISSIONS.getParametrizedUrl(request.id), {
      params: request.object,
      paramsSerializer: { serialize: serializeParams }
    });
  },
  createCommission: (request: EntityObject<CreateManuallyAddedCommission>): AxiosPromise<Commission> => {
    return apiService.post(CREATE_COMMISSION.getParametrizedUrl(request.id), request.object);
  },
  updateCommission: (request: TwoLevelEntityObject<UpdateCommission>): AxiosPromise<Commission> => {
    return apiService.put(UPDATE_COMMISSION.getParametrizedUrl(request.id1, request.id2), request.object);
  },
  postponeCommission: (request: TwoLevelEntityObject<PostponeCommission>): AxiosPromise<Commission> => {
    return apiService.put(POSTPONE_COMMISSION.getParametrizedUrl(request.id1, request.id2), request.object);
  },
  tryToIncludeCommission: (request: TwoLevelEntityObject<OptimisticLockVersion>): AxiosPromise<Commission> => {
    return apiService.put(TRY_TO_INCLUDE_COMMISSION.getParametrizedUrl(request.id1, request.id2), request.object);
  },
  recalculateCommissionsForContract: (
    request: EntityObject<RecalculateCommissionsForContractRequest>
  ): AxiosPromise<Commission[]> => {
    return apiService.put(RECALCULATE_COMMISSIONS_FOR_CONTRACT.getParametrizedUrl(request.id), null, {
      params: { contractId: request.object.contractId }
    });
  },
  updateCalculatedCommissions: (
    request: TwoLevelEntityObject<UpdateCalculatedCommissionList>
  ): AxiosPromise<Commission> => {
    return apiService.put(UPDATE_CALCULATED_COMMISSIONS.getParametrizedUrl(request.id1, request.id2), request.object);
  },
  deleteCommission: (request: TwoLevelEntityIdObject): AxiosPromise => {
    return apiService.delete(DELETE_COMMISSION.getParametrizedUrl(request.id1, request.id2));
  },
  filterManualBailAccountsMovements: (
    request: EntityObject<ManualBailAccountMovementsFilterPageRequest>
  ): AxiosPromise<ManualBailAccountMovementsFilterPageResult> => {
    return apiService.get(FILTER_MANUAL_BAIL_ACCOUNT_MOVEMENTS.getParametrizedUrl(request.id), {
      params: request.object,
      paramsSerializer: { serialize: serializeParams }
    });
  },
  associateBailAccountMovementToBatch: (
    request: TwoLevelEntityObject<OptimisticLockVersion>
  ): AxiosPromise<BailAccountMovement> => {
    return apiService.put(
      ASSOCIATE_BAIL_ACCOUNT_MOVEMENT_TO_BATCH.getParametrizedUrl(request.id1, request.id2),
      request.object
    );
  },
  disassociateBailAccountMovementFromBatch: (
    request: TwoLevelEntityObject<OptimisticLockVersion>
  ): AxiosPromise<BailAccountMovement> => {
    return apiService.put(
      DISASSOCIATE_BAIL_ACCOUNT_MOVEMENT_FROM_BATCH.getParametrizedUrl(request.id1, request.id2),
      request.object
    );
  },
  filterSpecialCommissions: (
    request: TwoLevelEntityObject<SpecialCommissionsFilterPageRequest>
  ): AxiosPromise<SpecialCommissionsFilterPageResult<SpecialCommission>> => {
    return apiService.get(FILTER_SPECIAL_COMMISSIONS.getParametrizedUrl(request.id1, request.id2), {
      params: request.object
    });
  },
  excludeSpecialCommission: (
    request: ThreeLevelEntityObject<ExcludeSpecialCommission>
  ): AxiosPromise<SpecialCommission> => {
    return apiService.put(
      EXCLUDE_SPECIAL_COMMISSION.getParametrizedUrl(request.id1, request.id2, request.id3),
      request.object
    );
  },
  includeSpecialCommission: (
    request: ThreeLevelEntityObject<OptimisticLockVersion>
  ): AxiosPromise<SpecialCommission> => {
    return apiService.put(
      INCLUDE_SPECIAL_COMMISSION.getParametrizedUrl(request.id1, request.id2, request.id3),
      request.object
    );
  }
};

export default api;
