import { FormInstance } from "antd/lib/form";
import dayjs from "dayjs";
import t, { DEFAULT_LOCALE } from "../../../app/i18n";
import { HttpStatus } from "../../../common/api/http";
import { ErrorResponse } from "../../../common/types";
import { showErrorResponseNotification } from "../../../common/utils/apiUtils";
import { dateToIsoDateString, setErrorsToForm } from "../../../common/utils/formUtils";
import messageUtils from "../../../common/utils/messageUtils";
import { numberOrZero } from "../../../common/utils/utils";
import { DeepPartial } from "../../../typings/global";
import { ClientFormType, ClientType } from "../../client/enums";
import { Client, LegalClient, NaturalClient, SelfEmployedClient } from "../../client/types";
import {
  getCreateUpdateContractClientFromClient,
  isLegalClient,
  processClientsDataViolations
} from "../../client/utils";
import { PaymentFrequency } from "../../contract/enums";
import {
  CrashInsuranceData,
  GapInsuranceData,
  MtplInsuranceData,
  RealtyInsurance,
  TravelInsurance,
  VehicleInsurance
} from "../../contract/types";
import {
  convertInsuranceTypeToCalcType,
  isRealtyInsurance,
  isTravelInsurance,
  isVehicleInsurance
} from "../../contract/utils";
import { CalcDraft } from "../drafts/types";
import { CalcType, ClientExperience } from "../enums";
import { CalcRecord, RealtyCalcRecord, TravelCalcRecord, VehicleCalcRecord } from "../records/types";
import { CalcResultDataType, CalcResultsSorting } from "./enums";
import { RealtyCalc, RealtyCalcDraft, RealtyCalcResultData, RealtyGen } from "./realty/types";
import { TravelCalc, TravelCalcDraft, TravelCalcResultData, TravelGen } from "./travel/types";
import {
  CalcResult,
  CalcResultData,
  CalculatorsGen,
  GenFinancialMediationData,
  GenResponseViolations,
  Offer,
  StepInfo
} from "./types";
import { AnimalReinsuranceAmount, VehicleOwnerRelation, VehiclePolicyHolderRelation } from "./vehicle/enums";
import { VehicleCalc, VehicleCalcDraft, VehicleCalcResultData, VehicleCalcType, VehicleGen } from "./vehicle/types";

export const DEFEND_D2C_COVERAGE = "D2C";

export const sortAndGroupCalcResults = <T extends CalcResultData = CalcResultData>(
  calcResults: CalcResult<T>[]
): CalcResult<T>[][] => {
  let sortedResults: CalcResult<T>[][] = [];

  calcResults.forEach(result => {
    const index = sortedResults.findIndex(
      items => items[0]?.insuranceInstitution.id === result.insuranceInstitution.id
    );
    if (index === -1) {
      sortedResults.push([result]);
    } else {
      sortedResults[index]?.push(result);
    }
  });

  const rowsWithoutError: CalcResult<T>[][] = [];
  const rowsWithError: CalcResult<T>[][] = [];
  sortedResults.forEach(resultsRow => {
    if (resultsRow.some(result => result.error)) {
      rowsWithError.push(resultsRow);
    } else {
      rowsWithoutError.push(resultsRow);
    }
  });

  sortedResults = [
    ...sortResultGroupsByInstitutionName(rowsWithoutError),
    ...sortResultGroupsByInstitutionName(rowsWithError)
  ];

  sortedResults = sortResultsWithinGroupsByCoverageIndex(sortedResults);

  return sortedResults;
};

export const checkIfStepsAreSuccessful = <T>(steps: StepInfo<T>[], ...checkedSteps: T[]): boolean => {
  return checkedSteps.every(checkedStep => steps.some(step => step.step === checkedStep && step.successful));
};

export const filterApplicableResults = <T extends CalcResultData = CalcResultData>(
  results: CalcResult<T>[]
): CalcResult<T>[] => {
  return results.filter(result => isApplicableResult(result));
};

export const isApplicableResult = <T extends CalcResultData = CalcResultData>(result: CalcResult<T>): boolean => {
  return !result.data?.notApplicable;
};

export const filterApplicableSuccessResults = <T extends CalcResultData = CalcResultData>(
  results: CalcResult<T>[]
): CalcResult<T>[] => {
  return results.filter(result => isApplicableSuccessResult(result));
};

export const isApplicableSuccessResult = <T extends CalcResultData = CalcResultData>(
  result: CalcResult<T>
): boolean => {
  return !!result.data && !result.data.notApplicable;
};

export const sortResultGroupsByInstitutionName = <T extends CalcResultData>(
  resultGroups: CalcResult<T>[][]
): CalcResult<T>[][] => {
  const result: CalcResult<T>[][] = structuredClone(resultGroups);

  result.sort((a, b) => {
    const institutionNameA = a[0]?.insuranceInstitution.name ?? "";
    const institutionNameB = b[0]?.insuranceInstitution.name ?? "";

    return institutionNameA.localeCompare(institutionNameB, DEFAULT_LOCALE, {
      sensitivity: "accent"
    });
  });

  return result;
};

export const groupByInstitution = <T extends CalcResultData>(results: CalcResult<T>[]): CalcResult<T>[][] => {
  const grouped = results.reduce(
    (acc, result) => {
      const institutionId = result.insuranceInstitution.id;
      if (!acc[institutionId]) {
        acc[institutionId] = [];
      }
      acc[institutionId]?.push(result);
      return acc;
    },
    {} as Record<string, CalcResult<T>[]>
  );

  return Object.values(grouped);
};

export const sortResultGroupsByAnnualPremium = <T extends CalcResultData>(
  resultGroups: CalcResult<T>[][]
): CalcResult<T>[][] => {
  const result: CalcResult<T>[][] = structuredClone(resultGroups);

  result.sort((a, b) => {
    const result1 = a
      .filter((result): result is CalcResult<T> & { data: T } => !!result.data)
      .find(result => !result.data.notApplicable);
    const result2 = b
      .filter((result): result is CalcResult<T> & { data: T } => !!result.data)
      .find(result => result.data && !result.data.notApplicable);

    return !result1 ? 1 : !result2 ? -1 : result1.data.annualPremium - result2.data.annualPremium;
  });

  return result;
};

export const compareByCoverageOrderIndex = <T extends CalcResultData>(a: CalcResult<T>, b: CalcResult<T>): number => {
  return (a.coverageOrderIndex ?? Number.POSITIVE_INFINITY) - (b.coverageOrderIndex ?? Number.POSITIVE_INFINITY);
};

export const sortResultsWithinGroupsByCoverageIndex = <T extends CalcResultData>(
  resultGroups: CalcResult<T>[][]
): CalcResult<T>[][] => {
  const result: CalcResult<T>[][] = [];

  resultGroups.forEach((group, index) => {
    result[index] = group?.sort(compareByCoverageOrderIndex);
  });

  return result;
};

export const processGenResultError = (
  error: ErrorResponse,
  clientsIndicesMap: Map<ClientFormType, number>,
  form: FormInstance,
  translationRootPath: string
): GenResponseViolations | undefined => {
  if (error.status === HttpStatus.UNPROCESSABLE_ENTITY && error.violations) {
    const clientsViolations = processClientsDataViolations(clientsIndicesMap, "clientsData.clients", error.violations);
    const notificationViolations = error.violations.filter(
      violation => !violation.fieldPath.startsWith("clientsData.clients")
    );

    if (clientsViolations.size > 0) {
      notificationViolations.unshift({
        fieldPath: "clientsData._label",
        errors: [t("calc.validations.clientsViolationError")],
        fieldValue: undefined
      });
    }

    setErrorsToForm(form, translationRootPath, error.violations);

    return { clientsViolations, notificationViolations };
  }

  showErrorResponseNotification(error);
  return undefined;
};

export const resolveClientFormTypeIdentifierName = (type: ClientFormType): string => {
  switch (type) {
    case ClientFormType.INSURED:
      return "insuredClientIdentifier";
    case ClientFormType.POLICY_HOLDER:
      return "policyHolderIdentifier";
    case ClientFormType.REPRESENTATIVE:
      return "representativeIdentifier";
    case ClientFormType.VINCULATION:
      return "vinculationClientIdentifier";
    case ClientFormType.HOLDER:
      return "holderIdentifier";
    case ClientFormType.OWNER:
      return "ownerIdentifier";
    case ClientFormType.CO_DEBTOR:
      return "coDebtorIdentifier";
    default:
      return "";
  }
};

const getDefaultGenFinancialMediationData = (policyHolderType: ClientType): Partial<GenFinancialMediationData> => ({
  clientExperience:
    policyHolderType === ClientType.SELF_EMPLOYED || policyHolderType === ClientType.LEGAL
      ? ClientExperience.SUFFICIENT
      : undefined,
  otherClientRequirements: undefined,
  recommendedResult: undefined,
  recommendationReason: undefined,
  additionalClientStatement: undefined,
  additionalSuitabilityStatement: undefined
});

const prepareClientsData = (clients: (NaturalClient | SelfEmployedClient | LegalClient)[]) => {
  return clients.map(client => {
    const clientData = getCreateUpdateContractClientFromClient(client);

    if (isLegalClient(client)) {
      return { ...clientData };
    }

    return {
      ...clientData,
      client: {
        ...clientData.client,
        identityCardNumber: undefined,
        previousIdentityCardNumber: client.identityCardNumber
      }
    };
  });
};

type PrepareGenDataFromContract = {
  insurance: TravelInsurance | RealtyInsurance | VehicleInsurance;
  contractData: {
    policyHolderIndex: number;
    clients: Client[];
    paymentFrequency: PaymentFrequency;
  };
};

export const prepareCalcGenDataFromContract = ({
  insurance,
  contractData
}: PrepareGenDataFromContract): CalculatorsGen | undefined => {
  try {
    const cloneCalcData = structuredClone(insurance.calcData);

    if (!cloneCalcData) {
      return prepareCalcGenDataFromInsurance({ insurance, contractData });
    }

    return prepareCalcGenDataFromInsuranceCalcData({
      insurance,
      contractData,
      calcData: cloneCalcData
    });
  } catch {
    messageUtils.errorNotification({
      message: t("common.error"),
      description: t("calc.helpers.loadDataError"),
      key: "loadCalcDataError"
    });

    return undefined;
  }
};

type PrepareGenDataFromInsurance = {
  insurance: TravelInsurance | RealtyInsurance | VehicleInsurance;
  contractData: {
    policyHolderIndex: number;
    clients: Client[];
    paymentFrequency: PaymentFrequency;
  };
};

const prepareCalcGenDataFromInsurance = ({
  insurance,
  contractData: { policyHolderIndex, clients, paymentFrequency }
}: PrepareGenDataFromInsurance): CalculatorsGen | undefined => {
  if (isTravelInsurance(insurance)) {
    return {
      generalData: {
        effectiveBeginningDate: dateToIsoDateString(dayjs()),
        effectiveEndDate: undefined,
        territorialValidity: insurance.insuranceData.territorialValidity,
        riskGroup: insurance.insuranceData.riskGroup,
        studyStay: undefined
      },
      clientsData: {
        policyHolderIndex: policyHolderIndex,
        clients: [...prepareClientsData(clients)]
      },
      financialMediationData: {
        ...getDefaultGenFinancialMediationData(clients[policyHolderIndex]?.type as ClientType)
      },
      type: CalcType.TRAVEL
    } as DeepPartial<TravelGen>;
  } else if (isRealtyInsurance(insurance)) {
    return {
      type: CalcType.REALTY,
      generalBuildingData: {
        type: insurance.insuranceData.buildingType,
        placeOfInsurance: insurance.insuranceData.placeOfInsurance,
        state: insurance.insuranceData.buildingState,
        constructionYear: insurance.insuranceData.constructionYear,
        permanentlyOccupied: insurance.insuranceData.permanentlyOccupied
      },
      houseOrRecreationalBuildingData: {
        buildArea: insurance.insuranceData.houseOrRecreationalBuilding?.buildArea,
        roofType: insurance.insuranceData.houseOrRecreationalBuilding?.roofType,
        constructionMaterial: insurance.insuranceData.houseOrRecreationalBuilding?.constructionMaterial
      },
      apartmentData: {
        floor: insurance.insuranceData.apartment?.floor,
        floorArea: insurance.insuranceData.apartment?.floorArea
      },
      realtyInsuranceData: insurance.insuranceData.realty?.insuranceAmount
        ? {
            insuranceAmount: insurance.insuranceData.realty?.insuranceAmount
          }
        : undefined,
      householdInsuranceData: insurance.insuranceData.household?.insuranceAmount
        ? {
            insuranceAmount: insurance.insuranceData.household?.insuranceAmount
          }
        : undefined,
      generalInsuranceData: {
        effectiveBeginningDate: dateToIsoDateString(dayjs().add(1, "day")),
        paymentFrequency: paymentFrequency,
        crossSelling: {
          csobContract: false,
          unionHealthContract: false,
          unionHealthContractNumber: undefined,
          uniqaContractNumber: undefined,
          premiumContractNumber: undefined,
          colonnadeDiscountCode: undefined
        },
        cyberneticReinsurance: insurance.insuranceData.reinsurances.cyberSecurity,
        warrantyReinsurance: insurance.insuranceData.reinsurances.warranty,
        cyclingReinsurance: insurance.insuranceData.reinsurances.cycling,
        loanReinsurance: insurance.insuranceData.reinsurances.loan,
        rentReinsurance: insurance.insuranceData.reinsurances.rent,
        allRiskReinsurance: insurance.insuranceData.reinsurances.allRisk,
        complicity: insurance.insuranceData.complicity,
        vinculation: insurance.insuranceData.vinculation
      },
      clientsData: {
        policyHolderIndex: policyHolderIndex,
        insuredClientIndex: insurance.insuredClientIndex,
        vinculationClientIndex: insurance.vinculationClientIndex,
        clients: [...prepareClientsData(clients)]
      },
      financialMediationData: {
        ...getDefaultGenFinancialMediationData(clients[policyHolderIndex]?.type as ClientType),
        ownedByClient: true,
        businessDesc: undefined
      },
      liabilityInsuranceData: {
        liability: undefined
      }
    } as DeepPartial<RealtyGen>;
  } else if (isVehicleInsurance(insurance)) {
    const calcType = convertInsuranceTypeToCalcType(insurance);
    const clientsDataClients = [...prepareClientsData(clients)];
    const isNotNaturalClient = clientsDataClients[insurance.vehicleHolderIndex]?.client.type !== ClientType.NATURAL;
    const ownerRelation = insurance.insuranceData.leasing
      ? VehicleOwnerRelation.DIFFERENT_FROM_BOTH
      : isNotNaturalClient
        ? VehicleOwnerRelation.SAME_AS_VEHICLE_HOLDER
        : undefined;

    return {
      type: calcType,
      vehicleData: {
        licensePlate: insurance.licensePlate,
        vin: insurance.vehicle.vin,
        purpose: insurance.insuranceData.purpose,
        registrationCertificateNumber: undefined,
        previousRegistrationCertificateNumber: insurance.insuranceData.registrationCertificateNumber,
        firstRegistrationDate: insurance.vehicle.firstRegistrationDate,
        modelId: insurance.vehicle.model.id,
        brandId: insurance.vehicle.model.brand.id,
        customModel: insurance.vehicle.customModel,
        customBrand: insurance.vehicle.customBrand,
        category: insurance.vehicle.category,
        engineDisplacement: insurance.vehicle.engineDisplacement,
        enginePower: insurance.vehicle.enginePower,
        fuelType: insurance.vehicle.fuelType,
        transmission: insurance.vehicle.transmission,
        bodywork: insurance.vehicle.bodywork,
        seatsNumber: insurance.vehicle.seatsNumber,
        doorsNumber: insurance.vehicle.doorsNumber,
        totalWeight: insurance.vehicle.totalWeight,
        colorId: insurance.vehicle.color.id,
        price: undefined,
        generalPrice: undefined,
        buyingPrice: undefined,
        odometer: undefined,
        newVehicle: false
      },
      clientsData: {
        policyHolderIndex: policyHolderIndex,
        holderIndex: insurance.vehicleHolderIndex,
        ownerIndex: insurance.vehicleOwnerIndex,
        clients: clientsDataClients,
        holderTimeWithoutAccident: undefined,
        holderAccidentsIn2Years: undefined,
        holderAccidentsIn3Years: undefined,
        holderAccidentsIn5Years: undefined,
        holderAccidentsIn8Years: undefined,
        holderAccidentsIn10Years: undefined,
        holderAccidentsInAllYears: undefined,
        currentlyInsuredVehicles: undefined,
        leasing: insurance.insuranceData.leasing,
        policyHolderRelation:
          isNotNaturalClient || insurance.insuranceData.leasing
            ? VehiclePolicyHolderRelation.SAME_AS_VEHICLE_HOLDER
            : undefined,
        ownerRelation: ownerRelation
      },
      generalData: {
        effectiveBeginningDate: dateToIsoDateString(dayjs()),
        mtplInsuranceEffectiveBeginningDate: undefined,
        crossSelling: {
          csobContract: false,
          kooperativaContract: false,
          unionHealthContract: false,
          unionHealthContractNumber: undefined,
          uniqaContract: false,
          uniqaContractNumber: undefined
        },
        gapDuration: (insurance.insuranceData as GapInsuranceData).duration,
        paymentFrequency: paymentFrequency
      },
      reinsurancesData: {
        extendedAssistance: (insurance.insuranceData as MtplInsuranceData | CrashInsuranceData).coverages
          .extendedAssistance,
        glass: (insurance.insuranceData as MtplInsuranceData | CrashInsuranceData).coverages.glass,
        animal: calcType === CalcType.MTPL ? (insurance.insuranceData as MtplInsuranceData).coverages.animal : false,
        element: calcType === CalcType.MTPL ? (insurance.insuranceData as MtplInsuranceData).coverages.element : false,
        theftAndVandalism:
          calcType === CalcType.MTPL
            ? (insurance.insuranceData as MtplInsuranceData).coverages.theftAndVandalism
            : false,
        injury: calcType === CalcType.CRASH ? false : (insurance.insuranceData as MtplInsuranceData).coverages.injury,
        gap: calcType === CalcType.MTPL ? false : (insurance.insuranceData as CrashInsuranceData).coverages.gap,
        gapComplicityReinsurance: (insurance.insuranceData as CrashInsuranceData).coverages.gapComplicityReinsurance,
        gapDuration: (insurance.insuranceData as CrashInsuranceData).coverages.gapDuration,
        replacementVehicle:
          calcType === CalcType.MTPL
            ? false
            : (insurance.insuranceData as CrashInsuranceData).coverages.replacementVehicle,
        generaliAbroadVehicleRepair:
          calcType === CalcType.CRASH
            ? (insurance.insuranceData as CrashInsuranceData).coverages.abroadVehicleRepair
            : false
      },
      financialMediationData: {
        ...getDefaultGenFinancialMediationData(clients[policyHolderIndex]?.type as ClientType),
        recommendedGapComplicityReinsurance: false,
        recommendedGapCoverageLimit: undefined
      }
    } as DeepPartial<VehicleGen>;
  }

  return;
};

type PrepareCalcGenDataFromContractCalcData = {
  contractData: {
    policyHolderIndex: number;
    clients: Client[];
    paymentFrequency: PaymentFrequency;
  };
  calcData: CalculatorsGen;
  insurance: TravelInsurance | RealtyInsurance | VehicleInsurance;
};

const prepareCalcGenDataFromInsuranceCalcData = ({
  contractData: { policyHolderIndex, clients },
  calcData,
  insurance
}: PrepareCalcGenDataFromContractCalcData): CalculatorsGen | undefined => {
  if (isVehicleGenData(calcData)) {
    return {
      ...calcData,
      generalData: {
        ...calcData.generalData,
        effectiveBeginningDate: dateToIsoDateString(dayjs()),
        mtplInsuranceEffectiveBeginningDate: undefined,
        crossSelling: {
          csobContract: false,
          kooperativaContract: false,
          unionHealthContract: false,
          unionHealthContractNumber: undefined,
          uniqaContract: false,
          uniqaContractNumber: undefined
        }
      },
      clientsData: {
        ...calcData.clientsData,
        policyHolderIndex: policyHolderIndex,
        holderIndex: (insurance as VehicleInsurance).vehicleHolderIndex,
        ownerIndex: (insurance as VehicleInsurance).vehicleOwnerIndex,
        clients: [...prepareClientsData(clients)],
        holderTimeWithoutAccident: undefined,
        holderAccidentsIn2Years: undefined,
        holderAccidentsIn3Years: undefined,
        holderAccidentsIn5Years: undefined,
        holderAccidentsIn8Years: undefined,
        holderAccidentsIn10Years: undefined,
        holderAccidentsInAllYears: undefined,
        currentlyInsuredVehicles: undefined
      },
      vehicleData: {
        ...calcData.vehicleData,
        price: undefined,
        generalPrice: undefined,
        buyingPrice: undefined,
        odometer: undefined,
        newVehicle: false,
        registrationCertificateNumber: undefined,
        previousRegistrationCertificateNumber: calcData.vehicleData.registrationCertificateNumber
      },
      financialMediationData: {
        ...getDefaultGenFinancialMediationData(clients[policyHolderIndex]?.type as ClientType),
        recommendedGapComplicityReinsurance: false,
        recommendedGapCoverageLimit: undefined
      },
      reinsurancesData: {
        ...calcData.reinsurancesData,
        animalAmount:
          calcData.reinsurancesData.animalAmount &&
          Object.values(AnimalReinsuranceAmount).includes(calcData.reinsurancesData.animalAmount)
            ? calcData.reinsurancesData.animalAmount
            : undefined
      }
    } as DeepPartial<VehicleGen>;
  } else if (isRealtyGenData(calcData)) {
    return {
      ...calcData,
      generalInsuranceData: {
        ...calcData.generalInsuranceData,
        effectiveBeginningDate: dateToIsoDateString(dayjs().add(1, "day")),
        crossSelling: {
          csobContract: false,
          unionHealthContract: false,
          unionHealthContractNumber: undefined,
          uniqaContractNumber: undefined,
          premiumContractNumber: undefined,
          colonnadeDiscountCode: undefined
        }
      },
      clientsData: {
        ...calcData.clientsData,
        policyHolderIndex: policyHolderIndex,
        insuredClientIndex: (insurance as RealtyInsurance).insuredClientIndex,
        vinculationClientIndex: (insurance as RealtyInsurance).vinculationClientIndex,
        clients: [...prepareClientsData(clients)]
      },
      financialMediationData: {
        ...getDefaultGenFinancialMediationData(clients[policyHolderIndex]?.type as ClientType),
        ownedByClient: true,
        businessDesc: undefined
      }
    } as DeepPartial<RealtyGen>;
  } else if (isTravelGenData(calcData)) {
    return {
      ...calcData,
      generalData: {
        ...calcData.generalData,
        effectiveBeginningDate: dateToIsoDateString(dayjs()),
        effectiveEndDate: undefined
      },
      clientsData: {
        ...calcData.clientsData,
        policyHolderIndex: policyHolderIndex,
        clients: [...prepareClientsData(clients)]
      },
      cancellationData: {
        ...calcData.cancellationData,
        tripPrice: undefined,
        firstPaymentDate: undefined
      },
      financialMediationData: {
        ...getDefaultGenFinancialMediationData(clients[policyHolderIndex]?.type as ClientType)
      },
      coveragesData: {
        ...calcData.coveragesData,
        cancellationData: {
          ...calcData.coveragesData?.cancellationData,
          tripPrice: undefined,
          firstPaymentDate: undefined
        }
      }
    } as DeepPartial<TravelGen>;
  }

  return;
};

export type SortCalcResultItemsParams<T extends CalcResultData> = {
  sortBy: CalcResultsSorting;
  items: CalcResult<T>[];
};

export const sortCalcResultItems = <T extends CalcResultData>({ sortBy, items }: SortCalcResultItemsParams<T>) => {
  const itemsWithErrorKeys = new Set(items.filter(item => item.error).map(item => item.id));

  return [...items].sort((itemA, itemB) => {
    const priorityA = itemsWithErrorKeys.has(itemA.id) ? 2 : !itemA.active ? 1 : 0;
    const priorityB = itemsWithErrorKeys.has(itemB.id) ? 2 : !itemB.active ? 1 : 0;

    if (priorityA !== priorityB) {
      return priorityA - priorityB;
    }

    switch (sortBy) {
      case CalcResultsSorting.INSITUTION:
        return itemA.insuranceInstitution.name.localeCompare(itemB.insuranceInstitution.name);
      case CalcResultsSorting.PRICE_ASC:
        return numberOrZero(itemA.data?.annualPremium) - numberOrZero(itemB.data?.annualPremium);
      case CalcResultsSorting.PRICE_DESC:
        return numberOrZero(itemB.data?.annualPremium) - numberOrZero(itemA.data?.annualPremium);
      case CalcResultsSorting.RECOMMENDED:
        return compareByCoverageOrderIndex(itemA, itemB);
      default:
        return 0;
    }
  });
};

export const isRealtyGenData = (calcData: CalculatorsGen): calcData is RealtyGen => {
  return calcData.type === CalcType.REALTY;
};

export const isTravelGenData = (calcData: CalculatorsGen): calcData is TravelGen => {
  return calcData.type === CalcType.TRAVEL;
};

export const isVehicleGenData = (calcData: CalculatorsGen): calcData is VehicleGen => {
  return calcData.type ? isVehicleCalcType(calcData.type) : false;
};

export const isVehicleCalcType = (calcType: CalcType): calcType is VehicleCalcType =>
  calcType === CalcType.MTPL ||
  calcType === CalcType.MTPL_CRASH ||
  calcType === CalcType.GAP ||
  calcType === CalcType.CRASH ||
  calcType === CalcType.PAS;

export const isRealtyCalcRecord = (calcRecord: CalcRecord): calcRecord is RealtyCalcRecord =>
  calcRecord.calcType === CalcType.REALTY;

export const isTravelCalcRecord = (calcRecord: CalcRecord): calcRecord is TravelCalcRecord =>
  calcRecord.calcType === CalcType.TRAVEL;

export const isVehicleCalcRecord = (calcRecord: CalcRecord): calcRecord is VehicleCalcRecord =>
  isVehicleCalcType(calcRecord.calcType);

export const isRealtyCalcDraft = (calcDraft: CalcDraft): calcDraft is RealtyCalcDraft =>
  calcDraft.calcType === CalcType.REALTY;

export const isTravelCalcDraft = (calcDraft: CalcDraft): calcDraft is TravelCalcDraft =>
  calcDraft.calcType === CalcType.TRAVEL;

export const isVehicleCalcDraft = (calcDraft: CalcDraft): calcDraft is VehicleCalcDraft =>
  isVehicleCalcType(calcDraft.calcType);

export const isTravelCalcData = (calcData: TravelCalc | RealtyCalc | VehicleCalc): calcData is TravelCalc =>
  calcData.type === CalcType.TRAVEL;

export const isRealtyCalcData = (calcData: TravelCalc | RealtyCalc | VehicleCalc): calcData is RealtyCalc =>
  calcData.type === CalcType.REALTY;

export const isVehicleCalcData = (calcData: TravelCalc | RealtyCalc | VehicleCalc): calcData is VehicleGen =>
  isVehicleCalcType(calcData.type);

export const isTravelOffer = (
  offer: Offer<TravelCalc | RealtyCalc | VehicleCalc>
): offer is Offer<TravelCalc, TravelCalcResultData> => offer.calcRequest.type === CalcType.TRAVEL;

export const isRealtyOffer = (
  offer: Offer<TravelCalc | RealtyCalc | VehicleCalc>
): offer is Offer<RealtyCalc, RealtyCalcResultData> => offer.calcRequest.type === CalcType.REALTY;

export const isVehicleOffer = (
  offer: Offer<TravelCalc | RealtyCalc | VehicleCalc>
): offer is Offer<VehicleCalc, VehicleCalcResultData> => isVehicleCalcType(offer.calcRequest.type);

export const isRealtyCalcResultsData = (
  data: TravelCalcResultData | RealtyCalcResultData | VehicleCalcResultData
): data is RealtyCalcResultData =>
  data.type === CalcResultDataType.REALTY || data.type === CalcResultDataType.REALTY_ALLIANZ;

export const isTravelCalcResultsData = (
  data: TravelCalcResultData | RealtyCalcResultData | VehicleCalcResultData
): data is TravelCalcResultData =>
  data.type === CalcResultDataType.TRAVEL || data.type === CalcResultDataType.TRAVEL_ALLIANZ;

export const isVehicleCalcResultsData = (
  data: TravelCalcResultData | RealtyCalcResultData | VehicleCalcResultData
): data is VehicleCalcResultData =>
  data.type === CalcResultDataType.MTPL ||
  data.type === CalcResultDataType.MTPL_ALLIANZ ||
  data.type === CalcResultDataType.PAS ||
  data.type === CalcResultDataType.GAP ||
  data.type === CalcResultDataType.CRASH ||
  data.type === CalcResultDataType.CRASH_KPAS_KOOP;
