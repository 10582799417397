import { AxiosPromise } from "axios";
import { ApiRequestAdapter } from "../../common/api/ApiRequestAdapter";
import { apiService } from "../../common/api/apiService";
import { Role } from "../../common/security/authorization/enums";
import {
  EntityIdObject,
  EntityObject,
  SearchPageRequest,
  SearchPageResult,
  TwoLevelEntityIdObject,
  TwoLevelEntityObject
} from "../../common/types";
import {
  AdminCreateUpdateAgentUserAccount,
  AdminCreateUserWithAgentUserAccounts,
  AdminUpdateUser,
  ConfirmUser,
  CreateUserTotpDevice,
  CreateUserTotpDeviceSecret,
  DeleteUserTotpDevice,
  GetUsersByAgentRequest,
  PasswordReset,
  RequestPasswordReset,
  RequestUserEmailUpdate,
  RequestUserPhoneUpdate,
  UpdateUserEmail,
  UpdateUserPhone,
  UpdateUserTotpDevice,
  UserAccount,
  UserAdminView,
  UserProfile,
  UserSendTotpCodeRequest,
  UserTotpDevice,
  UserTotpDeviceSecret,
  UserUpdatePassword
} from "./types";

const ADMIN_FILTER_USERS = ApiRequestAdapter.create("/users", "GET");
const ADMIN_GET_USERS_BY_AGENT = ApiRequestAdapter.create("/users", "GET");
const ADMIN_CREATE_USER_WITH_AGENT_USER_ACCOUNTS = ApiRequestAdapter.create("/users", "POST");
const ADMIN_GET_USER = ApiRequestAdapter.create("/users/{0}", "GET");
const USER_SEND_TOTP_CODE_VIA_SMS = ApiRequestAdapter.create("/users/{0}/send-totp-code-via-sms", "POST");
const USER_CONFIRM_USER = ApiRequestAdapter.create("/users/{0}/confirm", "PUT");
const ADMIN_UPDATE_USER = ApiRequestAdapter.create("/users/{0}", "PUT");
const ADMIN_RESEND_USER_CONFIRM_LINK = ApiRequestAdapter.create("/users/{0}/resend-confirm-link", "PUT");
const USER_GET_USER_PROFILE = ApiRequestAdapter.create("/users/{0}/profile", "GET");
const USER_UPDATE_PASSWORD = ApiRequestAdapter.create("/users/{0}/password", "PUT");
const USER_REQUEST_EMAIL_UPDATE = ApiRequestAdapter.create("/users/{0}/request-email-update", "POST");
const USER_UPDATE_EMAIL = ApiRequestAdapter.create("/users/{0}/update-email", "PUT");
const USER_REQUEST_PHONE_UPDATE = ApiRequestAdapter.create("/users/{0}/request-phone-update", "POST");
const USER_UPDATE_PHONE = ApiRequestAdapter.create("/users/{0}/update-phone", "PUT");
const USER_REQUEST_PASSWORD_RESET = ApiRequestAdapter.create("/users/request-password-reset", "POST");
const USER_RESET_PASSWORD = ApiRequestAdapter.create("/users/{0}/reset-password", "PUT");
const ADMIN_ADD_AGENT_USER_ACCOUNT = ApiRequestAdapter.create("/users/{0}/agent-accounts", "POST");
const ADMIN_UPDATE_AGENT_USER_ACCOUNT = ApiRequestAdapter.create("/users/{0}/agent-accounts/{1}", "PUT");
const ADMIN_DELETE_AGENT_USER_ACCOUNT = ApiRequestAdapter.create("/users/{0}/agent-accounts/{1}", "DELETE");
const USER_GET_USER_TOTP_DEVICES = ApiRequestAdapter.create("/users/{0}/totp-devices", "GET");
const USER_CREATE_USER_TOTP_DEVICE_SECRET = ApiRequestAdapter.create("/users/{0}/totp-devices/secret", "POST");
const USER_CREATE_USER_TOTP_DEVICE = ApiRequestAdapter.create("/users/{0}/totp-devices", "POST");
const USER_UPDATE_USER_TOTP_DEVICE = ApiRequestAdapter.create("/users/{0}/totp-devices/{1}", "PUT");
const USER_DELETE_USER_TOTP_DEVICE = ApiRequestAdapter.create("/users/{0}/totp-devices/{1}/delete", "POST");

export const requests = {
  ADMIN_FILTER_USERS,
  ADMIN_GET_USERS_BY_AGENT,
  ADMIN_CREATE_USER_WITH_AGENT_USER_ACCOUNTS,
  ADMIN_GET_USER,
  USER_SEND_TOTP_CODE_VIA_SMS,
  USER_CONFIRM_USER,
  ADMIN_UPDATE_USER,
  ADMIN_RESEND_USER_CONFIRM_LINK,
  USER_GET_USER_PROFILE,
  USER_UPDATE_PASSWORD,
  USER_REQUEST_EMAIL_UPDATE,
  USER_UPDATE_EMAIL,
  USER_REQUEST_PHONE_UPDATE,
  USER_UPDATE_PHONE,
  USER_REQUEST_PASSWORD_RESET,
  USER_RESET_PASSWORD,
  ADMIN_ADD_AGENT_USER_ACCOUNT,
  ADMIN_UPDATE_AGENT_USER_ACCOUNT,
  ADMIN_DELETE_AGENT_USER_ACCOUNT,
  USER_GET_USER_TOTP_DEVICES,
  USER_CREATE_USER_TOTP_DEVICE_SECRET,
  USER_CREATE_USER_TOTP_DEVICE,
  USER_UPDATE_USER_TOTP_DEVICE,
  USER_DELETE_USER_TOTP_DEVICE
};

const api = {
  adminFilterUsers: (request: SearchPageRequest): AxiosPromise<SearchPageResult<UserAdminView>> => {
    return apiService.get(ADMIN_FILTER_USERS.url, { params: request });
  },
  adminGetUsersByAgent: (request: GetUsersByAgentRequest): AxiosPromise<UserAdminView[]> => {
    return apiService.get(ADMIN_GET_USERS_BY_AGENT.url, { params: request });
  },
  adminCreateUserWithAgentUserAccounts: (
    request: AdminCreateUserWithAgentUserAccounts
  ): AxiosPromise<UserAdminView> => {
    return apiService.post(ADMIN_CREATE_USER_WITH_AGENT_USER_ACCOUNTS.url, request, { params: { role: Role.AGENT } });
  },
  adminGetUser: (request: EntityIdObject): AxiosPromise<UserAdminView> => {
    return apiService.get(ADMIN_GET_USER.getParametrizedUrl(request.id));
  },
  userSendTotpCodeViaSms: (request: EntityObject<UserSendTotpCodeRequest>): AxiosPromise => {
    return apiService.post(USER_SEND_TOTP_CODE_VIA_SMS.getParametrizedUrl(request.id), request.object);
  },
  userConfirmUser: (request: EntityObject<ConfirmUser>): AxiosPromise => {
    return apiService.put(USER_CONFIRM_USER.getParametrizedUrl(request.id), request.object);
  },
  adminUpdateUser: (request: EntityObject<AdminUpdateUser>): AxiosPromise<UserAdminView> => {
    return apiService.put(ADMIN_UPDATE_USER.getParametrizedUrl(request.id), request.object);
  },
  adminResendUserConfirmLink: (request: EntityIdObject): AxiosPromise<UserAdminView> => {
    return apiService.put(ADMIN_RESEND_USER_CONFIRM_LINK.getParametrizedUrl(request.id));
  },
  userGetUserProfile: (request: EntityIdObject): AxiosPromise<UserProfile> => {
    return apiService.get(USER_GET_USER_PROFILE.getParametrizedUrl(request.id));
  },
  userUpdatePassword: (request: EntityObject<UserUpdatePassword>): AxiosPromise<UserProfile> => {
    return apiService.put(USER_UPDATE_PASSWORD.getParametrizedUrl(request.id), request.object);
  },
  userRequestEmailUpdate: (request: EntityObject<RequestUserEmailUpdate>): AxiosPromise<UserProfile> => {
    return apiService.post(USER_REQUEST_EMAIL_UPDATE.getParametrizedUrl(request.id), request.object);
  },
  userUpdateEmail: (request: EntityObject<UpdateUserEmail>): AxiosPromise => {
    return apiService.put(USER_UPDATE_EMAIL.getParametrizedUrl(request.id), request.object);
  },
  userRequestPhoneUpdate: (request: EntityObject<RequestUserPhoneUpdate>): AxiosPromise<UserProfile> => {
    return apiService.post(USER_REQUEST_PHONE_UPDATE.getParametrizedUrl(request.id), request.object);
  },
  userUpdatePhone: (request: EntityObject<UpdateUserPhone>): AxiosPromise<UserProfile> => {
    return apiService.put(USER_UPDATE_PHONE.getParametrizedUrl(request.id), request.object);
  },
  requestPasswordReset: (request: RequestPasswordReset): AxiosPromise => {
    return apiService.post(USER_REQUEST_PASSWORD_RESET.url, request);
  },
  resetPassword: (request: EntityObject<PasswordReset>): AxiosPromise => {
    return apiService.put(USER_RESET_PASSWORD.getParametrizedUrl(request.id), request.object);
  },
  adminAddAgentUserAccount: (request: EntityObject<AdminCreateUpdateAgentUserAccount>): AxiosPromise<UserAccount> => {
    return apiService.post(ADMIN_ADD_AGENT_USER_ACCOUNT.getParametrizedUrl(request.id), request.object);
  },
  adminUpdateAgentUserAccount: (
    request: TwoLevelEntityObject<AdminCreateUpdateAgentUserAccount>
  ): AxiosPromise<UserAccount> => {
    return apiService.put(ADMIN_UPDATE_AGENT_USER_ACCOUNT.getParametrizedUrl(request.id1, request.id2), request.object);
  },
  adminDeleteAgentUserAccount: (request: TwoLevelEntityIdObject): AxiosPromise => {
    return apiService.delete(ADMIN_DELETE_AGENT_USER_ACCOUNT.getParametrizedUrl(request.id1, request.id2));
  },
  userGetUserTotpDevices: (request: EntityIdObject): AxiosPromise<UserTotpDevice[]> => {
    return apiService.get(USER_GET_USER_TOTP_DEVICES.getParametrizedUrl(request.id));
  },
  userCreateUserTotpDeviceSecret: (
    request: EntityObject<CreateUserTotpDeviceSecret>
  ): AxiosPromise<UserTotpDeviceSecret> => {
    return apiService.post(USER_CREATE_USER_TOTP_DEVICE_SECRET.getParametrizedUrl(request.id), request.object);
  },
  userCreateUserTotpDevice: (request: EntityObject<CreateUserTotpDevice>): AxiosPromise<UserTotpDevice> => {
    return apiService.post(USER_CREATE_USER_TOTP_DEVICE.getParametrizedUrl(request.id), request.object);
  },
  userUpdateUserTotpDevice: (request: TwoLevelEntityObject<UpdateUserTotpDevice>): AxiosPromise<UserTotpDevice> => {
    return apiService.put(USER_UPDATE_USER_TOTP_DEVICE.getParametrizedUrl(request.id1, request.id2), request.object);
  },
  userDeleteUserTotpDevice: (request: TwoLevelEntityObject<DeleteUserTotpDevice>): AxiosPromise => {
    return apiService.post(USER_DELETE_USER_TOTP_DEVICE.getParametrizedUrl(request.id1, request.id2), request.object);
  }
};

export default api;
