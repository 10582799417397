import { AxiosPromise } from "axios";
import { ApiRequestAdapter } from "../../../common/api/ApiRequestAdapter";
import { apiService } from "../../../common/api/apiService";
import { VehicleAutocompleteRequest, VehicleAutocompleteResult } from "./types";

const AUTOCOMPLETE_VEHICLES = ApiRequestAdapter.create("/vehicles/autocomplete", "GET");

export const requests = {
  AUTOCOMPLETE_VEHICLES
};

const api = {
  autocompleteVehicles: (request: VehicleAutocompleteRequest): AxiosPromise<VehicleAutocompleteResult> => {
    return apiService.get(AUTOCOMPLETE_VEHICLES.url, { params: request });
  }
};

export default api;
