import { closestCenter, DndContext, KeyboardSensor, PointerSensor, useSensor, useSensors } from "@dnd-kit/core";
import type { DragEndEvent } from "@dnd-kit/core/dist/types";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy
} from "@dnd-kit/sortable";
import { Button, Card, Empty, Flex, Form, Select, SelectProps, Space } from "antd";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import ActionButton from "../../../../../../common/components/buttons/ActionButton";
import AntIcon from "../../../../../../common/components/icons/AntIcon";
import { SortableItem } from "../../../../../../common/components/sortable/SortableItem";
import type { FieldConstraintViolation, RootState } from "../../../../../../common/types";
import { InsuranceCoverageType } from "../../../../../contract/enums";
import { CalcResultSorting } from "../../../components/calc-result/CalcResultSorting";
import { CalcNavigation } from "../../../components/CalcNavigation";
import { DraftOfferForm } from "../../../components/DraftOfferForm";
import { CalcResultsSorting } from "../../../enums";
import { OperationStage } from "../../../realty/enums";
import type { CalcResult } from "../../../types";
import { sortCalcResultItems } from "../../../utils";
import { selectTravelDraft } from "../../ducks";
import type { TravelCalc, TravelCalcDraft, TravelCalcResultData } from "../../types";
import { TravelCalcResult } from "./travel-calc-result/TravelCalcResult";
import { TravelSaveDraftButton } from "./TravelSaveDraftButton";

type TravelCalcResultsFilterForm = {
  institutions: string[];
  coverages: string[];
};

const TRAVEL_COVERAGES = {
  MEDICAL_EXPENSES: InsuranceCoverageType.TRAVEL_MEDICAL_EXPENSES,
  INJURY: InsuranceCoverageType.TRAVEL_INJURY,
  LIABILITY: InsuranceCoverageType.TRAVEL_LIABILITY,
  RESCUE_SERVICE: InsuranceCoverageType.TRAVEL_RESCUE_SERVICE,
  LUGGAGE: InsuranceCoverageType.TRAVEL_LUGGAGE,
  COVID: InsuranceCoverageType.TRAVEL_COVID,
  CHRONIC_DISEASES: InsuranceCoverageType.TRAVEL_CHRONIC_DISEASES,
  CANCELLATION: InsuranceCoverageType.TRAVEL_CANCELLATION,
  DRINK_ALLOWED: InsuranceCoverageType.TRAVEL_DRINK_ALLOWED,
  VEHICLE_ASSISTANCE: InsuranceCoverageType.TRAVEL_VEHICLE_ASSISTANCE,
  VEHICLE_RENTAL: InsuranceCoverageType.TRAVEL_VEHICLE_RENTAL,
  ELECTRONIC_DEVICES: InsuranceCoverageType.TRAVEL_ELECTRONIC_DEVICES,
  DOCUMENTS: InsuranceCoverageType.TRAVEL_DOCUMENTS,
  PET: InsuranceCoverageType.TRAVEL_PET,
  ABANDONED_HOUSEHOLD: InsuranceCoverageType.TRAVEL_ABANDONED_HOUSEHOLD,
  AIRPLANE: InsuranceCoverageType.TRAVEL_AIRPLANE
};

export type Props = {
  calcData: TravelCalc;
  resultItems: CalcResult<TravelCalcResultData>[];
  handleGenerateContract: (selectedItem: CalcResult<TravelCalcResultData>) => void;
  handleShowErrors: (errors: FieldConstraintViolation[]) => void;
  handleGoToPreviousStep: VoidFunction;
};

export const TravelCalcResultsView = ({
  calcData,
  resultItems,
  handleGenerateContract,
  handleShowErrors,
  handleGoToPreviousStep
}: Props) => {
  const { t } = useTranslation();
  const [form] = Form.useForm<TravelCalcResultsFilterForm>();
  const draft = useSelector<RootState, TravelCalcDraft | undefined>(selectTravelDraft);

  const [resultsSorting, setResultsSorting] = useState<CalcResultsSorting>(CalcResultsSorting.PRICE_ASC);
  const [calcResultItems, setCalcResultItems] = useState<CalcResult<TravelCalcResultData>[]>(
    sortCalcResultItems({
      sortBy: resultsSorting,
      items: resultItems
    })
  );

  const [draftOfferForm, setDraftOfferForm] = useState<"createOffer" | "createUpdateDraft" | "sendOffer">();
  const [isUpdateDraftOffer, setIsUpdateDraftOffer] = useState(false);

  const coveragesOptions: SelectProps["options"] = Object.values(TRAVEL_COVERAGES).map(value => ({
    value: value,
    label: t(`contract.enums.insuranceCoverageType.${value}`)
  }));

  const insuranceInstitutions: SelectProps["options"] = useMemo(
    () =>
      resultItems
        .filter(item => !item.error)
        .map(item => item.insuranceInstitution)
        .filter((item, index, self) => !self.some((institution, i) => institution.id === item.id && i < index))
        .map(value => ({
          value: value.id,
          label: value.name
        })),
    [resultItems]
  );

  useEffect(() => {
    setCalcResultItems(
      sortCalcResultItems({
        sortBy: resultsSorting,
        items: resultItems
      })
    );

    form.resetFields();
  }, [resultItems]);

  useEffect(() => {
    const sortedCalcResultItems = calcResultItems
      .filter(item => !item.error)
      .sort((a, b) => {
        const isIncludedA = a.active ? 1 : 0;
        const isIncludedB = b.active ? 1 : 0;

        return isIncludedB - isIncludedA;
      });

    const result = calcResultItems.map(item => (item.error ? item : sortedCalcResultItems.shift()!));

    setCalcResultItems(result);
  }, [calcResultItems.filter(item => item.active).length]);

  const handleSaveDraft = (isUpdate: boolean): void => {
    setIsUpdateDraftOffer(isUpdate);
    setDraftOfferForm("createUpdateDraft");
  };

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (!over) {
      return;
    }

    const activeIndex = calcResultItems.findIndex(item => item.id === active.id);
    const overIndex = calcResultItems.findIndex(item => item.id === over.id);

    if (activeIndex !== overIndex) {
      setCalcResultItems(prev => arrayMove(prev, activeIndex, overIndex));
    }
  };

  const handleItemActivation = (id: string, activation: boolean) => {
    setCalcResultItems(
      calcResultItems.map(item => {
        if (item.id === id) {
          item.active = activation;
        }

        return item;
      })
    );
  };

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates
    })
  );

  const handleSubmitFilter = () => {
    form.validateFields().then(values => {
      const sortedItems = sortCalcResultItems({
        sortBy: resultsSorting,
        items: resultItems
      });

      if (values.institutions.length === 0 && values.coverages.length === 0) {
        setCalcResultItems(sortedItems);

        return;
      }

      const result = sortedItems
        .filter(item => !item.error)
        .filter(item => {
          if (values.institutions.length === 0) {
            return true;
          }

          return values.institutions.includes(item.insuranceInstitution.id);
        })
        .filter(item => {
          if (values.coverages.length === 0) {
            return true;
          }

          return values.coverages.some(coverage =>
            (item.data?.appliedCoverages ?? []).some(appliedCoverage => appliedCoverage.type === coverage)
          );
        });

      setCalcResultItems(result);
    });
  };

  return (
    <div>
      <Card
        title={<h2>{t("calc.titles.insuranceOffer")}</h2>}
        className="card-box"
        extra={
          <Space>
            <TravelSaveDraftButton draft={draft} handleSaveDraft={handleSaveDraft} />
            <ActionButton
              icon="download"
              color="green"
              variant="outlined"
              size="middle"
              disabled={!calcResultItems.length}
              onClick={() => setDraftOfferForm("createOffer")}
            >
              {t("calc.actions.generateOffer")}
            </ActionButton>
            <ActionButton
              icon="mail"
              color="green"
              variant="outlined"
              size="middle"
              disabled={!calcResultItems.length}
              onClick={() => setDraftOfferForm("sendOffer")}
            >
              {t("calc.actions.sendOffer")}
            </ActionButton>
          </Space>
        }
      >
        <Form
          form={form}
          name="insuranceFilterForm"
          initialValues={{ institutions: [], coverages: [] }}
          layout="vertical"
        >
          <Flex gap="small" align="end">
            <Form.Item name="institutions" label={t("common.insuranceInstitutions")} style={{ flex: 1 }}>
              <Select mode="multiple" options={insuranceInstitutions} allowClear />
            </Form.Item>
            <Form.Item name="coverages" label={t("calc.helpers.coverages")} style={{ flex: 1 }}>
              <Select mode="multiple" options={coveragesOptions} allowClear />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit" icon={<AntIcon type="search" />} onClick={handleSubmitFilter}>
                {t("common.filter")}
              </Button>
            </Form.Item>
          </Flex>
        </Form>
      </Card>

      <Card
        className="card-box"
        title={
          <h2 style={{ fontSize: 20 }}>
            {t("calc.travel.titles.insuranceType." + calcData.generalData.insuranceType)}
          </h2>
        }
        extra={
          <CalcResultSorting
            resultItems={resultItems}
            resultsSorting={resultsSorting}
            onChange={(sorting, results) => {
              setResultsSorting(sorting);
              setCalcResultItems(results);
            }}
          />
        }
      >
        {calcResultItems.length ? (
          <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
            <SortableContext items={calcResultItems} strategy={verticalListSortingStrategy}>
              {calcResultItems.map(item => (
                <SortableItem key={item.id} id={item.id} error={item.error} disabled={!item.active}>
                  <TravelCalcResult
                    item={item}
                    handleItemActivation={handleItemActivation}
                    handleGenerateContract={handleGenerateContract}
                    handleShowErrors={handleShowErrors}
                    handleItemRecommendation={(itemId: string, activation: boolean) =>
                      setCalcResultItems(
                        calcResultItems.map(item => {
                          if (item.id === itemId) {
                            item.recommended = activation;
                          }

                          return item;
                        })
                      )
                    }
                  />
                </SortableItem>
              ))}
            </SortableContext>
          </DndContext>
        ) : (
          <Empty description={t("calc.helpers.noDataByFilter")} />
        )}
      </Card>

      <DraftOfferForm
        isUpdate={isUpdateDraftOffer}
        formType={draftOfferForm}
        calcData={calcData}
        calcResults={calcResultItems.filter(item => item.active && !item.error)}
        operationStage={OperationStage.CALCULATE}
        draft={draft}
        onFinish={() => setDraftOfferForm(undefined)}
      />

      <CalcNavigation onGoToPrevious={handleGoToPreviousStep} />
    </div>
  );
};
