import { AxiosPromise } from "axios";
import { ApiRequestAdapter } from "../../common/api/ApiRequestAdapter";
import { apiService } from "../../common/api/apiService";
import { EntityIdObject } from "../../common/types";
import { serializeParams } from "../../common/utils/apiUtils";
import {
  MarkNotificationsAsSeenRequest,
  NotificationFilterPageRequest,
  NotificationFilterPageResult,
  NotificationSettings,
  UpdateNotificationSettings
} from "./types";

const GET_NOTIFICATION_SETTINGS = ApiRequestAdapter.create("/notification-settings", "GET");
const UPDATE_NOTIFICATION_SETTINGS = ApiRequestAdapter.create("/notification-settings", "PUT");

const FILTER_NOTIFICATIONS = ApiRequestAdapter.create("/notifications", "GET");
const MARK_ALL_NOTIFICATIONS_AS_SEEN = ApiRequestAdapter.create("/notifications/mark-all-as-seen", "PUT");
const MARK_NOTIFICATIONS_AS_SEEN = ApiRequestAdapter.create("/notifications", "PUT");
const TOGGLE_NOTIFICATION_SENT_AND_SEEN_STATUS = ApiRequestAdapter.create("/notifications/{0}", "PUT");

export const requests = {
  GET_NOTIFICATION_SETTINGS,
  UPDATE_NOTIFICATION_SETTINGS,
  FILTER_NOTIFICATIONS,
  MARK_ALL_NOTIFICATIONS_AS_SEEN,
  MARK_NOTIFICATIONS_AS_SEEN,
  TOGGLE_NOTIFICATION_SENT_AND_SEEN_STATUS
};

const api = {
  getNotificationSettings: (): AxiosPromise<NotificationSettings[]> => {
    return apiService.get(GET_NOTIFICATION_SETTINGS.url);
  },
  updateNotificationSettings: (request: UpdateNotificationSettings[]): AxiosPromise<NotificationSettings[]> => {
    return apiService.put(UPDATE_NOTIFICATION_SETTINGS.url, request);
  },
  filterNotifications: (request: NotificationFilterPageRequest): AxiosPromise<NotificationFilterPageResult> => {
    return apiService.get(FILTER_NOTIFICATIONS.url, {
      params: request,
      paramsSerializer: { serialize: serializeParams }
    });
  },
  markAllNotificationsAsSeen: (): AxiosPromise<void> => {
    return apiService.put(MARK_ALL_NOTIFICATIONS_AS_SEEN.url);
  },
  markNotificationsAsSeen: (request: MarkNotificationsAsSeenRequest): AxiosPromise<Notification[]> => {
    return apiService.put(MARK_NOTIFICATIONS_AS_SEEN.url, request);
  },
  toggleNotificationSentAndSeenStatus: (request: EntityIdObject): AxiosPromise<Notification> => {
    return apiService.put(TOGGLE_NOTIFICATION_SENT_AND_SEEN_STATUS.getParametrizedUrl(request.id));
  }
};

export default api;
