import { Card, Tabs, TabsProps } from "antd";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { bindActionCreators } from "redux";
import AntIcon from "../../../common/components/icons/AntIcon";
import ContentWrapper from "../../../common/modules/wrappers/ContentWrapper";
import DisplayWrapper from "../../../common/modules/wrappers/DisplayWrapper";
import { Permission } from "../../../common/security/authorization/enums";
import { RootState } from "../../../common/types";
import { formatAgentAggregatedName } from "../../../common/utils/formatUtils";
import { appendSearchParamsToURL } from "../../../common/utils/utils";
import AgentAttachmentsTableView from "../../agent/components/views/detail/AgentAttachmentsTableView";
import AgentBrokerNumberTableView from "../../agent/components/views/detail/AgentBrokerNumberTableView";
import AgentCompetenceTableView from "../../agent/components/views/detail/AgentCompetenceTableView";
import { AgentCompletedEducationTableView } from "../../agent/components/views/detail/AgentCompletedEducationTableView";
import AgentLicenseTableView from "../../agent/components/views/detail/AgentLicenseTableView";
import { AgentMentoringTableView } from "../../agent/components/views/detail/AgentMentoringTableView";
import {
  deleteStateAgentDetailAction,
  downloadAgentAttachmentsAsZipActions,
  getAgentBrokerNumbersActions,
  getAgentCompetencesActions,
  getAgentLicensesActions,
  selectAgentBrokerNumbers,
  selectAgentCompetences,
  selectAgentLicenses,
  uploadAgentAttachmentsActions
} from "../../agent/ducks";
import { AgentBrokerNumber, AgentCompetence, AgentLicense } from "../../agent/types";
import { selectHasPermissions, selectUser } from "../../auth/ducks";
import { selectRouterLocationSearchParam } from "../../ducks";
import { USER_PROFILE_TABS } from "../../globalsearch/utils";
import NotificationSettingsView from "../../notifications/components/views/NotificationSettingsView";
import {
  deleteStateNotificationSettingsListAction,
  getNotificationSettingsActions,
  selectNotificationSettings,
  updateNotificationSettingsActions
} from "../../notifications/ducks";
import { NotificationSettings } from "../../notifications/types";
import { requests } from "../api";
import UserProfileAgentTabSwitchView from "../components/views/detail/UserProfileAgentTabSwitchView";
import UserProfileUserAccountView from "../components/views/detail/UserProfileUserAccountView";
import UserProfileUserView from "../components/views/detail/UserProfileUserView";
import {
  deleteStateUserProfileAction,
  deleteStateUserTotpDevicesAction,
  deleteStateUserTotpDeviceSecretAction,
  selectUserProfile,
  selectUserTotpDevices,
  selectUserTotpDeviceSecret,
  userCreateUserTotpDeviceActions,
  userCreateUserTotpDeviceSecretActions,
  userDeleteUserTotpDeviceActions,
  userGetUserProfileActions,
  userGetUserTotpDevicesActions,
  userRequestEmailUpdateActions,
  userRequestPhoneUpdateActions,
  userUpdatePasswordActions,
  userUpdatePhoneActions,
  userUpdateUserTotpDeviceActions
} from "../ducks";
import { User, UserProfile, UserTotpDevice, UserTotpDeviceSecret } from "../types";

export const UserProfileContainer = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const [urlParams] = useSearchParams();
  const urlTabKey = urlParams.get("tab");

  const [tabKey, setTabKey] = useState<string>(USER_PROFILE_TABS.USER);

  const user = useSelector<RootState, User | undefined>(selectUser);
  const userProfile = useSelector<RootState, UserProfile | undefined>(selectUserProfile);
  const totpDeviceSecret = useSelector<RootState, UserTotpDeviceSecret | undefined>(selectUserTotpDeviceSecret);
  const totpDevices = useSelector<RootState, UserTotpDevice[]>(selectUserTotpDevices);
  const competences = useSelector<RootState, AgentCompetence[]>(selectAgentCompetences);
  const licenses = useSelector<RootState, AgentLicense[]>(selectAgentLicenses);
  const brokerNumbers = useSelector<RootState, AgentBrokerNumber[]>(selectAgentBrokerNumbers);
  const notificationSettings = useSelector<RootState, NotificationSettings[]>(selectNotificationSettings);
  const openTotpDeviceFormByDefault = useSelector<RootState, boolean>(
    state => selectRouterLocationSearchParam(state, "addTotpDevice") === "true"
  );

  const hasProfileCompetencePermission = useSelector<RootState, boolean>(
    selectHasPermissions(Permission.PROFILE_COMPETENCE)
  );
  const hasProfileLicensePermission = useSelector<RootState, boolean>(selectHasPermissions(Permission.PROFILE_LICENSE));
  const hasProfileBrokerNumberPermission = useSelector<RootState, boolean>(
    selectHasPermissions(Permission.PROFILE_BROKER_NUMBER)
  );
  const hasProfileEducationPermission = useSelector<RootState, boolean>(
    selectHasPermissions(Permission.PROFILE_EDUCATION)
  );
  const hasProfileMentoringPermission = useSelector<RootState, boolean>(
    selectHasPermissions(Permission.PROFILE_MENTORING)
  );

  const dispatch = useDispatch();
  const actions = useMemo(
    () =>
      bindActionCreators(
        {
          userGetUserProfile: userGetUserProfileActions.request,
          userRequestEmailUpdate: userRequestEmailUpdateActions.request,
          userRequestPhoneUpdate: userRequestPhoneUpdateActions.request,
          userUpdatePhone: userUpdatePhoneActions.request,
          userUpdatePassword: userUpdatePasswordActions.request,
          deleteStateUserProfile: deleteStateUserProfileAction,
          userGetUserTotpDevices: userGetUserTotpDevicesActions.request,
          userCreateUserTotpDeviceSecret: userCreateUserTotpDeviceSecretActions.request,
          userCreateUserTotpDevice: userCreateUserTotpDeviceActions.request,
          userUpdateUserTotpDevice: userUpdateUserTotpDeviceActions.request,
          userDeleteUserTotpDevice: userDeleteUserTotpDeviceActions.request,
          deleteStateUserTotpDeviceSecret: deleteStateUserTotpDeviceSecretAction,
          deleteStateUserTotpDevices: deleteStateUserTotpDevicesAction,
          downloadAgentAttachmentsAsZip: downloadAgentAttachmentsAsZipActions.request,
          uploadAgentAttachments: uploadAgentAttachmentsActions.request,
          getAgentCompetences: getAgentCompetencesActions.request,
          getAgentLicenses: getAgentLicensesActions.request,
          getAgentBrokerNumbers: getAgentBrokerNumbersActions.request,
          deleteStateAgentDetail: deleteStateAgentDetailAction,
          getNotificationSettings: getNotificationSettingsActions.request,
          updateNotificationSettings: updateNotificationSettingsActions.request,
          deleteStateNotificationSettingsList: deleteStateNotificationSettingsListAction
        },
        dispatch
      ),
    [dispatch]
  );

  useEffect(() => {
    if (!user) {
      return;
    }

    actions.userGetUserProfile({ id: user.id });
    actions.userGetUserTotpDevices({ id: user.id });
    actions.getNotificationSettings();

    return () => {
      actions.deleteStateUserProfile();
      actions.deleteStateUserTotpDevices();
      actions.deleteStateAgentDetail();
      actions.deleteStateNotificationSettingsList();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (urlTabKey !== tabKey) {
      checkAndSetTabKey();
    }
  }, [urlTabKey]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleTabKeyChange = (tabKey: string): void => {
    navigate(appendSearchParamsToURL({ tab: tabKey }), { replace: true });
  };

  const checkAndSetTabKey = (): void => {
    switch (urlTabKey) {
      case USER_PROFILE_TABS.USER:
      case USER_PROFILE_TABS.ACCOUNT:
      case USER_PROFILE_TABS.ATTACHMENTS:
      case USER_PROFILE_TABS.NOTIFICATION_SETTINGS:
        setTabKey(urlTabKey);
        break;
      case USER_PROFILE_TABS.COMPETENCES:
        setTabKey(hasProfileCompetencePermission ? urlTabKey : USER_PROFILE_TABS.USER);
        break;
      case USER_PROFILE_TABS.LICENSES:
        setTabKey(hasProfileLicensePermission ? urlTabKey : USER_PROFILE_TABS.USER);
        break;
      case USER_PROFILE_TABS.BROKER_NUMBERS:
        setTabKey(hasProfileBrokerNumberPermission ? urlTabKey : USER_PROFILE_TABS.USER);
        break;
      case USER_PROFILE_TABS.EDUCATION:
        setTabKey(hasProfileEducationPermission ? urlTabKey : USER_PROFILE_TABS.USER);
        break;
      case USER_PROFILE_TABS.MENTORING:
        setTabKey(hasProfileMentoringPermission ? urlTabKey : USER_PROFILE_TABS.USER);
        break;
      default:
        setTabKey(USER_PROFILE_TABS.USER);
        break;
    }
  };

  const getItems = (userProfile: UserProfile): TabsProps["items"] => {
    const items: TabsProps["items"] = [
      {
        key: USER_PROFILE_TABS.USER,
        label: (
          <span>
            <AntIcon type="user" className="margin-right-tiny" />
            {t("user.titles.userData")}
          </span>
        ),
        children: (
          <UserProfileUserView
            user={userProfile}
            totpDeviceSecret={totpDeviceSecret}
            totpDevices={totpDevices}
            openTotpDeviceFormByDefault={openTotpDeviceFormByDefault}
            userRequestEmailUpdate={actions.userRequestEmailUpdate}
            userRequestPhoneUpdate={actions.userRequestPhoneUpdate}
            userUpdatePhone={actions.userUpdatePhone}
            userUpdatePassword={actions.userUpdatePassword}
            userCreateUserTotpDeviceSecret={actions.userCreateUserTotpDeviceSecret}
            userCreateUserTotpDevice={actions.userCreateUserTotpDevice}
            userUpdateUserTotpDevice={actions.userUpdateUserTotpDevice}
            userDeleteUserTotpDevice={actions.userDeleteUserTotpDevice}
            deleteStateUserTotpDeviceSecret={actions.deleteStateUserTotpDeviceSecret}
          />
        )
      },
      {
        key: USER_PROFILE_TABS.ACCOUNT,
        label: (
          <span>
            <AntIcon type="solution" className="margin-right-tiny" />
            {t("user.titles.agentData")}
          </span>
        ),
        children: (
          <UserProfileAgentTabSwitchView
            userProfile={userProfile}
            title={t("user.titles.agentData")}
            component={agentId => (
              <UserProfileUserAccountView
                agent={
                  agentId === userProfile.representingAgent?.id ? userProfile.representingAgent : userProfile.agent
                }
              />
            )}
          />
        )
      },
      {
        key: USER_PROFILE_TABS.ATTACHMENTS,
        label: (
          <span>
            <AntIcon type="paper-clip" className="margin-right-tiny" />
            {t("user.titles.attachments")}
          </span>
        ),
        children: (
          <UserProfileAgentTabSwitchView
            userProfile={userProfile}
            title={t("user.titles.agentAttachments")}
            component={agentId => (
              <AgentAttachmentsTableView
                agent={
                  agentId === userProfile.representingAgent?.id ? userProfile.representingAgent : userProfile.agent
                }
                onDownloadAsZip={actions.downloadAgentAttachmentsAsZip}
                onUpload={actions.uploadAgentAttachments}
              />
            )}
          />
        )
      }
    ];

    if (hasProfileCompetencePermission) {
      items.push({
        key: USER_PROFILE_TABS.COMPETENCES,
        label: (
          <span>
            <AntIcon type="file-done" className="margin-right-tiny" />
            {t("user.titles.competences")}
          </span>
        ),
        children: (
          <>
            <h2>
              {t("user.titles.competences")} |{" "}
              {formatAgentAggregatedName(userProfile.representingAgent || userProfile.agent)}
            </h2>
            <AgentCompetenceTableView
              agentId={(userProfile.representingAgent || userProfile.agent)?.id}
              competences={competences}
              onGet={actions.getAgentCompetences}
            />
          </>
        )
      });
    }

    if (hasProfileLicensePermission) {
      items.push({
        key: USER_PROFILE_TABS.LICENSES,
        label: (
          <span>
            <AntIcon type="bank" className="margin-right-tiny" />
            {t("user.titles.licenses")}
          </span>
        ),
        children: (
          <UserProfileAgentTabSwitchView
            userProfile={userProfile}
            title={t("user.titles.licenses")}
            component={agentId => (
              <AgentLicenseTableView agentId={agentId} licenses={licenses} onGet={actions.getAgentLicenses} />
            )}
          />
        )
      });
    }

    if (hasProfileBrokerNumberPermission) {
      items.push({
        key: USER_PROFILE_TABS.BROKER_NUMBERS,
        label: (
          <span>
            <AntIcon type="id-card" className="margin-right-tiny" />
            {t("user.titles.brokerNumbers")}
          </span>
        ),
        children: (
          <UserProfileAgentTabSwitchView
            userProfile={userProfile}
            title={t("user.titles.brokerNumbers")}
            component={agentId => (
              <AgentBrokerNumberTableView
                agentId={agentId}
                brokerNumbers={brokerNumbers}
                onGet={actions.getAgentBrokerNumbers}
              />
            )}
          />
        )
      });
    }

    if (hasProfileEducationPermission) {
      items.push({
        key: USER_PROFILE_TABS.EDUCATION,
        icon: <AntIcon type="reconciliation" />,
        label: t("user.titles.education"),
        children: (
          <UserProfileAgentTabSwitchView
            userProfile={userProfile}
            title={t("user.titles.education")}
            component={agentId => <AgentCompletedEducationTableView agentId={agentId} />}
          />
        )
      });
    }

    if (hasProfileMentoringPermission) {
      items.push({
        key: USER_PROFILE_TABS.MENTORING,
        icon: <AntIcon type="projection" />,
        label: t("user.titles.mentoring"),
        children: (
          <UserProfileAgentTabSwitchView
            userProfile={userProfile}
            title={t("user.titles.mentoring")}
            component={agentId => <AgentMentoringTableView agentId={agentId} />}
          />
        )
      });
    }

    items.push({
      key: USER_PROFILE_TABS.NOTIFICATION_SETTINGS,
      label: (
        <span>
          <AntIcon type="bell" className="margin-right-tiny" />
          {t("user.titles.notifications")}
        </span>
      ),
      children: (
        <>
          <h2>{t("user.titles.notificationSettings")}</h2>
          <NotificationSettingsView
            settings={notificationSettings.filter(settings => settings.available)}
            onUpdate={actions.updateNotificationSettings}
          />
        </>
      )
    });

    return items;
  };

  return (
    <ContentWrapper>
      <Card>
        <DisplayWrapper itemLoaded={!!userProfile} notFoundCheckRequest={requests.USER_GET_USER_PROFILE}>
          {userProfile && (
            <Tabs
              className="tabs-left"
              tabPosition="left"
              activeKey={tabKey}
              onChange={handleTabKeyChange}
              items={getItems(userProfile)}
            />
          )}
        </DisplayWrapper>
      </Card>
    </ContentWrapper>
  );
};
