import { closestCenter, DndContext, KeyboardSensor, PointerSensor, useSensor, useSensors } from "@dnd-kit/core";
import type { DragEndEvent } from "@dnd-kit/core/dist/types";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy
} from "@dnd-kit/sortable";
import { Button, Card, Empty, Flex, Form, Select, SelectProps, Space } from "antd";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import ActionButton from "../../../../../../common/components/buttons/ActionButton";
import AntIcon from "../../../../../../common/components/icons/AntIcon";
import { SortableItem } from "../../../../../../common/components/sortable/SortableItem";
import type { FieldConstraintViolation, RootState } from "../../../../../../common/types";
import { CalcType } from "../../../../enums";
import { CalcResultSorting } from "../../../components/calc-result/CalcResultSorting";
import { CalcNavigation } from "../../../components/CalcNavigation";
import { DraftOfferForm } from "../../../components/DraftOfferForm";
import { CalcResultsSorting } from "../../../enums";
import { OperationStage } from "../../../realty/enums";
import type { CalcResult } from "../../../types";
import { sortCalcResultItems } from "../../../utils";
import { selectVehicleDraft } from "../../ducks";
import type { VehicleCalc, VehicleCalcDraft, VehicleCalcResultData, VehicleFormClients } from "../../types";
import { VehicleSaveDraftButton } from "../VehicleSaveDraftButton";
import { VehicleCalcResult } from "./vehicle-calc-result/VehicleCalcResult";

const mtplCoverages = [
  "EXTENDED_ASSISTANCE",
  "GLASS",
  "ANIMAL",
  "ELEMENT",
  "THEFT_AND_VANDALISM",
  "INJURY_CREW",
  "LUGGAGE",
  "BAD_ROAD_CONDITIONS"
];

const crashCoverages = ["EXTENDED_ASSISTANCE", "GLASS", "GAP", "REPLACEMENT_VEHICLE", "LUGGAGE"];

const mtplCrashCoverages = [
  "EXTENDED_ASSISTANCE",
  "GLASS",
  "REPLACEMENT_VEHICLE",
  "KOOP_EXTENDED_WARRANTY",
  "INJURY_CREW",
  "GAP"
];

type VehicleCalcResultsFilterForm = {
  institutions: string[];
  coverages: string[];
};

type Props = {
  calcData: VehicleCalc;
  resultItems: CalcResult<VehicleCalcResultData>[];
  goToPreviousStep: VoidFunction;
  handleGenerateContract: (selectedItem: CalcResult<VehicleCalcResultData>) => void;
  handleShowErrors: (errors: FieldConstraintViolation[]) => void;
  clients: VehicleFormClients;
};

export const VehicleCalcResultsView = ({
  calcData,
  resultItems,
  goToPreviousStep,
  handleShowErrors,
  handleGenerateContract,
  clients
}: Props) => {
  const { t } = useTranslation();
  const draft = useSelector<RootState, VehicleCalcDraft | undefined>(selectVehicleDraft);

  const [draftOfferForm, setDraftOfferForm] = useState<"createOffer" | "createUpdateDraft" | "sendOffer">();
  const [isUpdateDraftOffer, setIsUpdateDraftOffer] = useState(false);

  const mainResultItems = useMemo(() => resultItems.filter(result => result.calcType === calcData.type), [resultItems]);

  const gapResultItems = useMemo(() => resultItems.filter(result => result.calcType === CalcType.GAP), [resultItems]);
  const pasResultItems = useMemo(() => resultItems.filter(result => result.calcType === CalcType.PAS), [resultItems]);

  const [resultsSorting, setResultsSorting] = useState<CalcResultsSorting>(CalcResultsSorting.PRICE_ASC);
  const [calcResultItems, setCalcResultItems] = useState<CalcResult<VehicleCalcResultData>[]>(
    sortCalcResultItems({
      sortBy: resultsSorting,
      items: mainResultItems
    })
  );

  const [form] = Form.useForm<VehicleCalcResultsFilterForm>();

  useEffect(() => {
    setCalcResultItems(
      sortCalcResultItems({
        sortBy: resultsSorting,
        items: mainResultItems
      })
    );

    form.resetFields();
  }, [mainResultItems]);

  useEffect(() => {
    const sortedCalcResultItems = calcResultItems
      .filter(item => !item.error)
      .sort((a, b) => {
        const isIncludedA = a.active ? 1 : 0;
        const isIncludedB = b.active ? 1 : 0;

        return isIncludedB - isIncludedA;
      });

    const result = calcResultItems.map(item => (item.error ? item : sortedCalcResultItems.shift()!));

    setCalcResultItems(result);
  }, [calcResultItems.filter(item => item.active).length]);

  const getCoverages = (): string[] => {
    switch (calcData.type) {
      case CalcType.MTPL:
        return mtplCoverages;
      case CalcType.CRASH:
        return crashCoverages;
      case CalcType.MTPL_CRASH:
        return mtplCrashCoverages;
      default:
        return mtplCoverages;
    }
  };

  const coveragesOptions: SelectProps["options"] = getCoverages().map(value => ({
    value: value,
    label: t(`contract.enums.insuranceCoverageType.${value}`)
  }));

  const insuranceInstitutions: SelectProps["options"] = useMemo(
    () =>
      mainResultItems
        .filter(item => !item.error)
        .map(item => item.insuranceInstitution)
        .filter((item, index, self) => !self.some((institution, i) => institution.id === item.id && i < index))
        .map(value => ({
          value: value.id,
          label: value.name
        })),
    [mainResultItems]
  );

  const handleSaveDraft = (isUpdate: boolean): void => {
    setIsUpdateDraftOffer(isUpdate);
    setDraftOfferForm("createUpdateDraft");
  };

  const handleSubmitFilter = () => {
    form.validateFields().then(values => {
      const sortedItems = sortCalcResultItems({
        sortBy: resultsSorting,
        items: mainResultItems
      });

      if (values.institutions.length === 0 && values.coverages.length === 0) {
        setCalcResultItems(sortedItems);
        return;
      }

      const result = sortedItems
        .filter(item => !item.error)
        .filter(item => {
          if (values.institutions.length === 0) {
            return true;
          }

          return values.institutions.includes(item.insuranceInstitution.id);
        })
        .filter(item => {
          if (values.coverages.length === 0) {
            return true;
          }

          return values.coverages.some(coverage =>
            (item.data?.appliedCoverages ?? []).some(
              appliedCoverage => appliedCoverage.type.includes(coverage) || coverage.includes(appliedCoverage.type)
            )
          );
        });

      setCalcResultItems(result);
    });
  };

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (!over) {
      return;
    }

    const activeIndex = calcResultItems.findIndex(item => item.id === active.id);
    const overIndex = calcResultItems.findIndex(item => item.id === over.id);

    if (activeIndex !== overIndex) {
      setCalcResultItems(prev => arrayMove(prev, activeIndex, overIndex));
    }
  };

  const handleItemActivation = (id: string, activation: boolean) => {
    setCalcResultItems(
      calcResultItems.map(item => {
        if (item.id === id) {
          item.active = activation;
        }

        return item;
      })
    );
  };

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates
    })
  );

  return (
    <div>
      <Card
        title={<h2>{t("calc.titles.insuranceOffer")}</h2>}
        className="card-box"
        extra={
          <Space>
            <VehicleSaveDraftButton draft={draft} handleSaveDraft={handleSaveDraft} />
            <ActionButton
              icon="download"
              color="green"
              variant="outlined"
              size="middle"
              disabled={!calcResultItems.length}
              onClick={() => setDraftOfferForm("createOffer")}
            >
              {t("calc.actions.generateOffer")}
            </ActionButton>
            <ActionButton
              icon="mail"
              color="green"
              variant="outlined"
              size="middle"
              disabled={!calcResultItems.length}
              onClick={() => setDraftOfferForm("sendOffer")}
            >
              {t("calc.actions.sendOffer")}
            </ActionButton>
          </Space>
        }
      >
        <Form
          form={form}
          name="insuranceFilterForm"
          initialValues={{ institutions: [], coverages: [] }}
          layout="vertical"
        >
          <Flex gap="middle" align="end">
            <Form.Item name="institutions" label={t("common.insuranceInstitutions")} style={{ flex: 1 }}>
              <Select mode="multiple" options={insuranceInstitutions} allowClear />
            </Form.Item>
            <Form.Item name="coverages" label={t("calc.helpers.coverages")} style={{ flex: 1 }}>
              <Select mode="multiple" options={coveragesOptions} allowClear />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit" icon={<AntIcon type="search" />} onClick={handleSubmitFilter}>
                {t("common.filter")}
              </Button>
            </Form.Item>
          </Flex>
        </Form>
      </Card>

      <Card
        className="card-box"
        title={<h2 style={{ fontSize: 20 }}>{t("calc.vehicle.titles.calculation." + calcData.type)}</h2>}
        extra={
          <CalcResultSorting
            resultItems={mainResultItems}
            resultsSorting={resultsSorting}
            onChange={(sorting, results) => {
              setResultsSorting(sorting);
              setCalcResultItems(results);
            }}
          />
        }
      >
        {calcResultItems.length ? (
          <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
            <SortableContext items={calcResultItems} strategy={verticalListSortingStrategy}>
              {calcResultItems.map(item => (
                <SortableItem key={item.id} id={item.id} error={item.error} disabled={!item.active}>
                  <VehicleCalcResult
                    item={item}
                    handleItemActivation={handleItemActivation}
                    handleGenerateContract={handleGenerateContract}
                    handleShowErrors={handleShowErrors}
                    handleItemRecommendation={(itemId: string, activation: boolean) =>
                      setCalcResultItems(
                        calcResultItems.map(item => {
                          if (item.id === itemId) {
                            item.recommended = activation;
                          }

                          return item;
                        })
                      )
                    }
                  />
                </SortableItem>
              ))}
            </SortableContext>
          </DndContext>
        ) : (
          <Empty description={t("calc.helpers.noDataByFilter")} />
        )}
      </Card>

      {gapResultItems.length ? (
        <Card className="card-box" title={<h3 style={{ marginBottom: 0 }}>{t("calc.enums.calcType.GAP")}</h3>}>
          {gapResultItems.map(item => (
            <VehicleCalcResult
              key={item.id}
              item={item}
              handleItemActivation={handleItemActivation}
              handleGenerateContract={handleGenerateContract}
              handleShowErrors={handleShowErrors}
              hideDrag={true}
            />
          ))}
        </Card>
      ) : undefined}

      {pasResultItems.length ? (
        <Card className="card-box" title={<h3 style={{ marginBottom: 0 }}>{t("calc.enums.calcType.PAS")}</h3>}>
          {pasResultItems.map(item => (
            <VehicleCalcResult
              key={item.id}
              item={item}
              handleItemActivation={handleItemActivation}
              handleGenerateContract={handleGenerateContract}
              handleShowErrors={handleShowErrors}
              hideDrag={true}
            />
          ))}
        </Card>
      ) : undefined}

      <DraftOfferForm
        isUpdate={isUpdateDraftOffer}
        formType={draftOfferForm}
        calcData={calcData}
        calcResults={[...calcResultItems, ...gapResultItems, ...pasResultItems].filter(
          item => item.active && !item.error
        )}
        operationStage={OperationStage.CALCULATE}
        draft={draft}
        onFinish={() => setDraftOfferForm(undefined)}
        clients={clients}
      />

      <CalcNavigation onGoToPrevious={goToPreviousStep} />
    </div>
  );
};
