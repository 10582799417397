import { Badge, Flex, List, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import classNames from "classnames";
import { TFunction } from "i18next";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { generatePath, Link } from "react-router-dom";
import { FlipCard } from "../../../../../../../common/components/flip-card/FlipCard";
import FileTypeIcon from "../../../../../../../common/components/icons/FileTypeIcon";
import { LeftCard } from "../../../../../../../common/components/left-card/LeftCard";
import Ellipsis from "../../../../../../../common/components/views/Ellipsis";
import { FieldConstraintViolation } from "../../../../../../../common/types";
import { formatLocaleCurrency } from "../../../../../../../common/utils/formatUtils";
import { cssVariables, isDefined } from "../../../../../../../common/utils/utils";
import { InsuranceCoverageType } from "../../../../../../contract/enums";
import { CALC_ROUTE_PATHS } from "../../../../../paths";
import { CalcResultActions } from "../../../../components/calc-result/CalcResultActions";
import { DragHandle } from "../../../../components/calc-result/DragHandle";
import { CalcResult, InsuranceCoverage } from "../../../../types";
import { TravelCalcResultData } from "../../../types";
import styles from "./TravelCalcResult.module.scss";
import { TravelCalcResultInfo } from "./TravelCalcResultInfo";

const getCoverageTitle = (coverage: InsuranceCoverage, t: TFunction) => {
  switch (coverage.type) {
    case InsuranceCoverageType.TRAVEL_INJURY:
      return (
        <>
          {t("calc.travel.results.coverageInjuryLongTermEffects")}
          <br />
          {t("calc.travel.results.coverageInjuryDeath")}
        </>
      );
    case InsuranceCoverageType.TRAVEL_LIABILITY:
      return isDefined(coverage.insuranceAmount) ? (
        t("contract.enums.insuranceCoverageType." + coverage.type)
      ) : (
        <>
          {t("calc.travel.results.coverageLiabilityHealth")}
          <br />
          {t("calc.travel.results.coverageLiabilityProperty")}
        </>
      );
    default:
      return t("contract.enums.insuranceCoverageType." + coverage.type);
  }
};

const getCoverageValue = (coverage: InsuranceCoverage) => {
  switch (coverage.type) {
    case InsuranceCoverageType.TRAVEL_INJURY:
      return (
        <>
          {formatLocaleCurrency(coverage.injuryLongTermEffectsInsuranceAmount)}
          <br />
          {formatLocaleCurrency(coverage.injuryDeathInsuranceAmount)}
        </>
      );
    case InsuranceCoverageType.TRAVEL_LIABILITY:
      return isDefined(coverage.insuranceAmount) ? (
        formatLocaleCurrency(coverage.insuranceAmount)
      ) : (
        <>
          {formatLocaleCurrency(coverage.liabilityHealthInsuranceAmount)}
          <br />
          {formatLocaleCurrency(coverage.liabilityPropertyInsuranceAmount)}
        </>
      );
    default:
      return isDefined(coverage.insuranceAmount)
        ? formatLocaleCurrency(coverage.insuranceAmount)
        : coverage.insuranceAmountDesc;
  }
};

type Props = {
  item: CalcResult<TravelCalcResultData>;
  handleItemActivation: (id: string, activation: boolean) => void;
  handleItemRecommendation: (itemId: string, activation: boolean) => void;
  handleGenerateContract: (selectedItem: CalcResult<TravelCalcResultData>) => void;
  handleShowErrors: (errors: FieldConstraintViolation[]) => void;
};

export const TravelCalcResult = ({
  item,
  handleItemActivation,
  handleItemRecommendation,
  handleGenerateContract,
  handleShowErrors
}: Props) => {
  const { t } = useTranslation();

  const [isRotated, setIsRotated] = useState(false);

  const coveragesTableColumns: ColumnsType<InsuranceCoverage> = [
    {
      key: "coverage",
      title: t("calc.travel.results.coverage"),
      width: 180,
      render: (_, record) => getCoverageTitle(record, t)
    },
    {
      key: "coverageValue",
      title: t("calc.travel.results.coverageValue"),
      width: 120,
      align: "right",
      render: (_, record) => getCoverageValue(record)
    }
  ];

  return (
    <FlipCard
      isRotated={isRotated}
      closeRotation={() => setIsRotated(false)}
      frontCard={
        <Badge.Ribbon
          placement="start"
          text={t("calc.helpers.recommended")}
          color={cssVariables.colorGreen}
          style={{ display: item.recommended ? undefined : "none" }}
        >
          <div
            className={classNames(styles.wrapper, {
              [styles.error as string]: !!item.error,
              [styles.disabled as string]: !item.active && !item.error,
              [styles.recommended as string]: item.recommended
            })}
          >
            <DragHandle isDraggable={item.active && !item.error} />

            <div className={styles.infoBox}>
              <TravelCalcResultInfo
                item={item}
                rotate={() => setIsRotated(!isRotated)}
                handleItemRecommendation={handleItemRecommendation}
              />
            </div>

            <div className={styles.actionsBox}>
              <CalcResultActions
                item={item}
                handleItemActivation={handleItemActivation}
                handleGenerateContract={handleGenerateContract}
                handleShowErrors={handleShowErrors}
              />
            </div>
          </div>
        </Badge.Ribbon>
      }
      backCard={
        <div
          style={{ padding: 20 }}
          className={classNames(styles.wrapper, {
            [styles.error as string]: !!item.error
          })}
        >
          <Flex>
            <div style={{ flex: 1, paddingRight: 20 }}>
              <LeftCard description={t("calc.travel.results.additionalInfo")}>
                <Table
                  columns={coveragesTableColumns}
                  dataSource={item.data?.appliedCoverages.map((coverage, index) => ({ ...coverage, id: index }))}
                  rowKey="id"
                  pagination={false}
                  size="small"
                  scroll={{ y: 150 }}
                />
              </LeftCard>
            </div>
            <div style={{ flex: 1, paddingLeft: 20, marginRight: 26 }}>
              <LeftCard description={t("common.documents")}>
                <div style={{ overflowY: "auto", width: "100%", height: "100%", minWidth: 0 }}>
                  <List
                    size="small"
                    dataSource={item.attachments}
                    renderItem={attachment => (
                      <List.Item key={attachment.id}>
                        <Ellipsis>
                          <Link
                            to={generatePath(CALC_ROUTE_PATHS.resultAttachment.to, { id: attachment.id })}
                            target="_blank"
                          >
                            <FileTypeIcon contentType={attachment.file.contentType} /> {attachment.filename}
                          </Link>
                        </Ellipsis>
                      </List.Item>
                    )}
                  />
                </div>
              </LeftCard>
            </div>
          </Flex>
        </div>
      }
    />
  );
};
