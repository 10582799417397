import { Card, Form } from "antd";
import { RcFile } from "antd/lib/upload";
import cloneDeep from "lodash/cloneDeep";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import t from "../../../../../../app/i18n";
import FieldViolationsView from "../../../../../../common/components/views/FieldViolationsView";
import { Permission } from "../../../../../../common/security/authorization/enums";
import { FieldConstraintViolation, RootState } from "../../../../../../common/types";
import { resolveFormValidationError, setErrorsToForm } from "../../../../../../common/utils/formUtils";
import messageUtils from "../../../../../../common/utils/messageUtils";
import { contains, removeStringWhiteSpaces } from "../../../../../../common/utils/utils";
import type { UUID } from "../../../../../../typings/global";
import { selectUserAccount } from "../../../../../auth/ducks";
import { CalcValidationGroup, ClientFormType, ClientType } from "../../../../../client/enums";
import {
  Client,
  CreateUpdateContractClient,
  CreateUpdateNaturalClient,
  NaturalClient
} from "../../../../../client/types";
import {
  clientToCreateUpdateContractClient,
  processClientsDataViolations,
  useClientsValidation,
  useClientValidation
} from "../../../../../client/utils";
import { InstitutionEnum } from "../../../../../institution/enums";
import type { UserAccount } from "../../../../../user/types";
import { CalcType, ClientExperience } from "../../../../enums";
import { CalcGenResultModal } from "../../../components/CalcGenResultModal";
import { CalcNavigation } from "../../../components/CalcNavigation";
import { CalcSummaryModal } from "../../../components/summary/CalcSummaryModal";
import { CalcAttachmentType } from "../../../enums";
import { CalcResult, GenGainersData, GenResponse } from "../../../types";
import { isVehicleCalcType, processGenResultError } from "../../../utils";
import { deleteStateVehicleGenResultAction, generateVehicleActions } from "../../ducks";
import {
  GapCoverageLimit,
  VehicleEquipmentType,
  VehicleOwnerRelation,
  VehiclePolicyHolderRelation,
  VehiclePriceSource
} from "../../enums";
import {
  CrashCalcResultData,
  GapCalcResultData,
  VehicleCalc,
  VehicleCalcResultData,
  VehicleCalcType,
  VehicleEquipmentInfo,
  VehicleFormClients,
  VehicleGen,
  VehicleGenForm,
  VehicleGenFormVehicleData
} from "../../types";
import {
  getVehicleCalcResultsByType,
  M1_N1,
  resolveVehiclePolicyHolder,
  resolveVehiclePolicyHolderType
} from "../../utils";
import VehicleGenClientsDataSection from "./sections/VehicleGenClientsDataSection";
import VehicleGenOtherDataSection from "./sections/VehicleGenOtherDataSection";
import VehicleGenVehicleDataSection from "./sections/VehicleGenVehicleDataSection";

interface Props {
  initialData?: VehicleGenForm;
  genResult?: GenResponse;
  calcData: VehicleCalc;
  clients: VehicleFormClients;
  calcResults: CalcResult<VehicleCalcResultData>[];
  selectedResult: CalcResult<VehicleCalcResultData>;
  draftId?: UUID;
  selectedVehicleVin?: string;
  onGenerateFormSubmit: typeof generateVehicleActions.request;
  onGenResultDelete: typeof deleteStateVehicleGenResultAction;
  onReturnToCalculationClick: (genData: VehicleGenForm) => void;
  onClientChange: (type: ClientFormType, client?: Client) => VehicleFormClients;
  onSelectedVehicleVinSet: (vin?: string) => void;
  goToPreviousStep: VoidFunction;
}

const VehicleGenWrapper = (props: Props) => {
  const [form] = Form.useForm<VehicleGenForm>();
  const clientValidation = useClientValidation();
  const clientsValidation = useClientsValidation();

  const [summaryOpen, setSummaryOpen] = useState<boolean>(false);
  const [vehicleGenFormData, setVehicleGenFormData] = useState<VehicleGenForm>();
  const [clientsViolationErrors, setClientsViolationErrors] = useState<Map<ClientFormType, FieldConstraintViolation[]>>(
    new Map()
  );
  const [calcAttachments, setCalcAttachments] = useState<Map<CalcAttachmentType, RcFile>>(new Map());

  const userAccount = useSelector<RootState, UserAccount | undefined>(selectUserAccount);

  useEffect(() => {
    if (props.initialData) {
      const { clientsData, vehicleData } = props.calcData;
      const { calcType, data } = props.selectedResult;
      const { institutionEnum } = props.selectedResult.insuranceInstitution;
      const formData = cloneDeep(props.initialData);

      formData.generalData = formData.generalData || {};

      if (vehicleData.vin !== props.selectedVehicleVin) {
        formData.vehicleData.registrationCertificatePresent = !!vehicleData.registrationCertificatePresent;
        formData.vehicleData.registrationCertificateNumber = undefined;
        formData.vehicleData.colorId = vehicleData.colorId ?? "";
        props.onSelectedVehicleVinSet(vehicleData.vin);
      } else {
        formData.vehicleData.registrationCertificatePresent = !!formData.vehicleData.registrationCertificatePresent;
      }
      formData.vehicleData.damaged = !!formData.vehicleData.damaged;

      if (
        institutionEnum === InstitutionEnum.UNION &&
        calcType === CalcType.CRASH &&
        contains(M1_N1, vehicleData.category) &&
        !formData.vehicleData.equipment
      ) {
        formData.vehicleData.equipment = Object.values(VehicleEquipmentType).map<VehicleEquipmentInfo>(type => ({
          selected: false,
          type
        }));
      }

      if (
        (institutionEnum === InstitutionEnum.KOMUNALNA || institutionEnum === InstitutionEnum.KOOPERATIVA) &&
        (calcType === CalcType.CRASH || calcType === CalcType.MTPL_CRASH)
      ) {
        if ((data as CrashCalcResultData).vehiclePriceFromKpasKoopPriceList) {
          formData.vehicleData.priceSource = VehiclePriceSource.KPAS_KOOP_PRICE_LIST;
        } else if (vehicleData.newVehicle) {
          formData.vehicleData.priceSource = VehiclePriceSource.KPAS_KOOP_INVOICE;
        } else if (
          formData.vehicleData.priceSource !== VehiclePriceSource.KPAS_KOOP_INVOICE &&
          formData.vehicleData.priceSource !== VehiclePriceSource.KPAS_KOOP_SLOVEXPERTA
        ) {
          formData.vehicleData.priceSource = undefined;
        }
      }

      if (institutionEnum === InstitutionEnum.WUSTENROT && calcType === CalcType.CRASH) {
        formData.generalData.childrenUnder15InVehicle = !!formData.generalData.childrenUnder15InVehicle;
      }

      if (institutionEnum === InstitutionEnum.CSOB && calcType === CalcType.CRASH) {
        formData.generalData.agentIsClosePersonToClient = !!formData.generalData.agentIsClosePersonToClient;
      }

      if (institutionEnum === InstitutionEnum.KOOPERATIVA && calcType === CalcType.MTPL_CRASH) {
        formData.vehicleData.allKeysAvailable = !!formData.vehicleData.allKeysAvailable;
        formData.vehicleData.allServiceInspectionsPassed = !!formData.vehicleData.allServiceInspectionsPassed;
      }

      if (clientsData.policyHolderRelation !== VehiclePolicyHolderRelation.DIFFERENT_FROM_VEHICLE_HOLDER) {
        delete formData.clientsData.policyHolderIdentifier;
      }

      if (clientsData.ownerRelation !== VehicleOwnerRelation.DIFFERENT_FROM_BOTH) {
        delete formData.clientsData.ownerIdentifier;
      }

      const policyHolder = resolveVehiclePolicyHolder(props.clients, clientsData.policyHolderRelation);
      if (policyHolder?.type !== ClientType.LEGAL) {
        delete formData.clientsData.representativeIdentifier;
        delete formData.clientsData.representativeFunction;
        if (props.clients.representative) {
          props.onClientChange(ClientFormType.REPRESENTATIVE);
        }
      }

      if (institutionEnum === InstitutionEnum.DEFEND && calcType === CalcType.GAP) {
        formData.generalData.gapComplicityReinsurance = (data as GapCalcResultData).options?.[0]?.complicityReinsurance;
      }

      if (institutionEnum === InstitutionEnum.COLONNADE && calcType === CalcType.GAP) {
        formData.generalData.gapComplicityReinsurance = !!formData.generalData.gapComplicityReinsurance;
      }

      formData.financialMediationData = {
        ...formData.financialMediationData,
        clientExperience: (policyHolder?.type === ClientType.SELF_EMPLOYED || policyHolder?.type === ClientType.LEGAL
          ? ClientExperience.SUFFICIENT
          : formData.financialMediationData.clientExperience === ClientExperience.SUFFICIENT
            ? undefined
            : formData.financialMediationData.clientExperience) as ClientExperience,
        recommendedResult: undefined
      };

      form.setFieldsValue(formData);

      const clients = createContractClientsArray(props.clients);
      if (clients.length > 1) {
        clientsValidation.onValidate({
          prefix: "clientsData.clients",
          clients,
          validationGroup: CalcValidationGroup.GENERATE
        });
      }
    } else {
      const holder = props.calcData.clientsData.clients[props.calcData.clientsData.holderIndex]
        ?.client as CreateUpdateNaturalClient;

      form.setFieldsValue({
        vehicleData: {
          registrationCertificatePresent: !!props.calcData.vehicleData.registrationCertificatePresent
        },
        clientsData: {
          holderIdentityCardNumber:
            holder.identityCardNumber !== holder.previousIdentityCardNumber ? holder.identityCardNumber : undefined
        }
      });

      props.onSelectedVehicleVinSet(props.calcData.vehicleData.vin);
    }

    return () => {
      clientValidation.onErrorResponseDelete();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const violations = clientValidation.errorResponse?.violations?.length
      ? clientValidation.errorResponse.violations
      : clientsValidation.errorResponse?.violations?.length
        ? clientsValidation.errorResponse.violations
        : undefined;

    if (violations) {
      const clientsIndicesMap = createClientsIndicesMap(props.clients);
      setClientsViolationErrors(
        new Map([
          ...clientsViolationErrors,
          ...processClientsDataViolations(clientsIndicesMap, "clientsData.clients", violations)
        ])
      );

      const holderIdentityCardNumberViolations = violations
        .filter(
          violation =>
            violation.fieldPath ===
            `clientsData.clients[${clientsIndicesMap.get(ClientFormType.HOLDER)}].client.identityCardNumber`
        )
        .map(violation => ({ ...violation, fieldPath: "clientsData.holderIdentityCardNumber" }));

      if (holderIdentityCardNumberViolations?.length) {
        setErrorsToForm(form, "calc.vehicle.attrs", holderIdentityCardNumberViolations);
      }
    }
  }, [clientValidation.errorResponse, clientsValidation.errorResponse]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (props.genResult?.error) {
      const holderIndex = createClientsIndicesMap(props.clients).get(ClientFormType.HOLDER);

      const violations = processGenResultError(
        {
          ...props.genResult.error,
          violations: props.genResult.error.violations?.map(violation =>
            violation.fieldPath === `clientsData.clients[${holderIndex}].client.identityCardNumber`
              ? { ...violation, fieldPath: "clientsData.holderIdentityCardNumber" }
              : violation
          )
        },
        createClientsIndicesMap(props.clients),
        form,
        "calc.vehicle.attrs"
      );

      if (violations) {
        messageUtils.errorNotification({
          message: props.genResult.error.message,
          description: (
            <FieldViolationsView violations={violations.notificationViolations} rootPath="calc.vehicle.attrs" />
          ),
          duration: 10
        });
        setClientsViolationErrors(violations.clientsViolations);
      }
    }
  }, [props.genResult]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleGenerateFormSubmit = (): void => {
    form
      .validateFields()
      .then(values => {
        const missingAttachments = checkHasMissingAttachments(values.vehicleData);

        if (checkHasClientsError()) {
          messageUtils.errorNotification({ message: t("common.error"), description: t("calc.validations.formError") });
        } else if (missingAttachments.length > 0) {
          messageUtils.errorNotification({
            message: t("common.error"),
            description: t("calc.validations.missingAttachmentsError", {
              attachmentTypes: missingAttachments.map(type => t("calc.enums.calcAttachmentType." + type)).join(", ")
            })
          });
        } else {
          setSummaryOpen(true);
          setVehicleGenFormData(form.getFieldsValue());
        }
      })
      .catch(errorInfo => {
        messageUtils.errorNotification({ message: t("common.error"), description: t("calc.validations.formError") });
        resolveFormValidationError(errorInfo);
      });
  };

  const handleGenerateFormApprovalsSubmit = (gainersData?: GenGainersData): void => {
    setSummaryOpen(false);
    form
      .validateFields()
      .then(values => {
        const missingAttachments = checkHasMissingAttachments(values.vehicleData);

        if (checkHasClientsError()) {
          messageUtils.errorNotification({ message: t("common.error"), description: t("calc.validations.formError") });
        } else if (missingAttachments.length > 0) {
          messageUtils.errorNotification({
            message: t("common.error"),
            description: t("calc.validations.missingAttachmentsError", {
              attachmentTypes: missingAttachments.map(type => t("calc.enums.calcAttachmentType." + type)).join(", ")
            })
          });
        } else {
          props.onGenerateFormSubmit(processAndGetGenFormData(values, gainersData));
        }
      })
      .catch(errorInfo => {
        messageUtils.errorNotification({ message: t("common.error"), description: t("calc.validations.formError") });
        resolveFormValidationError(errorInfo);
      });
  };

  const handleGenResultReturnToCalculationClick = (): void => {
    props.onReturnToCalculationClick(form.getFieldsValue());
  };

  const handleClientChange = (type: ClientFormType, client?: Client): void => {
    const clients = props.onClientChange(type, client);

    if (client) {
      clientValidation.onValidate({
        prefix: `clientsData.clients[${createClientsIndicesMap(clients).get(type)}]`,
        client: clientToCreateUpdateContractClient(client),
        validationGroup: CalcValidationGroup.GENERATE
      });

      if (type === ClientFormType.POLICY_HOLDER) {
        if (client.type === ClientType.SELF_EMPLOYED || client.type === ClientType.LEGAL) {
          form.setFieldsValue({ financialMediationData: { clientExperience: ClientExperience.SUFFICIENT } });
        } else if (form.getFieldValue(["financialMediationData", "clientExperience"]) === ClientExperience.SUFFICIENT) {
          form.setFieldsValue({ financialMediationData: { clientExperience: undefined } });
        }
      }
    }
  };

  const handleClientViolationErrorsChange = (type: ClientFormType, violations?: FieldConstraintViolation[]): void => {
    const updatedViolationsErrors = new Map<ClientFormType, FieldConstraintViolation[]>([...clientsViolationErrors]);
    if (violations?.length) {
      updatedViolationsErrors.set(type, violations);
    } else {
      updatedViolationsErrors.delete(type);
    }
    setClientsViolationErrors(updatedViolationsErrors);
  };

  const handleCalcAttachmentChange = (type: CalcAttachmentType, file?: RcFile): void => {
    const updatedCalcAttachments = new Map<CalcAttachmentType, RcFile>([...calcAttachments]);
    if (file) {
      updatedCalcAttachments.set(type, file);
    } else {
      updatedCalcAttachments.delete(type);
    }
    setCalcAttachments(updatedCalcAttachments);
  };

  const processAndGetGenFormData = (values: VehicleGenForm, gainersData?: GenGainersData): VehicleGen => {
    const { calcData, clients, selectedResult, calcResults } = props;
    const indicesMap = createClientsIndicesMap(clients);

    let recommendedResult;
    let recommendedGapComplicityReinsurance: boolean | undefined = undefined;
    let recommendedGapCoverageLimit: GapCoverageLimit | undefined = undefined;
    if (values.financialMediationData.recommendedResult && isVehicleCalcType(selectedResult.calcType)) {
      const recommendedResultData = values.financialMediationData.recommendedResult as [
        InstitutionEnum,
        boolean | string | GapCoverageLimit
      ];
      const institutionResults = getVehicleCalcResultsByType(calcResults, selectedResult.calcType).filter(
        resultsRow =>
          resultsRow.insuranceInstitution.institutionEnum ===
          InstitutionEnum[recommendedResultData[0] as keyof typeof InstitutionEnum]
      );

      if (selectedResult.calcType === CalcType.GAP) {
        if (institutionResults?.[0]?.insuranceInstitution.institutionEnum === InstitutionEnum.COLONNADE) {
          recommendedGapComplicityReinsurance = recommendedResultData[1] as boolean;
          recommendedResult = institutionResults[0];
        } else if (institutionResults?.[0]?.insuranceInstitution.institutionEnum === InstitutionEnum.DEFEND) {
          recommendedGapCoverageLimit = recommendedResultData[1] as GapCoverageLimit;
          recommendedResult = institutionResults?.find(result => !result.data?.notApplicable);
        }
      } else {
        recommendedResult =
          recommendedResultData.length > 1
            ? institutionResults?.find(result => result.coverage === recommendedResultData[1])
            : institutionResults?.[0];
      }
    }

    const reinsurancesData = values.reinsurancesData?.uniqaSidecarMtplData
      ? {
          ...values.reinsurancesData,
          uniqaSidecarMtplData: {
            ...values.reinsurancesData.uniqaSidecarMtplData,
            licensePlate: removeStringWhiteSpaces(values.reinsurancesData.uniqaSidecarMtplData.licensePlate)
          }
        }
      : values.reinsurancesData;

    return {
      type: selectedResult.calcType as VehicleCalcType,
      calcResult: selectedResult.data as VehicleCalcResultData,
      coverage: selectedResult.coverage,
      calcResponse: {
        results: calcResults
      },
      insuranceInstitutionId: selectedResult.insuranceInstitution.id,
      draftId: props.draftId,
      attachmentsToUpload: calcAttachments,
      vehicleData: {
        ...calcData.vehicleData,
        ...values.vehicleData,
        registrationCertificateNumber: values.vehicleData.registrationCertificateNumber || undefined,
        security: calcData.vehicleData.security.map((s, i) => ({
          ...s,
          numberOfKeysOrControllers: values.vehicleData?.security?.[i]?.numberOfKeysOrControllers
        }))
      },
      clientsData: {
        ...calcData.clientsData,
        clients: createContractClientsArray(clients),
        holderIndex: indicesMap.get(ClientFormType.HOLDER) as number,
        policyHolderIndex: indicesMap.get(ClientFormType.POLICY_HOLDER) as number,
        ownerIndex: indicesMap.get(ClientFormType.OWNER) as number,
        representativeIndex: indicesMap.get(ClientFormType.REPRESENTATIVE),
        representativeFunction: values.clientsData.representativeFunction,
        policyHolderEmail: values.clientsData.policyHolderEmail,
        policyHolderPhone: values.clientsData.policyHolderPhone,
        policyHolderMarketingConsent: values.clientsData.policyHolderMarketingConsent
      },
      generalData: {
        ...calcData.generalData,
        ...values.generalData,
        crossSelling: { ...calcData.generalData.crossSelling, ...(values.generalData?.crossSelling || {}) }
      },
      reinsurancesData:
        selectedResult.calcType === CalcType.MTPL
          ? {
              ...calcData.reinsurancesData,
              ...reinsurancesData,
              gap: false,
              gapComplicityReinsurance: false,
              gapDuration: undefined,
              replacementVehicle: false,
              replacementVehicleCategory: undefined,
              generaliAbroadVehicleRepair: false,
              koopExtendedWarranty: false
            }
          : selectedResult.calcType === CalcType.CRASH
            ? {
                ...calcData.reinsurancesData,
                ...reinsurancesData,
                animal: false,
                animalAmount: undefined,
                element: false,
                theftAndVandalism: false,
                injury: false,
                koopExtendedWarranty: false,
                uniqaSmallDamage: false,
                uniqaSmallDamageAmount: undefined,
                uniqaTotalDamage: false,
                uniqaTotalDamageAmount: undefined,
                uniqaLawyerAssistance: false,
                uniqaSidecarMtpl: false,
                uniqaChildrenInjury: false,
                uniqaUnlimitedTow: false
              }
            : {
                ...calcData.reinsurancesData,
                ...reinsurancesData,
                animal: false,
                animalAmount: undefined,
                element: false,
                theftAndVandalism: false,
                generaliAbroadVehicleRepair: false,
                uniqaSmallDamage: false,
                uniqaSmallDamageAmount: undefined,
                uniqaTotalDamage: false,
                uniqaTotalDamageAmount: undefined
              },
      gainersData: gainersData ?? { gainer1Id: userAccount?.agent?.canBeGainer ? userAccount.agent.id : "" },
      financialMediationData: {
        ...values.financialMediationData,
        recommendedGapComplicityReinsurance,
        recommendedGapCoverageLimit,
        recommendedResult
      }
    };
  };

  const createClientsIndicesMap = (clients: VehicleFormClients): Map<ClientFormType, number> => {
    const { policyHolderRelation, ownerRelation } = props.calcData.clientsData;
    const { policyHolder, owner, representative } = clients;
    const indices = new Map<ClientFormType, number>();
    let index = 0;

    indices.set(ClientFormType.HOLDER, index++);

    if (policyHolderRelation === VehiclePolicyHolderRelation.DIFFERENT_FROM_VEHICLE_HOLDER) {
      if (policyHolder) {
        indices.set(ClientFormType.POLICY_HOLDER, index++);
      }
    } else {
      indices.set(ClientFormType.POLICY_HOLDER, indices.get(ClientFormType.HOLDER) as number);
    }

    switch (ownerRelation) {
      case VehicleOwnerRelation.SAME_AS_POLICY_HOLDER:
        indices.set(
          ClientFormType.OWNER,
          indices.has(ClientFormType.POLICY_HOLDER) ? (indices.get(ClientFormType.POLICY_HOLDER) as number) : index++
        );
        break;
      case VehicleOwnerRelation.SAME_AS_VEHICLE_HOLDER:
        indices.set(ClientFormType.OWNER, indices.get(ClientFormType.HOLDER) as number);
        break;
      case VehicleOwnerRelation.DIFFERENT_FROM_BOTH:
        if (owner) {
          indices.set(ClientFormType.OWNER, index++);
        }
        break;
    }

    if (resolveVehiclePolicyHolderType(clients, policyHolderRelation) === ClientType.LEGAL && representative) {
      indices.set(ClientFormType.REPRESENTATIVE, index);
    }

    return indices;
  };

  const createContractClientsArray = (clients: VehicleFormClients): CreateUpdateContractClient[] => {
    const { policyHolderRelation, ownerRelation } = props.calcData.clientsData;
    const { holder, policyHolder, owner, representative } = clients;
    const createUpdateClients: CreateUpdateContractClient[] = [];

    createUpdateClients.push(
      clientToCreateUpdateContractClient({
        ...holder,
        identityCardNumber: form.getFieldValue(["clientsData", "holderIdentityCardNumber"])
      } as NaturalClient)
    );

    if (policyHolderRelation === VehiclePolicyHolderRelation.DIFFERENT_FROM_VEHICLE_HOLDER && policyHolder) {
      createUpdateClients.push(clientToCreateUpdateContractClient(policyHolder));
    }

    if (ownerRelation === VehicleOwnerRelation.DIFFERENT_FROM_BOTH && owner) {
      createUpdateClients.push(clientToCreateUpdateContractClient(owner));
    }

    if (resolveVehiclePolicyHolderType(clients, policyHolderRelation) === ClientType.LEGAL && representative) {
      createUpdateClients.push(clientToCreateUpdateContractClient(representative));
    }

    return createUpdateClients;
  };

  const checkHasClientsError = (): boolean => {
    const { policyHolder, owner, representative } = props.clients;
    const { policyHolderRelation, ownerRelation } = props.calcData.clientsData;

    if (
      policyHolderRelation === VehiclePolicyHolderRelation.DIFFERENT_FROM_VEHICLE_HOLDER &&
      (!policyHolder || clientsViolationErrors.has(ClientFormType.POLICY_HOLDER))
    ) {
      return true;
    }

    if (
      ownerRelation === VehicleOwnerRelation.DIFFERENT_FROM_BOTH &&
      (!owner || clientsViolationErrors.has(ClientFormType.OWNER))
    ) {
      return true;
    }

    if (
      resolveVehiclePolicyHolderType(props.clients, policyHolderRelation) === ClientType.LEGAL &&
      (!representative || clientsViolationErrors.has(ClientFormType.REPRESENTATIVE))
    ) {
      return true;
    }

    return false;
  };

  const checkHasMissingAttachments = (vehicleData: VehicleGenFormVehicleData): CalcAttachmentType[] => {
    const { selectedResult, calcData } = props;
    const missingAttachments: CalcAttachmentType[] = [];

    if (
      selectedResult.insuranceInstitution.institutionEnum === InstitutionEnum.KOMUNALNA ||
      selectedResult.insuranceInstitution.institutionEnum === InstitutionEnum.KOOPERATIVA
    ) {
      if (selectedResult.calcType === CalcType.CRASH || selectedResult.calcType === CalcType.MTPL_CRASH) {
        if (
          vehicleData.registrationCertificatePresent &&
          !calcAttachments.has(CalcAttachmentType.REGISTRATION_CERTIFICATE)
        ) {
          missingAttachments.push(CalcAttachmentType.REGISTRATION_CERTIFICATE);
        }

        if (calcData.vehicleData.newVehicle) {
          if (!calcAttachments.has(CalcAttachmentType.VEHICLE_INVOICE)) {
            missingAttachments.push(CalcAttachmentType.VEHICLE_INVOICE);
          }
          if (selectedResult.insuranceInstitution.institutionEnum === InstitutionEnum.KOMUNALNA) {
            if (!calcAttachments.has(CalcAttachmentType.ACCEPTANCE_PROTOCOL)) {
              missingAttachments.push(CalcAttachmentType.ACCEPTANCE_PROTOCOL);
            }
          }
        } else if (
          selectedResult.insuranceInstitution.institutionEnum === InstitutionEnum.KOOPERATIVA &&
          vehicleData.priceSource === VehiclePriceSource.KPAS_KOOP_INVOICE
        ) {
          if (!calcAttachments.has(CalcAttachmentType.VEHICLE_INVOICE)) {
            missingAttachments.push(CalcAttachmentType.VEHICLE_INVOICE);
          }
        }
      }
      if (
        selectedResult.calcType === CalcType.MTPL &&
        selectedResult.insuranceInstitution.institutionEnum === InstitutionEnum.KOOPERATIVA
      ) {
        if (calcData.clientsData.holderIsDisabledPerson) {
          if (!calcAttachments.has(CalcAttachmentType.DISABLED_PERSON_ID_CARD)) {
            missingAttachments.push(CalcAttachmentType.DISABLED_PERSON_ID_CARD);
          }
        }
      }
    }

    if (
      selectedResult.insuranceInstitution.institutionEnum === InstitutionEnum.CSOB &&
      selectedResult.calcType === CalcType.CRASH
    ) {
      if (calcData.vehicleData.newVehicle && !calcAttachments.has(CalcAttachmentType.VEHICLE_INVOICE)) {
        missingAttachments.push(CalcAttachmentType.VEHICLE_INVOICE);
      }

      if (
        vehicleData.registrationCertificatePresent &&
        !calcAttachments.has(CalcAttachmentType.REGISTRATION_CERTIFICATE)
      ) {
        missingAttachments.push(CalcAttachmentType.REGISTRATION_CERTIFICATE);
      }
    }

    return missingAttachments;
  };

  const resolvePageTitle = (): string => {
    const { calcType, coverage, insuranceInstitution } = props.selectedResult;
    return (
      `${t("calc.vehicle.titles.generation." + calcType)} - ${insuranceInstitution.name}` +
      (calcType !== CalcType.CRASH && coverage ? ` (${t("calc.vehicle.sections.coverage")} ${coverage})` : "")
    );
  };

  return (
    <>
      <Card className="card-box card-box--no-body" title={<h2>{resolvePageTitle()}</h2>} />

      <Form form={form} layout="vertical" name="vehicleGenForm">
        <VehicleGenVehicleDataSection
          calcData={props.calcData}
          selectedResult={props.selectedResult}
          onCalcAttachmentChange={handleCalcAttachmentChange}
        />

        <VehicleGenClientsDataSection
          form={form}
          clientsData={props.calcData.clientsData}
          clients={props.clients}
          selectedResult={props.selectedResult}
          clientsViolationErrors={clientsViolationErrors}
          onClientChange={handleClientChange}
          onClientViolationErrorsChange={handleClientViolationErrorsChange}
          onCalcAttachmentChange={handleCalcAttachmentChange}
        />

        <VehicleGenOtherDataSection
          policyHolderType={resolveVehiclePolicyHolderType(
            props.clients,
            props.calcData.clientsData.policyHolderRelation
          )}
          calcData={props.calcData}
          calcResults={props.calcResults}
          selectedResult={props.selectedResult}
        />
      </Form>

      <CalcNavigation
        requiredPermission={
          props.selectedResult.calcType === CalcType.MTPL
            ? Permission.MTPL_GENERATE_CONTRACT
            : Permission.CRASH_GENERATE_CONTRACT
        }
        onGenerateContract={handleGenerateFormSubmit}
        onGoToPrevious={props.goToPreviousStep}
      />

      {vehicleGenFormData ? (
        <CalcSummaryModal
          open={summaryOpen}
          data={{
            calcData: props.calcData,
            selectedCalcResult: props.selectedResult,
            clients: props.clients,
            formData: vehicleGenFormData
          }}
          onOkClick={gainersData => handleGenerateFormApprovalsSubmit(gainersData)}
          onCancelClick={() => setSummaryOpen(false)}
        />
      ) : undefined}

      <CalcGenResultModal
        result={props.genResult}
        onCancel={props.onGenResultDelete}
        onReturnToCalculationClick={
          props.calcData.type === CalcType.CRASH || props.calcData.type === CalcType.MTPL_CRASH
            ? handleGenResultReturnToCalculationClick
            : undefined
        }
      />
    </>
  );
};

export default VehicleGenWrapper;
