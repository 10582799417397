import { AxiosPromise } from "axios";
import { ApiRequestAdapter } from "../../../common/api/ApiRequestAdapter";
import { apiService } from "../../../common/api/apiService";
import { EntityIdObject, EntityObject, SearchPageRequest, SearchPageResult } from "../../../common/types";
import { serializeParams } from "../../../common/utils/apiUtils";
import { CertificateVehicleColor, CreateUpdateCertificateVehicleColor } from "./types";

const FILTER_VEHICLE_COLORS = ApiRequestAdapter.create("/vehicle-colors", "GET");
const CREATE_VEHICLE_COLOR = ApiRequestAdapter.create("/vehicle-colors", "POST");
const UPDATE_VEHICLE_COLOR = ApiRequestAdapter.create("/vehicle-colors/{0}", "PUT");
const DELETE_VEHICLE_COLOR = ApiRequestAdapter.create("/vehicle-colors/{0}", "DELETE");

export const requests = {
  FILTER_VEHICLE_COLORS,
  CREATE_VEHICLE_COLOR,
  UPDATE_VEHICLE_COLOR,
  DELETE_VEHICLE_COLOR
};

const api = {
  filterVehicleColors: (request: SearchPageRequest): AxiosPromise<SearchPageResult<CertificateVehicleColor>> => {
    return apiService.get(FILTER_VEHICLE_COLORS.url, {
      params: request,
      paramsSerializer: { serialize: serializeParams }
    });
  },
  createVehicleColor: (request: CreateUpdateCertificateVehicleColor): AxiosPromise<CertificateVehicleColor> => {
    return apiService.post(CREATE_VEHICLE_COLOR.url, request);
  },
  updateVehicleColor: (
    request: EntityObject<CreateUpdateCertificateVehicleColor>
  ): AxiosPromise<CertificateVehicleColor> => {
    return apiService.put(UPDATE_VEHICLE_COLOR.getParametrizedUrl(request.id), request.object);
  },
  deleteVehicleColor: (request: EntityIdObject): AxiosPromise => {
    return apiService.delete(DELETE_VEHICLE_COLOR.getParametrizedUrl(request.id));
  }
};

export default api;
