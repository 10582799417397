import { AxiosPromise } from "axios";
import { ApiRequestAdapter } from "../../common/api/ApiRequestAdapter";
import { apiService } from "../../common/api/apiService";
import { EntityIdObject, EntityObject, SearchPageRequest, SearchPageResult } from "../../common/types";
import { serializeParams } from "../../common/utils/apiUtils";
import {
  CreateUpdateLifeInsuranceTariff,
  CreateUpdateLifeInsuranceTariffGroup,
  LifeInsuranceTariff,
  LifeInsuranceTariffFilterPageRequest,
  LifeInsuranceTariffFilterPageResult,
  LifeInsuranceTariffGroup
} from "./types";

const FILTER_LIFE_INSURANCE_TARIFF_GROUPS = ApiRequestAdapter.create("/life-insurance-tariff-groups", "GET");
const CREATE_LIFE_INSURANCE_TARIFF_GROUP = ApiRequestAdapter.create("/life-insurance-tariff-groups", "POST");
const UPDATE_LIFE_INSURANCE_TARIFF_GROUP = ApiRequestAdapter.create("/life-insurance-tariff-groups/{0}", "PUT");
const DELETE_LIFE_INSURANCE_TARIFF_GROUP = ApiRequestAdapter.create("/life-insurance-tariff-groups/{0}", "DELETE");
const FILTER_LIFE_INSURANCE_TARIFFS = ApiRequestAdapter.create("/life-insurance-tariffs/filter", "POST");
const CREATE_LIFE_INSURANCE_TARIFF = ApiRequestAdapter.create("/life-insurance-tariffs", "POST");
const UPDATE_LIFE_INSURANCE_TARIFF = ApiRequestAdapter.create("/life-insurance-tariffs/{0}", "PUT");
const DELETE_LIFE_INSURANCE_TARIFF = ApiRequestAdapter.create("/life-insurance-tariffs/{0}", "DELETE");

export const requests = {
  FILTER_LIFE_INSURANCE_TARIFF_GROUPS,
  CREATE_LIFE_INSURANCE_TARIFF_GROUP,
  UPDATE_LIFE_INSURANCE_TARIFF_GROUP,
  DELETE_LIFE_INSURANCE_TARIFF_GROUP,
  FILTER_LIFE_INSURANCE_TARIFFS,
  CREATE_LIFE_INSURANCE_TARIFF,
  UPDATE_LIFE_INSURANCE_TARIFF,
  DELETE_LIFE_INSURANCE_TARIFF
};

const api = {
  filterLifeInsuranceTariffGroups: (
    request: SearchPageRequest
  ): AxiosPromise<SearchPageResult<LifeInsuranceTariffGroup>> => {
    return apiService.get(FILTER_LIFE_INSURANCE_TARIFF_GROUPS.url, {
      params: request,
      paramsSerializer: { serialize: serializeParams }
    });
  },
  createLifeInsuranceTariffGroup: (
    request: CreateUpdateLifeInsuranceTariffGroup
  ): AxiosPromise<LifeInsuranceTariffGroup> => {
    return apiService.post(CREATE_LIFE_INSURANCE_TARIFF_GROUP.url, request);
  },
  updateLifeInsuranceTariffGroup: (
    request: EntityObject<CreateUpdateLifeInsuranceTariffGroup>
  ): AxiosPromise<LifeInsuranceTariffGroup> => {
    return apiService.put(UPDATE_LIFE_INSURANCE_TARIFF_GROUP.getParametrizedUrl(request.id), request.object);
  },
  deleteLifeInsuranceTariffGroup: (request: EntityIdObject): AxiosPromise => {
    return apiService.delete(DELETE_LIFE_INSURANCE_TARIFF_GROUP.getParametrizedUrl(request.id));
  },
  filterLifeInsuranceTariffs: (
    request: LifeInsuranceTariffFilterPageRequest
  ): AxiosPromise<LifeInsuranceTariffFilterPageResult> => {
    return apiService.post(FILTER_LIFE_INSURANCE_TARIFFS.url, request);
  },
  createLifeInsuranceTariff: (request: CreateUpdateLifeInsuranceTariff): AxiosPromise<LifeInsuranceTariff> => {
    return apiService.post(CREATE_LIFE_INSURANCE_TARIFF.url, request);
  },
  updateLifeInsuranceTariff: (
    request: EntityObject<CreateUpdateLifeInsuranceTariff>
  ): AxiosPromise<LifeInsuranceTariff> => {
    return apiService.put(UPDATE_LIFE_INSURANCE_TARIFF.getParametrizedUrl(request.id), request.object);
  },
  deleteLifeInsuranceTariff: (request: EntityIdObject): AxiosPromise => {
    return apiService.delete(DELETE_LIFE_INSURANCE_TARIFF.getParametrizedUrl(request.id));
  }
};

export default api;
