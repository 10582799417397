import { Tabs, TabsProps } from "antd";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { bindActionCreators } from "redux";
import AntIcon from "../../../common/components/icons/AntIcon";
import { PageSizes } from "../../../common/constants";
import ContentWrapper from "../../../common/modules/wrappers/ContentWrapper";
import { Permission } from "../../../common/security/authorization/enums";
import { RootState } from "../../../common/types";
import { appendSearchParamsToURL, cssVariables, numberOrZero } from "../../../common/utils/utils";
import { selectHasPermissions } from "../../auth/ducks";
import FinancialMediationFilterView from "../../financialmediation/components/views/FinancialMediationFilterView";
import FinancialMediationTableView from "../../financialmediation/components/views/FinancialMediationTableView";
import {
  deleteStateFinancialMediationsPageAction,
  filterFinancialMediationsActions,
  selectFinancialMediationsCurrentPage
} from "../../financialmediation/ducks";
import { FinancialMediationFilterPageRequest } from "../../financialmediation/types";
import { ProductFinancialSector } from "../../product/enums";
import { ContractTerminationForm } from "../components/forms/ContractTerminationForm";
import { RegisterVehicleDataForm } from "../components/forms/RegisterVehicleDataForm";

export const CONTRACT_FORMS_TABS = {
  MEDIATION: "mediation",
  TERMINATION: "termination",
  REGISTER_VEHICLE_DATA: "registerVehicleData"
};

export const ContractFormsContainer = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const [urlParams] = useSearchParams();

  const [tabKey, setTabKey] = useState<string>(CONTRACT_FORMS_TABS.MEDIATION);

  const financialMediationsPage = useSelector(selectFinancialMediationsCurrentPage);
  const hasInsuranceReadPermission = useSelector<RootState, boolean>(state =>
    selectHasPermissions(Permission.INSURANCE_READ)(state)
  );

  const dispatch = useDispatch();
  const actions = useMemo(
    () =>
      bindActionCreators(
        {
          filterFinancialMediations: filterFinancialMediationsActions.request,
          deleteStateFinancialMediationsPage: deleteStateFinancialMediationsPageAction
        },
        dispatch
      ),
    [dispatch]
  );

  useEffect(() => {
    const paramTab = urlParams.get("tab") ?? tabKey;

    switch (paramTab) {
      case CONTRACT_FORMS_TABS.MEDIATION:
        actions.filterFinancialMediations({
          pageIndex: numberOrZero(urlParams.get("pageIndex")),
          pageSize: PageSizes.LARGE,
          keyword: urlParams.get("keyword") ?? undefined,
          includeAssigned: urlParams.get("includeAssigned") === "true",
          sectors: urlParams.getAll("sectors") as ProductFinancialSector[]
        });
        setTabKey(paramTab);
        break;
      case CONTRACT_FORMS_TABS.TERMINATION:
      case CONTRACT_FORMS_TABS.REGISTER_VEHICLE_DATA:
        setTabKey(hasInsuranceReadPermission ? paramTab : CONTRACT_FORMS_TABS.MEDIATION);
        break;
      default:
        setTabKey(CONTRACT_FORMS_TABS.MEDIATION);
        break;
    }
  }, [urlParams, tabKey]);

  useEffect(() => {
    return () => {
      actions.deleteStateFinancialMediationsPage();
    };
  }, []);

  const handleTabKeyChange = (key: string): void => {
    navigate(
      appendSearchParamsToURL({
        tab: key,
        pageIndex: undefined,
        keyword: undefined,
        includeAssigned: undefined,
        sectors: undefined
      }),
      { replace: true }
    );

    if (key === CONTRACT_FORMS_TABS.MEDIATION) {
      actions.filterFinancialMediations({
        pageIndex: 0,
        pageSize: PageSizes.LARGE
      });
    }
  };

  const handleFinancialMediationFilterSubmit = (filter: FinancialMediationFilterPageRequest): void => {
    navigate(
      appendSearchParamsToURL({
        ...filter,
        pageIndex: undefined,
        keyword: filter.keyword,
        includeAssigned: filter.includeAssigned,
        tab: tabKey
      }),
      { replace: true }
    );

    actions.filterFinancialMediations({
      ...filter,
      pageIndex: 0,
      pageSize: financialMediationsPage.pageSize
    });
  };

  const handleFinancialMediationsPageChange = (pageNumber: number): void => {
    const { pageSize, keyword, includeAssigned, sectors } = financialMediationsPage;
    navigate(appendSearchParamsToURL({ pageIndex: pageNumber - 1 }), { replace: true });
    actions.filterFinancialMediations({
      pageIndex: pageNumber - 1,
      pageSize,
      keyword,
      includeAssigned,
      sectors
    });
  };

  const getItems = (): TabsProps["items"] => {
    const items: TabsProps["items"] = [];

    items.push({
      key: CONTRACT_FORMS_TABS.MEDIATION,
      icon: <AntIcon type="profile" />,
      label: t("financialMediation.titles.listTab"),
      children: (
        <div>
          <FinancialMediationFilterView
            currentFilter={financialMediationsPage}
            onFilterSubmit={handleFinancialMediationFilterSubmit}
          />

          <FinancialMediationTableView
            mediationsPage={financialMediationsPage}
            onPageChange={handleFinancialMediationsPageChange}
          />
        </div>
      )
    });

    if (hasInsuranceReadPermission) {
      items.push({
        key: CONTRACT_FORMS_TABS.TERMINATION,
        icon: <AntIcon type="stop" />,
        label: t("contractTermination.titles.page"),
        children: <ContractTerminationForm />
      });

      items.push({
        key: CONTRACT_FORMS_TABS.REGISTER_VEHICLE_DATA,
        icon: <AntIcon type="car" />,
        label: t("registerVehicleData.titles.page"),
        children: <RegisterVehicleDataForm />
      });
    }

    return items;
  };

  return (
    <ContentWrapper>
      <Tabs
        tabBarStyle={{
          backgroundColor: "white",
          padding: "0 24px",
          marginBottom: 24,
          borderRadius: 8,
          boxShadow: `0 1px 3px 0 ${cssVariables.colorShadow}, 0 1px 2px 0 ${cssVariables.colorShadow}`,
          border: `1px solid ${cssVariables.colorHover}`,
          overflow: "hidden"
        }}
        activeKey={tabKey}
        onChange={handleTabKeyChange}
        items={getItems()}
      />
    </ContentWrapper>
  );
};
