import { Badge, Flex, List, Tag } from "antd";
import classNames from "classnames";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { generatePath, Link } from "react-router-dom";
import { FlipCard } from "../../../../../../../common/components/flip-card/FlipCard";
import AntIcon from "../../../../../../../common/components/icons/AntIcon";
import FileTypeIcon from "../../../../../../../common/components/icons/FileTypeIcon";
import { LeftCard } from "../../../../../../../common/components/left-card/LeftCard";
import Ellipsis from "../../../../../../../common/components/views/Ellipsis";
import { FieldConstraintViolation } from "../../../../../../../common/types";
import { cssVariables } from "../../../../../../../common/utils/utils";
import { CALC_ROUTE_PATHS } from "../../../../../paths";
import { CalcResultActions } from "../../../../components/calc-result/CalcResultActions";
import { DragHandle } from "../../../../components/calc-result/DragHandle";
import { CalcResult } from "../../../../types";
import { VehicleCalcResultData } from "../../../types";
import {
  isCrashCalcResult,
  isGapCalcResult,
  isMtplCalcResult,
  isMtplCrashCalcResult,
  isPasCalcResult
} from "../../../utils";
import { AdditionalInformation } from "./AdditionalInformation";
import { CrashCalcResultInfo } from "./CrashCalcResultInfo";
import { GapCalcResultInfo } from "./GapCalcResultInfo";
import { MtplCalcResultInfo } from "./MtplCalcResultInfo";
import { MtplCrashCalcResultInfo } from "./MtplCrashCalcResultInfo";
import { PasCalcResultInfo } from "./PasCalcResultInfo";
import styles from "./VehicleCalcResult.module.scss";

type Props = {
  item: CalcResult<VehicleCalcResultData>;
  handleItemActivation: (id: string, activation: boolean) => void;
  handleItemRecommendation?: (itemId: string, activation: boolean) => void;
  handleGenerateContract: (selectedItem: CalcResult<VehicleCalcResultData>) => void;
  handleShowErrors: (errors: FieldConstraintViolation[]) => void;
  hideDrag?: boolean;
};

export const VehicleCalcResult = ({
  item,
  handleItemActivation,
  handleGenerateContract,
  handleShowErrors,
  handleItemRecommendation,
  hideDrag
}: Props) => {
  const { t } = useTranslation();

  const [isRotated, setIsRotated] = useState(false);

  const renderCalcResultInfo = () => {
    if (isMtplCalcResult(item) && handleItemRecommendation) {
      return (
        <MtplCalcResultInfo
          item={item}
          rotate={() => setIsRotated(!isRotated)}
          handleItemRecommendation={handleItemRecommendation}
        />
      );
    } else if (isMtplCrashCalcResult(item) && handleItemRecommendation) {
      return (
        <MtplCrashCalcResultInfo
          item={item}
          rotate={() => setIsRotated(!isRotated)}
          handleItemRecommendation={handleItemRecommendation}
        />
      );
    } else if (isCrashCalcResult(item) && handleItemRecommendation) {
      return (
        <CrashCalcResultInfo
          item={item}
          rotate={() => setIsRotated(!isRotated)}
          handleItemRecommendation={handleItemRecommendation}
        />
      );
    } else if (isGapCalcResult(item)) {
      return (
        <GapCalcResultInfo
          item={item}
          rotate={() => setIsRotated(!isRotated)}
          handleItemRecommendation={handleItemRecommendation}
        />
      );
    } else if (isPasCalcResult(item)) {
      return (
        <PasCalcResultInfo
          item={item}
          rotate={() => setIsRotated(!isRotated)}
          handleItemRecommendation={handleItemRecommendation}
        />
      );
    }

    return null;
  };

  return (
    <FlipCard
      height={270}
      isRotated={isRotated}
      closeRotation={() => setIsRotated(false)}
      frontCard={
        <Badge.Ribbon
          placement="start"
          text={t("calc.helpers.recommended")}
          color={cssVariables.colorGreen}
          style={{ display: item.recommended ? undefined : "none" }}
        >
          <div
            className={classNames(styles.wrapper, {
              [styles.error as string]: !!item.error,
              [styles.disabled as string]: !item.active && !item.error,
              [styles.recommended as string]: item.recommended
            })}
            style={hideDrag ? { paddingLeft: 20 } : undefined}
          >
            {hideDrag ? undefined : <DragHandle isDraggable={item.active && !item.error} />}

            <div className={styles.infoBox}>{renderCalcResultInfo()}</div>

            <div className={styles.actionsBox}>
              <CalcResultActions
                item={item}
                handleItemActivation={handleItemActivation}
                handleGenerateContract={handleGenerateContract}
                handleShowErrors={handleShowErrors}
              />
            </div>
          </div>
        </Badge.Ribbon>
      }
      backCard={
        <div style={{ padding: 20, paddingRight: 46, flexDirection: "column" }} className={styles.wrapper}>
          <Flex style={{ width: "100%", flexGrow: 1, overflowY: "auto" }} gap="middle">
            <div className={styles.additionalInfoWrapper}>
              <AdditionalInformation item={item} />
            </div>
            <div className={styles.documentsWrapper}>
              <LeftCard description={t("common.documents")}>
                <div className={styles.listBox}>
                  <List
                    size="small"
                    dataSource={item.attachments}
                    renderItem={attachment => (
                      <List.Item key={attachment.id}>
                        <Ellipsis>
                          <Link
                            to={generatePath(CALC_ROUTE_PATHS.resultAttachment.to, { id: attachment.id })}
                            target="_blank"
                          >
                            <FileTypeIcon contentType={attachment.file.contentType} /> {attachment.filename}
                          </Link>
                        </Ellipsis>
                      </List.Item>
                    )}
                  />
                </div>
              </LeftCard>
            </div>
          </Flex>

          {item.data?.additionalWarnings.length ? (
            <Tag
              style={{ minWidth: 0, display: "block", paddingRight: 24, marginTop: 8, marginRight: 0 }}
              icon={<AntIcon type="exclamation" />}
              color="warning"
            >
              <Ellipsis style={{ color: cssVariables.colorYellow }}>{item.data?.additionalWarnings.join(" ")}</Ellipsis>
            </Tag>
          ) : undefined}
        </div>
      }
    />
  );
};
