import { Checkbox, Col, DatePicker, Form, Input, Modal, Row } from "antd";
import { useEffect } from "react";
import t from "../../../../app/i18n";
import HiddenInput from "../../../../common/components/form/components/HiddenInput";
import ItemCreatedUpdatedInfoView from "../../../../common/components/views/ItemCreatedUpdatedInfoView";
import { ModalSizes, rowGutter } from "../../../../common/constants";
import {
  datePickerClearableProps,
  datePickerFormItemProps,
  disableDatePickerOutOfMinDateIncluded,
  resolveFormValidationError,
  useFormErrorHandler
} from "../../../../common/utils/formUtils";
import { useRequestFinishedCallback } from "../../../../common/utils/hooksUtils";
import { validations } from "../../../../common/utils/validationUtils";
import type { UUID } from "../../../../typings/global";
import InstitutionSelect from "../../../enumerations/components/form/InstitutionSelect";
import { requests } from "../../api";
import { createAgentBrokerNumberActions, updateAgentBrokerNumberActions } from "../../ducks";
import { AgentBrokerNumber, CreateUpdateAgentBrokerNumber } from "../../types";

interface Props {
  open: boolean;
  agentId: UUID;
  brokerNumber?: AgentBrokerNumber;
  onCreate?: typeof createAgentBrokerNumberActions.request;
  onUpdate?: typeof updateAgentBrokerNumberActions.request;
  onFormCancel: () => void;
}

const AgentBrokerNumberForm = ({ open, agentId, brokerNumber, onCreate, onUpdate, onFormCancel }: Props) => {
  const [form] = Form.useForm<CreateUpdateAgentBrokerNumber>();
  useFormErrorHandler(form, "agent.brokerNumber.attrs", [
    requests.CREATE_AGENT_BROKER_NUMBER,
    requests.UPDATE_AGENT_BROKER_NUMBER
  ]);

  useEffect(() => {
    if (open && brokerNumber) {
      form.setFieldsValue({
        ...brokerNumber,
        institutionId: brokerNumber.institution.id
      });
    }
  }, [open, brokerNumber, form]);

  const inProgress = useRequestFinishedCallback(
    [requests.CREATE_AGENT_BROKER_NUMBER, requests.UPDATE_AGENT_BROKER_NUMBER],
    onFormCancel
  );

  const handleFormSubmit = (): void => {
    form
      .validateFields()
      .then(values => {
        if (brokerNumber) {
          onUpdate?.({ id1: agentId, id2: brokerNumber.id, object: values });
        } else {
          onCreate?.({ id: agentId, object: values });
        }
      })
      .catch(resolveFormValidationError);
  };

  return (
    <Modal
      width={ModalSizes.MEDIUM}
      open={open}
      title={t(brokerNumber ? "agent.brokerNumber.titles.update" : "agent.brokerNumber.titles.create")}
      cancelText={t("common.cancel")}
      okText={t("common.save")}
      maskClosable={false}
      confirmLoading={inProgress}
      afterClose={() => form.resetFields()}
      onOk={handleFormSubmit}
      onCancel={onFormCancel}
    >
      <ItemCreatedUpdatedInfoView className="margin-bottom-small" item={brokerNumber} />

      <Form form={form} layout="vertical" name="agentBrokerNumberForm">
        <HiddenInput name="optimisticLockVersion" />

        <Row gutter={rowGutter}>
          <Col span={12}>
            <InstitutionSelect
              formItemProps={{
                name: "institutionId",
                label: t("agent.brokerNumber.attrs.institutionId"),
                rules: [validations.notNull]
              }}
              optionsProps={{
                selected: brokerNumber?.institution ? [brokerNumber.institution] : undefined,
                groupByType: true
              }}
            />
          </Col>

          <Col span={12}>
            <Form.Item
              name="value"
              label={t("agent.brokerNumber.attrs.value")}
              rules={[validations.notBlank, validations.size(1, 64)]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={rowGutter}>
          <Col span={8}>
            <Form.Item
              name="validFrom"
              label={t("agent.brokerNumber.attrs.validFrom")}
              rules={[validations.none]}
              {...datePickerFormItemProps}
            >
              <DatePicker {...datePickerClearableProps} showNow={false} />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item noStyle shouldUpdate={(prev, next) => prev.validFrom !== next.validFrom}>
              {({ getFieldValue }) => {
                const validFrom = getFieldValue("validFrom") as string | undefined;
                return (
                  <Form.Item
                    name="validTo"
                    label={t("agent.brokerNumber.attrs.validTo")}
                    rules={[
                      validFrom
                        ? validations.notSameOrBefore(validFrom, t("agent.brokerNumber.attrs.validFrom"))
                        : validations.none
                    ]}
                    {...datePickerFormItemProps}
                  >
                    <DatePicker
                      {...datePickerClearableProps}
                      showNow={false}
                      disabledDate={checked =>
                        validFrom ? disableDatePickerOutOfMinDateIncluded(checked, validFrom) : false
                      }
                    />
                  </Form.Item>
                );
              }}
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item
              name="commonUsage"
              className="form-item-without-label"
              valuePropName="checked"
              rules={[validations.none]}
              initialValue={false}
            >
              <Checkbox>{t("agent.brokerNumber.attrs.commonUsage")}</Checkbox>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={rowGutter}>
          <Col span={24}>
            <Form.Item name="note" label={t("agent.brokerNumber.attrs.note")} rules={[validations.size(1, 8192)]}>
              <Input.TextArea autoSize={{ minRows: 2, maxRows: 5 }} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default AgentBrokerNumberForm;
