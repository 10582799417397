import { Card } from "antd";
import { useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import t from "../../../app/i18n";
import BackNavigationArrow from "../../../common/components/views/BackNavigationArrow";
import ContentWrapper from "../../../common/modules/wrappers/ContentWrapper";
import { AgentForm } from "../components/forms/agent/AgentForm";
import { createAgentActions } from "../ducks";
import { useAgentSearch } from "../utils";

export const AgentCreateContainer = () => {
  const dispatch = useDispatch();
  const actions = useMemo(
    () =>
      bindActionCreators(
        {
          createAgent: createAgentActions.request
        },
        dispatch
      ),
    [dispatch]
  );

  const agentSearch = useAgentSearch();

  useEffect(() => {
    return () => {
      agentSearch.onResultDelete();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <ContentWrapper>
      <Card
        className="card-box"
        title={
          <BackNavigationArrow>
            <h2>{t("agent.titles.create")}</h2>
          </BackNavigationArrow>
        }
      >
        <AgentForm onCreate={actions.createAgent} agentSearch={agentSearch} />
      </Card>
    </ContentWrapper>
  );
};
