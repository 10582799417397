import { Flex, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import { useTranslation } from "react-i18next";
import LabelWithTooltip from "../../../../../../../common/components/form/labels/LabelWithTooltip";
import { LeftCard } from "../../../../../../../common/components/left-card/LeftCard";
import Ellipsis from "../../../../../../../common/components/views/Ellipsis";
import { formatLocaleCurrency } from "../../../../../../../common/utils/formatUtils";
import { useToken } from "../../../../../../../common/utils/hooksUtils";
import { InsuranceCoverageType } from "../../../../../../contract/enums";
import { CalcResult, InsuranceCoverage } from "../../../../types";
import { VehicleCalcResultData } from "../../../types";
import { isGapCalcResult, isMtplCalcResult, isPasCalcResult } from "../../../utils";

type Props = {
  item: CalcResult<VehicleCalcResultData>;
};

export const AdditionalInformation = ({ item }: Props) => {
  const { t } = useTranslation();
  const { token } = useToken();

  if (isPasCalcResult(item) || isGapCalcResult(item)) {
    return (
      <LeftCard description={t("calc.vehicle.results.additionalInfo")}>
        <Flex style={{ height: "100%" }} justify="center" align="center">
          {t("calc.vehicle.results.noExtendedCoverages")}
        </Flex>
      </LeftCard>
    );
  }

  const getColumns = () => {
    const columns: ColumnsType<InsuranceCoverage> = [
      {
        key: "coverage",
        title: t("contract.enums.insuranceCoverageType._label"),
        width: 152,
        render: (_, record) =>
          record.note ? (
            <LabelWithTooltip label={t("contract.enums.insuranceCoverageType." + record.type)} tooltip={record.note} />
          ) : (
            <span>{t("contract.enums.insuranceCoverageType." + record.type)}</span>
          )
      },
      {
        key: "insuranceAmount",
        title: t("calc.vehicle.results.insuranceAmount"),
        width: 100,
        align: "right",
        ellipsis: { showTitle: false },
        render: (_, record) =>
          record.injuryLongTermEffectsInsuranceAmount && record.injuryDeathInsuranceAmount ? (
            <>
              <Ellipsis>
                {t("calc.vehicle.results.injuryLongTermEffectsInsuranceAmount")}:{" "}
                {formatLocaleCurrency(record.injuryLongTermEffectsInsuranceAmount)}
              </Ellipsis>
              <br />
              <Ellipsis>
                {t("calc.vehicle.results.injuryDeathInsuranceAmount")}:{" "}
                {formatLocaleCurrency(record.injuryDeathInsuranceAmount)}
              </Ellipsis>
            </>
          ) : record.gapDuration ? (
            <span className="no-break">{t("calc.vehicle.enums.gapDuration." + record.gapDuration)}</span>
          ) : record.replacementVehicleLimit ? (
            <Ellipsis>{record.replacementVehicleLimit}</Ellipsis>
          ) : record.insuranceAmountDesc ? (
            <Ellipsis>{record.insuranceAmountDesc}</Ellipsis>
          ) : (
            <>{formatLocaleCurrency(record.insuranceAmount)}</>
          )
      },
      {
        key: "complicity",
        title: t("calc.vehicle.results.complicity"),
        width: 90,
        align: "right",
        render: (_, record) =>
          record.type === InsuranceCoverageType.GAP
            ? record.gapComplicityReinsurance
              ? t("calc.vehicle.helpers.gapWithComplicityReinsurance")
              : t("calc.vehicle.helpers.gapWithoutComplicityReinsurance")
            : record.complicity
      }
    ];

    if (isMtplCalcResult(item)) {
      columns.push({
        key: "territorialValidity",
        title: t("calc.vehicle.enums.territorialValidity._label"),
        width: 115,
        align: "right",
        render: (_, record) =>
          record.territorialValidity ? (
            <span className="no-break">
              {t("calc.vehicle.enums.territorialValidity." + record.territorialValidity)}
            </span>
          ) : undefined
      });
    }

    columns.push({
      key: "availability",
      title: t("calc.vehicle.results.availability"),
      width: 85,
      align: "right",
      render: (_, record) => t("calc.vehicle.results." + (record.reinsured ? "reinsurance" : "inCoverage"))
    });

    return columns;
  };

  const dataSource = item.data?.appliedCoverages.map((coverage, index) => ({ ...coverage, id: index }));

  const hasAdditionalWarning = item.data?.additionalWarnings && item.data?.additionalWarnings.length > 0;

  const setScroll = () => {
    if (!dataSource) {
      return undefined;
    }

    return { y: hasAdditionalWarning ? 135 : 160 };
  };

  return (
    <div style={{ borderRadius: 8, border: `1px solid ${token.colorBorderSecondary}`, height: `100%` }}>
      <Table
        columns={getColumns()}
        rowKey="id"
        pagination={false}
        size="small"
        scroll={setScroll()}
        dataSource={dataSource}
      />
    </div>
  );
};
