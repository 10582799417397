import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { DEFAULT_LOCALE } from "../../app/i18n";
import { ContactType } from "../../common/modules/enums";
import { RootState } from "../../common/types";
import { formatAgentStructureIdNumber, formatIban, formatPhoneNumber } from "../../common/utils/formatUtils";
import { toDate } from "../../common/utils/formUtils";
import { selectIsRequestInProgress } from "../ducks";
import { InstitutionType } from "../institution/enums";
import { requests } from "./api";
import { deleteStateAgentSearchResultAction, searchAgentActions, selectAgentSearchResult } from "./ducks";
import { AgentCompetenceLevel, AgentCompetenceType, AgentType, FinancialSector } from "./enums";
import type {
  Agent,
  AgentBrokerNumber,
  AgentCompetence,
  AgentCompletedEducation,
  AgentDirectorate,
  AgentEducation,
  AgentLicense,
  AgentMentoring,
  AgentSearchProps,
  AgentSearchResult,
  CreateUpdateAgent,
  CreateUpdateAgentCompletedEducation,
  CreateUpdateAgentDirectorate,
  CreateUpdateAgentEducation,
  CreateUpdateAgentMentoring,
  CreateUpdateLegalAgent,
  CreateUpdateNaturalAgent,
  CreateUpdateSelfEmployedAgent,
  LegalAgent,
  NaturalAgent,
  SelfEmployedAgent
} from "./types";

export const useAgentSearch = (): AgentSearchProps => {
  const result = useSelector<RootState, AgentSearchResult>(selectAgentSearchResult);
  const inProgress = useSelector<RootState, boolean>(state => selectIsRequestInProgress(state, requests.SEARCH_AGENT));

  const dispatch = useDispatch();
  const actions = useMemo(
    () =>
      bindActionCreators(
        {
          onSearch: searchAgentActions.request,
          onResultDelete: deleteStateAgentSearchResultAction
        },
        dispatch
      ),
    [dispatch]
  );

  return { result, inProgress, ...actions };
};

export const sortAgentCompetences = (competences: AgentCompetence[]): AgentCompetence[] => {
  return [...competences].sort(
    (a, b) =>
      Object.keys(AgentCompetenceType).indexOf(a.type) - Object.keys(AgentCompetenceType).indexOf(b.type) ||
      Object.keys(FinancialSector).indexOf(a.sector) - Object.keys(FinancialSector).indexOf(b.sector) ||
      Object.keys(AgentCompetenceLevel).indexOf(a.level) - Object.keys(AgentCompetenceLevel).indexOf(b.level) ||
      a.label.localeCompare(b.label, DEFAULT_LOCALE, { sensitivity: "accent" })
  );
};

export const sortAgentLicenses = (licenses: AgentLicense[]): AgentLicense[] => {
  return [...licenses].sort(
    (a, b) =>
      Object.keys(FinancialSector).indexOf(a.sector) - Object.keys(FinancialSector).indexOf(b.sector) ||
      toDate(a.registrationProposalDate)?.diff(toDate(b.registrationProposalDate)) ||
      a.value?.localeCompare(b.value, DEFAULT_LOCALE, { sensitivity: "accent" })
  );
};

export const sortAgentBrokerNumbers = (brokerNumbers: AgentBrokerNumber[]): AgentBrokerNumber[] => {
  return [...brokerNumbers].sort(
    (a, b) =>
      Object.keys(InstitutionType).indexOf(a.institution.type) -
        Object.keys(InstitutionType).indexOf(b.institution.type) ||
      a.institution.name.localeCompare(b.institution.name, DEFAULT_LOCALE, { sensitivity: "accent" }) ||
      toDate(a.validFrom)?.diff(toDate(b.validFrom)) ||
      toDate(a.validTo)?.diff(toDate(b.validTo)) ||
      a.value.localeCompare(b.value, DEFAULT_LOCALE, { sensitivity: "accent" })
  );
};

export const convertAgentToCreateUpdateAgent = <T extends Agent>(agent: T): CreateUpdateAgent => {
  const agentBase = {
    parentId: agent.parent ? agent.parent.id : undefined,
    phone: formatPhoneNumber(agent.phone),
    bankAccountNumber: agent.bankAccountNumber ? formatIban(agent.bankAccountNumber) : undefined,
    contacts: agent.contacts.map(contact => ({
      ...contact,
      value: contact.type === ContactType.PHONE_NUMBER ? formatPhoneNumber(contact.value) : contact.value
    })),
    directorateId: agent.directorate?.id,
    structureIdNumber: formatAgentStructureIdNumber(agent.structureIdNumber)
  } as Partial<CreateUpdateAgent>;

  const { profilePicture, parent, attachments, historyRecords, createdBy, ...data } = agent;

  if (isLegalAgent(agent)) {
    return {
      ...data,
      ...agentBase,
      representativeIds: agent.representatives.map(r => r.id)
    } as CreateUpdateLegalAgent;
  } else if (isSelfEmployedAgent(agent)) {
    return {
      ...data,
      ...agentBase
    } as CreateUpdateSelfEmployedAgent;
  } else {
    return {
      ...data,
      ...agentBase
    } as CreateUpdateNaturalAgent;
  }
};

export const createPartialCreateUpdateAgent = (agent: Partial<Agent>): Partial<CreateUpdateAgent> => {
  const { profilePicture, parent, attachments, historyRecords, createdBy, ...data } = agent;

  let result: Partial<CreateUpdateAgent> = { ...data };

  if (data.type === AgentType.LEGAL) {
    result = {
      ...result,
      representativeIds: data.representatives?.map(r => r.id)
    } as Partial<CreateUpdateLegalAgent>;
  }

  return {
    ...result,
    parentId: agent.parent ? agent.parent.id : undefined,
    phone: agent.phone ? formatPhoneNumber(agent.phone) : undefined,
    bankAccountNumber: agent.bankAccountNumber ? formatIban(agent.bankAccountNumber) : undefined,
    contacts: agent.contacts?.map(contact => ({
      ...contact,
      value: contact.type === ContactType.PHONE_NUMBER ? formatPhoneNumber(contact.value) : contact.value
    })),
    directorateId: agent.directorate?.id,
    structureIdNumber: formatAgentStructureIdNumber(agent.structureIdNumber)
  };
};

export const convertAgentDirectorateToCreateUpdateObject = (
  directorate: AgentDirectorate
): CreateUpdateAgentDirectorate => {
  const { createdAt, updatedAt, id, ...data } = directorate;

  return { ...data };
};

export const convertAgentEducationToCreateUpdateObject = (education: AgentEducation): CreateUpdateAgentEducation => {
  const { createdAt, updatedAt, id, ...data } = education;

  return { ...data };
};

export const convertAgentCompletedEducationToCreateUpdateObject = (
  completedEducation: AgentCompletedEducation
): CreateUpdateAgentCompletedEducation => {
  const { createdAt, updatedAt, id, education, ...data } = completedEducation;

  return { ...data, educationId: education?.id };
};

export const convertAgentMentoringToCreateUpdateObject = (mentoring: AgentMentoring): CreateUpdateAgentMentoring => {
  const { createdAt, updatedAt, contractsCount, mentor, adept, id, ...data } = mentoring;

  return { ...data, mentorId: mentor.id, adeptId: adept.id };
};

export const isNaturalAgent = (agent: Agent): agent is NaturalAgent => agent.type === AgentType.NATURAL;

export const isSelfEmployedAgent = (agent: Agent): agent is SelfEmployedAgent => agent.type === AgentType.SELF_EMPLOYED;

export const isLegalAgent = (agent: Agent): agent is LegalAgent => agent.type === AgentType.LEGAL;
