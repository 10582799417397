import { Alert, ConfigProvider } from "antd";
import { useTranslation } from "react-i18next";
import { HttpStatus } from "../../../../../common/api/http";
import FieldViolationsView from "../../../../../common/components/views/FieldViolationsView";
import MultiLineText from "../../../../../common/components/views/MultiLineText";
import { cssVariables } from "../../../../../common/utils/utils";
import { CalcType } from "../../../enums";
import { CalcResult, CalcResultData } from "../../types";
import { isVehicleCalcType } from "../../utils";

type Props<T extends CalcResultData> = {
  item: CalcResult<T>;
};

export const ErrorAlert = <T extends CalcResultData>({ item }: Props<T>) => {
  const { t } = useTranslation();

  const getRootPath = () => {
    if (isVehicleCalcType(item.calcType)) {
      return "calc.vehicle.attrs";
    } else if (item.calcType === CalcType.REALTY) {
      return "calc.realty.attrs";
    } else {
      return "calc.travel.attrs";
    }
  };

  let violations = item.error?.violations;

  if (violations?.some(violation => violation.fieldPath.startsWith("clientsData.clients"))) {
    violations = violations?.filter(violation => !violation.fieldPath.startsWith("clientsData.clients"));

    violations?.unshift({
      fieldPath: isVehicleCalcType(item.calcType) ? "clientsData.holder" : "clientsData.policyHolder",
      errors: [t("calc.validations.clientViolationError")],
      fieldValue: undefined
    });
  }

  return (
    <ConfigProvider
      theme={{
        components: { Alert: { withDescriptionPadding: "0 0 18px" } },
        token: { colorTextHeading: cssVariables.colorRed, colorText: cssVariables.colorRed }
      }}
    >
      <Alert
        style={{ paddingBottom: 0 }}
        type="error"
        showIcon={false}
        banner
        message={item.error?.message}
        description={
          item.error?.status === HttpStatus.UNPROCESSABLE_ENTITY ? (
            <FieldViolationsView violations={violations} rootPath={getRootPath()} />
          ) : (
            <MultiLineText tokens={item.error?.errors} />
          )
        }
      />
    </ConfigProvider>
  );
};
