import { Button, Flex, Space, Tag } from "antd";
import { useTranslation } from "react-i18next";
import AntIcon from "../../../../../../../common/components/icons/AntIcon";
import Ellipsis from "../../../../../../../common/components/views/Ellipsis";
import { formatLocaleCurrency } from "../../../../../../../common/utils/formatUtils";
import { cssVariables } from "../../../../../../../common/utils/utils";
import { resolveInstitutionLogo } from "../../../../../../institution/utils";
import { ErrorAlert } from "../../../../components/calc-result/ErrorAlert";
import { RecommendationButton } from "../../../../components/calc-result/RecommendationButton";
import { CalcResult } from "../../../../types";
import { RealtyCalcResultData } from "../../../types";

type Props = {
  item: CalcResult<RealtyCalcResultData>;
  rotate: VoidFunction;
  handleItemRecommendation: (itemId: string, activation: boolean) => void;
};

export const RealtyCalcResultInfo = ({ item, rotate, handleItemRecommendation }: Props) => {
  const { t } = useTranslation();

  return (
    <Flex gap="middle" style={{ height: "100%" }}>
      <div style={{ marginTop: 10 }}>
        <img
          width="100"
          height="60"
          style={{ objectFit: "contain" }}
          src={resolveInstitutionLogo(item.insuranceInstitution)}
          alt="Institution Logo"
        />
      </div>

      <Flex justify="space-between" vertical flex={1} style={{ minWidth: 0 }}>
        <div>
          <Flex gap="middle" style={{ marginBottom: 8 }}>
            <h2>
              {item.insuranceInstitution.name} {item.coverage}
            </h2>

            <RecommendationButton item={item} handleItemRecommendation={handleItemRecommendation} />
          </Flex>

          <div style={{ maxHeight: 100, overflowY: "auto" }}>
            {item.error ? (
              <ErrorAlert item={item} />
            ) : (
              <>
                <b className="margin-right-tiny">{t("contract.enums.insuranceCoverageType._label")}</b>

                {item.data?.realtyInsuranceAmount && (
                  <div>
                    <span style={{ display: "inline-block", width: 190 }}>
                      {t("calc.realty.results.realtyInsuranceAmount")}:
                    </span>
                    <b style={{ width: 110, textAlign: "right", display: "inline-block" }}>
                      {formatLocaleCurrency(item.data.realtyInsuranceAmount)}
                    </b>
                  </div>
                )}

                {item.data?.householdInsuranceAmount && (
                  <div>
                    <span style={{ display: "inline-block", width: 190 }}>
                      {t("calc.realty.results.householdInsuranceAmount")}:
                    </span>
                    <b style={{ width: 110, textAlign: "right", display: "inline-block" }}>
                      {formatLocaleCurrency(item.data.householdInsuranceAmount)}
                    </b>
                  </div>
                )}

                {item.data?.warnings.length ? (
                  <Tag
                    style={{ minWidth: 0, display: "block", paddingRight: 24, marginTop: 8 }}
                    icon={<AntIcon type="exclamation" />}
                    color="warning"
                  >
                    <Ellipsis style={{ color: cssVariables.colorYellow }}>{item.data?.warnings.join(" ")}</Ellipsis>
                  </Tag>
                ) : undefined}
              </>
            )}
          </div>
        </div>

        <Space.Compact block>
          <Button style={{ flex: 1 }} disabled={!!item.error} onClick={rotate}>
            {t("calc.travel.results.additionalInfo")}
          </Button>
          <Button style={{ flex: 1 }} disabled={!!item.error} onClick={rotate}>
            {t("common.documents")}
          </Button>
        </Space.Compact>
      </Flex>
    </Flex>
  );
};
