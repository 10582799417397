import { Button } from "antd";
import classNames from "classnames";
import AntIcon from "../icons/AntIcon";
import styles from "./FlipCard.module.scss";

type Props = {
  frontCard: React.ReactNode;
  backCard: React.ReactNode;
  isRotated: boolean;
  closeRotation?: VoidFunction;
  height?: number;
};

const CLOSE_BUTTON_ID = "close-flip-card";

export const FlipCard = ({ frontCard, backCard, isRotated, closeRotation, height = 250 }: Props) => {
  return (
    <div className={styles.flipContent} style={{ height }}>
      <div className={classNames(styles.flipCard, { [styles.flipCardFrontRotate as string]: isRotated })}>
        {frontCard}
      </div>
      <div
        className={classNames(styles.flipCard, styles.flipCardBack, {
          [styles.flipCardBackRotate as string]: isRotated
        })}
        onMouseLeave={event => {
          if (event.relatedTarget && "id" in event.relatedTarget && event.relatedTarget.id !== CLOSE_BUTTON_ID) {
            closeRotation?.();
          }
        }}
      >
        <Button
          id={CLOSE_BUTTON_ID}
          className={styles.closeButton}
          type="text"
          icon={<AntIcon type="close" />}
          onClick={closeRotation}
        />
        {backCard}
      </div>
    </div>
  );
};
