import { Button, Divider, Popconfirm, Table, TablePaginationConfig } from "antd";
import { ColumnsType } from "antd/lib/table";
import dayjs from "dayjs";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { useDeleteAgentMentoring, useFilterAgentMentorings } from "../../../../../common/api/queries/agentMentorings";
import LabelWithPopover from "../../../../../common/components/form/labels/LabelWithPopover";
import ActionTextIcon from "../../../../../common/components/icons/ActionTextIcon";
import AntIcon from "../../../../../common/components/icons/AntIcon";
import PopconfirmDeleteIcon from "../../../../../common/components/icons/PopconfirmDeleteIcon";
import Ellipsis from "../../../../../common/components/views/Ellipsis";
import { formatAgentName, formatLocaleDate } from "../../../../../common/utils/formatUtils";
import { useFilterSearchParams } from "../../../../../common/utils/hooksUtils";
import { QueryKeys, URLQuery } from "../../../../../common/utils/queryUtils";
import { cssVariables, numberOrZero, tableStandardProps } from "../../../../../common/utils/utils";
import { CONTRACT_ROUTE_PATHS } from "../../../../contract/paths";
import { selectAllAgentsEnums, selectInstitutionsEnums } from "../../../../enumerations/ducks";
import { InstitutionType } from "../../../../institution/enums";
import { AgentType } from "../../../enums";
import { AgentMentoring } from "../../../types";
import { AgentMentoringModalForm } from "../../forms/AgentMentoringModalForm";

type Props = {
  agentId: string;
  showActions?: boolean;
};

export const AgentMentoringTableView = ({ agentId, showActions }: Props) => {
  const { t } = useTranslation();

  const institutionsEnums = useSelector(selectInstitutionsEnums);
  const agentEnums = useSelector(selectAllAgentsEnums);

  const navigate = useNavigate();
  const searchParams = useFilterSearchParams();

  const [isOpenModal, setIsOpenModal] = useState(false);
  const [mentoring, setMentoring] = useState<AgentMentoring>();

  const { data, isLoading } = useFilterAgentMentorings({ agentId, ...searchParams });
  const deleteAgentMentoring = useDeleteAgentMentoring();

  const handleCreateAgentMentoring = () => {
    setIsOpenModal(true);
  };

  const handleCancelModal = () => {
    setIsOpenModal(false);
    setMentoring(undefined);
  };

  const handleChangeAgentMentoring = (mentoring: AgentMentoring) => {
    setMentoring(mentoring);
    setIsOpenModal(true);
  };

  const handleTableChange = (pagination: TablePaginationConfig) => {
    navigate(
      {
        search: URLQuery.searchParamsToString({
          searchParams: {
            pageIndex: pagination.current ? pagination.current - 1 : undefined
          },
          append: true
        })
      },
      { replace: true }
    );
  };

  const getColumns = () => {
    const columns: ColumnsType<AgentMentoring> = [
      {
        key: "mentor",
        title: t("agent.mentoring.attrs.mentor"),
        width: 200,
        ellipsis: { showTitle: false },
        render: (_, record) => <Ellipsis>{formatAgentName(record.mentor)}</Ellipsis>
      },
      {
        key: "adept",
        title: t("agent.mentoring.attrs.adept"),
        width: 200,
        ellipsis: { showTitle: false },
        render: (_, record) => <Ellipsis>{formatAgentName(record.adept)}</Ellipsis>
      },
      {
        key: "startDate",
        title: t("agent.mentoring.attrs.startDate"),
        width: 105,
        render: (_, record) => formatLocaleDate(record.startDate)
      },
      {
        key: "endDate",
        title: t("agent.mentoring.attrs.endDate"),
        width: 105,
        render: (_, record) => formatLocaleDate(record.endDate)
      },
      {
        key: "contractsCount",
        title: (
          <LabelWithPopover
            label={t("agent.mentoring.attrs.contractsCount")}
            popoverContent={t("agent.mentoring.helpers.contractCountHint")}
          />
        ),
        align: "right",
        width: 120,
        render: (_, record) => {
          let signerIds: string[] | undefined = [record.adept.id];

          if (record.adept.type === AgentType.LEGAL) {
            signerIds = agentEnums
              .find(agentEnum => agentEnum.id === record.adept.id)
              ?.representatives?.map(representative => representative.id);
          }

          return (
            <>
              {record.contractsCount}
              {record.contractsCount ? (
                <Link
                  style={{ marginLeft: 8 }}
                  to={`${CONTRACT_ROUTE_PATHS.list.to}${URLQuery.searchParamsToString({
                    searchParams: {
                      [QueryKeys.SIGNER_IDS]: signerIds,
                      [QueryKeys.INSTITUTION_IDS]: institutionsEnums
                        .filter(institutionEnum => institutionEnum.type === InstitutionType.SECURITIES_BROKER)
                        .map(institutionEnum => institutionEnum.id),
                      [QueryKeys.EFFECTIVE_BEGINNING_DATE_OR_SIGN_DATE_MIN]: record.startDate,
                      [QueryKeys.EFFECTIVE_BEGINNING_DATE_OR_SIGN_DATE_MAX]: record.endDate
                    }
                  })}`}
                  target="_blank"
                >
                  <AntIcon type="eye" />
                </Link>
              ) : undefined}
            </>
          );
        }
      },
      {
        key: "completed",
        title: t("agent.mentoring.attrs.completed"),
        width: 80,
        align: "center",
        render: (_, record) => <AntIcon type={record.completed ? "check" : "close"} />
      },
      {
        key: "active",
        title: t("agent.mentoring.helpers.isMentoringActive"),
        width: 80,
        align: "center",
        render: (_, record) => {
          const isActive = dayjs().isBetween(record.startDate, record.endDate, "days", "[]");

          return isActive ? (
            <AntIcon type="check-circle-two-tone" twoToneColor={cssVariables.colorGreen} />
          ) : (
            <AntIcon type="close-circle-two-tone" twoToneColor={cssVariables.colorRed} />
          );
        }
      }
    ];

    if (showActions) {
      columns.push({
        key: "actions",
        align: "right",
        fixed: "right",
        width: 180,
        render: (_, record) => (
          <>
            <ActionTextIcon
              icon="edit"
              color="blue"
              text={t("common.edit")}
              onClick={() => handleChangeAgentMentoring(record)}
            />

            <Divider type="vertical" />

            <Popconfirm
              title={t("agent.mentoring.helpers.deleteConfirm")}
              icon={<PopconfirmDeleteIcon />}
              cancelText={t("common.no")}
              okText={t("common.yes")}
              okType="danger"
              onConfirm={() => deleteAgentMentoring.mutate(record.id)}
            >
              <span>
                <ActionTextIcon icon="delete" color="red" text={t("common.delete")} />
              </span>
            </Popconfirm>
          </>
        )
      });
    }

    return columns;
  };

  return (
    <>
      <Table<AgentMentoring>
        {...tableStandardProps()}
        loading={isLoading}
        columns={getColumns()}
        dataSource={data?.pageData}
        pagination={{
          ...data,
          current: numberOrZero(data?.pageIndex) + 1,
          pageSize: data?.pageSize,
          total: data?.totalElementsCount
        }}
        onChange={handleTableChange}
      />

      {showActions ? (
        <>
          <div className={data?.pageData?.length ? "table-footer-margin" : "margin-top-small"}>
            <Button
              size="small"
              type="primary"
              onClick={() => handleCreateAgentMentoring()}
              icon={<AntIcon type="plus" />}
            >
              {t("common.add")}
            </Button>
          </div>

          <AgentMentoringModalForm
            isOpen={isOpenModal}
            onCancel={handleCancelModal}
            agentId={agentId}
            mentoring={mentoring}
          />
        </>
      ) : undefined}
    </>
  );
};
