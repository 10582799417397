import { useQuery } from "@tanstack/react-query";
import api from "../../../modules/agent/api";

export const AGENT_QUERY_KEYS = {
  getPartnerIdNumber: () => ["getPartnerIdNumber"]
};

export const useGetAgentPartnerIdNumber = () => {
  return useQuery({
    queryKey: AGENT_QUERY_KEYS.getPartnerIdNumber(),
    queryFn: async () => {
      const { data } = await api.getAgentPartnerIdNumber();

      return data;
    },
    staleTime: 0
  });
};
