import { AxiosPromise } from "axios";
import { ApiRequestAdapter } from "../../../common/api/ApiRequestAdapter";
import { apiService } from "../../../common/api/apiService";
import { EntityIdObject, EntityObject, TwoLevelEntityIdObject, TwoLevelEntityObject } from "../../../common/types";
import {
  CommissionsSettings,
  CommissionsSettingsAgentProfile,
  CommissionsSettingsAttachment,
  CommissionsSettingsRule,
  CreateCommissionsSettingsRule,
  UpdateCommissionsSettings,
  UpdateCommissionsSettingsRule
} from "./types";

const GET_COMMISSIONS_SETTINGS = ApiRequestAdapter.create("/agents/{0}/commissions-settings", "GET");
const UPDATE_COMMISSIONS_SETTINGS = ApiRequestAdapter.create("/agents/{0}/commissions-settings", "PUT");
const CREATE_COMMISSIONS_SETTINGS_RULE = ApiRequestAdapter.create("/agents/{0}/commissions-settings/rules", "POST");
const UPDATE_COMMISSIONS_SETTINGS_RULE = ApiRequestAdapter.create("/agents/{0}/commissions-settings/rules/{1}", "PUT");
const DELETE_COMMISSIONS_SETTINGS_RULE = ApiRequestAdapter.create(
  "/agents/{0}/commissions-settings/rules/{1}",
  "DELETE"
);
const UPLOAD_COMMISSIONS_SETTINGS_ATTACHMENTS = ApiRequestAdapter.create(
  "/agents/{0}/commissions-settings/attachments",
  "POST"
);
const DOWNLOAD_COMMISSIONS_SETTINGS_ATTACHMENT = ApiRequestAdapter.create(
  "/agents/{0}/commissions-settings/attachments/{1}",
  "GET"
);
const DELETE_COMMISSIONS_SETTINGS_ATTACHMENT = ApiRequestAdapter.create(
  "/agents/{0}/commissions-settings/attachments/{1}",
  "DELETE"
);

export const requests = {
  GET_COMMISSIONS_SETTINGS,
  UPDATE_COMMISSIONS_SETTINGS,
  CREATE_COMMISSIONS_SETTINGS_RULE,
  UPDATE_COMMISSIONS_SETTINGS_RULE,
  DELETE_COMMISSIONS_SETTINGS_RULE,
  UPLOAD_COMMISSIONS_SETTINGS_ATTACHMENTS,
  DOWNLOAD_COMMISSIONS_SETTINGS_ATTACHMENT,
  DELETE_COMMISSIONS_SETTINGS_ATTACHMENT
};

const api = {
  getCommissionsSettings: (
    request: EntityIdObject
  ): AxiosPromise<CommissionsSettings | CommissionsSettingsAgentProfile> => {
    return apiService.get(GET_COMMISSIONS_SETTINGS.getParametrizedUrl(request.id));
  },
  updateCommissionsSettings: (request: EntityObject<UpdateCommissionsSettings>): AxiosPromise<CommissionsSettings> => {
    return apiService.put(UPDATE_COMMISSIONS_SETTINGS.getParametrizedUrl(request.id), request.object);
  },
  createCommissionsSettingsRule: (
    request: EntityObject<CreateCommissionsSettingsRule>
  ): AxiosPromise<CommissionsSettingsRule> => {
    return apiService.post(CREATE_COMMISSIONS_SETTINGS_RULE.getParametrizedUrl(request.id), request.object);
  },
  updateCommissionsSettingsRule: (
    request: TwoLevelEntityObject<UpdateCommissionsSettingsRule>
  ): AxiosPromise<CommissionsSettingsRule> => {
    return apiService.put(
      UPDATE_COMMISSIONS_SETTINGS_RULE.getParametrizedUrl(request.id1, request.id2),
      request.object
    );
  },
  deleteCommissionsSettingsRule: (request: TwoLevelEntityIdObject): AxiosPromise => {
    return apiService.delete(DELETE_COMMISSIONS_SETTINGS_RULE.getParametrizedUrl(request.id1, request.id2));
  },
  uploadCommissionsSettingsAttachments: (
    request: EntityObject<FormData>
  ): AxiosPromise<CommissionsSettingsAttachment[]> => {
    return apiService.post(UPLOAD_COMMISSIONS_SETTINGS_ATTACHMENTS.getParametrizedUrl(request.id), request.object, {
      headers: { "Content-Type": "multipart/form-data" }
    });
  },
  downloadCommissionsSettingsAttachment: (request: TwoLevelEntityIdObject): AxiosPromise<Blob> => {
    return apiService.get(DOWNLOAD_COMMISSIONS_SETTINGS_ATTACHMENT.getParametrizedUrl(request.id1, request.id2), {
      responseType: "blob"
    });
  },
  deleteCommissionsSettingsAttachment: (request: TwoLevelEntityIdObject): AxiosPromise => {
    return apiService.delete(DELETE_COMMISSIONS_SETTINGS_ATTACHMENT.getParametrizedUrl(request.id1, request.id2));
  }
};

export default api;
