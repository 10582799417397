import { Flex, Form, InputNumber, Select, Space } from "antd";
import { FormInstance } from "antd/lib/form";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import ActionButton from "../../../../../common/components/buttons/ActionButton";
import InputAddon from "../../../../../common/components/form/addons/InputAddon";
import { RootState } from "../../../../../common/types";
import { inputNumberDecimalStandardProps, selectStandardProps } from "../../../../../common/utils/formUtils";
import { validations } from "../../../../../common/utils/validationUtils";
import { selectUserAccount } from "../../../../auth/ducks";
import AgentSelect from "../../../../enumerations/components/form/AgentSelect";
import type { UserAccount } from "../../../../user/types";
import type { GenGainersData } from "../../types";
import styles from "./GainersCommissionRateDivisionForm.module.scss";

type Props = {
  form: FormInstance<GenGainersData>;
  allowOtherOption?: boolean;
};

enum CommissionRateRatio {
  _100_ = "_100_",
  _0_100_ = "_0_100_",
  _50_50_ = "_50_50_",
  _0_50_50_ = "_0_50_50_",
  OTHER = "OTHER"
}

export const GainersCommissionRateDivisionForm = ({ form, allowOtherOption }: Props) => {
  const { t } = useTranslation();
  const [rateRatio, setRateRatio] = useState<CommissionRateRatio>(CommissionRateRatio._100_);
  const [gainersCount, setGainersCount] = useState(1);

  const userAccount = useSelector<RootState, UserAccount | undefined>(selectUserAccount);

  const handleRemoveGainer = (gainerNumber: number) => {
    setGainersCount(gainerNumber - 1);

    form.setFieldValue(`gainer${gainerNumber}Id` as keyof GenGainersData, undefined);
    form.setFieldValue(`gainer${gainerNumber}Rate` as keyof GenGainersData, undefined);

    if (gainerNumber === 2) {
      form.setFieldValue("gainer1Rate", 100);
    }
  };

  useEffect(() => {
    switch (rateRatio) {
      case CommissionRateRatio._100_:
        setGainersCount(1);
        form.setFieldValue("gainer1Rate", 100);
        form.setFieldValue("gainer2Rate", undefined);
        form.setFieldValue("gainer3Rate", undefined);
        break;
      case CommissionRateRatio._0_100_:
        setGainersCount(2);
        form.setFieldValue("gainer1Rate", 0);
        form.setFieldValue("gainer2Rate", 100);
        form.setFieldValue("gainer3Rate", undefined);
        break;
      case CommissionRateRatio._50_50_:
        setGainersCount(2);
        form.setFieldValue("gainer1Rate", 50);
        form.setFieldValue("gainer2Rate", 50);
        form.setFieldValue("gainer3Rate", undefined);
        break;
      case CommissionRateRatio._0_50_50_:
        setGainersCount(3);
        form.setFieldValue("gainer1Rate", 0);
        form.setFieldValue("gainer2Rate", 50);
        form.setFieldValue("gainer3Rate", 50);
        break;
      case CommissionRateRatio.OTHER:
        break;
      default:
        setGainersCount(1);
        form.setFieldValue("gainer1Rate", 100);
    }
  }, [rateRatio]);

  return (
    <div>
      <span style={{ paddingRight: 8 }}>{t("commissions.enums.commissionRateRatio._label")}</span>
      <Select<CommissionRateRatio>
        {...selectStandardProps}
        className={styles.rateSelect}
        value={rateRatio}
        onChange={setRateRatio}
        options={Object.values(CommissionRateRatio)
          .filter(value => allowOtherOption || value !== CommissionRateRatio.OTHER)
          .map(value => ({
            value,
            label: t(`commissions.enums.commissionRateRatio.${value}`)
          }))}
      />
      <div className={styles.gainersBox}>
        <Form form={form} layout="vertical">
          {Array.from({ length: gainersCount }, (_, index) => index + 1).map(i => {
            return (
              <Flex gap="middle" key={i}>
                <AgentSelect
                  formItemProps={{
                    name: `gainer${i}Id`,
                    style: { flex: 1 },
                    label: t(`contract.attrs.gainerRecords.gainer${i}Id`),
                    rules: [validations.notNull, validations.noRepeatedGainer(gainersCount)],
                    initialValue: i === 1 && userAccount?.agent?.canBeGainer ? userAccount.agent.id : undefined,
                    dependencies: Array.from({ length: gainersCount }, (_, index) => index + 1)
                      .filter(value => i !== value)
                      .map(value => `gainer${value}Id`)
                  }}
                  optionsProps={{
                    useAllAgents: true,
                    filter: agentOption => !!agentOption.canBeGainer
                  }}
                />
                <div style={{ flex: 1 }}>
                  {gainersCount > 1 ? (
                    <Form.Item
                      name={`gainer${i}Rate`}
                      label={t(`contract.attrs.gainerRecords.gainer${i}Rate`)}
                      dependencies={Array.from({ length: gainersCount }, (_, index) => index + 1)
                        .filter(value => i !== value)
                        .map(value => `gainer${value}Rate`)}
                      rules={[
                        validations.notNull,
                        validations.minNumber(0),
                        validations.maxNumber(100),
                        validations.gainersRateSumOneHundred(gainersCount)
                      ]}
                      validateDebounce={350}
                      initialValue={i === 1 ? 100 : undefined}
                    >
                      <InputNumber
                        {...inputNumberDecimalStandardProps}
                        addonAfter={<InputAddon type="percentage" />}
                        disabled={gainersCount === 1 || rateRatio !== CommissionRateRatio.OTHER}
                      />
                    </Form.Item>
                  ) : undefined}
                </div>
              </Flex>
            );
          })}
        </Form>

        {rateRatio === CommissionRateRatio.OTHER ? (
          <Space>
            <ActionButton icon="plus" disabled={gainersCount > 2} onClick={() => setGainersCount(gainersCount + 1)}>
              {t("contract.actions.addGainer")}
            </ActionButton>
            <ActionButton icon="minus" disabled={gainersCount < 2} onClick={() => handleRemoveGainer(gainersCount)}>
              {t("contract.actions.removeGainer")}
            </ActionButton>
          </Space>
        ) : undefined}
      </div>
    </div>
  );
};
