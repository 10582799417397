import { Button, Card, Input } from "antd";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { bindActionCreators } from "redux";
import AntIcon from "../../../common/components/icons/AntIcon";
import ContentWrapper from "../../../common/modules/wrappers/ContentWrapper";
import { useFilterSearchParams } from "../../../common/utils/hooksUtils";
import { URLQuery } from "../../../common/utils/queryUtils";
import { getEnumerationsActions } from "../../enumerations/ducks";
import { AgentDirectorateModalForm } from "../components/forms/AgentDirectorateModalForm";
import { AgentDirectorateTableView } from "../components/views/directorate/AgentDirectorateTableView";
import { AgentDirectorate } from "../types";

export const AgentDirectorateContainer = () => {
  const { t } = useTranslation();

  const [isOpenModalForm, setIsOpenModalForm] = useState(false);
  const [directorate, setDirectorate] = useState<AgentDirectorate>();

  const navigate = useNavigate();
  const keywordSearchParam = useFilterSearchParams().keyword;

  const dispatch = useDispatch();
  const actions = useMemo(
    () => bindActionCreators({ getEnumerations: getEnumerationsActions.request }, dispatch),
    [dispatch]
  );

  const handleCancelModalForm = () => {
    setDirectorate(undefined);
    setIsOpenModalForm(false);
    actions.getEnumerations();
  };

  const handleClickAddAgentDirectorate = () => {
    setDirectorate(undefined);
    setIsOpenModalForm(true);
  };

  const handleChangeAgentDirectorate = (directorate: AgentDirectorate) => {
    setDirectorate(directorate);
    setIsOpenModalForm(true);
  };

  const handleSearchSubmit = (value: string) => {
    navigate(
      {
        search: URLQuery.searchParamsToString({ searchParams: { keyword: value }, append: true })
      },
      { replace: true }
    );
  };

  return (
    <ContentWrapper>
      <Card
        className="card-box"
        title={<h2>{t("agent.directorates.titles.admin")}</h2>}
        extra={
          <>
            <Button type="primary" icon={<AntIcon type="plus" />} onClick={handleClickAddAgentDirectorate}>
              {t("agent.actions.addAgentDirectorate")}
            </Button>

            <Input.Search
              style={{ width: 350 }}
              enterButton
              allowClear
              placeholder={t("agent.directorates.helpers.searchHint")}
              defaultValue={keywordSearchParam ?? undefined}
              onSearch={handleSearchSubmit}
            />
          </>
        }
      >
        <AgentDirectorateTableView onChange={handleChangeAgentDirectorate} />

        <AgentDirectorateModalForm
          isOpen={isOpenModalForm}
          directorate={directorate}
          onCancel={handleCancelModalForm}
        />
      </Card>
    </ContentWrapper>
  );
};
