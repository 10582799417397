import { AxiosPromise } from "axios";
import { ApiRequestAdapter } from "../../../common/api/ApiRequestAdapter";
import { apiService } from "../../../common/api/apiService";
import { EntityIdObject, EntityObject, TwoLevelEntityIdObject, TwoLevelEntityObject } from "../../../common/types";
import { serializeParams } from "../../../common/utils/apiUtils";
import {
  BailAccountMovement,
  BailAccountMovementFilterPageRequest,
  BailAccountMovementFilterPageResult,
  BailAccountSettings,
  BailAccountsReportFilterPageRequest,
  BailAccountsReportFilterPageResult,
  CreateUpdateBailAccountMovement,
  CreateUpdateBailAccountSettings
} from "./types";

const GET_BAIL_ACCOUNTS_REPORT = ApiRequestAdapter.create("/bail-accounts-report", "GET");

const CREATE_BAIL_ACCOUNT_SETTINGS = ApiRequestAdapter.create("/agents/{0}/bail-account/settings", "POST");
const UPDATE_BAIL_ACCOUNT_SETTINGS = ApiRequestAdapter.create("/agents/{0}/bail-account/settings", "PUT");
const DELETE_BAIL_ACCOUNT_SETTINGS = ApiRequestAdapter.create("/agents/{0}/bail-account/settings", "DELETE");

const FILTER_BAIL_ACCOUNT_MOVEMENTS = ApiRequestAdapter.create("/agents/{0}/bail-account/movements", "GET");
const CREATE_BAIL_ACCOUNT_MOVEMENT = ApiRequestAdapter.create("/agents/{0}/bail-account/movements", "POST");
const UPDATE_BAIL_ACCOUNT_MOVEMENT = ApiRequestAdapter.create("/agents/{0}/bail-account/movements/{1}", "PUT");
const DELETE_BAIL_ACCOUNT_MOVEMENT = ApiRequestAdapter.create("/agents/{0}/bail-account/movements/{1}", "DELETE");

export const requests = {
  GET_BAIL_ACCOUNTS_REPORT,
  CREATE_BAIL_ACCOUNT_SETTINGS,
  UPDATE_BAIL_ACCOUNT_SETTINGS,
  DELETE_BAIL_ACCOUNT_SETTINGS,
  FILTER_BAIL_ACCOUNT_MOVEMENTS,
  CREATE_BAIL_ACCOUNT_MOVEMENT,
  UPDATE_BAIL_ACCOUNT_MOVEMENT,
  DELETE_BAIL_ACCOUNT_MOVEMENT
};

const api = {
  getBailAccountsReport: (
    request: BailAccountsReportFilterPageRequest
  ): AxiosPromise<BailAccountsReportFilterPageResult> => {
    return apiService.get(GET_BAIL_ACCOUNTS_REPORT.url, {
      params: request,
      paramsSerializer: { serialize: serializeParams }
    });
  },
  createBailAccountSettings: (
    request: EntityObject<CreateUpdateBailAccountSettings>
  ): AxiosPromise<BailAccountSettings> => {
    return apiService.post(CREATE_BAIL_ACCOUNT_SETTINGS.getParametrizedUrl(request.id), request.object);
  },
  updateBailAccountSettings: (
    request: EntityObject<CreateUpdateBailAccountSettings>
  ): AxiosPromise<BailAccountSettings> => {
    return apiService.put(UPDATE_BAIL_ACCOUNT_SETTINGS.getParametrizedUrl(request.id), request.object);
  },
  deleteBailAccountSettings: (request: EntityIdObject): AxiosPromise => {
    return apiService.delete(DELETE_BAIL_ACCOUNT_SETTINGS.getParametrizedUrl(request.id));
  },
  filterBailAccountMovements: (
    request: EntityObject<BailAccountMovementFilterPageRequest>
  ): AxiosPromise<BailAccountMovementFilterPageResult> => {
    return apiService.get(FILTER_BAIL_ACCOUNT_MOVEMENTS.getParametrizedUrl(request.id), {
      params: request.object
    });
  },
  createBailAccountMovement: (
    request: EntityObject<CreateUpdateBailAccountMovement>
  ): AxiosPromise<BailAccountMovement> => {
    return apiService.post(CREATE_BAIL_ACCOUNT_MOVEMENT.getParametrizedUrl(request.id), request.object);
  },
  updateBailAccountMovement: (
    request: TwoLevelEntityObject<CreateUpdateBailAccountMovement>
  ): AxiosPromise<BailAccountMovement> => {
    return apiService.put(UPDATE_BAIL_ACCOUNT_MOVEMENT.getParametrizedUrl(request.id1, request.id2), request.object);
  },
  deleteBailAccountMovement: (request: TwoLevelEntityIdObject): AxiosPromise => {
    return apiService.delete(DELETE_BAIL_ACCOUNT_MOVEMENT.getParametrizedUrl(request.id1, request.id2));
  }
};

export default api;
