import { Skeleton } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectIsNotFoundErrorResponse } from "../../../modules/ducks";
import type { ApiRequest } from "../../api/ApiRequestAdapter";
import NotFound from "../../pages/NotFound/NotFound";
import type { RootState } from "../../types";

interface Props {
  children: React.ReactNode;
  itemLoaded: boolean;
  notFoundCheckRequest?: ApiRequest;
}

const DisplayWrapper = (props: Props) => {
  const [isNotFoundError, setIsNotFoundError] = useState(false);

  const itemNotFound = useSelector<RootState, any>(state =>
    props.notFoundCheckRequest ? selectIsNotFoundErrorResponse(state, props.notFoundCheckRequest) : false
  );

  useEffect(() => {
    if (itemNotFound) {
      setIsNotFoundError(true);
    }
  }, [itemNotFound]);

  if (props.itemLoaded) {
    return <>{props.children}</>;
  }

  if (isNotFoundError) {
    return <NotFound />;
  }

  return (
    <>
      <Skeleton active paragraph={{ rows: 6 }} />
      <Skeleton active paragraph={{ rows: 3 }} />
      <Skeleton active paragraph={{ rows: 2 }} />
    </>
  );
};

export default DisplayWrapper;
