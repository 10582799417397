import { AxiosPromise } from "axios";
import { ApiRequestAdapter } from "../../common/api/ApiRequestAdapter";
import { apiService } from "../../common/api/apiService";
import { EntityIdObject, EntityObject } from "../../common/types";
import { serializeParams } from "../../common/utils/apiUtils";
import {
  CreateUpdateInstitution,
  Institution,
  InstitutionFilterExportRequest,
  InstitutionFilterPageRequest,
  InstitutionFilterPageResult,
  InstitutionSettings,
  UpdateInstitutionSettings
} from "./types";

const FILTER_INSTITUTIONS = ApiRequestAdapter.create("/institutions", "GET");
const DOWNLOAD_INSTITUTIONS_EXPORT = ApiRequestAdapter.create("/institutions/export", "GET");
const GET_INSTITUTION = ApiRequestAdapter.create("/institutions/{0}", "GET");
const CREATE_INSTITUTION = ApiRequestAdapter.create("/institutions", "POST");
const UPDATE_INSTITUTION = ApiRequestAdapter.create("/institutions/{0}", "PUT");
const UPDATE_INSTITUTION_SETTINGS = ApiRequestAdapter.create("/institutions/{0}/settings", "PUT");
const DELETE_INSTITUTION = ApiRequestAdapter.create("/institutions/{0}", "DELETE");

export const requests = {
  FILTER_INSTITUTIONS,
  DOWNLOAD_INSTITUTIONS_EXPORT,
  GET_INSTITUTION,
  CREATE_INSTITUTION,
  UPDATE_INSTITUTION,
  UPDATE_INSTITUTION_SETTINGS,
  DELETE_INSTITUTION
};

const api = {
  filterInstitutions: (request: InstitutionFilterPageRequest): AxiosPromise<InstitutionFilterPageResult> => {
    return apiService.get(FILTER_INSTITUTIONS.url, {
      params: request,
      paramsSerializer: { serialize: serializeParams }
    });
  },
  downloadInstitutionsExportActions: (request: InstitutionFilterExportRequest): AxiosPromise<Blob> => {
    return apiService.get(DOWNLOAD_INSTITUTIONS_EXPORT.url, {
      params: request,
      paramsSerializer: { serialize: serializeParams },
      responseType: "blob"
    });
  },
  getInstitution: (request: EntityIdObject): AxiosPromise<Institution> => {
    return apiService.get(GET_INSTITUTION.getParametrizedUrl(request.id));
  },
  createInstitution: (request: CreateUpdateInstitution): AxiosPromise<Institution> => {
    return apiService.post(CREATE_INSTITUTION.url, request);
  },
  updateInstitution: (request: EntityObject<CreateUpdateInstitution>): AxiosPromise<Institution> => {
    return apiService.put(UPDATE_INSTITUTION.getParametrizedUrl(request.id), request.object);
  },
  updateInstitutionSettings: (request: EntityObject<UpdateInstitutionSettings>): AxiosPromise<InstitutionSettings> => {
    return apiService.put(UPDATE_INSTITUTION_SETTINGS.getParametrizedUrl(request.id), request.object);
  },
  deleteInstitution: (request: EntityIdObject): AxiosPromise => {
    return apiService.delete(DELETE_INSTITUTION.getParametrizedUrl(request.id));
  }
};

export default api;
