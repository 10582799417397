import { closestCenter, DndContext, KeyboardSensor, PointerSensor, useSensor, useSensors } from "@dnd-kit/core";
import type { DragEndEvent } from "@dnd-kit/core/dist/types";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy
} from "@dnd-kit/sortable";
import { Button, Card, Empty, Flex, Form, Select, SelectProps, Space } from "antd";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import ActionButton from "../../../../../../common/components/buttons/ActionButton";
import AntIcon from "../../../../../../common/components/icons/AntIcon";
import { SortableItem } from "../../../../../../common/components/sortable/SortableItem";
import type { FieldConstraintViolation, RootState } from "../../../../../../common/types";
import { isDefined } from "../../../../../../common/utils/utils";
import { CalcResultSorting } from "../../../components/calc-result/CalcResultSorting";
import { CalcNavigation } from "../../../components/CalcNavigation";
import { DraftOfferForm } from "../../../components/DraftOfferForm";
import { CalcResultsSorting } from "../../../enums";
import type { CalcResult } from "../../../types";
import { sortCalcResultItems } from "../../../utils";
import { selectRealtyDraft } from "../../ducks";
import { OperationStage } from "../../enums";
import { RealtyCalc, RealtyCalcDraft, RealtyCalcResultData, RealtyFormClients } from "../../types";
import { realtyCalcPageTitle } from "../../utils";
import { RealtySaveDraftButton } from "../RealtySaveDraftButton";
import { RealtyCalcResult } from "./realty-calc-result/RealtyCalcResult";

type RealtyCalcResultsFilterForm = {
  institutions: string[];
  coverages: string[];
};

type Props = {
  calcData: RealtyCalc;
  resultItems: CalcResult<RealtyCalcResultData>[];
  handleGenerateContract: (selectedItem: CalcResult<RealtyCalcResultData>) => void;
  handleShowErrors: (errors: FieldConstraintViolation[]) => void;
  handleGoToPreviousStep: VoidFunction;
  clients: RealtyFormClients;
};

const REALTY_COVERAGES: Record<string, { reinsuranceKey: string; valueKey: keyof RealtyCalcResultData }> = {
  CYBER_SECURITY: { reinsuranceKey: "cyberSecurity", valueKey: "cyberneticReinsuranceAnnualPremium" },
  WARRANTY: { reinsuranceKey: "warranty", valueKey: "warrantyReinsuranceAnnualPremium" },
  CYCLING: { reinsuranceKey: "cycling", valueKey: "cyclingReinsuranceAnnualPremium" },
  LOAN: { reinsuranceKey: "loan", valueKey: "loanReinsuranceAnnualPremium" },
  RENT: { reinsuranceKey: "rent", valueKey: "rentReinsuranceAnnualPremium" }
};

export const RealtyCalcResultsView = ({
  resultItems,
  calcData,
  handleGenerateContract,
  handleShowErrors,
  handleGoToPreviousStep,
  clients
}: Props) => {
  const { t } = useTranslation();
  const draft = useSelector<RootState, RealtyCalcDraft | undefined>(selectRealtyDraft);

  const [draftOfferForm, setDraftOfferForm] = useState<"createOffer" | "createUpdateDraft" | "sendOffer">();
  const [isUpdateDraftOffer, setIsUpdateDraftOffer] = useState(false);

  const [resultsSorting, setResultsSorting] = useState<CalcResultsSorting>(CalcResultsSorting.PRICE_ASC);
  const [calcResultItems, setCalcResultItems] = useState<CalcResult<RealtyCalcResultData>[]>(
    sortCalcResultItems({
      sortBy: resultsSorting,
      items: resultItems
    })
  );

  const [form] = Form.useForm<RealtyCalcResultsFilterForm>();

  const coveragesOptions: SelectProps["options"] = Object.values(REALTY_COVERAGES).map(value => ({
    value: value.reinsuranceKey,
    label: t(`contract.attrs.insurances.insuranceData.reinsurances.${value.reinsuranceKey}`)
  }));

  const insuranceInstitutions: SelectProps["options"] = useMemo(
    () =>
      resultItems
        .filter(item => !item.error)
        .map(item => item.insuranceInstitution)
        .filter((item, index, self) => !self.some((institution, i) => institution.id === item.id && i < index))
        .map(value => ({
          value: value.id,
          label: value.name
        })),
    [resultItems]
  );

  useEffect(() => {
    setCalcResultItems(
      sortCalcResultItems({
        sortBy: resultsSorting,
        items: resultItems
      })
    );

    form.resetFields();
  }, [resultItems]);

  useEffect(() => {
    const sortedCalcResultItems = calcResultItems
      .filter(item => !item.error)
      .sort((a, b) => {
        const isIncludedA = a.active ? 1 : 0;
        const isIncludedB = b.active ? 1 : 0;

        return isIncludedB - isIncludedA;
      });

    const result = calcResultItems.map(item => (item.error ? item : sortedCalcResultItems.shift()!));

    setCalcResultItems(result);
  }, [calcResultItems.filter(item => item.active).length]);

  const handleSaveDraft = (isUpdate: boolean): void => {
    setIsUpdateDraftOffer(isUpdate);
    setDraftOfferForm("createUpdateDraft");
  };

  const handleSubmitFilter = () => {
    form.validateFields().then(values => {
      const sortedItems = sortCalcResultItems({
        sortBy: resultsSorting,
        items: resultItems
      });

      if (values.institutions.length === 0 && values.coverages.length === 0) {
        setCalcResultItems(sortedItems);
        return;
      }

      const result = sortedItems
        .filter(item => !item.error)
        .filter(item => {
          if (values.institutions.length === 0) {
            return true;
          }

          return values.institutions.includes(item.insuranceInstitution.id);
        })
        .filter(item => {
          if (values.coverages.length === 0) {
            return true;
          }

          return values.coverages.some(coverage => {
            const coverageDataKeys = Object.values(REALTY_COVERAGES).find(
              data => data.reinsuranceKey === coverage
            )?.valueKey;

            if (coverageDataKeys) {
              return isDefined(item.data?.[coverageDataKeys]);
            }

            return false;
          });
        });

      setCalcResultItems(result);
    });
  };

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates
    })
  );

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (!over) {
      return;
    }

    const activeIndex = calcResultItems.findIndex(item => item.id === active.id);
    const overIndex = calcResultItems.findIndex(item => item.id === over.id);

    if (activeIndex !== overIndex) {
      setCalcResultItems(prev => arrayMove(prev, activeIndex, overIndex));
    }
  };

  const handleItemActivation = (id: string, activation: boolean) => {
    setCalcResultItems(
      calcResultItems.map(item => {
        if (item.id === id) {
          item.active = activation;
        }

        return item;
      })
    );
  };

  return (
    <div>
      <Card
        title={<h2>{t("calc.titles.insuranceOffer")}</h2>}
        className="card-box"
        extra={
          <Space>
            <RealtySaveDraftButton draft={draft} handleSaveDraft={handleSaveDraft} />
            <ActionButton
              icon="download"
              color="green"
              variant="outlined"
              size="middle"
              disabled={!calcResultItems.length}
              onClick={() => setDraftOfferForm("createOffer")}
            >
              {t("calc.actions.generateOffer")}
            </ActionButton>
            <ActionButton
              icon="mail"
              color="green"
              variant="outlined"
              size="middle"
              disabled={!calcResultItems.length}
              onClick={() => setDraftOfferForm("sendOffer")}
            >
              {t("calc.actions.sendOffer")}
            </ActionButton>
          </Space>
        }
      >
        <Form
          form={form}
          name="insuranceFilterForm"
          initialValues={{ institutions: [], coverages: [] }}
          layout="vertical"
        >
          <Flex gap="middle" align="end">
            <Form.Item name="institutions" label={t("common.insuranceInstitutions")} style={{ flex: 1 }}>
              <Select mode="multiple" options={insuranceInstitutions} allowClear />
            </Form.Item>
            <Form.Item name="coverages" label={t("calc.helpers.coverages")} style={{ flex: 1 }}>
              <Select mode="multiple" options={coveragesOptions} allowClear />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit" icon={<AntIcon type="search" />} onClick={handleSubmitFilter}>
                {t("common.filter")}
              </Button>
            </Form.Item>
          </Flex>
        </Form>
      </Card>

      <Card
        className="card-box"
        title={
          <h2 style={{ fontSize: 20 }}>
            {realtyCalcPageTitle(
              calcData.generalBuildingData.type,
              !!calcData.realtyInsuranceData,
              !!calcData.householdInsuranceData
            )}
          </h2>
        }
        extra={
          <CalcResultSorting
            resultItems={resultItems}
            resultsSorting={resultsSorting}
            onChange={(sorting, results) => {
              setResultsSorting(sorting);
              setCalcResultItems(results);
            }}
          />
        }
      >
        {calcResultItems.length ? (
          <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
            <SortableContext items={calcResultItems} strategy={verticalListSortingStrategy}>
              {calcResultItems.map(item => (
                <SortableItem key={item.id} id={item.id} error={item.error} disabled={!item.active}>
                  <RealtyCalcResult
                    item={item}
                    handleItemActivation={handleItemActivation}
                    handleGenerateContract={handleGenerateContract}
                    handleShowErrors={handleShowErrors}
                    handleItemRecommendation={(itemId: string, activation: boolean) =>
                      setCalcResultItems(
                        calcResultItems.map(item => {
                          if (item.id === itemId) {
                            item.recommended = activation;
                          }

                          return item;
                        })
                      )
                    }
                  />
                </SortableItem>
              ))}
            </SortableContext>
          </DndContext>
        ) : (
          <Empty description={t("calc.helpers.noDataByFilter")} />
        )}
      </Card>

      <DraftOfferForm
        isUpdate={isUpdateDraftOffer}
        formType={draftOfferForm}
        calcData={calcData}
        calcResults={calcResultItems.filter(item => item.active && !item.error)}
        operationStage={OperationStage.CALCULATE}
        draft={draft}
        onFinish={() => setDraftOfferForm(undefined)}
        clients={clients}
      />

      <CalcNavigation onGoToPrevious={handleGoToPreviousStep} />
    </div>
  );
};
