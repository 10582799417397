import { AxiosPromise } from "axios";
import { ApiRequestAdapter } from "../../common/api/ApiRequestAdapter";
import { apiService } from "../../common/api/apiService";
import { serializeParams } from "../../common/utils/apiUtils";
import { GlobalSearchRequest, GlobalSearchResult } from "./types";

const SEARCH_GLOBALLY = ApiRequestAdapter.create("/global-search", "GET");

export const requests = {
  SEARCH_GLOBALLY
};

const api = {
  searchGlobally: (request: GlobalSearchRequest): AxiosPromise<GlobalSearchResult> => {
    return apiService.get(SEARCH_GLOBALLY.url, { params: request, paramsSerializer: { serialize: serializeParams } });
  }
};

export default api;
