import { ConfigProvider, Layout, Skeleton, Spin } from "antd";
import { Pathname } from "history";
import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import logo from "../../assets/images/logo-cliip-white-color-1000x.png";
import squareLogo from "../../assets/images/logo-clip-1000xsquare-flower-transparent.png";
import { ATTACHMENT_BOUNDARY_PATH_PREFIX, ViewBreakpoints } from "../../common/constants";
import { Role } from "../../common/security/authorization/enums";
import { RootState } from "../../common/types";
import { useGoogleAnalytics } from "../../common/utils/analyticsUtils";
import { useDocumentTitle, useToken, useWindowSize } from "../../common/utils/hooksUtils";
import { cssVariables, getActiveEnvProfile } from "../../common/utils/utils";
import { checkAuthStateAction, selectIsUserAuthenticated, selectSelectedAccount } from "../../modules/auth/ducks";
import { AuthSelectedAccount } from "../../modules/auth/types";
import { DASHBOARD_ROUTE_PATHS } from "../../modules/dashboard/paths";
import { selectBrandingPersistData, selectHasActiveRequest, selectRouterLocationPathname } from "../../modules/ducks";
import { refreshEnumerationsAction, selectEnumsLastRefreshTime } from "../../modules/enumerations/ducks";
import { AgentBrandingEnumeration } from "../../modules/enumerations/types";
import {
  FILTER_NOTIFICATIONS_DEFAULT_REQUEST,
  filterHeaderNotificationsActions
} from "../../modules/notifications/ducks";
import BreadcrumbsBox from "../components/layout/BreadcrumbsBox";
import Footer from "../components/layout/Footer";
import Header from "../components/layout/Header";
import SideMenu from "../components/layout/SideMenu";
import { agentRoleRoutes, attachmentRoutes, clientRoleRoutes } from "../routes";
import styles from "./App.module.scss";

export const App = () => {
  useGoogleAnalytics();
  useDocumentTitle();

  const { token } = useToken();
  const routerPathname = useSelector<RootState, Pathname>(selectRouterLocationPathname);
  const userAuthenticated = useSelector<RootState, boolean | undefined>(selectIsUserAuthenticated);
  const selectedAccount = useSelector<RootState, AuthSelectedAccount>(selectSelectedAccount);
  const showSpinner = useSelector<RootState, boolean>(selectHasActiveRequest);
  const enumsLastRefreshTime = useSelector<RootState, string | undefined>(selectEnumsLastRefreshTime);
  const branding = useSelector<RootState, AgentBrandingEnumeration | undefined>(selectBrandingPersistData);

  const isAttachmentView = routerPathname.startsWith(ATTACHMENT_BOUNDARY_PATH_PREFIX);

  const dispatch = useDispatch();
  const actions = useMemo(
    () =>
      bindActionCreators(
        {
          checkAuthState: checkAuthStateAction,
          refreshEnumerations: refreshEnumerationsAction,
          filterHeaderNotifications: filterHeaderNotificationsActions.request
        },
        dispatch
      ),
    [dispatch]
  );

  useEffect(() => {
    actions.checkAuthState();
    if (userAuthenticated && selectedAccount.role === Role.AGENT) {
      actions.refreshEnumerations();
      actions.filterHeaderNotifications(FILTER_NOTIFICATIONS_DEFAULT_REQUEST);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const windowSize = useWindowSize();

  if (windowSize.outerWidth < ViewBreakpoints.TABLET) {
    document.querySelector("meta[name=viewport]")?.setAttribute("content", "width=991");
  } else {
    document
      .querySelector("meta[name=viewport]")
      ?.setAttribute("content", "width=device-width, initial-scale=1, shrink-to-fit=no");
  }

  const marginLayout =
    windowSize.innerWidth >= token.screenXL ? cssVariables.sideMenuWidth : cssVariables.sideMenuResponsiveWidth;

  return isAttachmentView ? (
    attachmentRoutes
  ) : (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: branding?.primaryColor ?? cssVariables.colorPrimary,
          colorText: cssVariables.colorText
        }
      }}
    >
      <Layout hasSider>
        {userAuthenticated && (
          <Layout.Sider
            breakpoint="xl"
            collapsedWidth={cssVariables.sideMenuResponsiveWidth}
            width={cssVariables.sideMenuWidth}
            style={{ position: "fixed", overflow: "auto", height: "100vh", top: 0, bottom: 0 }}
          >
            <div className={styles.logo}>
              <Link to={DASHBOARD_ROUTE_PATHS.homepage.to} className={styles.logoLink}>
                <img
                  className={styles.logoImage}
                  src={
                    branding?.logoResourcePath
                      ? `/images/${branding.logoResourcePath}`
                      : windowSize.innerWidth > ViewBreakpoints.DESKTOP
                        ? logo
                        : squareLogo
                  }
                  alt="Logo"
                />
              </Link>
            </div>
            {selectedAccount.role === Role.AGENT && <SideMenu />}
          </Layout.Sider>
        )}

        <Layout
          style={{ marginInlineStart: userAuthenticated ? marginLayout : "inherit" }}
          className={`${styles.rootLayout} ${userAuthenticated ? "root-layout--signed" : ""} ${getActiveEnvProfile()}`}
        >
          <Header userAuthenticated={userAuthenticated} selectedAccountRole={selectedAccount.role} />

          <Layout.Content className={styles.layoutContent}>
            <Spin spinning={showSpinner} size="large">
              <div className={styles.mainWrapper}>
                {!userAuthenticated ||
                (selectedAccount.role === Role.AGENT && enumsLastRefreshTime) ||
                selectedAccount.role === Role.CLIENT ? (
                  !userAuthenticated || selectedAccount.role === Role.AGENT ? (
                    <>
                      <BreadcrumbsBox />
                      {agentRoleRoutes}
                    </>
                  ) : (
                    clientRoleRoutes
                  )
                ) : (
                  <>
                    <Skeleton active paragraph={{ rows: 6 }} />
                    <Skeleton active paragraph={{ rows: 3 }} />
                    <Skeleton active paragraph={{ rows: 2 }} />
                  </>
                )}
              </div>
            </Spin>
          </Layout.Content>

          <Footer />
        </Layout>
      </Layout>
    </ConfigProvider>
  );
};
