export enum NotificationTopic {
  SYSADMIN_CALC_GENERATE_CONTRACT_FAILED = "SYSADMIN_CALC_GENERATE_CONTRACT_FAILED",
  SYSADMIN_VEHICLE_MAPPING_FINISHED = "SYSADMIN_VEHICLE_MAPPING_FINISHED",
  SYSADMIN_BUG_REPORT_CREATED = "SYSADMIN_BUG_REPORT_CREATED",
  SYSADMIN_MONLY_WEBHOOK_EVENT_FAILED = "SYSADMIN_MONLY_WEBHOOK_EVENT_FAILED",
  SYSADMIN_FINGO_EVENT_FAILED = "SYSADMIN_FINGO_EVENT_FAILED",
  AGENT_CONTRACT_GAINERS_CHANGED = "AGENT_CONTRACT_GAINERS_CHANGED",
  AGENT_CONTRACT_INTERVENTION_ADDED = "AGENT_CONTRACT_INTERVENTION_ADDED",
  AGENT_ENDING_COMPETENCE = "AGENT_ENDING_COMPETENCE",
  AGENT_ENDING_COMPETENCE_FOR_BACKOFFICE = "AGENT_ENDING_COMPETENCE_FOR_BACKOFFICE",
  AGENT_COMMISSIONS_OUTPUT_ATTACHMENT_CREATED = "AGENT_COMMISSIONS_OUTPUT_ATTACHMENT_CREATED",
  AGENT_ENDING_MENTORING = "AGENT_ENDING_MENTORING",
  CONTRACT_IMPORT_FINISHED = "CONTRACT_IMPORT_FINISHED",
  CLIENT_IMPORT_FINISHED = "CLIENT_IMPORT_FINISHED",
  CLIENT_UNPAID_CONTRACTS = "CLIENT_UNPAID_CONTRACTS",
  CLIENT_ANNIVERSARY_DATE = "CLIENT_ANNIVERSARY_DATE",
  CLIENT_UPCOMING_MONTH_PAYMENTS = "CLIENT_UPCOMING_MONTH_PAYMENTS"
}

export enum NotificationStatus {
  SCHEDULED = "SCHEDULED",
  SENT = "SENT",
  SEEN = "SEEN"
}

export const NotificationTopicParams = new Map<
  NotificationTopic,
  { mandatory: boolean; emailSendable: boolean; emailSendableMandatory: boolean; smsSendable: boolean }
>([
  [
    NotificationTopic.SYSADMIN_CALC_GENERATE_CONTRACT_FAILED,
    { mandatory: false, emailSendable: true, emailSendableMandatory: false, smsSendable: false }
  ],
  [
    NotificationTopic.SYSADMIN_VEHICLE_MAPPING_FINISHED,
    { mandatory: false, emailSendable: true, emailSendableMandatory: false, smsSendable: false }
  ],
  [
    NotificationTopic.SYSADMIN_BUG_REPORT_CREATED,
    { mandatory: false, emailSendable: true, emailSendableMandatory: false, smsSendable: false }
  ],
  [
    NotificationTopic.SYSADMIN_MONLY_WEBHOOK_EVENT_FAILED,
    { mandatory: false, emailSendable: true, emailSendableMandatory: false, smsSendable: false }
  ],
  [
    NotificationTopic.SYSADMIN_FINGO_EVENT_FAILED,
    { mandatory: false, emailSendable: true, emailSendableMandatory: false, smsSendable: false }
  ],
  [
    NotificationTopic.AGENT_CONTRACT_GAINERS_CHANGED,
    { mandatory: false, emailSendable: true, emailSendableMandatory: false, smsSendable: false }
  ],
  [
    NotificationTopic.AGENT_CONTRACT_INTERVENTION_ADDED,
    { mandatory: true, emailSendable: true, emailSendableMandatory: false, smsSendable: false }
  ],
  [
    NotificationTopic.AGENT_ENDING_COMPETENCE,
    { mandatory: true, emailSendable: true, emailSendableMandatory: true, smsSendable: false }
  ],
  [
    NotificationTopic.AGENT_ENDING_COMPETENCE_FOR_BACKOFFICE,
    { mandatory: false, emailSendable: true, emailSendableMandatory: false, smsSendable: false }
  ],
  [
    NotificationTopic.AGENT_COMMISSIONS_OUTPUT_ATTACHMENT_CREATED,
    { mandatory: true, emailSendable: true, emailSendableMandatory: true, smsSendable: false }
  ],
  [
    NotificationTopic.AGENT_ENDING_MENTORING,
    { mandatory: false, emailSendable: true, emailSendableMandatory: false, smsSendable: false }
  ],
  [
    NotificationTopic.CONTRACT_IMPORT_FINISHED,
    { mandatory: true, emailSendable: true, emailSendableMandatory: true, smsSendable: false }
  ],
  [
    NotificationTopic.CLIENT_IMPORT_FINISHED,
    { mandatory: true, emailSendable: true, emailSendableMandatory: true, smsSendable: false }
  ],
  [
    NotificationTopic.CLIENT_UNPAID_CONTRACTS,
    { mandatory: true, emailSendable: false, emailSendableMandatory: false, smsSendable: false }
  ],
  [
    NotificationTopic.CLIENT_ANNIVERSARY_DATE,
    { mandatory: true, emailSendable: false, emailSendableMandatory: false, smsSendable: false }
  ],
  [
    NotificationTopic.CLIENT_UPCOMING_MONTH_PAYMENTS,
    { mandatory: true, emailSendable: false, emailSendableMandatory: false, smsSendable: false }
  ]
]);
