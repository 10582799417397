import { Col, Form, Input, Row, Select, Switch } from "antd";
import { FormItemProps } from "antd/lib/form";
import classNames from "classnames";
import { FieldData } from "rc-field-form/lib/interface";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CountrySelect from "../../../modules/enumerations/components/form/CountrySelect";
import Ellipsis from "../../components/views/Ellipsis";
import { BratislavaCityDistricts, KosiceCityDistricts, rowGutter } from "../../constants";
import { selectStandardProps } from "../../utils/formUtils";
import { regexPatterns, validations } from "../../utils/validationUtils";
import { Country } from "../enums";
import { Address } from "../types";
import AddressAutocomplete from "./AddressAutocomplete";

const BA_CITY_NAME = "bratislava";
const KE_CITY_NAME = "košice";

const getDistrictOptions = (city?: string): string[] | undefined => {
  if (city?.toLowerCase() === BA_CITY_NAME) {
    return BratislavaCityDistricts;
  } else if (city?.toLowerCase() === KE_CITY_NAME) {
    return KosiceCityDistricts;
  }

  return;
};

type AddressSwitchProps = {
  enabled: boolean;
  onEnabledChange?: (enabled: boolean) => void;
  formItemProps?: FormItemProps;
};

type Props = {
  rootNamePath: (string | number)[];
  label: string;
  required?: boolean;
  switchProps?: AddressSwitchProps;
};

export const AddressForm = ({ rootNamePath, label, required, switchProps }: Props) => {
  const { t } = useTranslation();

  const [streetFilled, setStreetFilled] = useState<boolean>();

  const form = Form.useFormInstance();
  const hasStreet = !!Form.useWatch([...rootNamePath, "street"], form);
  const city = Form.useWatch([...rootNamePath, "city"], form) as Address["city"];

  useEffect(() => {
    form.validateFields([[...rootNamePath, streetFilled ? "descriptiveNumber" : "orientationNumber"]]);
  }, [streetFilled]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleEnabledSwitchChange = (checked: boolean): void => {
    if (!checked) {
      form.resetFields([
        [...rootNamePath, "street"],
        [...rootNamePath, "descriptiveNumber"],
        [...rootNamePath, "orientationNumber"],
        [...rootNamePath, "city"],
        [...rootNamePath, "zipCode"],
        [...rootNamePath, "country"]
      ]);
    }
    switchProps?.onEnabledChange?.(checked);
  };

  const handleAddressAutocompleteFind = (address: Address): void => {
    form.setFields(
      Object.keys(address).map<FieldData>(key => ({
        name: [...rootNamePath, key],
        value: address[key as keyof Address]
      }))
    );
  };

  const colSpan = 4;
  const smallColSpan = 3;

  const switchButton = switchProps ? (
    <span className="margin-left-tiny">
      {switchProps.formItemProps ? (
        <Form.Item {...switchProps.formItemProps} valuePropName="checked" noStyle>
          <Switch size="small" onChange={handleEnabledSwitchChange} />
        </Form.Item>
      ) : (
        <Switch size="small" checked={switchProps.enabled} onChange={handleEnabledSwitchChange} />
      )}
    </span>
  ) : undefined;

  return (
    <>
      <Row gutter={rowGutter} className="margin-bottom-tiny">
        <Col className={classNames("bold-text", "center-label-input", { "form-item-required": required })}>
          {label} {switchButton}
        </Col>
        <Col>
          <AddressAutocomplete
            hide={switchProps ? !switchProps.enabled : undefined}
            onAddressFind={handleAddressAutocompleteFind}
          />
        </Col>
      </Row>

      <Row gutter={rowGutter}>
        <Col span={colSpan}>
          <Form.Item
            name={[...rootNamePath, "street"]}
            label={t("address.attrs.street")}
            rules={[validations.size(1, 255), validations.pattern(regexPatterns.streetRegex)]}
          >
            <Input
              disabled={switchProps ? !switchProps.enabled : undefined}
              onChange={e => setStreetFilled(!!e.target.value)}
            />
          </Form.Item>
        </Col>

        <Col span={smallColSpan}>
          <Form.Item
            name={[...rootNamePath, "descriptiveNumber"]}
            label={t("address.attrs.descriptiveNumber")}
            rules={[
              hasStreet ? validations.none : validations.notBlank,
              validations.size(1, 64),
              validations.pattern(regexPatterns.streetNumberRegex)
            ]}
          >
            <Input disabled={switchProps ? !switchProps.enabled : undefined} />
          </Form.Item>
        </Col>

        <Col span={smallColSpan}>
          <Form.Item
            name={[...rootNamePath, "orientationNumber"]}
            label={<Ellipsis>{t("address.attrs.orientationNumber")}</Ellipsis>}
            rules={[
              hasStreet ? validations.notBlank : validations.none,
              validations.size(1, 64),
              validations.pattern(regexPatterns.streetNumberRegex)
            ]}
          >
            <Input disabled={switchProps ? !switchProps.enabled : undefined} />
          </Form.Item>
        </Col>

        <Col span={colSpan}>
          <Form.Item
            name={[...rootNamePath, "city"]}
            label={t("address.attrs.city")}
            rules={[validations.notBlank, validations.size(1, 64), validations.pattern(regexPatterns.wordRegex)]}
          >
            <Input disabled={switchProps ? !switchProps.enabled : undefined} />
          </Form.Item>
        </Col>

        {[BA_CITY_NAME, KE_CITY_NAME].includes(city?.toLowerCase()) ? (
          <Col span={4}>
            <Form.Item
              name={[...rootNamePath, "cityDistrict"]}
              label={t("address.attrs.cityDistrict")}
              rules={[validations.none]}
            >
              <Select
                {...selectStandardProps}
                options={getDistrictOptions(city)?.map(district => ({ value: district, label: district }))}
              />
            </Form.Item>
          </Col>
        ) : undefined}

        <Col span={smallColSpan}>
          <Form.Item
            name={[...rootNamePath, "zipCode"]}
            label={t("address.attrs.zipCode")}
            rules={[validations.notBlank, validations.length(5), validations.numeric]}
          >
            <Input disabled={switchProps ? !switchProps.enabled : undefined} />
          </Form.Item>
        </Col>

        <Col span={smallColSpan}>
          <CountrySelect
            formItemProps={{
              name: [...rootNamePath, "country"],
              label: t("address.enums.country._label"),
              rules: [validations.notNull],
              initialValue: Country.SVK
            }}
            selectProps={{ disabled: switchProps ? !switchProps.enabled : undefined }}
          />
        </Col>
      </Row>
    </>
  );
};
