import { Popconfirm } from "antd";
import { useTranslation } from "react-i18next";
import ActionButton from "../../../../../common/components/buttons/ActionButton";
import AntIcon from "../../../../../common/components/icons/AntIcon";
import { formatPlaceOfInsurance } from "../../../../../common/utils/formatUtils";
import { RealtyCalcDraft } from "../types";

type Props = {
  draft?: RealtyCalcDraft;
  handleSaveDraft: (value: boolean) => void;
};

export const RealtySaveDraftButton = ({ draft, handleSaveDraft }: Props) => {
  const { t } = useTranslation();

  return (
    <div>
      {draft ? (
        <Popconfirm
          title={
            <>
              <b>{t("calc.helpers.loadedDraftTitle")}:</b>
              <div className="margin-top-tiny margin-bottom-tiny">
                {draft.clientName}
                <br />
                {t("calc.realty.enums.buildingType." + draft.draftData.generalBuildingData.type)} |{" "}
                {draft.draftData.realtyInsuranceData && draft.draftData.householdInsuranceData
                  ? t("contract.helpers.realtyAndHousehold")
                  : draft.draftData.realtyInsuranceData
                    ? t("contract.attrs.insurances.insuranceData.realty._label")
                    : t("contract.attrs.insurances.insuranceData.household._label")}
                <br />
                {formatPlaceOfInsurance(draft.draftData.generalBuildingData.placeOfInsurance)}
              </div>
              <i>{t("calc.helpers.loadedDraftDesc")}</i>
            </>
          }
          icon={<AntIcon type="question" />}
          okText={t("calc.actions.overwriteDraft")}
          cancelText={t("calc.actions.saveNewDraft")}
          overlayStyle={{ maxWidth: 450 }}
          onConfirm={() => handleSaveDraft(true)}
          onCancel={() => handleSaveDraft(false)}
        >
          <span>
            <ActionButton icon="form" color="primary" variant="outlined" size="middle">
              {t("calc.actions.saveDraft")}
            </ActionButton>
          </span>
        </Popconfirm>
      ) : (
        <ActionButton
          icon="form"
          color="primary"
          variant="outlined"
          size="middle"
          onClick={() => handleSaveDraft(false)}
        >
          {t("calc.actions.saveDraft")}
        </ActionButton>
      )}
    </div>
  );
};
