import { Button, Card, Col, DatePicker, Form, InputNumber, List, Row, Space } from "antd";
import { FormInstance, Rule } from "antd/lib/form";
import { Dayjs } from "dayjs";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import t from "../../../../app/i18n";
import ActionButton from "../../../../common/components/buttons/ActionButton";
import InputAddon from "../../../../common/components/form/addons/InputAddon";
import HiddenInput from "../../../../common/components/form/components/HiddenInput";
import AntIcon from "../../../../common/components/icons/AntIcon";
import { rowGutter } from "../../../../common/constants";
import { Permission } from "../../../../common/security/authorization/enums";
import { RootState } from "../../../../common/types";
import {
  datePickerClearableProps,
  datePickerFormItemProps,
  datePickerStandardProps,
  dateToIsoDateString,
  disableDatePickerOutOfMinDate,
  inputNumberDecimalStandardProps,
  resolveFormValidationError,
  toDate
} from "../../../../common/utils/formUtils";
import { hasPermission } from "../../../../common/utils/utils";
import { validations } from "../../../../common/utils/validationUtils";
import { AgentType } from "../../../agent/enums";
import { selectUserAccount } from "../../../auth/ducks";
import AffiliatePartnerSelect from "../../../enumerations/components/form/AffiliatePartnerSelect";
import AgentSelect from "../../../enumerations/components/form/AgentSelect";
import type { UserAccount } from "../../../user/types";
import { ContractType } from "../../enums";
import type {
  Contract,
  ContractGainerKey,
  CreateUpdateContractGainerIdKey,
  CreateUpdateContractGainerRateKey,
  CreateUpdateContractGainerRecord
} from "../../types";
import { isContractVerified } from "../../utils";

interface Props {
  initialContract?: Contract;
  privilegedChangesPermission: Permission;
  form: FormInstance;
  onFinish: () => void;
  onCancel?: () => void;
}

const ContractFormAgentsSection = (props: Props) => {
  const [gainerCounts, setGainerCounts] = useState<number[]>(
    props.initialContract?.gainerRecords?.map(
      r => [r.gainer1, r.gainer2, r.gainer3, r.gainer4, r.gainer5].filter(g => !!g).length
    ) || [1]
  );

  const userAccount = useSelector<RootState, UserAccount | undefined>(selectUserAccount);

  const disablePrivilegedInput =
    isContractVerified(props.initialContract) &&
    userAccount &&
    !hasPermission(userAccount.permissions, props.privilegedChangesPermission);

  const handleGainerRecordEndDateChange = (index: number): void => {
    // @ts-ignore ts-problem
    const records = [...props.form.getFieldValue(["gainerRecords"])] as CreateUpdateContractGainerRecord[];

    if (records[index + 1]) {
      const recordEndDate = toDate(records[index]?.endDate);
      records[index + 1] = {
        ...(records[index + 1] as CreateUpdateContractGainerRecord),
        startDate: dateToIsoDateString(recordEndDate?.add(1, "day") as Dayjs)
      };
      props.form.setFieldsValue({ gainerRecords: records });
      props.form.validateFields([["gainerRecords", index + 1, "endDate"]]).catch(resolveFormValidationError);
    }
  };

  const handleGainerRecordAdd = (addEmptyGainerRecord: () => void): void => {
    addEmptyGainerRecord();

    const newGainerCounts = [...gainerCounts];
    newGainerCounts.push(1);
    setGainerCounts(newGainerCounts);
  };

  const handleGainerRecordDelete = (index: number, remove: (ind: number) => void): void => {
    const newGainerCounts = [...gainerCounts];
    newGainerCounts.splice(index, 1);
    setGainerCounts(newGainerCounts);

    // @ts-ignore ts-problem
    const oldGainerRecords = props.form.getFieldValue(["gainerRecords"]) as CreateUpdateContractGainerRecord[];

    const isLastIndex = index + 1 === oldGainerRecords.length;

    remove(index);

    const currentGainerRecordsAfterRemoving = props.form.getFieldValue([
      "gainerRecords"
    ]) as CreateUpdateContractGainerRecord[];

    if (index === 0) {
      const contractType = props.form.getFieldValue(["type"]) as ContractType;
      const startDateDefiningDate = props.form.getFieldValue([
        contractType === ContractType.INSURANCE_CONTRACT ? "effectiveBeginningDate" : "signDate"
      ]) as string;

      if (currentGainerRecordsAfterRemoving[0]) {
        currentGainerRecordsAfterRemoving[0].startDate = startDateDefiningDate;
      }
    } else if (!isLastIndex) {
      const previousRecordEndDate = toDate(currentGainerRecordsAfterRemoving[index - 1]?.endDate) as Dayjs;

      currentGainerRecordsAfterRemoving[index] = {
        ...(currentGainerRecordsAfterRemoving[index] as CreateUpdateContractGainerRecord),
        startDate: dateToIsoDateString(previousRecordEndDate.add(1, "day"))
      };
    } else if (isLastIndex) {
      currentGainerRecordsAfterRemoving[index - 1] = {
        ...(currentGainerRecordsAfterRemoving[index - 1] as CreateUpdateContractGainerRecord),
        endDate: undefined
      };
    }

    props.form.setFieldsValue({ gainerRecords: currentGainerRecordsAfterRemoving });
  };

  const handleGainerAdd = (index: number): void => {
    const newGainerCounts = [...gainerCounts];
    newGainerCounts[index]++;
    setGainerCounts(newGainerCounts);
  };

  const handleGainerRemove = (gainerRecordIndex: number): void => {
    const newGainerCounts = [...gainerCounts];
    newGainerCounts[gainerRecordIndex]--;
    setGainerCounts(newGainerCounts);

    // @ts-ignore ts-problem
    const records = [...props.form.getFieldValue(["gainerRecords"])] as CreateUpdateContractGainerRecord[];
    const record = { ...records[gainerRecordIndex] } as CreateUpdateContractGainerRecord;

    if (!record) {
      return;
    }

    if (newGainerCounts[gainerRecordIndex] === 1) {
      record.gainer1Rate = undefined;
    }

    const removedGainerIndex = gainerCounts[gainerRecordIndex] as number;

    record[`gainer${removedGainerIndex}Id` as Exclude<CreateUpdateContractGainerIdKey, "gainer1Id">] = undefined;
    record[`gainer${removedGainerIndex}Rate` as CreateUpdateContractGainerRateKey] = undefined;

    records[gainerRecordIndex] = record;

    props.form.setFieldsValue({ gainerRecords: records });
  };

  const colSpan = 4;

  return (
    <Card type="inner" className="card-box card-box--inner-extra" title={t("contract.sections.agents")}>
      <Row gutter={rowGutter}>
        <Col span={colSpan}>
          <AgentSelect
            formItemProps={{
              name: "signerId",
              label: t("contract.attrs.signerId"),
              rules: [validations.notNull],
              initialValue:
                userAccount?.agent?.type === AgentType.LEGAL
                  ? userAccount.representingAgent?.id
                  : userAccount?.agent?.id
            }}
            selectProps={{ disabled: disablePrivilegedInput }}
            optionsProps={{
              useAllAgents: true,
              selected: props.initialContract?.signer,
              filter: agent => agent.type !== AgentType.LEGAL
            }}
          />
        </Col>

        <Col span={colSpan}>
          <AgentSelect
            formItemProps={{
              name: "managerId",
              label: t("contract.attrs.managerId"),
              rules: [validations.notNull],
              initialValue:
                userAccount?.agent?.type === AgentType.LEGAL
                  ? userAccount.representingAgent?.id
                  : userAccount?.agent?.id
            }}
            selectProps={{ disabled: disablePrivilegedInput }}
            optionsProps={{
              useAllAgents: true,
              selected: props.initialContract?.manager,
              filter: agent => agent.type !== AgentType.LEGAL
            }}
          />
        </Col>

        <Col span={colSpan} offset={colSpan * 3}>
          <AffiliatePartnerSelect
            formItemProps={{
              name: "affiliatePartnerId",
              label: t("contract.attrs.affiliatePartnerId"),
              rules: [validations.none]
            }}
            selectProps={{ disabled: disablePrivilegedInput, allowClear: true }}
          />
        </Col>
      </Row>

      <Row gutter={rowGutter}>
        <Col>
          <h4>{t("contract.attrs.gainerRecords._label")}:</h4>
        </Col>
      </Row>

      <Form.List name="gainerRecords" initialValue={[{}]}>
        {(fields, { add, remove }) => (
          <>
            <List
              size="small"
              bordered
              dataSource={fields}
              renderItem={field => (
                <List.Item>
                  <div style={{ width: "100%" }}>
                    <HiddenInput name={[field.name, "id"]} />
                    <HiddenInput name={[field.name, "optimisticLockVersion"]} />

                    {fields.length > 1 && (
                      <Row gutter={rowGutter}>
                        <Col span={colSpan}>
                          <Form.Item
                            name={[field.name, "startDate"]}
                            label={t("contract.attrs.gainerRecords.startDate")}
                            rules={[validations.notNull]}
                            {...datePickerFormItemProps}
                          >
                            <DatePicker {...datePickerStandardProps} disabled />
                          </Form.Item>
                        </Col>
                        <Col span={colSpan}>
                          {field.name < fields.length - 1 && (
                            <Form.Item
                              noStyle
                              shouldUpdate={(prev, next) =>
                                prev.gainerRecords[field.name]?.startDate !== next.gainerRecords[field.name]?.startDate
                              }
                            >
                              {({ getFieldValue }) => {
                                const rules: Rule[] = [validations.notNull];
                                const startDate = getFieldValue(["gainerRecords", field.name, "startDate"]) as string;
                                const endDate = getFieldValue(["gainerRecords", field.name, "endDate"]) as string;
                                if (startDate) {
                                  rules.push(validations.notBefore(startDate));
                                }

                                return (
                                  <Form.Item
                                    name={[field.name, "endDate"]}
                                    label={t("contract.attrs.gainerRecords.endDate")}
                                    rules={rules}
                                    {...datePickerFormItemProps}
                                  >
                                    <DatePicker
                                      {...datePickerClearableProps}
                                      showNow={false}
                                      disabled={disablePrivilegedInput}
                                      disabledDate={current =>
                                        startDate ? disableDatePickerOutOfMinDate(current, startDate) : false
                                      }
                                      defaultPickerValue={toDate(endDate || startDate)}
                                      onChange={() => handleGainerRecordEndDateChange(field.name)}
                                    />
                                  </Form.Item>
                                );
                              }}
                            </Form.Item>
                          )}
                        </Col>
                        {!disablePrivilegedInput && (
                          <Col offset={colSpan * 3} span={colSpan} className="right-align">
                            <ActionButton icon="minus" onClick={() => handleGainerRecordDelete(field.name, remove)}>
                              {t("common.remove")}
                            </ActionButton>
                          </Col>
                        )}
                      </Row>
                    )}

                    <Row gutter={rowGutter}>
                      {Array.from({ length: gainerCounts[field.name] as number }, (_, i) => i + 1).map(i => (
                        <React.Fragment key={i}>
                          <Col span={colSpan}>
                            <AgentSelect
                              formItemProps={{
                                name: [field.name, `gainer${i}Id`],
                                label: t(`contract.attrs.gainerRecords.gainer${i}Id`),
                                rules: [
                                  validations.notNull,
                                  validations.noRepeatedGainer(gainerCounts[field.name] as number, [
                                    "gainerRecords",
                                    field.name
                                  ])
                                ],
                                initialValue:
                                  field.name === 0 && i === 1 && userAccount?.agent?.canBeGainer
                                    ? userAccount.agent.id
                                    : undefined,
                                dependencies: Array.from(
                                  { length: gainerCounts[field.name] as number },
                                  (_, i) => i + 1
                                )
                                  .filter(value => i !== value)
                                  .map(value => ["gainerRecords", field.name, `gainer${value}Id`])
                              }}
                              selectProps={{ disabled: disablePrivilegedInput }}
                              optionsProps={{
                                useAllAgents: true,
                                selected:
                                  props.initialContract?.gainerRecords?.[field.name]?.[
                                    `gainer${i}` as ContractGainerKey
                                  ],
                                filter: agentOption => !!agentOption.canBeGainer
                              }}
                            />
                          </Col>
                          {(gainerCounts[field.name] as number) > 1 && (
                            <>
                              <Col span={colSpan}>
                                <Form.Item
                                  name={[field.name, `gainer${i}Rate`]}
                                  dependencies={Array.from(
                                    { length: gainerCounts[field.name] as number },
                                    (_, i) => i + 1
                                  )
                                    .filter(value => i !== value)
                                    .map(value => ["gainerRecords", field.name, `gainer${value}Rate`])}
                                  label={t(`contract.attrs.gainerRecords.gainer${i}Rate`)}
                                  rules={[
                                    validations.notNull,
                                    validations.minNumber(0),
                                    validations.maxNumber(100),
                                    validations.gainersRateSumOneHundred(gainerCounts[field.name] as number, [
                                      "gainerRecords",
                                      field.name
                                    ])
                                  ]}
                                >
                                  <InputNumber
                                    {...inputNumberDecimalStandardProps}
                                    addonAfter={<InputAddon type="percentage" />}
                                    disabled={disablePrivilegedInput}
                                  />
                                </Form.Item>
                              </Col>
                            </>
                          )}
                        </React.Fragment>
                      ))}
                    </Row>

                    {!disablePrivilegedInput && (
                      <Row gutter={rowGutter} className="margin-top-tiny margin-bottom-small">
                        <Col span={8}>
                          <Space>
                            <ActionButton
                              icon="plus"
                              disabled={(gainerCounts[field.name] as number) >= 5}
                              onClick={() => handleGainerAdd(field.name)}
                            >
                              {t("contract.actions.addGainer")}
                            </ActionButton>

                            <ActionButton
                              icon="minus"
                              disabled={(gainerCounts[field.name] as number) <= 1}
                              onClick={() => handleGainerRemove(field.name)}
                            >
                              {t("contract.actions.removeGainer")}
                            </ActionButton>
                          </Space>
                        </Col>
                      </Row>
                    )}
                  </div>
                </List.Item>
              )}
            />
            {!disablePrivilegedInput && (
              <ActionButton
                icon="plus"
                className="margin-top-medium"
                onClick={() => handleGainerRecordAdd(() => add({}))}
              >
                {t("common.add")}
              </ActionButton>
            )}
          </>
        )}
      </Form.List>
      <Row gutter={rowGutter}>
        <Col span={24}>
          <div className="margin-top-medium">
            <Button type="primary" icon={<AntIcon type="save" />} onClick={props.onFinish}>
              {t("common.save")}
            </Button>

            {props.onCancel && (
              <Button className="margin-left-tiny" onClick={props.onCancel} icon={<AntIcon type="close" />}>
                {t("common.cancel")}
              </Button>
            )}
          </div>
        </Col>
      </Row>
    </Card>
  );
};

export default ContractFormAgentsSection;
