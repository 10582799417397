export enum OfferType {
  MTPL = "MTPL",
  CRASH = "CRASH",
  MTPL_CRASH = "MTPL_CRASH",
  REALTY = "REALTY",
  REALTY_RISKS = "REALTY_RISKS",
  TRAVEL = "TRAVEL"
}

export enum CalcResultDataType {
  MTPL = "MTPL",
  MTPL_ALLIANZ = "MTPL_ALLIANZ",
  CRASH = "CRASH",
  CRASH_KPAS_KOOP = "CRASH_KPAS_KOOP",
  MTPL_CRASH = "MTPL_CRASH",
  MTPL_CRASH_ALLIANZ = "MTPL_CRASH_ALLIANZ",
  MTPL_CRASH_KOOP = "MTPL_CRASH_KOOP",
  GAP = "GAP",
  PAS = "PAS",
  REALTY = "REALTY",
  REALTY_ALLIANZ = "REALTY_ALLIANZ",
  TRAVEL = "TRAVEL",
  TRAVEL_ALLIANZ = "TRAVEL_ALLIANZ"
}

export enum CalcStep {
  VALIDATE = "VALIDATE",
  CALCULATE = "CALCULATE"
}

export enum GenStep {
  VALIDATE = "VALIDATE",
  RECALCULATE = "RECALCULATE",
  CREATE_CONTRACT = "CREATE_CONTRACT",
  SAVE_CLIENTS_AND_CONTRACT = "SAVE_CLIENTS_AND_CONTRACT",
  DOWNLOAD_ATTACHMENTS = "DOWNLOAD_ATTACHMENTS",
  GENERATE_ATTACHMENTS = "GENERATE_ATTACHMENTS",
  SAVE_ATTACHMENTS = "SAVE_ATTACHMENTS",
  SEND_EMAIL = "SEND_EMAIL",
  FINALIZE_PROCESS = "FINALIZE_PROCESS"
}

export enum CalcAttachmentType {
  REGISTRATION_CERTIFICATE = "REGISTRATION_CERTIFICATE",
  ACCEPTANCE_PROTOCOL = "ACCEPTANCE_PROTOCOL",
  VEHICLE_INVOICE = "VEHICLE_INVOICE",
  DISABLED_PERSON_ID_CARD = "DISABLED_PERSON_ID_CARD"
}

export enum CalcResultsSorting {
  RECOMMENDED = "RECOMMENDED",
  PRICE_ASC = "PRICE_ASC",
  PRICE_DESC = "PRICE_DESC",
  INSITUTION = "INSITUTION"
}
