import { Divider, Popover, Space, Tag } from "antd";
import { useTranslation } from "react-i18next";
import ActionTextIcon from "../../../common/components/icons/ActionTextIcon";
import AntIcon from "../../../common/components/icons/AntIcon";
import { cssVariables } from "../../../common/utils/utils";
import { ContractVerificationStatus } from "../enums";

interface Props {
  status?: ContractVerificationStatus;
  note?: string;
  includedInExport?: boolean;
  closable?: boolean;
  style?: React.CSSProperties;
  label?: React.ReactNode;
  onNoteChangeClick?: () => void;
  onSendClick?: () => void;
  onExportToggleClick?: () => void;
  onClose?: (event?: React.MouseEvent<HTMLElement, MouseEvent>) => void;
}

const ContractVerificationStatusTag = ({
  status,
  note,
  includedInExport,
  closable,
  style,
  label,
  onNoteChangeClick,
  onSendClick,
  onExportToggleClick,
  onClose
}: Props) => {
  const { t } = useTranslation();

  let tagColor;

  switch (status) {
    case ContractVerificationStatus.TYPED:
      tagColor = cssVariables.colorYellow;
      break;
    case ContractVerificationStatus.SENT:
      tagColor = "warning";
      break;
    case ContractVerificationStatus.INTERNAL_INTERVENTION:
      tagColor = cssVariables.colorRed;
      break;
    case ContractVerificationStatus.EXTERNAL_INTERVENTION:
      tagColor = cssVariables.colorRed;
      break;
    case ContractVerificationStatus.NOT_ACCEPTED:
      tagColor = "black";
      break;
    case ContractVerificationStatus.VERIFIED:
      tagColor = cssVariables.colorOrange;
      break;
    case ContractVerificationStatus.PAID:
      tagColor = cssVariables.colorGreen;
      break;
  }

  const renderExportIcon = () => {
    if (!onExportToggleClick) {
      return null;
    }

    return (
      <span style={{ marginLeft: 4 }}>
        {includedInExport ? <AntIcon type="field-time" color="blue" /> : <AntIcon type="check" color="green" />}
      </span>
    );
  };

  const tag =
    status || label ? (
      <Tag color={tagColor} closable={closable} style={{ ...style }} onClose={onClose}>
        <span>{status ? t("contract.enums.verificationStatus." + status) : label}</span>
        {renderExportIcon()}
      </Tag>
    ) : null;

  const sendToBackofficeButton = onSendClick ? (
    <ActionTextIcon
      color="green"
      text={t("contract.actions.sendToBackoffice")}
      icon="file-done"
      onClick={onSendClick}
    />
  ) : undefined;

  const toggleExportButton = onExportToggleClick ? (
    <>
      <Divider dashed className="margin-top-small margin-bottom-tiny" />
      <Space>
        {includedInExport ? (
          <>
            <i>{t("contract.helpers.includedInVerificationExport")}</i>
            <ActionTextIcon color="orange" text={t("common.exclude")} icon="close" onClick={onExportToggleClick} />
          </>
        ) : (
          <>
            <i>{t("contract.helpers.excludedFromVerificationExport")}</i>
            <ActionTextIcon
              color="blue"
              text={t("contract.actions.verificationExportInclude")}
              icon="sister-node"
              onClick={onExportToggleClick}
            />
          </>
        )}
      </Space>
    </>
  ) : undefined;

  if (
    (status === ContractVerificationStatus.SENT ||
      status === ContractVerificationStatus.INTERNAL_INTERVENTION ||
      status === ContractVerificationStatus.EXTERNAL_INTERVENTION) &&
    (note || onNoteChangeClick)
  ) {
    return (
      <Popover
        title={t("contract.helpers.verificationStatusAdditionalInfo")}
        content={
          <>
            <span>{note}</span>

            {onNoteChangeClick && (
              <div className="margin-top-tiny right-align">
                <ActionTextIcon color="green" text={t("common.editNote")} icon="edit" onClick={onNoteChangeClick} />
              </div>
            )}

            {sendToBackofficeButton && <div className="margin-top-tiny right-align">{sendToBackofficeButton}</div>}

            {toggleExportButton && <div className="margin-top-tiny right-align">{toggleExportButton}</div>}
          </>
        }
        overlayStyle={{ maxWidth: 480 }}
      >
        {tag}
      </Popover>
    );
  } else if (sendToBackofficeButton) {
    return (
      <Popover
        content={
          <>
            {sendToBackofficeButton}
            {toggleExportButton && <div className="margin-top-tiny right-align">{toggleExportButton}</div>}
          </>
        }
      >
        {tag}
      </Popover>
    );
  } else if (toggleExportButton) {
    return <Popover content={toggleExportButton}>{tag}</Popover>;
  }

  return tag;
};

export default ContractVerificationStatusTag;
