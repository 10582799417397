import { AxiosPromise } from "axios";
import { ApiRequestAdapter } from "../../common/api/ApiRequestAdapter";
import { apiService } from "../../common/api/apiService";
import { Enumerations } from "./types";

const GET_ENUMERATIONS = ApiRequestAdapter.create("/enumerations", "GET");

export const requests = {
  GET_ENUMERATIONS
};

const api = {
  getEnumerations: (): AxiosPromise<Enumerations> => {
    return apiService.get(GET_ENUMERATIONS.url);
  }
};

export default api;
