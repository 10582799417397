import { keepPreviousData, useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import api from "../../../modules/agent/api";
import { CreateUpdateAgentDirectorate } from "../../../modules/agent/types";
import { UUID } from "../../../typings/global";
import { EntityObject, SearchPageRequest } from "../../types";
import messageUtils from "../../utils/messageUtils";

export const AGENT_DIRECTORATES_QUERY_KEYS = {
  filterAgentDirectorates: (filter?: SearchPageRequest) =>
    filter ? ["filterAgentDirectorates", filter] : ["filterAgentDirectorates"],
  createAgentDirectorate: () => ["createAgentDirectorate"],
  updateAgentDirectorate: () => ["updateAgentDirectorate"],
  deleteAgentDirectorate: () => ["deleteAgentDirectorate"]
};

export const useFilterAgentDirectorates = (payload: SearchPageRequest) => {
  return useQuery({
    queryKey: AGENT_DIRECTORATES_QUERY_KEYS.filterAgentDirectorates(payload),
    queryFn: async () => {
      const { data } = await api.filterAgentDirectorates(payload);

      return data;
    },
    placeholderData: keepPreviousData
  });
};

export const useCreateAgentDirectorate = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: AGENT_DIRECTORATES_QUERY_KEYS.createAgentDirectorate(),
    mutationFn: async (payload: CreateUpdateAgentDirectorate) => {
      const { data } = await api.createAgentDirectorate(payload);

      return data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: AGENT_DIRECTORATES_QUERY_KEYS.filterAgentDirectorates() });
      messageUtils.itemCreatedNotification();
    }
  });
};

export const useUpdateAgentDirectorate = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: AGENT_DIRECTORATES_QUERY_KEYS.updateAgentDirectorate(),
    mutationFn: async (payload: EntityObject<CreateUpdateAgentDirectorate>) => {
      const { data } = await api.updateAgentDirectorate(payload);

      return data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: AGENT_DIRECTORATES_QUERY_KEYS.filterAgentDirectorates() });
      messageUtils.itemUpdatedNotification();
    }
  });
};

export const useDeleteAgentDirectorate = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: AGENT_DIRECTORATES_QUERY_KEYS.deleteAgentDirectorate(),
    mutationFn: async (id: UUID) => {
      return api.deleteAgentDirectorate({ id });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: AGENT_DIRECTORATES_QUERY_KEYS.filterAgentDirectorates() });
      messageUtils.itemDeletedNotification();
    }
  });
};
