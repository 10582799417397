import { bindActionCreators } from "@reduxjs/toolkit";
import { Divider, Popconfirm, Table, TablePaginationConfig } from "antd";
import { ColumnsType } from "antd/lib/table";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useDeleteAgentEducation, useFilterAgentEducations } from "../../../../../common/api/queries";
import ActionTextIcon from "../../../../../common/components/icons/ActionTextIcon";
import PopconfirmDeleteIcon from "../../../../../common/components/icons/PopconfirmDeleteIcon";
import Ellipsis from "../../../../../common/components/views/Ellipsis";
import { TableSizes } from "../../../../../common/constants";
import { useFilterSearchParams } from "../../../../../common/utils/hooksUtils";
import { URLQuery } from "../../../../../common/utils/queryUtils";
import { numberOrZero, tableStandardProps } from "../../../../../common/utils/utils";
import { UUID } from "../../../../../typings/global";
import { getEnumerationsActions } from "../../../../enumerations/ducks";
import { AgentEducation, AgentEducationFilterPageRequest } from "../../../types";

type Props = {
  onChange: (agent: AgentEducation) => void;
};

export const AgentEducationTableView = ({ onChange }: Props) => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const searchParams = useFilterSearchParams<AgentEducationFilterPageRequest>({ params: ["types"] });

  const deleteAgentEducation = useDeleteAgentEducation();
  const { data, isLoading } = useFilterAgentEducations(searchParams);

  const dispatch = useDispatch();
  const actions = useMemo(
    () => bindActionCreators({ getEnumerations: getEnumerationsActions.request }, dispatch),
    [dispatch]
  );

  const handleTableChange = (pagination: TablePaginationConfig) => {
    navigate(
      {
        search: URLQuery.searchParamsToString({
          searchParams: {
            pageIndex: pagination.current ? pagination.current - 1 : undefined
          },
          append: true
        })
      },
      { replace: true }
    );
  };

  const handleDeleteAgentEducation = async (id: UUID) => {
    await deleteAgentEducation.mutateAsync(id);
    actions.getEnumerations();
  };

  const columns: ColumnsType<AgentEducation> = [
    {
      key: "code",
      title: t("agent.education.attrs.code"),
      width: 180,
      ellipsis: { showTitle: false },
      render: (_, record) => <Ellipsis>{record.code}</Ellipsis>
    },
    {
      key: "name",
      title: t("agent.education.attrs.name"),
      width: 300,
      ellipsis: { showTitle: false },
      render: (_, record) => <Ellipsis>{record.name}</Ellipsis>
    },
    {
      key: "type",
      title: t("agent.education.enums.agentEducationType._label"),
      width: 240,
      ellipsis: { showTitle: false },
      render: (_, record) => t(`agent.education.enums.agentEducationType.${record.type}`)
    },
    {
      key: "actions",
      align: "right",
      fixed: "right",
      width: 180,
      render: (_, record) => (
        <>
          <ActionTextIcon icon="edit" color="blue" text={t("common.edit")} onClick={() => onChange(record)} />

          <Divider type="vertical" />

          <Popconfirm
            title={t("agent.education.helpers.deleteConfirm")}
            icon={<PopconfirmDeleteIcon />}
            cancelText={t("common.no")}
            okText={t("common.yes")}
            okType="danger"
            onConfirm={() => handleDeleteAgentEducation(record.id)}
          >
            <span>
              <ActionTextIcon icon="delete" color="red" text={t("common.delete")} />
            </span>
          </Popconfirm>
        </>
      )
    }
  ];

  return (
    <Table
      {...tableStandardProps()}
      loading={isLoading}
      columns={columns}
      dataSource={data?.pageData}
      pagination={{
        ...data,
        current: numberOrZero(data?.pageIndex) + 1,
        pageSize: data?.pageSize,
        total: data?.totalElementsCount
      }}
      scroll={{ x: TableSizes.MEDIUM }}
      onChange={handleTableChange}
    />
  );
};
