import { AxiosPromise } from "axios";
import { apiService } from "../../common/api/apiService";
import requests from "./requests";
import { AuthData, LoginBaseRequest, LoginRequest, VerifyTotpCodeRequest } from "./types";

const api = {
  login: (request: LoginRequest): AxiosPromise<AuthData> => {
    return apiService.post(requests.LOGIN.url, request);
  },
  sendTotpCodeViaSms: (request: LoginBaseRequest): AxiosPromise => {
    return apiService.post(requests.SEND_TOTP_CODE_VIA_SMS.url, request);
  },
  verifyTotpCode: (request: VerifyTotpCodeRequest): AxiosPromise<AuthData> => {
    return apiService.post(requests.VERIFY_TOTP_CODE.url, request);
  },
  refreshToken: (): AxiosPromise<AuthData> => {
    return apiService.post(requests.REFRESH_TOKEN.url);
  }
};

export default api;
