import { Button, Flex, Form, Input, Select } from "antd";
import { useForm } from "antd/es/form/Form";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import AntIcon from "../../../../common/components/icons/AntIcon";
import { selectStandardProps } from "../../../../common/utils/formUtils";
import { useFilterSearchParams } from "../../../../common/utils/hooksUtils";
import { URLQuery } from "../../../../common/utils/queryUtils";
import { validationConstants, validations } from "../../../../common/utils/validationUtils";
import { AgentEducationType } from "../../enums";
import { AgentEducationFilterPageRequest } from "../../types";

export const AgentEducationFilterForm = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const searchParams = useFilterSearchParams<AgentEducationFilterPageRequest>({ params: ["types"] });

  const [form] = useForm<AgentEducationFilterPageRequest>();

  useEffect(() => {
    form.setFieldsValue({
      ...searchParams,
      types: searchParams.types && !Array.isArray(searchParams.types) ? [searchParams.types] : searchParams.types
    });
  }, [searchParams]);

  const handleFilterSubmit = (filter: AgentEducationFilterPageRequest) => {
    navigate(
      { search: URLQuery.searchParamsToString({ searchParams: { ...filter }, append: true }) },
      { replace: true }
    );
  };

  return (
    <Form form={form} onFinish={handleFilterSubmit} layout="vertical" name="agentEducationFilterForm">
      <Flex gap="middle" align="end" justify="center">
        <Form.Item
          style={{ width: 300 }}
          name="keyword"
          label={t("common.searchKeyword")}
          rules={[
            validations.size(
              validationConstants.SEARCH_KEYWORD_MIN_LENGTH,
              validationConstants.SEARCH_KEYWORD_MAX_LENGTH
            )
          ]}
        >
          <Input allowClear placeholder={t("agent.education.helpers.searchHint")} />
        </Form.Item>

        <Form.Item style={{ width: 400 }} name="types" label={t("agent.education.enums.agentEducationType._label")}>
          <Select
            {...selectStandardProps}
            allowClear
            mode="multiple"
            maxTagCount="responsive"
            options={Object.values(AgentEducationType)
              .filter(type => type !== AgentEducationType.MANAGERIAL_ACADEMY_COMPLETION)
              .map(type => ({
                value: type,
                label: t(`agent.education.enums.agentEducationType.${type}`)
              }))}
          />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" icon={<AntIcon type="search" />}>
            {t("common.filter")}
          </Button>
        </Form.Item>
      </Flex>
    </Form>
  );
};
