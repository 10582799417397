import { Card, Col, Form, Radio, Row } from "antd";
import { RadioChangeEvent } from "antd/lib/radio/interface";
import { isEqual } from "lodash";
import { FieldData } from "rc-field-form/lib/interface";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import { SecondaryButton } from "../../../../../../common/components/buttons/SecondaryButton";
import AntIcon, { type AntIconType } from "../../../../../../common/components/icons/AntIcon";
import { rowGutter } from "../../../../../../common/constants";
import { Permission } from "../../../../../../common/security/authorization/enums";
import type { FieldConstraintViolation, RootState } from "../../../../../../common/types";
import {
  licensePlateNormalizeFunction,
  resolveFormValidationError,
  setErrorsToForm
} from "../../../../../../common/utils/formUtils";
import { useScrollToTopOnLoad } from "../../../../../../common/utils/hooksUtils";
import messageUtils from "../../../../../../common/utils/messageUtils";
import { removeStringWhiteSpaces } from "../../../../../../common/utils/utils";
import { validations } from "../../../../../../common/utils/validationUtils";
import { selectContainedPermissions } from "../../../../../auth/ducks";
import { CalcValidationGroup, ClientFormType, ClientType } from "../../../../../client/enums";
import { Client, CreateUpdateContractClient } from "../../../../../client/types";
import {
  clientToCreateUpdateContractClient,
  createUpdateContractClientToClient,
  processClientsDataViolations,
  resolveClientIdentifier,
  useClientValidation
} from "../../../../../client/utils";
import { DashboardNoticePosition } from "../../../../../dashboard/enums";
import { CalcType } from "../../../../enums";
import { CALC_ROUTE_PATHS } from "../../../../paths";
import { CalcNavigation } from "../../../components/CalcNavigation";
import CalcNoticesIconView from "../../../components/CalcNoticesIconView";
import { downloadCardReaderActions } from "../../../ducks";
import type {
  VehicleCalc,
  VehicleCalcForm,
  VehicleCalcType,
  VehicleCalcVehicleData,
  VehicleFormClients
} from "../../types";
import { M1_N1 } from "../../utils";
import VehicleCalcClientsDataSection from "./sections/VehicleCalcClientsDataSection";
import VehicleCalcGeneralAndReinsurancesDataSection from "./sections/VehicleCalcGeneralAndReinsurancesDataSection";
import VehicleCalcVehicleDataSection from "./sections/VehicleCalcVehicleDataSection";

interface Props {
  calcData?: VehicleCalc;
  formErrors: FieldConstraintViolation[];
  setIsFormChanged: VoidFunction;
  holder?: Client;
  onCalculationFormSubmit: (calcData: VehicleCalc) => void;
  onClientChange: (type: ClientFormType, client?: Client) => VehicleFormClients;
  onResetCalculatorClick: () => void;
  goToCalcResults?: VoidFunction;
}

const HOLDER_INDEX_MAP = new Map<ClientFormType, number>([[ClientFormType.HOLDER, 0]]);

export const VehicleCalcWrapper = ({
  calcData,
  formErrors,
  setIsFormChanged,
  holder,
  onCalculationFormSubmit,
  onClientChange,
  onResetCalculatorClick,
  goToCalcResults
}: Props) => {
  useScrollToTopOnLoad();
  const { t } = useTranslation();

  const [form] = Form.useForm<VehicleCalcForm>();
  const clientValidation = useClientValidation();

  const vehicleCalcPermissions = useSelector<RootState, Permission[]>(state =>
    selectContainedPermissions(Permission.MTPL_CALCULATE, Permission.CRASH_CALCULATE)(state)
  );

  const [clientsViolationErrors, setClientsViolationErrors] = useState<Map<ClientFormType, FieldConstraintViolation[]>>(
    new Map()
  );

  const dispatch = useDispatch();
  const actions = useMemo(
    () => bindActionCreators({ downloadCardReader: downloadCardReaderActions.request }, dispatch),
    [dispatch]
  );

  useEffect(() => {
    if (calcData) {
      const licensePlate = calcData.vehicleData.licensePlate;

      const holder = createUpdateContractClientToClient(
        calcData.clientsData.clients[calcData.clientsData.holderIndex] as CreateUpdateContractClient
      );

      const { clients, holderIndex, ...clientsData } = calcData.clientsData;

      form.setFieldsValue({
        ...calcData,
        vehicleData: {
          ...calcData.vehicleData,
          licensePlate: licensePlate ? licensePlateNormalizeFunction(licensePlate) : undefined
        },
        clientsData: {
          ...clientsData,
          holderIdentifier: holder ? resolveClientIdentifier(holder) : undefined
        }
      });
    } else {
      form.resetFields();
    }
  }, [calcData]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!formErrors.length) {
      return;
    }

    if (formErrors.some(formError => formError.fieldPath.startsWith("clientsData.clients"))) {
      setClientsViolationErrors(processClientsDataViolations(HOLDER_INDEX_MAP, "clientsData.clients", formErrors));
    }

    setErrorsToForm(form, "calc.realty.attrs", formErrors);
  }, [formErrors]);

  useEffect(() => {
    if (clientValidation.errorResponse?.violations?.length) {
      setClientsViolationErrors(
        processClientsDataViolations(HOLDER_INDEX_MAP, "clientsData.clients", clientValidation.errorResponse.violations)
      );
    }
  }, [clientValidation.errorResponse]);

  useEffect(() => {
    return () => {
      clientValidation.onErrorResponseDelete();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleInsuranceTypeChange = (e: RadioChangeEvent): void => {
    const calcType = e.target.value as VehicleCalcType;
    const vehicleData = form.getFieldValue(["vehicleData"]) as VehicleCalcVehicleData;
    switch (calcType) {
      case CalcType.MTPL:
        form.setFieldsValue({
          vehicleData: {
            odometer: undefined,
            price: vehicleData.newVehicle && M1_N1.includes(vehicleData.category) ? vehicleData.price : undefined,
            generalPrice: undefined,
            buyingPrice: undefined
          },
          clientsData: {
            holderHasNeverBeenCrashInsured: false
          },
          generalData: {
            crossSelling: {
              kooperativaContract: false
            },
            gapDuration: undefined,
            gapCoverageLimit: undefined
          },
          reinsurancesData: {
            gap: false,
            replacementVehicle: false,
            generaliAbroadVehicleRepair: false,
            koopExtendedWarranty: false
          }
        });
        break;
      case CalcType.CRASH:
        form.setFieldsValue({
          vehicleData: {
            steeringWheelOnTheRight: false,
            emergencyBrakingSystem: false,
            parkingAssistant: false
          },
          reinsurancesData: {
            animal: false,
            element: false,
            theftAndVandalism: false,
            injury: false,
            koopExtendedWarranty: false,
            uniqaSmallDamage: false,
            uniqaTotalDamage: false,
            uniqaLawyerAssistance: false,
            uniqaSidecarMtpl: false,
            uniqaChildrenInjury: false,
            uniqaUnlimitedTow: false
          }
        });
        break;
      case CalcType.MTPL_CRASH:
        form.setFieldsValue({
          vehicleData: {
            steeringWheelOnTheRight: false,
            emergencyBrakingSystem: false,
            parkingAssistant: false
          },
          generalData: {
            crossSelling: {
              csobContract: false,
              kooperativaContract: false,
              unionHealthContract: false
            }
          },
          reinsurancesData: {
            animal: false,
            element: false,
            theftAndVandalism: false,
            generaliAbroadVehicleRepair: false,
            uniqaSmallDamage: false,
            uniqaTotalDamage: false
          }
        });
        break;
    }
  };

  const handleFormFieldsChange = (changedFields?: FieldData[]): void => {
    if (changedFields?.length) {
      setIsFormChanged();
    }
  };

  const handleCalculationFormSubmit = (): void => {
    form
      .validateFields()
      .then(values => {
        if (!holder || clientsViolationErrors.has(ClientFormType.HOLDER)) {
          messageUtils.errorNotification({ message: t("common.error"), description: t("calc.validations.formError") });
        } else {
          onCalculationFormSubmit(processAndGetCalcFormData(values));
        }
      })
      .catch(errorInfo => {
        messageUtils.errorNotification({ message: t("common.error"), description: t("calc.validations.formError") });
        resolveFormValidationError(errorInfo);
      });
  };

  const handleHolderChange = (client?: Client): void => {
    if (!isEqual(holder, client)) {
      setIsFormChanged();
    }

    onClientChange(ClientFormType.HOLDER, client);

    if (client) {
      clientValidation.onValidate({
        prefix: `clientsData.clients[0]`,
        client: clientToCreateUpdateContractClient(client),
        validationGroup: CalcValidationGroup.CALCULATE
      });

      if (client.type !== ClientType.NATURAL) {
        form.setFieldsValue({
          clientsData: {
            holderDrivingLicenseYear: undefined,
            holderIsDisabledPerson: false,
            disabledPersonIdCardNumber: undefined
          },
          generalData: {
            crossSelling: {
              unionHealthContract: false
            }
          }
        });
      }
    } else {
      form.setFieldsValue({
        generalData: {
          crossSelling: {
            unionHealthContract: false
          }
        }
      });
    }
  };

  const handleHolderViolationErrorsDelete = (): void => {
    setClientsViolationErrors(new Map<ClientFormType, FieldConstraintViolation[]>());
  };

  const processAndGetCalcFormData = (calcData: VehicleCalcForm): VehicleCalc => {
    const { holderIdentifier, ...clientsData } = calcData.clientsData;

    const calcClientsData = {
      ...clientsData,
      holderIndex: 0,
      clients: holder ? [clientToCreateUpdateContractClient(holder)] : []
    };

    return {
      ...calcData,
      vehicleData: {
        ...calcData.vehicleData,
        licensePlate: removeStringWhiteSpaces(calcData.vehicleData.licensePlate)
      },
      clientsData: calcClientsData
    };
  };

  const resolvePageTitle = (type: CalcType): string => {
    return type ? t("calc.vehicle.titles.calculation." + type) : t("calc.vehicle.titles.noSelectedCalcType");
  };

  const resolveCalcTypeIcon = (type: CalcType): AntIconType => {
    switch (type) {
      case CalcType.MTPL:
        return "dashboard";
      case CalcType.CRASH:
        return "car";
      case CalcType.MTPL_CRASH:
        return "safety";
      default:
        return "dashboard";
    }
  };

  const allowedCalcTypes =
    vehicleCalcPermissions.length === 2
      ? [CalcType.MTPL, CalcType.CRASH, CalcType.MTPL_CRASH]
      : [vehicleCalcPermissions.includes(Permission.MTPL_CALCULATE) ? CalcType.MTPL : CalcType.CRASH];

  const calcType = Form.useWatch("type", form);

  return (
    <>
      <Form form={form} layout="vertical" name="vehicleCalcForm" onFieldsChange={handleFormFieldsChange}>
        <Card
          title={<h2>{resolvePageTitle(calcType)}</h2>}
          className="card-box card-box--inner-extra"
          extra={
            <>
              <CalcNoticesIconView position={DashboardNoticePosition.VEHICLE_CALC} />
              <Link to={CALC_ROUTE_PATHS.vehicleDraft.to}>
                <SecondaryButton icon={<AntIcon type="form" />}>{t("calc.actions.showDrafts")}</SecondaryButton>
              </Link>
            </>
          }
        >
          <Row gutter={rowGutter} justify="center">
            <Col span={24} className="center-align">
              <Form.Item
                name="type"
                rules={[validations.notNull]}
                className="no-bottom-space"
                initialValue={allowedCalcTypes[0]}
              >
                <Radio.Group
                  size="large"
                  buttonStyle="solid"
                  className="radio-group-icon"
                  onChange={handleInsuranceTypeChange}
                >
                  {allowedCalcTypes.map(type => (
                    <Radio.Button value={type} key={type} style={{ width: 150 }}>
                      <AntIcon type={resolveCalcTypeIcon(type)} />
                      <span>{t("calc.enums.calcType." + type)}</span>
                    </Radio.Button>
                  ))}
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
        </Card>

        <VehicleCalcVehicleDataSection form={form} onDownloadCardReader={actions.downloadCardReader} />

        <VehicleCalcClientsDataSection
          form={form}
          holder={holder}
          clientsViolationErrors={clientsViolationErrors}
          onHolderChange={handleHolderChange}
          onHolderViolationErrorsDelete={handleHolderViolationErrorsDelete}
        />

        <VehicleCalcGeneralAndReinsurancesDataSection holder={holder} />
      </Form>

      <CalcNavigation
        onCalculate={handleCalculationFormSubmit}
        onResetCalculatorClick={onResetCalculatorClick}
        goToCalcResults={goToCalcResults}
      />
    </>
  );
};
