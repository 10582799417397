import { Button, Flex, Space, Tag } from "antd";
import { useTranslation } from "react-i18next";
import AntIcon from "../../../../../../../common/components/icons/AntIcon";
import Ellipsis from "../../../../../../../common/components/views/Ellipsis";
import { formatLocaleCurrency } from "../../../../../../../common/utils/formatUtils";
import { cssVariables } from "../../../../../../../common/utils/utils";
import { PaymentFrequency } from "../../../../../../contract/enums";
import { resolveInstitutionLogo } from "../../../../../../institution/utils";
import { ErrorAlert } from "../../../../components/calc-result/ErrorAlert";
import { RecommendationButton } from "../../../../components/calc-result/RecommendationButton";
import { CalcResult } from "../../../../types";
import { DEFEND_D2C_COVERAGE } from "../../../../utils";
import { GapCalcResultData, GapInsuranceOption } from "../../../types";

type Props = {
  item: CalcResult<GapCalcResultData>;
  rotate: VoidFunction;
  handleItemRecommendation?: (itemId: string, activation: boolean) => void;
};

type GapOption = {
  reinsurance: {
    once?: GapInsuranceOption;
    annually?: GapInsuranceOption;
  };
  noReinsurance: {
    once?: GapInsuranceOption;
    annually?: GapInsuranceOption;
  };
};

export const GapCalcResultInfo = ({ item, rotate, handleItemRecommendation }: Props) => {
  const { t } = useTranslation();

  const gapOptions = item.data?.options?.reduce<GapOption>(
    (acc, value) => {
      const target = value.complicityReinsurance ? acc.reinsurance : acc.noReinsurance;

      if (value.paymentFrequency === PaymentFrequency.ONCE) {
        target.once = { ...value };
      } else if (value.paymentFrequency === PaymentFrequency.ANNUALLY) {
        target.annually = { ...value };
      }

      return acc;
    },
    { reinsurance: {}, noReinsurance: {} }
  );

  return (
    <Flex gap="middle" style={{ height: "100%" }}>
      <div style={{ marginTop: 10 }}>
        <img
          width="100"
          height="60"
          style={{ objectFit: "contain" }}
          src={resolveInstitutionLogo(item.insuranceInstitution)}
          alt="Institution Logo"
        />
      </div>

      <Flex justify="space-between" vertical flex={1} style={{ minWidth: 0 }}>
        <div>
          <Flex gap="middle">
            <h2 style={{ marginBottom: 14 }}>
              {item.insuranceInstitution.name} {item.coverage}
            </h2>

            {handleItemRecommendation ? (
              <RecommendationButton item={item} handleItemRecommendation={handleItemRecommendation} />
            ) : undefined}
          </Flex>

          <div style={{ maxHeight: 115, overflowY: "auto" }}>
            {item.error ? (
              <ErrorAlert item={item} />
            ) : (
              <>
                <div style={{ maxWidth: 600 }}>
                  <Flex style={{ lineHeight: "28px" }}>
                    <div style={{ flex: 4 }}>
                      <b>{t("calc.vehicle.enums.gapDuration._label")}</b>
                    </div>
                    <div style={{ flex: 2, textAlign: "right" }}>
                      <b>{t("contract.enums.paymentFrequency.ONCE")}</b>
                    </div>
                    <div style={{ flex: 4, textAlign: "right" }}>
                      <b>{t("contract.enums.paymentFrequency.ANNUALLY")}</b>
                      <span style={{ marginLeft: 4 }}>({t("calc.vehicle.helpers.gapAnnualPremiumSum")})</span>
                    </div>
                  </Flex>

                  {gapOptions
                    ? Object.entries(gapOptions).map(([key, option]) => {
                        if (Object.keys(option).length === 0) {
                          return;
                        }

                        return (
                          <Flex key={key} style={{ lineHeight: "26px" }}>
                            <div style={{ flex: 4 }}>
                              {`${t("calc.vehicle.enums.gapDuration." + option.once?.duration)} ${
                                item.coverage !== DEFEND_D2C_COVERAGE
                                  ? key === "reinsurance"
                                    ? `(${t("calc.vehicle.helpers.gapWithComplicityReinsurance")})`
                                    : `(${t("calc.vehicle.helpers.gapWithoutComplicityReinsurance")})`
                                  : undefined
                              }
                          `}
                            </div>
                            <div style={{ flex: 2, textAlign: "right" }}>
                              {formatLocaleCurrency(option.once?.annualPremium)}
                            </div>
                            <div style={{ flex: 4, textAlign: "right" }}>
                              {`${formatLocaleCurrency(option.annually?.annualPremium)} (${formatLocaleCurrency(option.annually?.premium)})`}
                            </div>
                          </Flex>
                        );
                      })
                    : undefined}
                </div>

                {item.data?.warnings.length ? (
                  <Tag
                    style={{ minWidth: 0, display: "block", paddingRight: 24, marginTop: 8, marginRight: 0 }}
                    icon={<AntIcon type="exclamation" />}
                    color="warning"
                  >
                    <Ellipsis style={{ color: cssVariables.colorYellow }}>{item.data?.warnings.join(" ")}</Ellipsis>
                  </Tag>
                ) : undefined}
              </>
            )}
          </div>
        </div>

        <Space.Compact block>
          <Button style={{ flex: 1 }} disabled={!!item.error} onClick={rotate}>
            {t("calc.travel.results.additionalInfo")}
          </Button>
          <Button style={{ flex: 1 }} disabled={!!item.error} onClick={rotate}>
            {t("common.documents")}
          </Button>
        </Space.Compact>
      </Flex>
    </Flex>
  );
};
