import { Rule } from "antd/lib/form";
import dayjs, { Dayjs } from "dayjs";
import ibanUtils from "iban";
import { NumberType, parsePhoneNumber } from "libphonenumber-js/max";
import { NamePath } from "rc-field-form/lib/interface";
import isLowerCase from "voca/is_lower_case";
import isNumeric from "voca/is_numeric";
import isUpperCase from "voca/is_upper_case";
import XRegExp from "xregexp";
import t from "../../app/i18n";
import { ClientType } from "../../modules/client/enums";
import { formatLocaleDate } from "./formatUtils";
import { toDate } from "./formUtils";
import { ALL_WHITE_SPACES_PATTERN, isDefined, isDefinedValue, parseBirthDateFromPin } from "./utils";

// Regex patterns
const uuidRegex = new RegExp("[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}");
const exactUuidRegex = new RegExp("^" + uuidRegex.source + "$");
const urlPathParamRegex = new RegExp("{\\d{1,2}}", "g");
const webPageRegex = new RegExp(
  "^(https?://(www\\.)?)?[a-z0-9]+([\\-.][a-z0-9]+)*\\.[a-z]{2,5}(:[0-9]{1,5})?(/.*)?(\\?([a-z0-9\\-_%]+=[a-z0-9\\-_%]+&?)+)?$"
);
const numberRegex = new RegExp("^\\d*$");
const wordRegex = XRegExp("^[\\p{L} \\-]*$");
const uppercaseWordRegex = XRegExp("^\\p{Lu}[\\p{L} \\-]*$");
const usernameRegex = new RegExp("^[a-zA-Z0-9_\\-]*$");
const emailRegex = new RegExp("^[a-zA-Z0-9_.\\-]+@[a-zA-Z0-9_.\\-]+\\.[a-zA-Z0-9]+$");
const nameRegex = XRegExp("^[\\p{L} \\-,.]*$");
const idCardRegex = new RegExp("^[A-Z]{2,4}[0-9]{6}$");
const vatIdRegex = new RegExp("^SK[0-9]{10}$");
const parcelNumberRegex = new RegExp("^[0-9]+(/[0-9]+)?$");
const streetRegex = XRegExp("^[\\p{L}0-9 .,\\-]*$");
const streetNumberRegex = new RegExp("^[0-9/A-Z]*$");
const filenameRegex = new RegExp("^[0-9a-zA-Z-_.]*$");
const vaccinationIdentifierRegex = new RegExp("^SK[0-9]{9}$");
const registrationCertificateNumberRegex = new RegExp("^[A-Z]{2}\\d{6}$");
const colorRegex = new RegExp("^#([a-fA-F0-9]{6}|[a-fA-F0-9]{3})$");
const agentSortNameRegex = new RegExp("^[^|]+$");
const vehicleCategoryValueRegex = new RegExp("^[a-zA-Z0-9.-]*$");
const vehicleColorValueRegex = XRegExp("^[\\p{L} ]*$");
const vehicleColorCertValueRegex = XRegExp("^[\\p{Lu} ]*$");
const vehicleFuelTypeNameRegex = new RegExp("^[A-Z 0-9+:]*$");

const licensePlatePattern = new RegExp("^([A-Z0-9]{7})|([A-Z]{2}[HMSVXZ][0-9]{3})|([CP][0-9]{5})$");

export const regexPatterns = {
  uuidRegex,
  exactUuidRegex,
  urlPathParamRegex,
  webPageRegex,
  numberRegex,
  wordRegex,
  uppercaseWordRegex,
  usernameRegex,
  emailRegex,
  nameRegex,
  idCardRegex,
  vatIdRegex,
  parcelNumberRegex,
  streetRegex,
  streetNumberRegex,
  filenameRegex,
  vaccinationIdentifierRegex,
  registrationCertificateNumberRegex,
  colorRegex,
  agentSortNameRegex,
  vehicleCategoryValueRegex,
  vehicleColorValueRegex,
  vehicleColorCertValueRegex,
  vehicleFuelTypeNameRegex
};

// Custom validation functions
const SEARCH_KEYWORD_MIN_LENGTH = 1;
const SEARCH_KEYWORD_MAX_LENGTH = 64;

const validateSearchKeyword = (keyword: string): boolean => {
  return keyword && keyword !== ""
    ? keyword.trim().length >= SEARCH_KEYWORD_MIN_LENGTH && keyword.trim().length <= SEARCH_KEYWORD_MAX_LENGTH
    : true;
};

const validateIban = (value: string): boolean => !value || ibanUtils.isValid(value);

const validatePin = (pin: string): boolean => {
  if (!pin) {
    return true;
  }

  if (isNumeric(pin) && pin.length >= 9 && pin.length <= 10) {
    const m = parseInt(pin.substring(2, 4));
    if ((m >= 1 && m <= 12) || (m >= 51 && m <= 62)) {
      const y = pin.substring(0, 2);
      const month = m > 50 ? m - 51 : m - 1;
      const day = parseInt(pin.substring(4, 6));
      if (pin.length === 9) {
        const year = parseInt("19" + y);
        if (day >= 1 && day <= dayjs().year(year).month(month).date(1).endOf("month").date()) {
          if (pin.substring(6) === "999") {
            return true;
          }
          return parseInt(y) < 54;
        }
      } else {
        const year = parseInt((dayjs().year() - parseInt(y) < 2000 ? "19" : "20") + y);
        if (day >= 1 && day <= dayjs().year(year).month(month).date(1).endOf("month").date()) {
          if (pin.substring(6) === "9999") {
            return true;
          }
          return parseInt(pin) % 11 === 0;
        }
      }
    }
  }

  return false;
};

const validateCrn = (value: string): boolean => !value || (numberRegex.test(value) && value.length === 8);

const validatePinOrCrn = (value: string): boolean => !value || validatePin(value) || validateCrn(value);

const validateClientIdentifier = (value: string, type: ClientType): boolean => {
  switch (type) {
    case ClientType.NATURAL:
      return validationFunctions.validatePin(value);
    case ClientType.SELF_EMPLOYED:
    case ClientType.LEGAL:
      return validationFunctions.validateCrn(value);
    default:
      return validationFunctions.validatePinOrCrn(value);
  }
};

const validateLicensePlate = (value?: string): boolean => {
  return value ? licensePlatePattern.test(value.replace(ALL_WHITE_SPACES_PATTERN, "")) : true;
};

const validatePhoneNumber = (value: string, type?: NumberType): boolean => {
  if (value) {
    try {
      const phoneNumber = parsePhoneNumber(value, "SK");
      return phoneNumber.isValid() && (type ? phoneNumber.getType() === type : true);
    } catch {
      return false;
    }
  }
  return true;
};

export const validationFunctions = {
  validateSearchKeyword,
  validateIban,
  validatePin,
  validateCrn,
  validatePinOrCrn,
  validateClientIdentifier,
  validateLicensePlate,
  validatePhoneNumber
};

export const validationConstants = {
  SEARCH_KEYWORD_MIN_LENGTH,
  SEARCH_KEYWORD_MAX_LENGTH
};

/**
 * Empty rule with no validations.
 * Can be used e.g. as cleaner of field error messages in case when error of given field was set programmatically
 * and no other validation rule is suitable for use.
 */
const none: Rule = { validator: () => Promise.resolve() };

const url: Rule = { type: "url", message: t("validation.url") };

const email: Rule = { pattern: emailRegex, message: t("validation.email") };

const notNull: Rule = { required: true, message: t("validation.notNull") };

const notBlank: Rule = { required: true, whitespace: true, message: t("validation.notBlank") };

const length = (len: number): Rule => ({ len, message: t("validation.length", { len }) });

const size = (min: number, max: number): Rule => ({ min, max, message: t("validation.size", { min, max }) });

const min = (min: number): Rule => ({ min, message: t("validation.min", { min }) });

const max = (max: number): Rule => ({ max, message: t("validation.max", { max }) });

const pattern = (pattern: RegExp | string): Rule => ({
  pattern: typeof pattern === "string" ? new RegExp(pattern) : pattern,
  message: t("validation.pattern")
});

const trueValue: Rule = {
  validator: (_, value) => (value === false ? Promise.reject(t("validation.trueValue")) : Promise.resolve())
};

const falseValue: Rule = {
  validator: (_, value) => (value === true ? Promise.reject(t("validation.falseValue")) : Promise.resolve())
};

const minNumber = (min?: number, minLabel?: string): Rule => ({
  validator: (_, value) =>
    isDefined(value) && isDefined<number>(min) && value < min
      ? Promise.reject(t("validation.minNumber", { min: minLabel ? minLabel : min }))
      : Promise.resolve()
});

const maxNumber = (max?: number, maxLabel?: string): Rule => ({
  validator: (_, value) =>
    isDefined(value) && isDefined<number>(max) && value > max
      ? Promise.reject(t("validation.maxNumber", { max: maxLabel ? maxLabel : max }))
      : Promise.resolve()
});

const positiveOrNegativeNumber: Rule = {
  validator: (_, value) =>
    isDefinedValue(value) && value === 0 ? Promise.reject(t("validation.positiveOrNegativeNumber")) : Promise.resolve()
};

const notPresentAndFuture: Rule = {
  validator: (_, value) => {
    const date = toDate(value);

    return dayjs.isDayjs(date) && date.isSameOrAfter(dayjs(), "day")
      ? Promise.reject(t("validation.date.notPresentAndFuture"))
      : Promise.resolve();
  }
};

const notFuture: Rule = {
  validator: (_, value) => {
    const date = toDate(value);

    return dayjs.isDayjs(date) && date.isAfter(dayjs(), "day")
      ? Promise.reject(t("validation.date.notFuture"))
      : Promise.resolve();
  }
};

const notPresentAndPast: Rule = {
  validator: (_, value) => {
    const date = toDate(value);

    return dayjs.isDayjs(date) && date.isSameOrBefore(dayjs(), "day")
      ? Promise.reject(t("validation.date.notPresentAndPast"))
      : Promise.resolve();
  }
};

const notPast: Rule = {
  validator: (_, value) => {
    const date = toDate(value);

    return dayjs.isDayjs(date) && date.isBefore(dayjs(), "day")
      ? Promise.reject(t("validation.date.notPast"))
      : Promise.resolve();
  }
};

const notPresent: Rule = {
  validator: (_, value) => {
    const date = toDate(value);

    return dayjs.isDayjs(date) && date.isSame(dayjs(), "day")
      ? Promise.reject(t("validation.date.notPresent"))
      : Promise.resolve();
  }
};

const notBefore = (min?: string | Dayjs, minLabel?: string): Rule => ({
  validator: (_, value) => {
    const date = toDate(value);

    return dayjs.isDayjs(date) && date.isBefore(min, "day")
      ? Promise.reject(t("validation.date.notBefore", { min: minLabel || formatLocaleDate(min) }))
      : Promise.resolve();
  }
});

const notSameOrBefore = (min: string | Dayjs, minLabel?: string): Rule => ({
  validator: (_, value) => {
    const date = toDate(value);

    return dayjs.isDayjs(date) && date.isSameOrBefore(min, "day")
      ? Promise.reject(t("validation.date.notSameOrBefore", { min: minLabel || formatLocaleDate(min) }))
      : Promise.resolve();
  }
});

const notAfter = (max: string | Dayjs, maxLabel?: string): Rule => ({
  validator: (_, value) => {
    const date = toDate(value);

    return dayjs.isDayjs(date) && date.isAfter(max, "day")
      ? Promise.reject(t("validation.date.notAfter", { max: maxLabel || formatLocaleDate(max) }))
      : Promise.resolve();
  }
});

const notSameOrAfter = (max: string | Dayjs, maxLabel?: string): Rule => ({
  validator: (_, value) => {
    const date = toDate(value);

    return dayjs.isDayjs(date) && date.isSameOrAfter(max, "day")
      ? Promise.reject(t("validation.date.notSameOrAfter", { max: maxLabel || formatLocaleDate(max) }))
      : Promise.resolve();
  }
});

const dateInInterval = (min: string | Dayjs, max: string | Dayjs): Rule => ({
  validator: (_, value) => {
    const date = toDate(value);

    return dayjs.isDayjs(date) && (date.isBefore(min, "day") || date.isAfter(max, "day"))
      ? Promise.reject(t("validation.date.inInterval", { min: formatLocaleDate(min), max: formatLocaleDate(max) }))
      : Promise.resolve();
  }
});

const multipleOf100: Rule = {
  validator: (_, value) =>
    value && value % 100 !== 0 ? Promise.reject(t("validation.multipleOf100")) : Promise.resolve()
};

const multipleOf1000: Rule = {
  validator: (_, value) =>
    value && value % 1000 !== 0 ? Promise.reject(t("validation.multipleOf1000")) : Promise.resolve()
};

const iban: Rule = {
  validator: (_, value) => (validateIban(value) ? Promise.resolve() : Promise.reject(t("validation.iban")))
};

const numeric: Rule = {
  validator: (_, value) =>
    value && !numberRegex.test(value) ? Promise.reject(t("validation.number")) : Promise.resolve()
};

const phoneNumber: Rule = {
  validator: (_, value) =>
    validatePhoneNumber(value) ? Promise.resolve() : Promise.reject(t("validation.phoneNumber"))
};

const fixedLinePhoneNumber: Rule = {
  validator: (_, value) =>
    validatePhoneNumber(value, "FIXED_LINE") ? Promise.resolve() : Promise.reject(t("validation.fixedLinePhoneNumber"))
};

const mobilePhoneNumber: Rule = {
  validator: (_, value) =>
    validatePhoneNumber(value, "MOBILE") ? Promise.resolve() : Promise.reject(t("validation.mobilePhoneNumber"))
};

const sufficientPassword: Rule[] = [
  notBlank,
  {
    validator: (_, value) => {
      if (!value || value.trim().length < 8) {
        return Promise.reject(t("validation.password.length"));
      }

      let hasLowerCaseLetter = false;
      let hasUpperCaseLetter = false;
      let hasNumber = false;

      for (let i = 0; i < value.length; i++) {
        if (isLowerCase(value.charAt(i))) {
          hasLowerCaseLetter = true;
        } else if (isUpperCase(value.charAt(i))) {
          hasUpperCaseLetter = true;
        } else if (isNumeric(value.charAt(i))) {
          hasNumber = true;
        }
      }

      if (!hasLowerCaseLetter) {
        return Promise.reject(t("validation.password.lowerCaseMissing"));
      }
      if (!hasUpperCaseLetter) {
        return Promise.reject(t("validation.password.upperCaseMissing"));
      }
      if (!hasNumber) {
        return Promise.reject(t("validation.password.numberMissing"));
      }

      return Promise.resolve();
    }
  }
];

const repeatedPassword =
  (passwordKey: string = "password"): Rule =>
  ({ getFieldValue }) => ({
    validator: (_, value) =>
      value && value !== getFieldValue(passwordKey)
        ? Promise.reject(t("validation.password.repeatError"))
        : Promise.resolve()
  });

const pin: Rule = {
  validator: (_, value) => (validatePin(value) ? Promise.resolve() : Promise.reject(t("validation.pin")))
};

const crn: Rule = {
  validator: (_, value) => (validateCrn(value) ? Promise.resolve() : Promise.reject(t("validation.crn")))
};

const pinOrCrn: Rule = {
  validator: (_, value) => (validatePinOrCrn(value) ? Promise.resolve() : Promise.reject(t("validation.pinOrCrn")))
};

const adultByPin: Rule = {
  validator: (_, value) =>
    value && validationFunctions.validatePin(value) && dayjs().diff(parseBirthDateFromPin(value), "years") < 18
      ? Promise.reject(t("validation.adultByPin"))
      : Promise.resolve()
};

const licensePlate: Rule = {
  validator: (_, value) => {
    if (value) {
      const trimmedValue = value.replace(ALL_WHITE_SPACES_PATTERN, "");
      return trimmedValue.length >= 6 && trimmedValue.length <= 7 && validateLicensePlate(value)
        ? Promise.resolve()
        : Promise.reject(t("validation.licensePlate"));
    }
    return Promise.resolve();
  }
};

const fullLicensePlate: Rule = {
  validator: (_, value) =>
    value && (value.replace(ALL_WHITE_SPACES_PATTERN, "").length !== 7 || !validateLicensePlate(value))
      ? Promise.reject(t("validation.licensePlate"))
      : Promise.resolve()
};

const noRepeatedValue =
  (arrayPath: NamePath, messageKey: string = "validation.noRepeatedValue"): Rule =>
  ({ getFieldValue }) => ({
    validator: (_, value) =>
      value && ((getFieldValue(arrayPath) as any[]) || []).filter(v => v === value).length > 1
        ? Promise.reject(t(messageKey))
        : Promise.resolve()
  });

const noRepeatedClient = (arrayPath: NamePath): Rule => noRepeatedValue(arrayPath, "validation.noRepeatedClient");

const notNullIfOtherNull =
  (comparedFieldPath: NamePath, comparedFieldName: string): Rule =>
  ({ getFieldValue }) => ({
    validator: (_, value) =>
      !value && !getFieldValue(comparedFieldPath)
        ? Promise.reject(t("validation.notNullIfOtherNull", { fieldName: comparedFieldName }))
        : Promise.resolve()
  });

const notNullIfOtherNotNull =
  (comparedFieldPath: NamePath, comparedFieldName: string): Rule =>
  ({ getFieldValue }) => ({
    validator: (_, value) =>
      !value && getFieldValue(comparedFieldPath)
        ? Promise.reject(t("validation.notNullIfOtherNotNull", { fieldName: comparedFieldName }))
        : Promise.resolve()
  });

const notFalseIfOtherFalse =
  (comparedFieldPath: NamePath, comparedFieldName: string): Rule =>
  ({ getFieldValue }) => ({
    validator: (_, value) =>
      !value && !getFieldValue(comparedFieldPath)
        ? Promise.reject(t("validation.notFalseIfOtherFalse", { fieldName: comparedFieldName }))
        : Promise.resolve()
  });

const notEqualTo =
  (comparedFieldPath: NamePath, comparedFieldName: string): Rule =>
  ({ getFieldValue }) => ({
    validator: (_, value) =>
      value && value === getFieldValue(comparedFieldPath)
        ? Promise.reject(t("validation.notEqualTo", { fieldName: comparedFieldName }))
        : Promise.resolve()
  });

const jsonValue: Rule = {
  validator: (_, value) => (value && !JSON.parse(value) ? Promise.reject(t("validation.jsonValue")) : Promise.resolve())
};

const conditionalRule = (rule: Rule, condition?: boolean): Rule =>
  condition ? rule : { validator: () => Promise.resolve() };

const gainersRateSumOneHundred =
  (gainersCount: number, path?: (string | number)[]): Rule =>
  ({ getFieldValue }) => ({
    validator: () => {
      let sum = 0;

      Array.from({ length: gainersCount }).forEach((_, i) => {
        const rate = getFieldValue([...(path ?? []), `gainer${i + 1}Rate`]);

        if (!rate) {
          return;
        } else {
          sum += rate;
        }
      });

      if (sum === 100) {
        return Promise.resolve();
      }

      return Promise.reject(t("validation.gainerRatesSumMustBeOneHundred"));
    }
  });

const noRepeatedGainer =
  (gainersCount: number, path?: (string | number)[]): Rule =>
  ({ getFieldValue }) => ({
    validator: (_, value) => {
      const result =
        Array.from({ length: gainersCount })
          .map((_, i) => getFieldValue([...(path ?? []), `gainer${i + 1}Id`]))
          .filter(gainer => value === gainer).length === 1;

      return result ? Promise.resolve() : Promise.reject(t("validation.duplicateGainerWithinSameRecord"));
    }
  });

export const validations = {
  none,
  url,
  email,
  notNull,
  notBlank,
  length,
  size,
  min,
  max,
  pattern,
  trueValue,
  falseValue,
  minNumber,
  maxNumber,
  positiveOrNegativeNumber,
  notPresentAndFuture,
  notFuture,
  notPresentAndPast,
  notPast,
  notPresent,
  notBefore,
  notSameOrBefore,
  notAfter,
  notSameOrAfter,
  dateInInterval,
  multipleOf100,
  multipleOf1000,
  iban,
  numeric,
  phoneNumber,
  fixedLinePhoneNumber,
  mobilePhoneNumber,
  sufficientPassword,
  repeatedPassword,
  pin,
  crn,
  pinOrCrn,
  adultByPin,
  licensePlate,
  fullLicensePlate,
  noRepeatedValue,
  noRepeatedClient,
  notNullIfOtherNull,
  notNullIfOtherNotNull,
  notFalseIfOtherFalse,
  notEqualTo,
  jsonValue,
  conditionalRule,
  gainersRateSumOneHundred,
  noRepeatedGainer
};
