import { AxiosPromise } from "axios";
import { ApiRequestAdapter } from "../../../common/api/ApiRequestAdapter";
import { apiService } from "../../../common/api/apiService";
import { EntityIdObject, EntityObject } from "../../../common/types";
import { serializeParams } from "../../../common/utils/apiUtils";
import {
  CertificateFuelType,
  CertificateFuelTypeFilterPageRequest,
  CertificateFuelTypeFilterPageResult,
  CreateUpdateCertificateFuelType
} from "./types";

const FILTER_VEHICLE_FUEL_TYPES = ApiRequestAdapter.create("/vehicle-fuel-types", "GET");
const CREATE_VEHICLE_FUEL_TYPE = ApiRequestAdapter.create("/vehicle-fuel-types", "POST");
const UPDATE_VEHICLE_FUEL_TYPE = ApiRequestAdapter.create("/vehicle-fuel-types/{0}", "PUT");
const DELETE_VEHICLE_FUEL_TYPE = ApiRequestAdapter.create("/vehicle-fuel-types/{0}", "DELETE");

export const requests = {
  FILTER_VEHICLE_FUEL_TYPES,
  CREATE_VEHICLE_FUEL_TYPE,
  UPDATE_VEHICLE_FUEL_TYPE,
  DELETE_VEHICLE_FUEL_TYPE
};

const api = {
  filterVehicleFuelTypes: (
    request: CertificateFuelTypeFilterPageRequest
  ): AxiosPromise<CertificateFuelTypeFilterPageResult> => {
    return apiService.get(FILTER_VEHICLE_FUEL_TYPES.url, {
      params: request,
      paramsSerializer: { serialize: serializeParams }
    });
  },
  createVehicleFuelType: (request: CreateUpdateCertificateFuelType): AxiosPromise<CertificateFuelType> => {
    return apiService.post(CREATE_VEHICLE_FUEL_TYPE.url, request);
  },
  updateVehicleFuelType: (
    request: EntityObject<CreateUpdateCertificateFuelType>
  ): AxiosPromise<CertificateFuelType> => {
    return apiService.put(UPDATE_VEHICLE_FUEL_TYPE.getParametrizedUrl(request.id), request.object);
  },
  deleteVehicleFuelType: (request: EntityIdObject): AxiosPromise => {
    return apiService.delete(DELETE_VEHICLE_FUEL_TYPE.getParametrizedUrl(request.id));
  }
};

export default api;
