import { Popconfirm } from "antd";
import { useTranslation } from "react-i18next";
import ActionButton from "../../../../../../common/components/buttons/ActionButton";
import AntIcon from "../../../../../../common/components/icons/AntIcon";
import { tInterval } from "../../../../../../common/utils/translationUtils";
import { TravelCalcDraft } from "../../types";

type Props = {
  draft?: TravelCalcDraft;
  handleSaveDraft: (value: boolean) => void;
};

export const TravelSaveDraftButton = ({ draft, handleSaveDraft }: Props) => {
  const { t } = useTranslation();

  return (
    <div>
      {draft ? (
        <Popconfirm
          title={
            <>
              <b>{t("calc.helpers.loadedDraftTitle")}:</b>
              <div className="margin-top-tiny margin-bottom-tiny">
                {draft.clientName}
                <br />
                {t("calc.travel.enums.insuranceType." + draft.draftData.generalData.insuranceType)}
                <br />
                {t("calc.travel.enums.territorialValidity." + draft.draftData.generalData.territorialValidity)}
                {draft.draftData.generalData.riskGroup &&
                  ` | ${t("calc.travel.enums.riskGroup." + draft.draftData.generalData.riskGroup)}`}
                <br />
                {draft.draftData.clientsData.insuredClients &&
                  tInterval(
                    "contract.helpers.insuredClientsCount_interval",
                    draft.draftData.clientsData.insuredClients.length
                  )}
              </div>
              <i>{t("calc.helpers.loadedDraftDesc")}</i>
            </>
          }
          icon={<AntIcon type="question" />}
          okText={t("calc.actions.overwriteDraft")}
          cancelText={t("calc.actions.saveNewDraft")}
          overlayStyle={{ maxWidth: 450 }}
          onConfirm={() => handleSaveDraft(true)}
          onCancel={() => handleSaveDraft(false)}
        >
          <span>
            <ActionButton icon="form" color="primary" variant="outlined" size="middle">
              {t("calc.actions.saveDraft")}
            </ActionButton>
          </span>
        </Popconfirm>
      ) : (
        <ActionButton
          icon="form"
          color="primary"
          variant="outlined"
          size="middle"
          onClick={() => handleSaveDraft(false)}
        >
          {t("calc.actions.saveDraft")}
        </ActionButton>
      )}
    </div>
  );
};
