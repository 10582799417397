import { AxiosPromise } from "axios";
import { ApiRequestAdapter } from "../../../common/api/ApiRequestAdapter";
import { apiService } from "../../../common/api/apiService";
import { EntityIdObject } from "../../../common/types";
import { CalcRecord, CalcRecordFilterPageRequest, CalcRecordFilterPageResult } from "./types";

const FILTER_CALC_RECORDS = ApiRequestAdapter.create("/calculators/records", "GET");
const GET_CALC_RECORD_REQUEST_RESPONSE = ApiRequestAdapter.create("/calculators/records/{0}/request-response", "GET");
const GET_CALC_RECORD_WEBSERVICES_LOGS = ApiRequestAdapter.create("/calculators/records/{0}/webservices-logs", "GET");
const GET_CALC_RECORD_STACK_TRACES_LOGS = ApiRequestAdapter.create("/calculators/records/{0}/stack-traces-logs", "GET");

export const requests = {
  FILTER_CALC_RECORDS,
  GET_CALC_RECORD_REQUEST_RESPONSE,
  GET_CALC_RECORD_WEBSERVICES_LOGS,
  GET_CALC_RECORD_STACK_TRACES_LOGS
};

const api = {
  filterCalcRecords: (request: CalcRecordFilterPageRequest): AxiosPromise<CalcRecordFilterPageResult> => {
    return apiService.get(FILTER_CALC_RECORDS.url, { params: request });
  },
  getCalcRecordRequestResponse: (request: EntityIdObject): AxiosPromise<CalcRecord> => {
    return apiService.get(GET_CALC_RECORD_REQUEST_RESPONSE.getParametrizedUrl(request.id));
  },
  getCalcRecordWebservicesLogs: (request: EntityIdObject): AxiosPromise<CalcRecord> => {
    return apiService.get(GET_CALC_RECORD_WEBSERVICES_LOGS.getParametrizedUrl(request.id));
  },
  getCalcRecordStackTracesLogs: (request: EntityIdObject): AxiosPromise<CalcRecord> => {
    return apiService.get(GET_CALC_RECORD_STACK_TRACES_LOGS.getParametrizedUrl(request.id));
  }
};

export default api;
