import { Col, Form, Input, Row, Select } from "antd";
import { FormInstance, Rule } from "antd/lib/form";
import classNames from "classnames";
import get from "lodash/get";
import { FieldData } from "rc-field-form/lib/interface";
import { useEffect, useState } from "react";
import t from "../../../app/i18n";
import CountrySelect from "../../../modules/enumerations/components/form/CountrySelect";
import Ellipsis from "../../components/views/Ellipsis";
import { BratislavaCityDistricts, KosiceCityDistricts, rowGutter } from "../../constants";
import { selectStandardProps } from "../../utils/formUtils";
import { regexPatterns, validations } from "../../utils/validationUtils";
import { Country } from "../enums";
import { Address } from "../types";
import AddressAutocomplete from "./AddressAutocomplete";

interface Props {
  form: FormInstance;
  rootNamePath: (string | number)[];
  label: string;
  required?: boolean;
  buildingFinished: boolean;
  allowOnlySvkCountry?: boolean;
}

const onlySvkCountryRule = (allowOnlySvkCountry?: boolean): Rule => ({
  validator: (_, value) =>
    value && value !== Country.SVK && allowOnlySvkCountry
      ? Promise.reject(t("address.validations.onlySvkCountry"))
      : Promise.resolve()
});

const PlaceOfInsuranceForm = ({
  form,
  rootNamePath,
  label,
  required,
  buildingFinished,
  allowOnlySvkCountry
}: Props) => {
  const [streetFilled, setStreetFilled] = useState<boolean>();

  useEffect(() => {
    form.validateFields([[...rootNamePath, streetFilled ? "descriptiveNumber" : "orientationNumber"]]);
  }, [streetFilled]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleAddressAutocompleteFind = (address: Address): void => {
    form.setFields(
      [...Object.keys(address), "parcelNumber1", "parcelNumber2"].map<FieldData>(key => ({
        name: [...rootNamePath, key],
        value: address[key as keyof Address]
      }))
    );
  };

  const handleCityChange = (): void => {
    form.setFieldValue([...rootNamePath, "cityDistrict"], undefined);
  };

  return (
    <>
      <Row gutter={rowGutter} className="margin-bottom-tiny">
        <Col className={classNames("bold-text", "center-label-input", { "form-item-required": required })}>{label}</Col>
        <Col>
          <AddressAutocomplete onAddressFind={handleAddressAutocompleteFind} />
        </Col>
      </Row>

      <Row gutter={rowGutter}>
        <Col span={4}>
          <Form.Item
            name={[...rootNamePath, "street"]}
            label={t("address.attrs.street")}
            rules={[validations.size(1, 255), validations.pattern(regexPatterns.streetRegex)]}
          >
            <Input onChange={e => setStreetFilled(!!e.target.value)} />
          </Form.Item>
        </Col>

        <Form.Item
          noStyle
          shouldUpdate={(prev, next) => get(prev, rootNamePath)?.street !== get(next, rootNamePath)?.street}
        >
          {({ getFieldValue }) => {
            const hasStreet = !!getFieldValue([...rootNamePath, "street"]);
            return (
              <>
                <Col span={2}>
                  <Form.Item
                    name={[...rootNamePath, "descriptiveNumber"]}
                    label={<Ellipsis>{t("address.attrs.descriptiveNumber")}</Ellipsis>}
                    dependencies={[[...rootNamePath, "parcelNumber1"]]}
                    rules={[
                      buildingFinished && !hasStreet ? validations.notBlank : validations.none,
                      !buildingFinished && !hasStreet
                        ? validations.notNullIfOtherNull(
                            [...rootNamePath, "parcelNumber1"],
                            t("address.attrs.parcelNumber1")
                          )
                        : validations.none,
                      validations.size(1, 64),
                      validations.pattern(regexPatterns.streetNumberRegex)
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>

                <Col span={2}>
                  <Form.Item
                    name={[...rootNamePath, "orientationNumber"]}
                    label={<Ellipsis>{t("address.attrs.orientationNumber")}</Ellipsis>}
                    dependencies={[[...rootNamePath, "parcelNumber1"]]}
                    rules={[
                      buildingFinished && hasStreet ? validations.notBlank : validations.none,
                      !buildingFinished && hasStreet
                        ? validations.notNullIfOtherNull(
                            [...rootNamePath, "parcelNumber1"],
                            t("address.attrs.parcelNumber1")
                          )
                        : validations.none,
                      validations.size(1, 64),
                      validations.pattern(regexPatterns.streetNumberRegex)
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>

                {!buildingFinished && (
                  <>
                    <Col span={2}>
                      <Form.Item
                        name={[...rootNamePath, "parcelNumber1"]}
                        label={t("address.attrs.parcelNumber1")}
                        dependencies={[
                          [...rootNamePath, "descriptiveNumber"],
                          [...rootNamePath, "orientationNumber"],
                          [...rootNamePath, "parcelNumber2"]
                        ]}
                        rules={[
                          hasStreet
                            ? validations.notNullIfOtherNull(
                                [...rootNamePath, "orientationNumber"],
                                t("address.attrs.orientationNumber")
                              )
                            : validations.notNullIfOtherNull(
                                [...rootNamePath, "descriptiveNumber"],
                                t("address.attrs.descriptiveNumber")
                              ),
                          validations.notNullIfOtherNotNull(
                            [...rootNamePath, "parcelNumber2"],
                            t("address.attrs.parcelNumber2")
                          ),
                          validations.size(1, 64),
                          validations.pattern(regexPatterns.parcelNumberRegex)
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>

                    <Col span={2}>
                      <Form.Item
                        name={[...rootNamePath, "parcelNumber2"]}
                        label={t("address.attrs.parcelNumber2")}
                        rules={[validations.size(1, 64), validations.pattern(regexPatterns.parcelNumberRegex)]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                  </>
                )}
              </>
            );
          }}
        </Form.Item>

        <Col span={4}>
          <Form.Item
            name={[...rootNamePath, "city"]}
            label={t("address.attrs.city")}
            rules={[validations.notBlank, validations.size(1, 64), validations.pattern(regexPatterns.wordRegex)]}
          >
            <Input onChange={handleCityChange} />
          </Form.Item>
        </Col>

        <Form.Item
          noStyle
          shouldUpdate={(prev, next) => get(prev, rootNamePath)?.city !== get(next, rootNamePath)?.city}
        >
          {({ getFieldValue }) => {
            const city = getFieldValue([...rootNamePath, "city"]) as string;
            const districts: string[] = [];
            if (city?.toLowerCase() === "bratislava") {
              districts.push(...BratislavaCityDistricts);
            } else if (city?.toLowerCase() === "košice") {
              districts.push(...KosiceCityDistricts);
            }

            return districts.length > 0 ? (
              <Col span={4}>
                <Form.Item
                  name={[...rootNamePath, "cityDistrict"]}
                  label={t("address.attrs.cityDistrict")}
                  rules={[validations.none]}
                >
                  <Select
                    {...selectStandardProps}
                    options={districts.map(district => ({ value: district, label: district }))}
                  />
                </Form.Item>
              </Col>
            ) : undefined;
          }}
        </Form.Item>

        <Col span={2}>
          <Form.Item
            name={[...rootNamePath, "zipCode"]}
            label={t("address.attrs.zipCode")}
            rules={[validations.notBlank, validations.length(5), validations.numeric]}
          >
            <Input />
          </Form.Item>
        </Col>

        <Col span={2}>
          <CountrySelect
            formItemProps={{
              name: [...rootNamePath, "country"],
              label: t("address.enums.country._label"),
              rules: [validations.notNull, onlySvkCountryRule(allowOnlySvkCountry)],
              initialValue: Country.SVK
            }}
          />
        </Col>
      </Row>
    </>
  );
};

export default PlaceOfInsuranceForm;
