import { Flex, Form, Input, type InputRef, message, Tooltip } from "antd";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useGetAgentPartnerIdNumber } from "../../../../common/api/queries";
import AntIcon from "../../../../common/components/icons/AntIcon";
import { validations } from "../../../../common/utils/validationUtils";

type Props = {
  shouldAutoLoad: boolean;
};

const LABEL_TEXT_WIDTH = 105;

export const AgentPartnerIdNumber = ({ shouldAutoLoad }: Props) => {
  const { t } = useTranslation();

  const [messageApi, contextHolder] = message.useMessage();
  const form = Form.useFormInstance();

  const { data, refetch } = useGetAgentPartnerIdNumber();

  const ref = useRef<InputRef>(null);
  const [actionsWidth, setActionsWidth] = useState<number>(0);

  useEffect(() => {
    if (data && shouldAutoLoad) {
      form.setFieldValue("partnerIdNumber", data);
    }
  }, [form, data, shouldAutoLoad]);

  useEffect(() => {
    const elementWidth = ref.current?.input?.offsetWidth;

    if (elementWidth) {
      setActionsWidth(elementWidth - LABEL_TEXT_WIDTH);
    }
  }, [ref.current?.input?.offsetWidth]);

  const renderLabel = () => (
    <Flex style={{ width: "100%" }} justify="space-between">
      <span style={{ width: LABEL_TEXT_WIDTH }}>{t("agent.attrs.partnerIdNumber")}</span>
      <span style={{ width: actionsWidth, textAlign: "right", overflow: "hidden" }}>
        <Tooltip title={t("agent.helpers.generatePartnerIdNumberHint")}>
          <span>
            <AntIcon
              type="id-card"
              color="green"
              onClick={async () => {
                const { data } = await refetch();

                if (data) {
                  form.setFieldValue("partnerIdNumber", data);
                } else {
                  messageApi.info(t("agent.helpers.errorInfoPartnerIdNumber"), 5);
                }
              }}
            />
          </span>
        </Tooltip>
      </span>
    </Flex>
  );

  return (
    <>
      {contextHolder}

      <Form.Item name="partnerIdNumber" label={renderLabel()} rules={[validations.size(1, 64), validations.numeric]}>
        <Input ref={ref} />
      </Form.Item>
    </>
  );
};
