import { Alert, Button, Card, Col, Divider, Form, Input, message, Modal, Row, Select, Space } from "antd";
import { useForm } from "antd/es/form/Form";
import { UploadFile } from "antd/lib/upload/interface";
import debounce from "lodash/debounce";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useRegisterVehicleData, useSearchContract } from "../../../../common/api/queries";
import LabelWithTooltip from "../../../../common/components/form/labels/LabelWithTooltip";
import AntIcon from "../../../../common/components/icons/AntIcon";
import UploadDragger from "../../../../common/components/views/UploadDragger";
import { FileUploadAccept, rowGutter } from "../../../../common/constants";
import {
  convertObjectToFormData,
  licensePlateNormalizeFunction,
  setErrorsToForm,
  upperCaseStringNormalizeFunction
} from "../../../../common/utils/formUtils";
import { useFilterSearchParams } from "../../../../common/utils/hooksUtils";
import { ALL_WHITE_SPACES_PATTERN } from "../../../../common/utils/utils";
import { regexPatterns, validations } from "../../../../common/utils/validationUtils";
import { InsuranceType } from "../../../contract/enums";
import { isVehicleInsurance } from "../../../contract/utils";
import InstitutionSelect from "../../../enumerations/components/form/InstitutionSelect";
import { selectInstitutionsEnums } from "../../../enumerations/ducks";
import { InstitutionEnum, InstitutionType } from "../../../institution/enums";
import { ContractFormSearchContract, RegisterVehicleData } from "../../types";

const COVERED_INSURANCE_INSTITUTIONS = [
  InstitutionEnum.GENERALI,
  InstitutionEnum.GENERTEL,
  InstitutionEnum.KOMUNALNA,
  InstitutionEnum.KOOPERATIVA,
  InstitutionEnum.UNION,
  InstitutionEnum.WUSTENROT,
  InstitutionEnum.PILLOW
];

export const RegisterVehicleDataForm = () => {
  const { t } = useTranslation();

  const [messageApi, contextHolder] = message.useMessage();
  const [form] = useForm<RegisterVehicleData>();
  const institutionId = Form.useWatch("institutionId", form);

  const [isWustenrotOrKooperativa, setIsWustenrotOrKooperativa] = useState(false);
  const [isUniqa, setIsUniqa] = useState(false);
  const [isCSOB, setIsCSOB] = useState(false);
  const [isAllianz, setIsAllianz] = useState(false);

  const searchParams = useFilterSearchParams<{ contractNumber: string }>({ params: ["contractNumber"] });
  const [searchKeyword, setSearchKeyword] = useState(searchParams.contractNumber);

  const institutionEnum = useSelector(selectInstitutionsEnums);
  const isCoveredInstitution = useMemo(
    () =>
      !!institutionEnum.find(
        enumeration =>
          enumeration.id === institutionId &&
          enumeration.institutionEnum &&
          COVERED_INSURANCE_INSTITUTIONS.includes(enumeration.institutionEnum)
      ),
    [institutionEnum, institutionId]
  );
  const isNotSupportedInstitution = !isUniqa && !isCSOB && !isAllianz && !isCoveredInstitution;

  const { data, isLoading, refetch } = useSearchContract({ keyword: searchKeyword });
  const registerVehicleData = useRegisterVehicleData();

  const debouncedSetSearchKeyword = useMemo(() => debounce(setSearchKeyword, 500), []);
  const handleSearchChange = useCallback(
    (value: string) => debouncedSetSearchKeyword(value),
    [debouncedSetSearchKeyword]
  );

  const handleSearch = async (value: string) => {
    setSearchKeyword(value);

    const { data } = await refetch();

    if (data?.contract) {
      setFormData(data?.contract);
    }
  };

  const setFormData = useCallback(
    (contract: ContractFormSearchContract) => {
      form.setFieldsValue({
        contractNumber: contract.identifier,
        institutionId: contract.institution.id,
        insuranceType: contract.product.insuranceType,
        clientName: contract.policyHolder.aggregatedName
      });

      const insurance = contract.insurances[0];

      if (insurance && isVehicleInsurance(insurance)) {
        form.setFieldsValue({
          vin: insurance.vehicle.vin,
          licensePlate: insurance.licensePlate,
          registrationCertificateNumber: insurance.insuranceData.registrationCertificateNumber
        });
      }

      messageApi.open({
        key: "loadContractInfo",
        type: "success",
        content: t("registerVehicleData.helpers.loadContractInfo")
      });
    },
    [form]
  );

  useEffect(() => {
    const contract = data?.contract;

    if (contract) {
      setFormData(contract);
    }
  }, [data, setFormData]);

  useEffect(() => {
    if (!institutionId) {
      setIsWustenrotOrKooperativa(false);
      setIsCSOB(false);
      setIsAllianz(false);
      setIsUniqa(false);
      return;
    }

    const institutionMap = {
      isWustenrotOrKooperative: [InstitutionEnum.KOOPERATIVA, InstitutionEnum.WUSTENROT],
      isCSOB: [InstitutionEnum.CSOB],
      isAllianz: [InstitutionEnum.ALLIANZ],
      isUniqa: [InstitutionEnum.UNIQA]
    };

    const resultInstitutionEnum = institutionEnum.find(
      enumeration => enumeration.id === institutionId
    )?.institutionEnum;

    Object.entries(institutionMap).forEach(([key, values]) => {
      const isMatch = !!resultInstitutionEnum && values.includes(resultInstitutionEnum);

      switch (key) {
        case "isWustenrotOrKooperative":
          setIsWustenrotOrKooperativa(isMatch);
          break;
        case "isCSOB":
          setIsCSOB(isMatch);
          break;
        case "isAllianz":
          setIsAllianz(isMatch);
          break;
        case "isUniqa":
          setIsUniqa(isMatch);
          break;
      }
    });
  }, [institutionId]);

  const renderAlertMessage = () => {
    if (!institutionId) {
      return;
    }

    if (isNotSupportedInstitution) {
      return (
        <Alert
          className="margin-bottom-small"
          message={t("registerVehicleData.helpers.notSupportedInstitution")}
          type="info"
          showIcon
        />
      );
    } else if (isCSOB) {
      return (
        <Alert
          className="margin-bottom-small"
          message={t("registerVehicleData.helpers.csobInfo")}
          type="info"
          showIcon
        />
      );
    } else if (isUniqa) {
      return (
        <Alert
          className="margin-bottom-small"
          message={
            <span>
              {t("registerVehicleData.helpers.toCompleteData")}{" "}
              <Link to="https://epodatelna.uniqa.sk/klient/odoslanie-zasielky" target="_blank">
                www.epodatelna.uniqa.sk/klient/odoslanie-zasielky
              </Link>
              .
            </span>
          }
          type="info"
          showIcon
        />
      );
    } else if (isAllianz) {
      return (
        <Alert
          className="margin-bottom-small"
          message={
            <span>
              {t("registerVehicleData.helpers.toCompleteData")}{" "}
              <Link to="https://portal.allianz.sk/" target="_blank">
                www.portal.allianz.sk
              </Link>{" "}
              (e-žiadanky).
            </span>
          }
          type="info"
          showIcon
        />
      );
    }

    return;
  };

  const handleFormSubmit = () => {
    form.validateFields().then(values => {
      const formData = convertObjectToFormData({ object: values });

      registerVehicleData.mutate(formData, {
        onError: error => {
          setErrorsToForm(form, "registerVehicleData.attr", error.violations);
        }
      });
    });
  };

  const handleFormReset = () => {
    Modal.confirm({
      title: t("contractTermination.helpers.resetFormConfirm"),
      okText: t("common.yes"),
      cancelText: t("common.back"),
      onOk: () => {
        form.resetFields();
        setSearchKeyword(undefined);
      }
    });
  };

  return (
    <Card className="card-box">
      {contextHolder}

      <Form form={form} name="registerVehicleForm" layout="vertical">
        <Row gutter={rowGutter}>
          <Col span={8}>
            <Form.Item
              name="contractNumber"
              label={t("registerVehicleData.attrs.contractNumber")}
              rules={[validations.notNull, validations.size(1, 64)]}
            >
              <Input.Search
                loading={isLoading}
                onSearch={handleSearch}
                onChange={e => handleSearchChange(e.target.value)}
              />
            </Form.Item>
          </Col>
        </Row>

        <Divider orientation="left">{t("registerVehicleData.sections.generalData")}</Divider>

        <Row gutter={rowGutter}>
          <Col span={8}>
            <InstitutionSelect
              formItemProps={{
                name: "institutionId",
                label: t("registerVehicleData.attrs.institutionId"),
                rules: [validations.notNull]
              }}
              optionsProps={{ filterType: InstitutionType.INSURANCE_COMPANY }}
            />
          </Col>

          <Col span={8}>
            {isWustenrotOrKooperativa ? (
              <Form.Item
                name="insuranceType"
                label={t("contract.enums.insuranceType._label")}
                rules={[validations.notNull]}
              >
                <Select
                  options={[InsuranceType.MTPL, InsuranceType.CRASH].map(type => ({
                    value: type,
                    label: t(`contract.enums.insuranceType.${type}`)
                  }))}
                />
              </Form.Item>
            ) : undefined}
          </Col>

          <Col span={24}>{renderAlertMessage()}</Col>
        </Row>

        <Row gutter={rowGutter}>
          <Col span={8}>
            <Form.Item
              name="clientName"
              label={t("registerVehicleData.attrs.clientName")}
              rules={[validations.notBlank, validations.max(255)]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={rowGutter}>
          <Col span={8}>
            <Form.Item
              name="vin"
              label={t("registerVehicleData.attrs.vin")}
              normalize={upperCaseStringNormalizeFunction}
              rules={[validations.notBlank, validations.size(3, 17)]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="licensePlate"
              label={t("registerVehicleData.attrs.licensePlate")}
              getValueProps={value => ({ value: licensePlateNormalizeFunction(value) })}
              normalize={value => value?.replace(ALL_WHITE_SPACES_PATTERN, "").toUpperCase()}
              rules={[
                validations.licensePlate,
                validations.notNullIfOtherNull(
                  "registrationCertificateNumber",
                  t("registerVehicleData.attrs.registrationCertificateNumber")
                )
              ]}
              dependencies={["registrationCertificateNumber"]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item
              name={"registrationCertificateNumber"}
              label={
                <LabelWithTooltip
                  label={t("registerVehicleData.attrs.registrationCertificateNumber")}
                  tooltip={t("calc.vehicle.helpers.registrationCertificateNumberDesc")}
                />
              }
              dependencies={["licensePlate"]}
              rules={[
                validations.pattern(regexPatterns.registrationCertificateNumberRegex),
                validations.length(8),
                validations.notNullIfOtherNull("licensePlate", t("registerVehicleData.attrs.licensePlate"))
              ]}
              normalize={upperCaseStringNormalizeFunction}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item
          name="files"
          label={t("registerVehicleData.attrs.files")}
          getValueFromEvent={({ fileList }) =>
            fileList.map((item: UploadFile | File) => (item instanceof File ? item : item.originFileObj))
          }
          rules={[validations.none]}
          valuePropName="fileList"
        >
          <UploadDragger
            accept={[FileUploadAccept.IMAGE, FileUploadAccept.PDF].join(", ")}
            multiple
            beforeUpload={() => false}
          />
        </Form.Item>
      </Form>

      <Space className="margin-top-medium">
        <Button
          type="primary"
          icon={<AntIcon type="send" />}
          onClick={handleFormSubmit}
          disabled={!isCoveredInstitution}
        >
          {t("common.send")}
        </Button>

        <Button icon={<AntIcon type="reload" />} onClick={handleFormReset}>
          {t("contractTermination.actions.resetForm")}
        </Button>
      </Space>
    </Card>
  );
};
