import { AxiosPromise } from "axios";
import { ApiRequestAdapter } from "../../common/api/ApiRequestAdapter";
import { apiService } from "../../common/api/apiService";
import type { UUID } from "../../typings/global";
import {
  CreateUpdateMultiplePartnerConfigs,
  LogoutFromPartnerSso,
  PartnerConfig,
  PartnerConfigsAndSsoProperties,
  PartnerSso,
  RequestPartnerSso,
  RequestPartnerSsoAuthRedirectUrl
} from "./types";

const GET_PARTNER_CONFIGS_AND_SSO_PROPS = ApiRequestAdapter.create("/partner-configs", "GET");
const CREATE_PARTNER_CONFIGS = ApiRequestAdapter.create("/partner-configs", "POST");
const UPDATE_PARTNER_CONFIGS = ApiRequestAdapter.create("/partner-configs", "PUT");
const DELETE_PARTNER_CONFIGS = ApiRequestAdapter.create("/partner-configs/delete", "POST");

const GET_PARTNER_SSO = ApiRequestAdapter.create("/partner-sso", "PUT");
const LOGOUT_FROM_PARTNER_SSO = ApiRequestAdapter.create("/partner-sso/logout", "PUT");
const MONLY_AUTHORIZATION_CODE_GRANT = ApiRequestAdapter.create("/partner-sso/monly-auth-code", "PUT");

const GET_AUTH_REDIRECT_URL = ApiRequestAdapter.create("/partner-sso-oauth/auth-code-url", "GET");

export const requests = {
  GET_PARTNER_CONFIGS_AND_SSO_PROPS,
  CREATE_PARTNER_CONFIGS,
  UPDATE_PARTNER_CONFIGS,
  DELETE_PARTNER_CONFIGS,
  GET_PARTNER_SSO,
  LOGOUT_FROM_PARTNER_SSO,
  MONLY_AUTHORIZATION_CODE_GRANT,
  GET_AUTH_REDIRECT_URL
};

const api = {
  getPartnerConfigsAndSsoProps: (): AxiosPromise<PartnerConfigsAndSsoProperties> => {
    return apiService.get(GET_PARTNER_CONFIGS_AND_SSO_PROPS.url);
  },
  createPartnerConfigs: (request: CreateUpdateMultiplePartnerConfigs): AxiosPromise<PartnerConfig[]> => {
    return apiService.post(CREATE_PARTNER_CONFIGS.url, request);
  },
  updatePartnerConfigs: (request: CreateUpdateMultiplePartnerConfigs): AxiosPromise<PartnerConfig[]> => {
    return apiService.put(UPDATE_PARTNER_CONFIGS.url, request);
  },
  deletePartnerConfigs: (request: UUID[]): AxiosPromise => {
    return apiService.post(DELETE_PARTNER_CONFIGS.url, request);
  },
  getPartnerSso: (request: RequestPartnerSso): AxiosPromise<PartnerSso> => {
    return apiService.put(GET_PARTNER_SSO.url, request);
  },
  logoutFromPartnerSso: (request: LogoutFromPartnerSso): AxiosPromise => {
    return apiService.put(LOGOUT_FROM_PARTNER_SSO.url, request);
  },
  monlyAuthorizationCodeGrant: (code: string): AxiosPromise<PartnerSso> => {
    return apiService.put(MONLY_AUTHORIZATION_CODE_GRANT.url, null, {
      params: { code }
    });
  },
  getAuthRedirectUrl: (request: RequestPartnerSsoAuthRedirectUrl): AxiosPromise<string> => {
    return apiService.get(GET_AUTH_REDIRECT_URL.url, { params: request });
  }
};

export default api;
