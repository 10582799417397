import { useState } from "react";
import { useTranslation } from "react-i18next";
import { SecondaryButton } from "../../../../../common/components/buttons/SecondaryButton";
import AntIcon from "../../../../../common/components/icons/AntIcon";
import type { CalcResult, CalcResultData } from "../../types";

type RecommendationType<T extends CalcResultData> = {
  item: CalcResult<T>;
  handleItemRecommendation: (itemId: string, activation: boolean) => void;
  disabled?: boolean;
};

export const RecommendationButton = <T extends CalcResultData>({
  item,
  handleItemRecommendation
}: RecommendationType<T>) => {
  const { t } = useTranslation();
  const [hoverRecommend, setHoverRecommend] = useState(false);

  const getText = () => {
    if (item.recommended) {
      return hoverRecommend ? t("calc.actions.cancelRecommendation") : t("calc.helpers.recommended");
    }

    return t("calc.actions.recommend");
  };

  return (
    <SecondaryButton
      disabled={!!item.error || !item.active}
      color={item.recommended ? "primary" : "default"}
      type="default"
      variant="outlined"
      onMouseLeave={() => setHoverRecommend(false)}
      onMouseOver={() => (!!item.error || !item.active ? undefined : setHoverRecommend(true))}
      shape="round"
      icon={
        <AntIcon
          size={hoverRecommend || item.recommended ? "huge" : undefined}
          type={item.recommended && hoverRecommend ? "close" : "like"}
        />
      }
      iconPosition="end"
      onClick={() => handleItemRecommendation(item.id, !item.recommended)}
    >
      {getText()}
    </SecondaryButton>
  );
};
