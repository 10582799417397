import { all } from "redux-saga/effects";
import { affiliatePartnerSaga } from "../../modules/affiliate/ducks";
import { agentSaga } from "../../modules/agent/ducks";
import { authSaga } from "../../modules/auth/ducks";
import { bugReportSaga } from "../../modules/bugreport/ducks";
import { calculatorSaga } from "../../modules/calculator/ducks";
import { clientSaga } from "../../modules/client/ducks";
import { commissionsSaga } from "../../modules/commissions/ducks";
import { complicitySaga } from "../../modules/complicity/ducks";
import { contractSaga } from "../../modules/contract/ducks";
import { coverageLimitSaga } from "../../modules/coveragelimit/ducks";
import { dashboardSaga } from "../../modules/dashboard/ducks";
import { documentNodeSaga } from "../../modules/documents/ducks";
import { configPropertySaga } from "../../modules/dynamicconfig/ducks";
import { enumerationsSaga } from "../../modules/enumerations/ducks";
import { financialMediationSaga } from "../../modules/financialmediation/ducks";
import { globalSearchSaga } from "../../modules/globalsearch/ducks";
import { institutionSaga } from "../../modules/institution/ducks";
import { jobSaga } from "../../modules/jobs/ducks";
import { lifeInsuranceTariffSaga } from "../../modules/lifeinsurancetariff/ducks";
import { notificationSaga } from "../../modules/notifications/ducks";
import { partnerSaga } from "../../modules/partner/ducks";
import { productSaga } from "../../modules/product/ducks";
import { userSaga } from "../../modules/user/ducks";
import { vehicleSaga } from "../../modules/vehicle/ducks";

export default function* rootSaga() {
  yield all([
    affiliatePartnerSaga(),
    agentSaga(),
    authSaga(),
    bugReportSaga(),
    calculatorSaga(),
    clientSaga(),
    commissionsSaga(),
    complicitySaga(),
    configPropertySaga(),
    contractSaga(),
    coverageLimitSaga(),
    dashboardSaga(),
    documentNodeSaga(),
    enumerationsSaga(),
    financialMediationSaga(),
    globalSearchSaga(),
    institutionSaga(),
    jobSaga(),
    lifeInsuranceTariffSaga(),
    notificationSaga(),
    partnerSaga(),
    productSaga(),
    userSaga(),
    vehicleSaga()
  ]);
}
