import { keepPreviousData, useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import api from "../../../modules/agent/api";
import {
  AgentEducationFilterPageRequest,
  CreateUpdateAgentCompletedEducation,
  CreateUpdateAgentEducation
} from "../../../modules/agent/types";
import { UUID } from "../../../typings/global";
import { EntityObject, PageRequest, TwoLevelEntityIdObject, TwoLevelEntityObject } from "../../types";
import messageUtils from "../../utils/messageUtils";

export const AGENT_EDUCATION_QUERY_KEYS = {
  filterAgentEducations: (filter?: AgentEducationFilterPageRequest) =>
    filter ? ["filterAgentEducations", filter] : ["filterAgentEducations"],
  createAgentEducation: () => ["createAgentEducation"],
  updateAgentEducation: () => ["updateAgentEducation"],
  deleteAgentEducation: () => ["deleteAgentEducation"],
  getAgentCompletedEducation: (request?: EntityObject<PageRequest>) =>
    request ? ["getAgentCompletedEducation", request] : ["getAgentCompletedEducation"],
  createAgentCompletedEducation: () => ["createAgentCompletedEducation"],
  updateAgentCompletedEducation: () => ["updateAgentCompletedEducation"],
  deleteAgentCompletedEducation: () => ["deleteAgentCompletedEducation"]
};

export const useFilterAgentEducations = (payload: AgentEducationFilterPageRequest) => {
  return useQuery({
    queryKey: AGENT_EDUCATION_QUERY_KEYS.filterAgentEducations(payload),
    queryFn: async () => {
      const { data } = await api.filterAgentEducations(payload);

      return data;
    },
    placeholderData: keepPreviousData
  });
};

export const useCreateAgentEducation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: AGENT_EDUCATION_QUERY_KEYS.createAgentEducation(),
    mutationFn: async (payload: CreateUpdateAgentEducation) => {
      const { data } = await api.createAgentEducation(payload);

      return data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: AGENT_EDUCATION_QUERY_KEYS.filterAgentEducations() });
      messageUtils.itemCreatedNotification();
    }
  });
};

export const useUpdateAgentEducation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: AGENT_EDUCATION_QUERY_KEYS.updateAgentEducation(),
    mutationFn: async (payload: EntityObject<CreateUpdateAgentEducation>) => {
      const { data } = await api.updateAgentEducation(payload);

      return data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: AGENT_EDUCATION_QUERY_KEYS.filterAgentEducations() });
      messageUtils.itemUpdatedNotification();
    }
  });
};

export const useDeleteAgentEducation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: AGENT_EDUCATION_QUERY_KEYS.deleteAgentEducation(),
    mutationFn: async (id: UUID) => {
      return api.deleteAgentEducation({ id });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: AGENT_EDUCATION_QUERY_KEYS.filterAgentEducations() });
      messageUtils.itemDeletedNotification();
    }
  });
};

export const useGetAgentCompletedEducations = (payload: EntityObject<PageRequest>) => {
  return useQuery({
    queryKey: AGENT_EDUCATION_QUERY_KEYS.getAgentCompletedEducation(payload),
    queryFn: async () => {
      const { data } = await api.getAgentCompletedEducations(payload);

      return data;
    }
  });
};

export const useCreateAgentCompletedEducation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: AGENT_EDUCATION_QUERY_KEYS.createAgentCompletedEducation(),
    mutationFn: async (payload: EntityObject<CreateUpdateAgentCompletedEducation>) => {
      const { data } = await api.createAgentCompletedEducation(payload);

      return data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: AGENT_EDUCATION_QUERY_KEYS.getAgentCompletedEducation() });
      messageUtils.itemCreatedNotification();
    }
  });
};

export const useUpdateAgentCompletedEducation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: AGENT_EDUCATION_QUERY_KEYS.updateAgentCompletedEducation(),
    mutationFn: async (payload: TwoLevelEntityObject<CreateUpdateAgentCompletedEducation>) => {
      const { data } = await api.updateAgentCompletedEducation(payload);

      return data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: AGENT_EDUCATION_QUERY_KEYS.getAgentCompletedEducation() });
      messageUtils.itemUpdatedNotification();
    }
  });
};

export const useDeleteAgentCompletedEducation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: AGENT_EDUCATION_QUERY_KEYS.deleteAgentCompletedEducation(),
    mutationFn: async (payload: TwoLevelEntityIdObject) => {
      return api.deleteAgentCompletedEducation(payload);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: AGENT_EDUCATION_QUERY_KEYS.getAgentCompletedEducation() });
      messageUtils.itemDeletedNotification();
    }
  });
};
