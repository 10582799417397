import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import React from "react";
import { ErrorResponse } from "../../types";
import { SortableItemContext } from "./SortableItemContext";

type Props = {
  id: string;
  children: React.JSX.Element;
  error?: ErrorResponse;
  disabled?: boolean;
};

export const SortableItem = ({ id, children, error, disabled }: Props) => {
  const { attributes, listeners, setNodeRef, transform, transition, setActivatorNodeRef } = useSortable({
    id,
    disabled: !!disabled || !!error
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition
  };

  return (
    <SortableItemContext.Provider value={{ draggableElementProps: { ref: setActivatorNodeRef, ...listeners } }}>
      <div ref={setNodeRef} style={style} {...attributes}>
        {children}
      </div>
    </SortableItemContext.Provider>
  );
};
