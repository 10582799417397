import { Button } from "antd";
import { useTranslation } from "react-i18next";
import AntIcon from "../../../../common/components/icons/AntIcon";
import ComponentWithPermission from "../../../../common/security/authorization/ComponentWithPermission";
import { Permission } from "../../../../common/security/authorization/enums";

interface Props {
  onCalculate?: VoidFunction;
  onGenerateContract?: VoidFunction;
  onResetCalculatorClick?: VoidFunction;
  onGoToPrevious?: VoidFunction;
  requiredPermission?: Permission;
  goToCalcResults?: VoidFunction;
}

export const CalcNavigation = ({
  onCalculate,
  onGenerateContract,
  onResetCalculatorClick,
  onGoToPrevious,
  requiredPermission,
  goToCalcResults
}: Props) => {
  const { t } = useTranslation();

  return (
    <div className="bottom-fixed-navigation">
      <div style={{ flex: 1 }}>
        {onResetCalculatorClick ? (
          <Button icon={<AntIcon type="reload" />} onClick={onResetCalculatorClick}>
            {t("common.resetCalculator")}
          </Button>
        ) : undefined}

        {onGoToPrevious ? (
          <Button
            icon={<AntIcon type="arrow-left" />}
            onClick={event => {
              event.currentTarget.blur();
              onGoToPrevious();
            }}
          >
            {t("common.back")}
          </Button>
        ) : undefined}
      </div>

      <div style={{ flex: 1, textAlign: "center" }}>
        {onCalculate ? (
          <Button
            icon={<AntIcon type="calculator" />}
            type="primary"
            size="large"
            onClick={event => {
              event.currentTarget.blur();
              onCalculate();
            }}
          >
            {t("common.calculate")}
          </Button>
        ) : undefined}

        {onGenerateContract ? (
          <ComponentWithPermission permissions={requiredPermission ? [requiredPermission] : []}>
            <Button
              icon={<AntIcon type="calculator" />}
              type="primary"
              size="large"
              onClick={event => {
                event.currentTarget.blur();
                onGenerateContract();
              }}
            >
              {t("calc.actions.generateContract")}
            </Button>
          </ComponentWithPermission>
        ) : undefined}
      </div>

      <div style={{ flex: 1, display: "flex", justifyContent: "end" }}>
        {goToCalcResults ? (
          <Button
            icon={<AntIcon type="arrow-right" />}
            iconPosition="end"
            onClick={event => {
              event.currentTarget.blur();
              goToCalcResults();
            }}
          >
            {t("common.showResults")}
          </Button>
        ) : undefined}
      </div>
    </div>
  );
};
