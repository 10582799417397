import { AxiosPromise } from "axios";
import { ApiRequestAdapter } from "../../../common/api/ApiRequestAdapter";
import { apiService } from "../../../common/api/apiService";
import { EntityObject } from "../../../common/types";
import { serializeParams } from "../../../common/utils/apiUtils";
import {
  DebtAccountMovementFilterPageRequest,
  DebtAccountMovementFilterPageResult,
  DebtAccountsReportFilterPageRequest,
  DebtAccountsReportFilterPageResult
} from "./types";

const GET_DEBT_ACCOUNTS_REPORT = ApiRequestAdapter.create("/debt-accounts-report", "GET");
const FILTER_DEBT_ACCOUNT_MOVEMENTS = ApiRequestAdapter.create("/agents/{0}/debt-account/movements", "GET");

export const requests = { GET_DEBT_ACCOUNTS_REPORT, FILTER_DEBT_ACCOUNT_MOVEMENTS };

const api = {
  getDebtAccountsReport: (
    request: DebtAccountsReportFilterPageRequest
  ): AxiosPromise<DebtAccountsReportFilterPageResult> => {
    return apiService.get(GET_DEBT_ACCOUNTS_REPORT.url, {
      params: request,
      paramsSerializer: { serialize: serializeParams }
    });
  },
  filterDebtAccountMovements: (
    request: EntityObject<DebtAccountMovementFilterPageRequest>
  ): AxiosPromise<DebtAccountMovementFilterPageResult> => {
    return apiService.get(FILTER_DEBT_ACCOUNT_MOVEMENTS.getParametrizedUrl(request.id), { params: request.object });
  }
};

export default api;
