import { Button, Flex, Space, Tag } from "antd";
import { useTranslation } from "react-i18next";
import AntIcon from "../../../../../../../common/components/icons/AntIcon";
import Ellipsis from "../../../../../../../common/components/views/Ellipsis";
import { formatLocaleCurrency } from "../../../../../../../common/utils/formatUtils";
import { cssVariables } from "../../../../../../../common/utils/utils";
import { resolveInstitutionLogo } from "../../../../../../institution/utils";
import { ErrorAlert } from "../../../../components/calc-result/ErrorAlert";
import { RecommendationButton } from "../../../../components/calc-result/RecommendationButton";
import { CalcResult } from "../../../../types";
import { CrashCalcResultData } from "../../../types";

type Props = {
  item: CalcResult<CrashCalcResultData>;
  rotate: VoidFunction;
  handleItemRecommendation: (itemId: string, activation: boolean) => void;
};

export const CrashCalcResultInfo = ({ item, handleItemRecommendation, rotate }: Props) => {
  const { t } = useTranslation();

  return (
    <Flex gap="middle" style={{ height: "100%" }}>
      <div style={{ marginTop: 10 }}>
        <img
          width="100"
          height="60"
          style={{ objectFit: "contain" }}
          src={resolveInstitutionLogo(item.insuranceInstitution)}
          alt="Institution Logo"
        />
      </div>

      <Flex justify="space-between" vertical flex={1} style={{ minWidth: 0 }}>
        <Flex gap="middle">
          <h2 style={{ marginBottom: 12 }}>
            {item.insuranceInstitution.name} {item.coverage}
          </h2>

          <RecommendationButton item={item} handleItemRecommendation={handleItemRecommendation} />
        </Flex>

        <Flex style={{ flexDirection: "column", flexGrow: 1, overflowY: "auto" }}>
          {item.error ? (
            <ErrorAlert item={item} />
          ) : (
            <>
              <b>{t("contract.enums.insuranceCoverageType._label")}</b>

              <Flex style={{ flexDirection: "column", flexGrow: 1, overflowY: "auto" }}>
                <Flex>
                  <div style={{ flex: 1 }}>
                    <Flex justify="space-between">
                      <span>{t("calc.vehicle.results.complicity")}</span>
                      <b>{item.data?.complicity ?? "-"}</b>
                    </Flex>

                    <Flex justify="space-between">
                      <span>{t("calc.vehicle.results.vehiclePrice")}</span>
                      <b>{formatLocaleCurrency(item.data?.vehiclePrice) ?? "-"}</b>
                    </Flex>

                    <Flex justify="space-between">
                      <span>{t("calc.vehicle.results.totalDamage")}</span>
                      <b>{item.data?.totalDamage ? `${item.data.totalDamage}%` : "-"}</b>
                    </Flex>
                  </div>
                  <div
                    style={{
                      width: 1,
                      height: "100%",
                      backgroundColor: cssVariables.colorShadow,
                      marginLeft: 16,
                      marginRight: 16
                    }}
                  />
                  <div style={{ flex: 1 }}>
                    <Flex justify="space-between">
                      <span>{t("calc.vehicle.enums.territorialValidity._label")}</span>
                      <b>
                        {item.data?.territorialValidity
                          ? t("calc.vehicle.enums.territorialValidity." + item.data.territorialValidity)
                          : "-"}
                      </b>
                    </Flex>
                  </div>
                </Flex>

                {item.data?.warnings.length ? (
                  <Tag
                    style={{ minWidth: 0, display: "block", paddingRight: 24, marginTop: 8, marginRight: 0 }}
                    icon={<AntIcon type="exclamation" />}
                    color="warning"
                  >
                    <Ellipsis style={{ color: cssVariables.colorYellow }}>{item.data?.warnings.join(" ")}</Ellipsis>
                  </Tag>
                ) : undefined}
              </Flex>
            </>
          )}
        </Flex>

        <Space.Compact block>
          <Button style={{ flex: 1 }} disabled={!!item.error} onClick={rotate}>
            {t("calc.travel.results.additionalInfo")}
          </Button>
          <Button style={{ flex: 1 }} disabled={!!item.error} onClick={rotate}>
            {t("common.documents")}
          </Button>
        </Space.Compact>
      </Flex>
    </Flex>
  );
};
