import { isDefined } from "./utils";

export enum FilterQueryKeys {
  PAGE_INDEX = "pageIndex",
  PAGE_SIZE = "pageSize",
  KEYWORD = "keyword"
}

export enum QueryKeys {
  RECORD_ID = "recordId",
  DRAFT_ID = "draftId",
  CONTRACT_ID = "contractId",
  CONTRACT_NUMBER = "contractNumber",
  TAB = "tab",
  SIGNER_IDS = "signerIds",
  INSTITUTION_IDS = "institutionIds",
  EFFECTIVE_BEGINNING_DATE_OR_SIGN_DATE_MIN = "effectiveBeginningDateOrSignDateMin",
  EFFECTIVE_BEGINNING_DATE_OR_SIGN_DATE_MAX = "effectiveBeginningDateOrSignDateMax"
}

class URLQueryImpl {
  public copy(searchParams: URLSearchParams): URLSearchParams {
    return new URLSearchParams(searchParams.toString());
  }

  public toObject(searchParams: URLSearchParams): Record<string, string> {
    return Object.fromEntries(searchParams.entries());
  }

  public merge(target: URLSearchParams, source: URLSearchParams): URLSearchParams {
    const copy = this.copy(target);

    source.forEach((value, key) => {
      copy.append(key, value);
    });

    return copy;
  }

  public searchParamsToString({
    searchParams,
    append
  }: {
    searchParams: Record<string, string | number | boolean | undefined | string[] | number[]>;
    append?: boolean;
  }): string {
    const resultParams = new URLSearchParams(append ? window.location.search : undefined);

    Object.entries(searchParams).forEach(([searchParamKey, searchParamValue]) => {
      if (!isDefined(searchParamValue) || resultParams.has(searchParamKey)) {
        resultParams.delete(searchParamKey);
      }

      if (Array.isArray(searchParamValue)) {
        searchParamValue.forEach(value => {
          resultParams.append(searchParamKey, value.toString());
        });
      } else if (searchParamValue) {
        resultParams.append(searchParamKey, searchParamValue.toString());
      }
    });

    const prefix = [...resultParams.keys()].length ? "?" : "";
    return `${prefix}${resultParams.toString()}`;
  }
}

export const URLQuery = new URLQueryImpl();
