import { Flex } from "antd";
import styles from "./LeftCard.module.scss";

type Props = {
  description: string | React.ReactNode;
  children: React.ReactNode;
};

export const LeftCard = ({ description, children }: Props) => {
  return (
    <Flex className={styles.box}>
      <Flex align="center" justify="center" className={styles.description}>
        {description}
      </Flex>

      <div className={styles.children}>{children}</div>
    </Flex>
  );
};
