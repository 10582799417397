import { AxiosPromise } from "axios";
import { ApiRequestAdapter } from "../../../common/api/ApiRequestAdapter";
import { apiService } from "../../../common/api/apiService";
import { PointsReportFilterExportRequest, PointsReportFilterRequest, PointsReportFilterResult } from "./types";

const FILTER_POINTS_REPORT = ApiRequestAdapter.create("/points-report", "POST");
const DOWNLOAD_POINTS_REPORT_EXPORT = ApiRequestAdapter.create("/points-report/export", "POST");

export const requests = {
  FILTER_POINTS_REPORT,
  DOWNLOAD_POINTS_REPORT_EXPORT
};

const api = {
  filterPointsReport: (request: PointsReportFilterRequest): AxiosPromise<PointsReportFilterResult> => {
    return apiService.post(FILTER_POINTS_REPORT.url, request);
  },
  downloadPointsReportExport: (request: PointsReportFilterExportRequest): AxiosPromise<Blob> => {
    return apiService.post(DOWNLOAD_POINTS_REPORT_EXPORT.url, request, {
      responseType: "blob"
    });
  }
};

export default api;
